export const CREATE_STAFF_MODAL = 'CREATE_STAFF_MODAL';
export const CLOSE_FAIL_MODAL = 'CLOSE_FAIL_MODAL';
export const POSTPONE_MODAL = 'POSTPONE_MODAL';
export const CONFRIM_MODAL = 'CONFRIM_MODAL';
export const DECLINE_MODAL = 'DECLINE_MODAL';
export const FAIL_MODAL = 'FAIL_MODAL';
export const CANCEL_TASK_MODAL = 'CANCEL_TASK_MODAL';
export const EDIT_TASK_MODAL = 'EDIT_TASK_MODAL';
export const REQUEST_SIGN_MODAL = 'REQUEST_SIGN_MODAL';
export const ASSIGN_DRIVER_MODAL = 'ASSIGN_DRIVER_MODAL';
export const ENHANCED_ASSIGN_DRIVER_MODAL = 'ENHANCED_ASSIGN_DRIVER_MODAL';
export const COMPLETE_MODAL = 'COMPLETE_MODAL';
export const RESCHEDULE_MODAL = 'RESCHEDULE_MODAL';
export const CANCEL_ORDER_MODAL = 'CANCEL_ORDER_MODAL';
export const PENDING_CANCELLATION_ORDER_MODAL = 'PENDING_CANCELLATION_ORDER_MODAL';
export const CANCEL_MULTIPLE_ORDERS_MODAL = 'CANCEL_MULTIPLE_ORDERS_MODAL';
export const ENHANCED_COMMENTS_MODAL = 'ENHANCED_COMMENTS_MODAL';
export const EDIT_ORDER_MODAL = 'EDIT_ORDER_MODAL';
export const PAID_ORDER_MODAL = 'PAID_ORDER_MODAL';
export const PAY_CUSTOMER_ORDERS_MODAL = 'PAY_CUSTOMER_ORDERS_MODAL';
export const NEW_CONTACT_MODAL = 'NEW_CONTACT_MODAL';
export const EDIT_CONTACT_MODAL = 'EDIT_CONTACT_MODAL';
export const DELETE_CONTACT_MODAL = 'DELETE_CONTACT_MODAL';
export const EDIT_RECIPIENT_MODAL = 'EDIT_RECIPIENT_MODAL';
export const DELETE_LOCATION_LOG_MODAL = 'DELETE_LOCATION_LOG_MODAL';
export const DELETE_LOCATION_MODAL = 'DELETE_LOCATION_MODAL';
export const CHECK_IN_MODAL = 'CHECK_IN_MODAL';
export const CHECK_OUT_MODAL = 'CHECK_OUT_MODAL';
export const PASSWORD_RESET_MODAL = 'PASSWORD_RESET_MODAL';
export const EMAIL_RESEND_MODAL = 'EMAIL_RESEND_MODAL';
export const CONFIRM_TIME_SHEET = 'CONFIRM_TIME_SHEET';
export const NEW_LOCATION = 'NEW_LOCATION';
export const NEW_RECEIVER = 'NEW_RECEIVER';
export const UNASSIGN_DRIVER_MODAL = 'UNASSIGN_DRIVER_MODAL';
export const CONFIRM_SUBMISSION = 'CONFIRM_SUBMISSION';
export const DECLINE_SUBMISSION = 'DECLINE_SUBMISSION';
export const EDIT_PRICE = 'EDIT_PRICE';
export const DELETE_PRICE = 'DELETE_PRICE';
export const NEW_PRICE = 'NEW_PRICE';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const EDIT_PHONE_NBR = 'EDIT_PHONE_NBR';
export const CREATE_BULK_ORDER_MODAL = 'CREATE_BULK_ORDER_MODAL';
export const IMPORT_ORDERS_MODAL = 'IMPORT_ORDERS_MODAL';
export const IMPORT_TASKS_MODAL = 'IMPORT_TASKS_MODAL';
export const EDIT_STAFF_MODAL = 'EDIT_STAFF_MODAL';
export const DETAILS_TASKS = 'DETAILS_TASKS';
export const EDIT_PIGGY_BANK_MODAL = 'EDIT_PIGGY_BANK_MODAL';
export const ADD_PIGGY_BANK_NOTE_MODAL = 'ADD_PIGGY_BANK_NOTE_MODAL';
export const EDIT_GOLDEN_RULE = 'EDIT_GOLDEN_RULE';
export const EDIT_SHOP_NOTE = 'EDIT_SHOP_NOTE';
export const VIEW_SHOP_NOTE = 'VIEW_SHOP_NOTE';
export const VIEW_EXTRA_TAGS = 'VIEW_EXTRA_TAGS';
export const EDIT_CURRENCY_MODAL = 'EDIT_CURRENCY_MODAL';
export const DEFAULT_CURRENCY_MODAL = 'DEFAULT_CURRENCY_MODAL';
export const PREFERRED_ALTERNATIVE_CURRENCY_MODAL =
  'PREFERRED_ALTERNATIVE_CURRENCY_ MODAL';
export const DELETE_CURRENCY_MODAL = 'DELETE_CURRENCY_MODAL';
export const CREATE_CURRENCY_MODAL = 'CREATE_CURRENCY_MODAL';
export const LINK_CURRENCY_MODAL = 'LINK_CURRENCY_MODAL';
export const EDIT_INHOUSE_NOTE = 'EDIT_INHOUSE_NOTE';
export const CREATE_APP_MODAL = 'CREATE_APP_MODAL';
export const EDIT_APP_MODAL = 'EDIT_APP_MODAL';
export const DELETE_APP_MODAL = 'DELETE_APP_MODAL';
export const CREATE_TOKEN_MODAL = 'CREATE_TOKEN_MODAL';
export const DELETE_TOKEN_MODAL = 'DELETE_TOKEN_MODAL';
export const EDIT_TOKEN_MODAL = 'EDIT_TOKEN_MODAL';
export const DELETE_WEBHOOK_MODAL = 'DELETE_WEBHOOK_MODAL';
export const EDIT_WEBHOOK_MODAL = 'EDIT_WEBHOOK_MODAL';
export const CREATE_WEBHOOK_MODAL = 'CREATE_WEBHOOK_MODAL';
export const LINK_CONFIGURATION = 'LINK_CONFIGURATION';
export const EDIT_CONFIGURATION = 'EDIT_CONFIGURATION';
export const EDIT_CUSTOM_MESSAGE = 'EDIT_CUSTOM_MESSAGE';
export const EDIT_AREA_MODAL = 'EDIT_AREA_MODAL';
export const DELETE_AREA_MODAL = 'DELETE_AREA_MODAL';
export const CREATE_AREA_MODAL = 'CREATE_AREA_MODAL';
export const SHOW_DISTRICT_MODAL = 'SHOW_DISTRICT_MODAL';
export const CREATE_DISTRICT_MODAL = 'CREATE_DISTRICT_MODAL';
export const EDIT_DISTRICT_MODAL = 'EDIT_DISTRICT_MODAL';
export const DELETE_DISTRICT_MODAL = 'DELETE_DISTRICT_MODAL';
export const SHOW_GOVERNORATE_MODAL = 'SHOW_GOVERNORATE_MODAL';
export const CREATE_GOVERNORATE_MODAL = 'CREATE_GOVERNORATE_MODAL';
export const EDIT_GOVERNORATE_MODAL = 'EDIT_GOVERNORATE_MODAL';
export const DELETE_GOVERNORATE_MODAL = 'DELETE_GOVERNORATE_MODAL';
export const REOPEN_ORDER = 'REOPEN_ORDER';
export const SUBMISSIONS_DETAILS_TASKS = 'SUBMISSIONS_DETAILS_TASKS';
export const UPDATE_PENDING_OBJECT = 'UPDATE_PENDING_OBJECT';
export const EDIT_STOCK_MODAL = 'EDIT_STOCK_MODAL';
export const DELETE_STOCK_MODAL = 'DELETE_STOCK_MODAL';
export const CREATE_STOCK_MODAL = 'CREATE_STOCK_MODAL';
export const ENHANCED_EDIT_ORDER_MODAL = 'ENHANCED_EDIT_ORDER_MODAL';
export const EDIT_VEHICLE_MODAL = 'EDIT_VEHICLE_MODAL';
export const DELETE_VEHICLE_MODAL = 'DELETE_VEHICLE_MODAL';
export const CREATE_VEHICLE_MODAL = 'CREATE_VEHICLE_MODAL';
export const EDIT_CUSTROMER_MODAL = 'EDIT_CUSTROMER_MODAL';
export const CUSTOMIZE_WAYBILL_MODAL = 'CUSTOMIZE_WAYBILL_MODAL';
export const VIEW_ZONE_MODAL = 'VIEW_ZONE_MODAL';
export const EDIT_ZONE_MODAL = 'EDIT_ZONE_MODAL';
export const DELETE_ZONE_MODAL = 'DELETE_ZONE_MODAL';
export const CREATE_ZONE_MODAL = 'CREATE_ZONE_MODAL';
export const MULTIPLE_DECLINE_ORDERS = 'MULTIPLE_DECLINE_ORDERS';
export const EDIT_OFFICE_MODAL = 'EDIT_OFFICE_MODAL';
export const EDIT_ACCOUNTANT_ORDER_MODAL = 'EDIT_ACCOUNTANT_ORDER_MODAL';
export const DELETE_OFFICE_MODAL = 'DELETE_OFFICE_MODAL';
export const CREATE_OFFICE_MODAL = 'CREATE_OFFICE_MODAL';
export const SET_DEFUALT_WAYBILL_ADDRESS_MODAL =
  'SET_DEFUALT_WAYBILL_ADDRESS_MODAL';
export const CLOSE_PIGGY_BANK_MODAL = 'CLOSE_PIGGY_BANK_MODAL';
export const IMPORTANT_COMMENT_MODAL = 'IMPORTANT_COMMENT_MODAL';
export const BARCODE_CONFIRMATION_MODAL = 'BARCODE_CONFIRMATION_MODAL';
export const ADD_REVIEW_MODAL = 'ADD_REVIEW_MODAL';
// export const UPDATE_TASK_OBJECT = 'UPDATE_TASK_OBJECT';
export const CREATE_EXISTING_RECIPIENT_LOCATION_MODAL =
  'CREATE_EXISTING_RECIPIENT_LOCATION_MODAL';

export const EDIT_TYPE_MODAL = 'EDIT_TYPE_MODAL';
export const CREATE_TYPE_MODAL = 'CREATE_TYPE_MODAL';

export const SHIP_ORDER = 'SHIP_ORDER';

export const REVIEW_FEEDBACK = 'REVIEW_FEEDBACK';

export const ORDER_REPORT_FIELDS = 'ORDER_REPORT_FIELDS';

export const ACTIVATE_DEACTIVATE_DRIVER_MODAL =
  'ACTIVATE_DEACTIVATE_DRIVER_MODAL';
export const ACTIVATE_DEACTIVATE_CUSTOMER_MODAL =
  'ACTIVATE_DEACTIVATE_CUSTOMER_MODAL';
export const ACTIVATE_DEACTIVATE_LOCATION_MODAL =
  'ACTIVATE_DEACTIVATE_LOCATION_MODAL';
export const ACTIVATE_DEACTIVATE_USER_MODAL = 'ACTIVATE_DEACTIVATE_USER_MODAL';
export const PIGGY_BANK_ORDERS_MODAL = 'PIGGY_BANK_ORDERS_MODAL';
export const IS_CRITICAL_MODAL = 'IS_CRITICAL_MODAL';
export const MAKE_DEFAULT_LOCATION_MODAL = 'MAKE_DEFAULT_LOCATION_MODAL';
export const EDIT_CUSTROMER_ACCOUNT_MANAGER_MODAL =
  'EDIT_CUSTROMER_ACCOUNT_MANAGER_MODAL';
export const EDIT_MULTIPLE_CUSTROMERS_ACCOUNT_MANAGER_MODAL =
  'EDIT_MULTIPLE_CUSTROMERS_ACCOUNT_MANAGER_MODAL';
export const EDIT_CUSTROMER_INDUSTRY_MODAL = 'EDIT_CUSTROMER_INDUSTRY_MODAL';

// Barcodes
export const GENERATE_AND_PRINT_BARCODES = 'GENERATE_AND_PRINT_BARCODES';
export const CREATE_DELIVERY_ORDER_FOR_BARCODE_MODAL =
  'CREATE_DELIVERY_ORDER_FOR_BARCODE_MODAL';
export const DELETE_BARCODE_MODAL = 'DELETE_BARCODE_MODAL';
export const ATTACH_BARCODE_TO_EXISTING_ORDER =
  'ATTACH_BARCODE_TO_EXISTING_ORDER';
export const CREATE_RETURN_DELIVERY_ORDER_FOR_BARCODE_MODAL =
  'CREATE_RETURN_DELIVERY_ORDER_FOR_BARCODE_MODAL';

// Orders
export const SET_PACKAGE_WITH_DRIVER_MODAL = 'SET_PACKAGE_WITH_DRIVER_MODAL';
export const DISPLAY_ORDER_PICTURE_MODAL = 'DISPLAY_ORDER_PICTURE_MODAL';

// Master Barcodes
export const GENERATE_AND_PRINT_MASTER_BARCODES =
  'GENERATE_AND_PRINT_MASTER_BARCODES';
export const DELETE_MASTER_BARCODE_MODAL = 'DELETE_MASTER_BARCODE_MODAL';
export const MOVE_ORDERS_FROM_MASTER_BARCODE = 'MOVE_ORDERS_FROM_MASTER_BARCODE';

// Services
export const CREATE_SERVICE_MODAL = 'CREATE_SERVICE_MODAL';
export const EDIT_SERVICE_MODAL = 'EDIT_SERVICE_MODAL';
export const DELETE_SERVICE_MODAL = 'DELETE_SERVICE_MODAL';

export const APPROVE_OR_REJECT_REQUEST_MODAL =
  'APPROVE_OR_REJECT_REQUEST_MODAL';

export const DELETE_REQUEST_MODAL = 'DELETE_REQUEST_MODAL';

// Services

export const CREATE_BANNER_MODAL = "CREATE_BANNER_MODAL";
export const EDIT_BANNER_MODAL = "EDIT_BANNER_MODAL";
export const DELETE_BANNER_MODAL = "DELETE_BANNER_MODAL";
export const NOTIFICATION_BANNER_MODAL = "NOTIFICATION_BANNER_MODAL";
// Marketplace
export const UPGRADE_TO_MARKETPLACE_SELLER_MODAL =
  "UPGRADE_TO_MARKETPLACE_SELLER_MODAL";

// internal banner
export const CREATE_INTERNAL_BANNER_MODAL = 'CREATE_INTERNAL_BANNER_MODAL';
export const EDIT_INTERNAL_BANNER_MODAL = 'EDIT_INTERNAL_BANNER_MODAL';
export const DELETE_INTERNAL_BANNER_MODAL = 'DELETE_INTERNAL_BANNER_MODAL';

export const CONFIRM_ORDER_MODAL = 'CONFIRM_ORDER_MODAL';
export const EXPRESS_WARNING_MODAL = 'EXPRESS_WARNING_MODAL';

// Area Route
export const EDIT_AREA_ROUTE_MODAL = 'EDIT_AREA_ROUTE_MODAL'
export const DELETE_AREA_ROUTE_MODAL = 'DELETE_AREA_ROUTE_MODAL'
export const CREATE_AREA_ROUTE_MODAL = 'CREATE_AREA_ROUTE_MODAL'

//Assign Area To Driver
export const ASSIGN_AREA_TO_DRIVER = 'ASSIGN_AREA_TO_DRIVER'
export const EDIT_ROUTE_AREA = 'EDIT_ROUTE_AREA'

export const CREATE_BULK_RETURN_MODAL = 'CREATE_BULK_RETURN_MODAL'
export const CREATE_CASHBOX_ORDER_MODAL = 'CREATE_CASHBOX_ORDER_MODAL'
export const CREATE_CASHBOX_SETTLEMENT = 'CREATE_CASHBOX_SETTLEMENT'
export const ADD_CASHBOX_EXTRA_FEES_MODAL = 'ADD_CASHBOX_EXTRA_FEES_MODAL'
export const UPDATE_CASHBOX_EXTRA_FEE_MODAL = 'UPDATE_CASHBOX_EXTRA_FEE_MODAL'

export const CANCEL_CASHBOX_MODAL = 'CANCEL_CASHBOX_MODAL'
//Add Excluded Client
export const ADD_EXCLUDED_CLIENT = 'ADD_EXCLUDED_CLIENT'
export const CANCEL_MULTIPLE_CASHBOXES_MODAL = 'CANCEL_MULTIPLE_CASHBOXES_MODAL'
export const CREATE_MULTIPLE_CASHBOX_ORDERS_MODAL = 'CREATE_MULTIPLE_CASHBOX_ORDERS_MODAL'
export const ADD_CASHBOX_MODAL = 'ADD_CASHBOX_MODAL'
export const EDIT_AUDITED_ORDER_COLLECTIONS_MODAL = 'EDIT_AUDITED_ORDER_COLLECTIONS_MODAL'
export const SAVE_AUDITED_ORDER_COLLECTIONS_MODAL = 'SAVE_AUDITED_ORDER_COLLECTIONS_MODAL'
export const CANCEL_AUDITED_ORDER_COLLECTIONS_MODAL = 'CANCEL_AUDITED_ORDER_COLLECTIONS_MODAL'
export const DELETE_HOLIDAY_MODAL = 'DELETE_HOLIDAY_MODAL';
export const CREATE_HOLIDAY_MODAL = 'CREATE_HOLIDAY_MODAL';
export const EDIT_HOLIDAY_MODAL = 'EDIT_HOLIDAY_MODAL';
export const EDIT_ORDER_DELIVERY_PRICE_MODAL = 'EDIT_ORDER_DELIVERY_PRICE_MODAL'

//International orders items modal
export const CREATE_EDIT_INTERNATIONAL_PACKAGE_ITEM_MODAL = 'CREATE_EDIT_INTERNATIONAL_PACKAGE_ITEM_MODAL';

// Add Area confirmation required
export const AREA_CONFIRMATION_MODAL = 'AREA_CONFIRMATION_MODAL';