import { FETCH_STORES_RESOLVED } from "../ActionTypes/store";
import _ from "underscore";

const initialState = {
  storeList: []
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORES_RESOLVED:
      return Object.assign({}, state, {
        storeList: action.payload
      });
    default:
      return state;
  }
};

export default storeReducer;
