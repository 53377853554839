import Http from "../utils/Http";
import * as authActions from "../stores/actions/auth";
import * as reviewsActions from "../stores/actions/reviews";
import { serializeSearchInput, serializeInput } from "utils/helpers";

export function submitReview(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("reviews", info.input)
        .then(res => {
          dispatch(reviewsActions.createReviewResolved(res.data));
          dispatch(
            authActions.serverSuccess(
              "Thank you. Your feedback has been received"
            )
          );
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchReviews(
  search = [],
  custom_search = [],
  pageNumber = null,
  orderBy = "created_at",
  sortedBy = "desc"
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `reviews${
        search.length > 0 ? serializeSearchInput(search) : ""
      }${
        custom_search.length > 0
          ? (search.length > 0 ? "&" : "?") + serializeInput(custom_search)
          : ""
      }${(search.length || custom_search.length ? "&" : "?") +
        `orderBy=${orderBy}&sortedBy=${sortedBy}`}`;
      if (pageNumber) {
        url = url + "&page=" + pageNumber;
      }
      Http.get(url)
        .then(res => {
          dispatch(reviewsActions.fetchReviewsResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateReview(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("reviews/" + info.id, info.input)
        .then(res => {
          dispatch(reviewsActions.updateReviewResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function submitFeedback(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("feedback", info.input)
        .then(res => {
          dispatch(
            authActions.serverSuccess(
              "Thank you. Your Feedback has been submitted successfully"
            )
          );
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function exportReviews(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `export/reviews${
        input.length > 0 ? "?" + serializeInput(input) : ""
      }`;
      Http.get(url)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
}
