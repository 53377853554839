import {
  FETCH_LOCATIONS_RESOLVED,
  FETCH_LOCATION_RESOLVED,
  UPDATE_LOCATION_RESOLVED,
  DELETE_LOCATION_LOG_RESOLVED,
  DELETE_LOCATION_RESOLVED,
  CREATE_LOCATION_RESOLVED,
  FETCH_LOCATIONS_PERSONABLES_RESOLVED,
  FETCH_LOCATIONS_NAMES_RESOLVED
} from "../ActionTypes/locations";
import _ from "lodash";

const initialState = {
  locationList: [],
  location: {},
  locationsPersonableList: [],
  locationsNamesList: []
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATIONS_NAMES_RESOLVED:
      return Object.assign({}, state, {
        locationsNamesList: action.payload
      });
    case FETCH_LOCATIONS_PERSONABLES_RESOLVED:
      return Object.assign({}, state, {
        locationsPersonableList: action.payload
      });
    case FETCH_LOCATIONS_RESOLVED:
      return Object.assign({}, state, {
        locationList: action.payload
      });
    case FETCH_LOCATION_RESOLVED:
      return Object.assign({}, state, {
        location: action.payload
      });
    case UPDATE_LOCATION_RESOLVED: {
      let locations = JSON.parse(JSON.stringify(state.locationList));
      locations.forEach((item, index) => {
        if (item.id == action.payload.id) {
          locations[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        locationList: locations,
        location: action.payload
      });
    }

    case DELETE_LOCATION_RESOLVED: {
      let locations = JSON.parse(JSON.stringify(state.locationList));
      let location = _.remove(locations, function(o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        locationList: locations
      });
    }
    case DELETE_LOCATION_LOG_RESOLVED: {
      let location = JSON.parse(JSON.stringify(state.location));
      let location_logs = _.remove(location.locationLogs, function(o) {
        return o.id == action.payload.id;
      });

      return Object.assign({}, state, {
        location
      });
    }
    case CREATE_LOCATION_RESOLVED: {
      let locations = JSON.parse(JSON.stringify(state.locationList));
      locations.push(action.payload);
      return Object.assign({}, state, {
        locationList: locations
      });
    }
    default:
      return state;
  }
};

export default locationReducer;
