import React, { Component } from "react";
import { ReCaptcha } from "react-recaptcha-google";
import APPCONFIG from "constants/Config";
import { connect } from "react-redux";
import * as authActions from "stores/actions/auth";

class ReCaptchaComponent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      token: ""
    };
  }

  componentDidMount() {
    this.onLoadRecaptcha();
  }

  onLoadRecaptcha = () => {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      // this.captchaDemo.execute();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.recaptcha && nextProps.recaptcha != this.props.recaptcha) {
      this.captchaDemo.reset();
    }
  }

  verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    this.setState({ token: recaptchaToken });
    this.props.dispatch(authActions.recaptcha(recaptchaToken));
  };

  render() {
    const grecaptchaObject = window.grecaptcha;

    // Check if the environment is 'development'
    const isDevEnvironment = process.env.DISABLE_RECAPTCHA === "true";

    // Conditionally render ReCaptcha component
    const captchaComponent = isDevEnvironment ? null : (
      <ReCaptcha
        ref={(el) => {
          this.captchaDemo = el;
        }}
        size="normal"
        render="explicit"
        sitekey={APPCONFIG.recaptcha.site_key}
        grecaptcha={grecaptchaObject}
        onloadCallback={this.onLoadRecaptcha}
        verifyCallback={this.verifyCallback}
      />
    );
    return (
      <div>
        {/* You can replace captchaDemo ref with whatever works for your component */}
        {captchaComponent}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    recaptcha: state.auth.recaptcha
  };
};

export default connect(mapStateToProps)(ReCaptchaComponent);
