import * as types from "../ActionTypes/reviews";

export function fetchReviewsResolved(payload) {
  return {
    type: types.FETCH_REVIEWS_RESOLVED,
    payload
  };
}

export function createReviewResolved(payload) {
  return {
    type: types.CREATE_REVIEW_RESOLVED,
    payload
  };
}

export function updateReviewResolved(payload) {
  return {
    type: types.UPDATE_REVIEW_RESOLVED,
    payload
  };
}
