/* import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { compose, withProps, lifecycle } from "recompose";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { geolocated } from "react-geolocated";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";

const PinLocation = compose(
  withProps(props => {
    return {
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyD0rokHYZxa0lT94aoKtSgbNXofpR14cZA&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: (
        <div style={{ height: props.height ? props.height : `50vh` }} />
      ),
      mapElement: <div style={{ height: `100%` }} />
    };
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        draggable: true,
        bounds: null,
        center: { lat: 33.8892171, lng: 35.4867727 },
        markers:
          this.props.coordinates.lat && this.props.coordinates.lng
            ? [
                {
                  position: this.props.coordinates
                }
              ]
            : [],
        search: "",
        onMapMounted: ref => {
          refs.map = ref;
        },
        onMapClick: e => {
          var location = e.latLng;
          this.props.handleChangeMarker({
            lat: location.lat(),
            lng: location.lng()
          });
        },
        onIdle: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter()
          });
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onMarkerMounted: ref => {
          refs.marker = ref;
        },
        onDragEnd: () => {
          var location = refs.marker.getPosition();
          this.props.handleChangeMarker({
            lat: location.lat(),
            lng: location.lng()
          });
          refs.map.panTo(location);
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new google.maps.LatLngBounds();
          if (places.length != 1) {
            // todo: send error message
          } else {
            places.forEach(place => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            var location = places[0].geometry.location;
            this.props.handleChangeMarker({
              lat: location.lat(),
              lng: location.lng()
            });
          }
          refs.map.fitBounds(bounds);
        }
      });
    },
    componentWillReceiveProps(nextProps) {
      if (nextProps.coordinates.lng != 0 && nextProps.coordinates.lat != 0) {
        this.setState({
          center: {
            lat: parseFloat(nextProps.coordinates.lat),
            lng: parseFloat(nextProps.coordinates.lng)
          }
        });
      } else if (nextProps.new && nextProps.coords) {
        this.setState({
          center: {
            lat: nextProps.coords.latitude,
            lng: nextProps.coords.longitude
          }
        });
      }
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={15}
    center={props.center}
    onIdle={props.onIdle}
    onClick={props.onMapClick}
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Pin location by search"
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `32px`,
          marginTop: `9px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`
        }}
      />
    </SearchBox>
    {props.coordinates &&
    props.coordinates.lng != 0 &&
    props.coordinates.lat != 0 ? (
      <Marker
        ref={props.onMarkerMounted}
        position={{
          lat: parseFloat(props.coordinates.lat),
          lng: parseFloat(props.coordinates.lng)
        }}
        draggable={true}
        onDragEnd={props.onDragEnd}
      />
    ) : null}
{    {props.coords &&
    props.coords.longitude != 0 &&
    props.coords.latitude != 0 ? (
      <MarkerWithLabel
        position={{ lat: props.coords.latitude, lng: props.coords.longitude }}
        labelAnchor={new google.maps.Point(0, 0)}
        labelStyle={{ color: "white", paddingLeft: "16px", paddingTop: "15px" }}
        icon={{
          path:
            "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z",
          fillColor: "#ffc107",
          fillOpacity: 1,
          strokeColor: "white",
          strokeWeight: 2,
          scale: 2
        }}
      >
        <div />
      </MarkerWithLabel>
    ) : null}}
  </GoogleMap>
));

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  // watchPosition: true,
  userDecisionTimeout: 5000
})(PinLocation);
// export default PinLocation */


import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, LoadScriptNext, Autocomplete, InfoWindow } from '@react-google-maps/api';
import { geolocated } from 'react-geolocated';

const containerStyle = {
  height: '100%',
};

const PinLocation = (props) => {
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({ lat: 33.8892171, lng: 35.4867727 });
  const [bounds, setBounds] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const mapRef = useRef(null);

  const onLoad = (map) => {
    mapRef.current = map;
    map.addListener('click', (e) => {
      const location = e.latLng;
      props.handleChangeMarker({
        lat: location.lat(),
        lng: location.lng()
      });
    });
  };

  const onBoundsChanged = (map) => {
    if (map) {
      setBounds(map.getBounds());
      setCenter(map.getCenter().toJSON());
    }
  };

  const onPlacesChanged = () => {
    if (autocomplete && mapRef.current) {
      const places = autocomplete.getPlace();
      const bounds = new window.google.maps.LatLngBounds();
      if (places && places.geometry) {
        if (places.geometry.viewport) {
          bounds.union(places.geometry.viewport);
        } else {
          bounds.extend(places.geometry.location);
        }
        const location = places.geometry.location;
        props.handleChangeMarker({
          lat: location.lat(),
          lng: location.lng()
        });
        mapRef.current.fitBounds(bounds);
      }
    }
  };

  useEffect(() => {
    if (props.coordinates.lat && props.coordinates.lng) {
      setCenter({
        lat: parseFloat(props.coordinates.lat),
        lng: parseFloat(props.coordinates.lng)
      });
      setMarkers([{
        position: props.coordinates
      }]);
    } else if (props.coords) {
      setCenter({
        lat: props.coords.latitude,
        lng: props.coords.longitude
      });
    }
  }, [props.coordinates, props.coords]);

  return (
    <LoadScriptNext
      googleMapsApiKey="AIzaSyD0rokHYZxa0lT94aoKtSgbNXofpR14cZA&v=3.exp"
      libraries={['geometry', 'drawing', 'places']}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onBoundsChanged={onBoundsChanged}
      >
        <Autocomplete onLoad={(ref) => setAutocomplete(ref)} onPlaceChanged={onPlacesChanged}>
          <input
            type="text"
            placeholder="Pin location by search"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              marginTop: `9px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipsis`,
              zIndex: 99999,
              position: 'absolute',
              top: '2px',
              left: '190px',
            }}
          />
        </Autocomplete>

        {props.coordinates && props.coordinates.lng !== 0 && props.coordinates.lat !== 0 && (
          <Marker
            position={{
              lat: parseFloat(props.coordinates.lat),
              lng: parseFloat(props.coordinates.lng)
            }}
            draggable={true}
            onDragEnd={(e) => {
              const location = e.latLng;
              props.handleChangeMarker({
                lat: location.lat(),
                lng: location.lng()
              });
              if (mapRef.current) {
                mapRef.current.panTo(location);
              }
            }}
            onClick={() => setSelectedMarker({
              position: {
                lat: parseFloat(props.coordinates.lat),
                lng: parseFloat(props.coordinates.lng)
              },
              label: 'Choosed Location'
            })}
          />
        )}
        {props.coords && props.coords.longitude !== 0 && props.coords.latitude !== 0 && (
          <Marker
            position={{ lat: props.coords.latitude, lng: props.coords.longitude }}
            onClick={() => setSelectedMarker({
              position: {
                lat: props.coords.latitude,
                lng: props.coords.longitude
              },
              label: 'Current Location',
            })}
            icon={{
              path:
                "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z",
              fillColor: "#ffc107",
              fillOpacity: 1,
              strokeColor: "white",
              strokeWeight: 2,
              scale: 2
            }}
          >
            {selectedMarker && selectedMarker.position.lat === props.coords.latitude && selectedMarker.position.lng === props.coords.longitude && (
              <InfoWindow
                position={selectedMarker.position}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <p style={{fontWeight: 'bold'}}>{selectedMarker.label}</p>
              </InfoWindow>
            )}
          </Marker>
        )}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
})(PinLocation);