import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isAccountant } from "utils/helpers";
import Dashboard from "./components/Dashboard";
import DetailedDashboard from "./DetailedDashboard";

const DashboardComponent = props => {
  const { user } = props;
  return isAccountant(user) ? <Dashboard /> : <DetailedDashboard />;
};

DashboardComponent.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(DashboardComponent);
