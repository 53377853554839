import {
  FETCH_DRIVERS_IN_ZONE_RESOLVED,
  FETCH_DRIVERS_RESOLVED,
  FETCH_DRIVER_RESOLVED,
  FETCH_DRIVERS_TASKS_RESOLVED,
  FETCH_RFID_DROPOFF_LOGS_RESOLVED,
  FETCH_DRIVERS_ROUTE_RESOLVED,
  UPDATE_DRIVER_RESOLVED,
  CHECK_IN_RESOLVED,
  CHECK_OUT_RESOLVED,
  CONFIRM_TIME_SHEET_RESOLVED,
  FETCH_DRIVER_SUBMISSIONS_RESOLVED,
  UPDATE_DRIVER_SUBMISSIONS_RESOLVED,
  CHOSEN_DRIVER,
  UPDATE_DRIVERS_TASK_RESOLVED,
  FETCH_SUBMISSIONS_RESOLVED,
  FETCH_DRIVERS_NAMES_LIST_RESOLVED,
  UPDATE_TASK_COLLECTIONS_RESOLVED
} from "../ActionTypes/drivers";
import * as statuses from "constants/Statuses";
import _ from "lodash";
import moment from "moment";

const initialState = {
  driverList: [],
  driver: {},
  tasks: [],
  route: [],
  rfidDropoff: [],
  ChosenDriverId: null,
  submissions: [],
  driversNames: []
};

const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DRIVERS_NAMES_LIST_RESOLVED:
      return Object.assign({}, state, {
        driversNames: action.payload
      });
    case FETCH_SUBMISSIONS_RESOLVED:
      return Object.assign({}, state, {
        submissions: action.payload
      });
    case UPDATE_DRIVERS_TASK_RESOLVED: {
      let tasks = JSON.parse(JSON.stringify(state.tasks));
      if (action.payload.driver) {
        tasks.forEach((item, index) => {
          if (item.id == action.payload.id) {
            tasks[index] = action.payload;
          }
        });
      } else {
        _.remove(tasks, function(o) {
          return o.id == action.payload.id;
        });
      }
      return Object.assign({}, state, {
        tasks
      });
    }
    case CHOSEN_DRIVER:
      return Object.assign({}, state, {
        ChosenDriverId: action.payload
      });
    case CONFIRM_TIME_SHEET_RESOLVED: {
      let drivers = JSON.parse(JSON.stringify(state.driverList));
      drivers.forEach((item, index) => {
        if (item.user_id == action.payload.user.id) {
          drivers[index].check_in =
            action.payload.from &&
            action.payload.status != statuses.STATUS_CHECK_OUT_CONFIRMED
              ? {
                  time: action.payload.from,
                  confirmed:
                    action.payload.status ==
                    statuses.STATUS_CHECK_IN_NOT_CONFIRMED
                      ? false
                      : true
                }
              : null;
          (drivers[index].check_out =
            action.payload.to &&
            action.payload.status != statuses.STATUS_CHECK_OUT_CONFIRMED
              ? {
                  time: action.payload.to,
                  confirmed:
                    action.payload.status == statuses.STATUS_CHECK_OUT_CONFIRMED
                      ? true
                      : false
                }
              : null),
            (drivers[index].time_sheet = action.payload);
          drivers[index].status =
            action.payload.status == statuses.STATUS_CHECK_OUT_CONFIRMED
              ? 5
              : 2;
          drivers[index].status_code =
            action.payload.status == statuses.STATUS_CHECK_OUT_CONFIRMED
              ? "Away"
              : "Free";
        }
      });
      return Object.assign({}, state, {
        driverList: drivers
      });
    }
    case CHECK_IN_RESOLVED: {
      let drivers = JSON.parse(JSON.stringify(state.driverList));
      drivers.forEach((item, index) => {
        if (item.user_id == action.payload.user.id) {
          drivers[index].check_in =
            action.payload.from &&
            action.payload.status != statuses.STATUS_CHECK_OUT_CONFIRMED
              ? {
                  time: action.payload.from,
                  confirmed:
                    action.payload.status ==
                    statuses.STATUS_CHECK_IN_NOT_CONFIRMED
                      ? false
                      : true
                }
              : null;
          drivers[index].time_sheet = action.payload;
          drivers[index].status = 2;
          drivers[index].status_code = "Free";
        }
      });
      return Object.assign({}, state, {
        driverList: drivers
      });
    }
    case CHECK_OUT_RESOLVED: {
      let drivers = JSON.parse(JSON.stringify(state.driverList));
      drivers.forEach((item, index) => {
        if (item.user_id == action.payload.user.id) {
          drivers[index].check_out = null;
          drivers[index].check_in = null;
          drivers[index].time_sheet = action.payload;
          drivers[index].status = 5;
          drivers[index].status_code = "Away";
        }
      });
      return Object.assign({}, state, {
        driverList: drivers
      });
    }
    case FETCH_DRIVERS_IN_ZONE_RESOLVED:
      return Object.assign({}, state, {
        driverList: action.payload
      });
    case FETCH_DRIVERS_ROUTE_RESOLVED:
      return Object.assign({}, state, {
        route: action.payload
      });
    case FETCH_DRIVERS_RESOLVED:
      return Object.assign({}, state, {
        driverList: action.payload
      });
    case FETCH_DRIVER_RESOLVED:
      return Object.assign({}, state, {
        driver: action.payload
      });
    case UPDATE_DRIVER_RESOLVED: {
      let drivers = JSON.parse(JSON.stringify(state.driverList));
      drivers.map((item, index) => {
        if (item.id == action.payload.id) {
          drivers[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        driver: action.payload,
        driverList: drivers
      });
    }
    case FETCH_DRIVERS_TASKS_RESOLVED:
      return Object.assign({}, state, {
        tasks: action.payload
      });
    case  FETCH_RFID_DROPOFF_LOGS_RESOLVED:
      return Object.assign({}, state, {
        rfidDropoff: action.payload
      });
    case FETCH_DRIVER_SUBMISSIONS_RESOLVED: {
      const submissions = _.sortBy(action.payload, function(o) {
        return new moment(o.created_at);
      }).reverse();
      return Object.assign({}, state, {
        submissions
      });
    }
    case UPDATE_DRIVER_SUBMISSIONS_RESOLVED: {
      let submissions = JSON.parse(JSON.stringify(state.submissions));
      submissions.forEach((item, index) => {
        if (item.id == action.payload.id) {
          submissions[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        submissions
      });
    }

    case UPDATE_TASK_COLLECTIONS_RESOLVED: {
      let submissions = JSON.parse(JSON.stringify(state.submissions));
      let submission_id = _.findIndex(submissions, function(o) {
        return o.id == action.submission_id;
      });
      if (submission_id >= 0) {
        let task_index = _.findIndex(submissions[submission_id].tasks, function(
          o
        ) {
          return o.id == action.payload.id;
        });
        if (task_index >= 0) {
          submissions[submission_id].tasks[task_index].collections =
            action.payload.collections;
        }
      }
      return Object.assign({}, state, {
        submissions
      });
    }
    default:
      return state;
  }
};

export default driverReducer;
