import {
  SHOW_ORDER_CHILDREN,
  HANDLE_SELECT_ORDER,
  HANDLE_SELECT_ALL_ORDER,
  FETCH_ORDERS_RESOLVED,
  FETCH_BULK_RETURN_FORM_ORDERS_RESOLVED,
  FETCH_CASHBOXES_ORDERS_RESOLVED,
  FETCH_ORDER_RESOLVED,
  UPDATE_TASK_RESOLVED,
  UPDATE_ORDER_RESOLVED,
  ADD_ORDER_RESOLVED,
  ADD_ENHANCED_COMMENT_RESOLVED,
  UPDATE_TASK_LOCATION_RESOLVED,
  FETCH_ORDERS_REPORTS_RESOLVED,
  CHOSEN_PACKAGE_TYPE,
  UPDATE_MULTIPLE_ORDERS_RESOLVED,
  FETCH_ACTIVE_ORDERS_RESOLVED,
  FETCH_CASHBOX_ORDERS_RESOLVED,
  LISTENER_ORDER_CREATED,
  SEEN_NEW_ORDERS,
  SEEN_NEW_ACTIVE_ORDERS,
  LISTENER_UPDATE_ORDER_RESOLVED,
  LISTENER_UPDATE_MULTIPLE_ORDERS_RESOLVED,
  FETCH_ACTIVITY_LOG_RESOLVED,
  SEARCH_ORDERS_RESOLVED,
  CLEAR_ORDERS_RESOLVED,
  FETCH_COMMENTS_RESOLVED,
  UPDATE_ENHANCED_IMP_COMMENT_RESOLVED,
  FETCH_ORDER_DETAILS_RESOLVED,
  CLEAR_COMMENTS_RESOLVED,
  CLEAR_TASKS_RESOLVED,
  FETCH_TASKS_FOR_ORDER,
  DELETE_COMMENT_RESOLVED,
  EDIT_COMMENT_RESOLVED,
  EDIT_ORDER_DELIVERY_PRICE_RESOLVED,
  CRITICAL_ORDERS_RESOLVED,
  CLEAR_CRITICAL_ORDERS_RESOLVED,
  FETCH_PICKUP_ORDERS_RESOLVED,
  FETCH_RETURN_ORDERS_RESOLVED,
  FETCH_EXPRESS_ORDERS_RESOLVED,
  CLEAR_ORDER_LIST,
  FETCH_ORDER_IMPORTS_RESOLVED,
  FETCH_REVERTED_ORDER_IMPORT_RESOLVED,
  FETCH_AUDITED_ORDER_PRICE_RESOLVED,
  FETCH_AUDITED_ORDER_COLLECTION_RESOLVED,
  
} from '../ActionTypes/orders';
import _ from 'lodash';
import * as statuses from 'constants/Statuses';

const initialState = {
  selected: [],
  show: [],
  orderList: [],
  orderReportList: [],
  orderImportsList:[],
  order: {},
  reverted_import:{},
  orderDetails: {},
  internationalOrderDetails:{},
  ChosenPackageTypeId: null,
  customersOrders: [],
  newOrdersListener: 0,
  newActiveOrdersListener: 0,
  logs: [],
  searchOrders: [],
  searchOrdersInfo: [],
  searchOrdersMeta: null,
  comments: [],
  tasks: [],
  criticalOrders: [],
  criticalOrdersPages: [],
  pickupOrders: [],
  returnOrders: [],
  expressOrders: [],
  bulkReturnFormOrdersList: [],
  cashboxOrders: [],
  auditedCollections: [],
  
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TASKS_FOR_ORDER:
      return Object.assign({}, state, {
        tasks: action.payload,
      });
    case CLEAR_TASKS_RESOLVED:
      return Object.assign({}, state, {
        tasks: [],
      });
    case FETCH_ORDER_DETAILS_RESOLVED:
      return Object.assign({}, state, {
        orderDetails: action.payload,
      });
    case CRITICAL_ORDERS_RESOLVED: {
      let criticalOrders = state.criticalOrders;
      let criticalOrdersPages = state.criticalOrdersPages;
      let index = _.findIndex(criticalOrdersPages, function (o) {
        return o == action.page;
      });
      if (index < 0) {
        criticalOrders = state.criticalOrders.concat(action.payload);
        criticalOrdersPages.push(action.page);
      }

      return Object.assign({}, state, {
        criticalOrders,
        criticalOrdersPages,
      });
    }
    case FETCH_PICKUP_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        pickupOrders: action.payload,
      });
    case FETCH_RETURN_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        returnOrders: action.payload,
      });
    case FETCH_BULK_RETURN_FORM_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        bulkReturnFormOrdersList: action.payload,
      });
    case FETCH_CASHBOXES_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        cashboxOrders: action.payload,
      });
    case FETCH_EXPRESS_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        expressOrders: action.payload,
      });
    case SEARCH_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        searchOrders: action.payload,
        searchOrdersInfo: action.info,
        searchOrdersMeta: action.meta,
      });
    case FETCH_ACTIVITY_LOG_RESOLVED:
      return Object.assign({}, state, {
        logs: action.payload,
      });
    case LISTENER_ORDER_CREATED: {
      let newOrdersListener = state.newOrdersListener;
      let newActiveOrdersListener = state.newActiveOrdersListener;
      let customersOrders = JSON.parse(JSON.stringify(state.customersOrders));

      if (action.payload.status == statuses.ORDER_STATUS_PENDING) {
        newOrdersListener = newOrdersListener + 1;
      } else {
        let index = _.findIndex(customersOrders, function (o) {
          return o.key.id == action.payload.orderDetails.customer.id;
        });
        if (index >= 0) {
          customersOrders.map((item, key) => {
            if (item.key.id == action.payload.orderDetails.customer.id) {
              customersOrders[key].values.push(action.payload);
              newActiveOrdersListener = newActiveOrdersListener + 1;
            }
          });
        } else {
          customersOrders.push({
            key: {
              id: action.payload.orderDetails.customer.id,
              name: action.payload.orderDetails.customer.name,
            },
            values: [action.payload],
          });
        }
      }

      return Object.assign({}, state, {
        customersOrders: customersOrders,
        newOrdersListener: newOrdersListener,
        newActiveOrdersListener: newActiveOrdersListener,
      });
    }
    case SEEN_NEW_ORDERS:
      return Object.assign({}, state, {
        newOrdersListener: 0,
      });
    case SEEN_NEW_ACTIVE_ORDERS:
      return Object.assign({}, state, {
        newActiveOrdersListener: 0,
      });
    case FETCH_ACTIVE_ORDERS_RESOLVED: {
      let customersOrders = JSON.parse(JSON.stringify(state.customersOrders));
      if (action.customer) {
        let index = _.findIndex(customersOrders, function (o) {
          return o.key.id == action.customer.id;
        });
        if (index >= 0) {
          customersOrders[index].values = action.payload;
        } else {
          customersOrders.push({
            key: action.customer,
            values: action.payload,
          });
        }
      }
      return Object.assign({}, state, {
        customersOrders,
      });
    }
    case FETCH_CASHBOX_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        orderList: action.payload,
      });
    case FETCH_COMMENTS_RESOLVED:
      return Object.assign({}, state, {
        comments: action.payload,
      });
    case CLEAR_COMMENTS_RESOLVED:
      return Object.assign({}, state, {
        comments: [],
      });
    case ADD_ENHANCED_COMMENT_RESOLVED: {
      let comments = state.comments;
      comments.unshift(action.payload);

      let searchOrders = JSON.parse(JSON.stringify(state.searchOrders));
      let searchOrderIndex = searchOrders.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (searchOrderIndex >= 0) {
        searchOrders[searchOrderIndex].has_comments = true;
      }

      let returnOrders = JSON.parse(JSON.stringify(state.returnOrders));
      let returnOrdersIndex = returnOrders.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (returnOrdersIndex >= 0) {
        returnOrders[returnOrdersIndex].has_comments = true;
      }

      let bulkReturnFormOrdersList = JSON.parse(
        JSON.stringify(state.bulkReturnFormOrdersList),
      );
      let bulkReturnFormOrdersListIndex = bulkReturnFormOrdersList.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (bulkReturnFormOrdersListIndex >= 0) {
        bulkReturnFormOrdersList[
          bulkReturnFormOrdersListIndex
        ].has_comments = true;
      }

      let criticalOrders = JSON.parse(JSON.stringify(state.criticalOrders));
      searchOrderIndex = criticalOrders.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (searchOrderIndex >= 0) {
        criticalOrders[searchOrderIndex].has_comments = true;
      }

      let orders = JSON.parse(JSON.stringify(state.orderList));
      let orderIndex = orders.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (orderIndex >= 0) {
        orders[orderIndex].has_comments = true;
      }

      let customersOrders = JSON.parse(JSON.stringify(state.customersOrders));
      if (action.customer_id) {
        customersOrders.map((item, key) => {
          if (item.key.id == action.customer_id) {
            let index = _.findIndex(item.values, function (o) {
              return o.id == action.payload.order_id;
            });
            if (index >= 0) {
              customersOrders[key].values[index].has_comments = true;
            }
          }
        });
      }

      return Object.assign({}, state, {
        comments,
        searchOrders,
        criticalOrders,
        returnOrders,
        bulkReturnFormOrdersList,
        orderList: orders,
        customersOrders,
      });
    }
    case UPDATE_ENHANCED_IMP_COMMENT_RESOLVED: {
      let comments = JSON.parse(JSON.stringify(state.comments));
      let commentIndex = comments.findIndex(
        (obj) => obj.id == action.payload.id,
      );
      if (commentIndex >= 0) {
        comments[commentIndex] = action.payload;
      }

      let searchOrders = JSON.parse(JSON.stringify(state.searchOrders));
      let searchOrderIndex = searchOrders.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (searchOrderIndex >= 0) {
        searchOrders[searchOrderIndex].imp_comment = action.make_important
          ? action.payload.comment
          : null;
        searchOrders[searchOrderIndex].comment_id = action.make_important
          ? action.payload.id
          : null;
      }

      let criticalOrders = JSON.parse(JSON.stringify(state.criticalOrders));
      let criticalOrderIndex = criticalOrders.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (criticalOrderIndex >= 0) {
        criticalOrders[criticalOrderIndex].imp_comment = action.make_important
          ? action.payload.comment
          : null;
        criticalOrders[criticalOrderIndex].comment_id = action.make_important
          ? action.payload.id
          : null;
      }

      let returnOrders = JSON.parse(JSON.stringify(state.returnOrders));
      let returnOrdersIndex = returnOrders.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (returnOrdersIndex >= 0) {
        returnOrders[returnOrdersIndex].imp_comment = action.make_important
          ? action.payload.comment
          : null;
        returnOrders[returnOrdersIndex].comment_id = action.make_important
          ? action.payload.id
          : null;
      }

      let bulkReturnFormOrdersList = JSON.parse(
        JSON.stringify(state.bulkReturnFormOrdersList),
      );
      let bulkReturnFormOrdersListIndex = bulkReturnFormOrdersList.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (bulkReturnFormOrdersListIndex >= 0) {
        bulkReturnFormOrdersList[bulkReturnFormOrdersListIndex].imp_comment =
          action.make_important ? action.payload.comment : null;
        bulkReturnFormOrdersList[bulkReturnFormOrdersListIndex].comment_id =
          action.make_important ? action.payload.id : null;
      }

      let orders = JSON.parse(JSON.stringify(state.orderList));
      let ordersIndex = orders.findIndex(
        (obj) => obj.id == action.payload.order_id,
      );
      if (ordersIndex >= 0) {
        orders[ordersIndex].imp_comment = action.make_important
          ? action.payload.comment
          : null;
        orders[ordersIndex].comment_id = action.make_important
          ? action.payload.id
          : null;
      }

      let customersOrders = JSON.parse(JSON.stringify(state.customersOrders));
      if (action.customer_id) {
        customersOrders.map((item, key) => {
          if (item.key.id == action.customer_id) {
            let index = _.findIndex(item.values, function (o) {
              return o.id == action.payload.order_id;
            });
            if (index >= 0) {
              customersOrders[key].values[index].imp_comment =
                action.make_important ? action.payload.comment : null;
              customersOrders[key].values[index].comment_id =
                action.make_important ? action.payload.id : null;
            }
          }
        });
      }
      return Object.assign({}, state, {
        comments,
        searchOrders,
        criticalOrders,
        returnOrders,
        bulkReturnFormOrdersList,
        orderList: orders,
        customersOrders,
      });
    }
    case CLEAR_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        customersOrders: [],
      });
    case CLEAR_CRITICAL_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        criticalOrders: [],
        criticalOrdersPages: [],
      });
    case CHOSEN_PACKAGE_TYPE:
      return Object.assign({}, state, {
        ChosenPackageTypeId: action.payload,
      });
    case FETCH_ORDERS_RESOLVED:
      return Object.assign({}, state, {
        orderList: action.payload,
      });
    case CLEAR_ORDER_LIST:
      return Object.assign({}, state, {
        bulkReturnFormOrdersList: [],
      });
    case FETCH_ORDER_RESOLVED:
      return Object.assign({}, state, {
        order: action.payload,
      });
    case FETCH_ORDERS_REPORTS_RESOLVED:
      return Object.assign({}, state, {
        orderReportList: action.payload,
      });
    case LISTENER_UPDATE_ORDER_RESOLVED: {
      let customersOrders = JSON.parse(JSON.stringify(state.customersOrders));
      let customer_found = _.findIndex(customersOrders, function (o) {
        return o.key.id == action.payload.orderDetails.customer.id;
      });
      if (customer_found >= 0) {
        customersOrders.map((item, key) => {
          if (item.key.id == action.payload.orderDetails.customer.id) {
            let index = _.findIndex(item.values, function (o) {
              return o.id == action.payload.id;
            });
            if (index >= 0) {
              if (
                action.payload.status == statuses.ORDER_STATUS_CONFIRMED ||
                action.payload.status == statuses.ORDER_STATUS_PROCESSING
              ) {
                customersOrders[key].values[index] = action.payload;
              } else {
                _.remove(
                  customersOrders[key].values,
                  (o) => o.id == action.payload.id,
                );
              }
            } else if (
              action.payload.status == statuses.ORDER_STATUS_CONFIRMED ||
              action.payload.status == statuses.ORDER_STATUS_PROCESSING ||
              action.payload.status == statuses.ORDER_STATUS_FAILED ||
              action.payload.status ==
                statuses.ORDER_STATUS_PENDING_CANCELLATION
            ) {
              customersOrders[key].values.push(action.payload);
            }
          }
        });
      } else {
        customersOrders.push({
          key: {
            id: action.payload.orderDetails.customer.id,
            name: action.payload.orderDetails.customer.name,
          },
          values: [action.payload],
        });
      }

      return Object.assign({}, state, {
        customersOrders,
      });
    }

    case LISTENER_UPDATE_MULTIPLE_ORDERS_RESOLVED: {
      //This is not tested because it is not used
      let customersOrders = JSON.parse(JSON.stringify(state.customersOrders));

      action.payload.orders.forEach((order) => {
        let customerIndex = _.findIndex(
          customersOrders,
          (o) => o.key.id === order.orderDetails.customer.id,
        );

        if (customerIndex >= 0) {
          // Customer already exists, update their orders
          let orderIndex = _.findIndex(
            customersOrders[customerIndex].values,
            (o) => o.id === order.id,
          );

          if (orderIndex >= 0) {
            // Order already exists, update or remove it
            if (statusesToUpdate.includes(order.status)) {
              customersOrders[customerIndex].values[orderIndex] = order;
            } else {
              _.remove(
                customersOrders[customerIndex].values,
                (o) => o.id === order.id,
              );
            }
          } else if (statusesToAdd.includes(order.status)) {
            // Order not found, add it if status matches
            customersOrders[customerIndex].values.push(order);
          }
        } else {
          // Customer not found, add new customer with the order
          customersOrders.push({
            key: {
              id: order.orderDetails.customer.id,
              name: order.orderDetails.customer.name,
            },
            values: [order],
          });
        }
      });

      return {
        ...state,
        customersOrders,
      };
    }
    case FETCH_AUDITED_ORDER_PRICE_RESOLVED:
      return Object.assign({}, state, {
        auditedCollections: action.payload,
      });
    case FETCH_AUDITED_ORDER_COLLECTION_RESOLVED:
      return Object.assign({}, state, {
        auditedCollections: action.payload,
      });
    case UPDATE_ORDER_RESOLVED: {
      let orderList = JSON.parse(JSON.stringify(state.orderList));
      if (action.remove) {
        _.remove(orderList, (o) => o.id == action.payload.id);
      } else {
        let index = _.findIndex(orderList, function (o) {
          return o.id == action.payload.id;
        });
        if (index >= 0) {
          orderList[index] = action.payload;
        }
      }

      let searchOrders = JSON.parse(JSON.stringify(state.searchOrders));
      let index = _.findIndex(searchOrders, function (o) {
        return o.id == action.payload.id;
      });
      if (index >= 0) {
        searchOrders[index] = action.payload;
      }

      let criticalOrders = JSON.parse(JSON.stringify(state.criticalOrders));
      index = _.findIndex(criticalOrders, function (o) {
        return o.id == action.payload.id;
      });
      if (index >= 0) {
        criticalOrders[index] = action.payload;
      }

      let returnOrders = JSON.parse(JSON.stringify(state.returnOrders));
      index = _.findIndex(returnOrders, function (o) {
        return o.id == action.payload.id;
      });
      if (index >= 0) {
        returnOrders[index] = action.payload;
      }

      let bulkReturnFormOrdersList = JSON.parse(
        JSON.stringify(state.bulkReturnFormOrdersList),
      );
      index = _.findIndex(bulkReturnFormOrdersList, function (o) {
        return o.id == action.payload.id;
      });
      if (index >= 0) {
        bulkReturnFormOrdersList[index] = action.payload;
      }

      let expressOrders = JSON.parse(JSON.stringify(state.expressOrders));
      index = _.findIndex(expressOrders, function (o) {
        return o.id == action.payload.id;
      });
      if (index >= 0) {
        expressOrders[index] = action.payload;
      }

      let customersOrders = JSON.parse(JSON.stringify(state.customersOrders));
      customersOrders.map((item, key) => {
        if (item.key.id == action.payload.orderDetails.customer.id) {
          let index = _.findIndex(item.values, function (o) {
            return o.id == action.payload.id;
          });
          if (index >= 0) {
            if (action.remove) {
              _.remove(
                customersOrders[key].values,
                (o) => o.id == action.payload.id,
              );
            } else if (
              action.payload.status == statuses.ORDER_STATUS_CONFIRMED ||
              action.payload.status == statuses.ORDER_STATUS_PROCESSING ||
              action.payload.status == statuses.ORDER_STATUS_FAILED ||
              action.payload.status == statuses.ORDER_STATUS_PENDING ||
              action.payload.status == statuses.ORDER_STATUS_POSTPONED ||
              action.payload.status ==
                statuses.ORDER_STATUS_PENDING_CANCELLATION
            ) {
              customersOrders[key].values[index] = action.payload;
            } else {
              _.remove(
                customersOrders[key].values,
                (o) => o.id == action.payload.id,
              );
            }
          }
        }
      });
      let cashboxOrders = JSON.parse(JSON.stringify(state.cashboxOrders));
      index = _.findIndex(cashboxOrders, function (o) {
        return o.id == action.payload.id;
      });
      if (index >= 0) {
        cashboxOrders[index] = action.payload;
      }
      return Object.assign({}, state, {
        orderList,
        customersOrders,
        searchOrders,
        criticalOrders,
        expressOrders,
        returnOrders,
        bulkReturnFormOrdersList,
        order: action.payload,
        cashboxOrders
      });
    }
      
    case UPDATE_MULTIPLE_ORDERS_RESOLVED: {
      const orders = action.payload;
      const remove = action.remove;

      return {
        ...state,
        orderList: updateOrdersInList(state.orderList, orders, remove),
        searchOrders: updateOrdersInList(state.searchOrders, orders, remove),
        criticalOrders: updateOrdersInList(
          state.criticalOrders,
          orders,
          remove,
        ),
        returnOrders: updateOrdersInList(state.returnOrders, orders, remove),
        bulkReturnFormOrdersList: updateOrdersInList(
          state.bulkReturnFormOrdersList,
          orders,
          remove,
        ),
        expressOrders: updateOrdersInList(state.expressOrders, orders, remove),
        customersOrders: updateOrdersInCustomersOrders(
          state.customersOrders,
          orders,
          remove,
        ),
      };
    }
    case ADD_ORDER_RESOLVED: {
      let orders = JSON.parse(JSON.stringify(state.orderList));
      orders.push(action.payload);
      return Object.assign({}, state, {
        orderList: orders,
        order: action.payload,
      });
    }
    case SHOW_ORDER_CHILDREN: {
      let show = JSON.parse(JSON.stringify(state.show));
      show.filter((elem) => elem == action.payload) == 0
        ? show.push(action.payload)
        : (show = _.without(show, action.payload));
      return Object.assign({}, state, {
        show,
      });
    }
    case HANDLE_SELECT_ORDER: {
      let selected = _.clone(state.selected);
      selected.filter((elem) => elem == action.id) == 0
        ? selected.push(action.id)
        : (selected = _.without(selected, action.id));
      return Object.assign({}, state, {
        selected,
      });
    }
    case HANDLE_SELECT_ALL_ORDER: {
      let selected = [];
      if (state.selected.length != state.orders.length) {
        state.orders.map((order) => {
          selected.push(order.id);
        });
      }
      return Object.assign({}, state, {
        selected,
      });
    }
    case UPDATE_TASK_RESOLVED: {
      let order = JSON.parse(JSON.stringify(state.order));
      order.tasks.forEach((item, index) => {
        if (item.id == action.payload.id) {
          order.tasks[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        order,
      });
    }
    case UPDATE_TASK_LOCATION_RESOLVED: {
      let order = JSON.parse(JSON.stringify(state.order));
      order.tasks.forEach((item, index) => {
        if (item.id == action.payload.id) {
          order.tasks[index] = action.payload;
          order.tasks[index].full_location =
            action.payload.location.area.name +
            ", " +
            action.payload.location.building +
            ", " +
            action.payload.location.floor;
        }
      });
      if (order.orderDetails.sender_location.id == action.payload.location.id) {
        order.orderDetails.sender_location = action.payload.location;
      }
      if (
        order.orderDetails.receiver_location.id == action.payload.location.id
      ) {
        order.orderDetails.receiver_location = action.payload.location;
      }
      return Object.assign({}, state, {
        order,
      });
    }

    case DELETE_COMMENT_RESOLVED: {
      let comments = JSON.parse(JSON.stringify(state.comments));
      _.remove(comments, function (o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        comments: comments,
      });
    }
    case EDIT_COMMENT_RESOLVED:
      return {
        ...state,
        comments: state.comments.map((c) => {
          if (c.id === action.payload.id) {
            return action.payload;
          }
          return c;
        }),
      };
    case EDIT_ORDER_DELIVERY_PRICE_RESOLVED:
      return Object.assign({}, state, {
        order: {
          ...state.order,
          price: action.payload.data.price
        },
      });
    case FETCH_ORDER_IMPORTS_RESOLVED:
      return Object.assign({}, state, {
        orderImportsList: action.payload,
      });
    case FETCH_REVERTED_ORDER_IMPORT_RESOLVED:
      return Object.assign({}, state, {
        reverted_import: action.payload,
      });
    default:
      return state;
  }
};
function updateOrdersInList(list, ordersToUpdate, remove = false) {
  return ordersToUpdate.reduce((updatedList, order) => {
    const index = updatedList.findIndex(o => o.id === order.id);
    if (index >= 0) {
      if (remove) {
        return updatedList.filter(o => o.id !== order.id);
      } else {
        updatedList[index] = order;
      }
    } else if (!remove) {
      updatedList.push(order);
    }
    return updatedList;
  }, [...list]);
}
function updateOrdersInCustomersOrders(customersOrders, ordersToUpdate, remove = false) {
  return customersOrders.map(group => ({
    ...group,
    values: updateOrdersInList(group.values, ordersToUpdate, remove),
  }));
}

export default orderReducer;
