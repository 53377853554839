import * as types from "../ActionTypes/hr";

export function fetchTimesheetsResolved(payload) {
  return {
    type: types.FETCH_TIMESHEETS_RESOLVED,
    payload
  };
}

export function fetchMessagesResolved(payload) {
  return {
    type: types.FETCH_MESSAGES_RESOLVED,
    payload
  };
}

export function updateTimesheetResolved(payload) {
  return {
    type: types.UPDATE_TIMESHEET_RESOLVED,
    payload
  };
}

export function fetchUpdateMessageResolved(payload) {
  return {
    type: types.UPDATE_MESSAGE_RESOLVED,
    payload
  };
}

export function fetchUsersResolved(payload) {
  return {
    type: types.FETCH_USERS_RESOLVED,
    payload
  };
}

export function createStaff(payload) {
  return { type: types.CREATE_STAFF, payload };
}

export function editStaff(payload) {
  return { type: types.EDIT_STAFF, payload };
}

export function deleteUserResolved(payload) {
  return { type: types.DELETE_USER, payload };
}

export function createRecruitmentRequest(payload) {
  return { type: types.CREATE_RECRUITMENT_REQUEST, payload };
}

export function deleteRecruitmentRequest(payload) {
  return { type: types.DELETE_RECRUITMENT_REQUEST, payload };
}

export function approveOrRejectRecruitmentRequest(payload) {
  return { type: types.APPROVE_OR_REJECT_RECRUITMENT_REQUEST, payload };
}

export function fetchRecruitmentRequestsResolved(payload) {
  return {
    type: types.FETCH_RECRUITMENT_REQUESTS_RESOLVED,
    payload
  };
}

export function createLeaveRequest(payload) {
  return {
    type: types.CREATE_RECRUITMENT_REQUEST,
    payload
  };
}

export function deleteLeaveRequest(payload) {
  return {
    type: types.DELETE_LEAVE_REQUEST,
    payload
  };
}

export function fetchLeaveRequestsResolved(payload) {
  return {
    type: types.FETCH_LEAVE_REQUESTS_RESOLVED,
    payload
  };
}

export function fetchLeaveRequestResolved(payload) {
  return {
    type: types.FETCH_LEAVE_REQUEST_RESOLVED,
    payload
  };
}

export function approveOrRejectLeaveRequest(payload) {
  return { type: types.APPROVE_OR_REJECT_LEAVE_REQUEST, payload };
}

export function createExitRequest(payload) {
  return {
    type: types.CREATE_EXIT_REQUEST,
    payload
  };
}

export function deleteExitRequest(payload) {
  return {
    type: types.DELETE_EXIT_REQUEST,
    payload
  };
}

export function fetchExitRequestsResolved(payload) {
  return {
    type: types.FETCH_EXIT_REQUESTS_RESOLVED,
    payload
  };
}

export function approveOrRejectExitRequest(payload) {
  return { type: types.APPROVE_OR_REJECT_EXIT_REQUEST, payload };
}
