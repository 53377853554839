import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import RaisedButton from "@mui/material/Button";
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

class InternalBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseError: {
        isError: false
      },
      internal_banner_modal: true
    };
  }

  handleClose = () => {
    let { internal_banner_modal } = this.state;
    internal_banner_modal = false;
    this.setState({
      ...this.state,
      internal_banner_modal
    });
    localStorage.setItem("is_internal_banner_viewed", "yes");
  };

  render() {
    const { activeInternalBanner } = this.props;
    const messageContent = this.convertToClickableLinks(activeInternalBanner.message);
    const actions = (
      <RaisedButton variant="contained" label="Close" color="primary" onClick={this.handleClose} >Close</RaisedButton>
    );

    return (
      <Dialog
        className="text-center font-weight-bold"
        open={this.state.internal_banner_modal}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: { xs: 'auto', lg: '700px' },
          },
        }}
      >
        <DialogTitle>{activeInternalBanner.subject}</DialogTitle>
        <DialogContent
          sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}
        >
          <DialogContentText>
            {/* {activeInternalBanner.message} */}
            {messageContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!activeInternalBanner ? <CircularProgress /> : actions}
        </DialogActions>
      </Dialog>
    );
  }
  convertToClickableLinks = text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
  
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            style={{
              color:"#ffc107",
              textDecoration:"underline",
              overflow:"hidden",
              textOverflow: "ellipsis",  
              whiteSpace: "nowrap",      
              display: "block",          
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
            <br/>
          </a>        
        );
      } else {
        return (
          <span key={index}>
            {part}
            <br/>
          </span>
        );
      }
    });
  };
}

InternalBanner.propTypes = {
    activeInternalBanner: PropTypes.object.isRequired
};

export default InternalBanner;
