import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import APPCONFIG from 'constants/Config';
import { toggleCollapsedNav } from 'stores/actions/settings';
import SidenavContent from './SidenavContent';
import { isAdmin, isCustomer, isOperator, isAccountant } from 'utils/helpers';

class Sidebar extends React.Component {
  componentDidMount() {
    // AutoCloseMobileNav
    const { history } = this.props;
    const $body = $('#body');

    if (APPCONFIG.AutoCloseMobileNav) {
      history.listen((location) => {
        setTimeout(() => {
          $body.removeClass('sidebar-mobile-open');
        }, 0);
      });
    }
  }

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    const { handleToggleCollapsedNav } = this.props;
    handleToggleCollapsedNav(val);
  };

  render() {
    const { navCollapsed, colorOption } = this.props;
    let toggleIcon = null;
    if (navCollapsed) {
      toggleIcon = <i className="material-icons">radio_button_unchecked</i>;
    } else {
      toggleIcon = <i className="material-icons">radio_button_checked</i>;
    }

    return (
      <nav
        className={classnames('app-sidebar', {
          'bg-color-light':
            ['31', '32', '33', '34', '35', '36'].indexOf(colorOption) >= 0,
          'bg-color-dark':
            ['31', '32', '33', '34', '35', '36'].indexOf(colorOption) < 0,
        })}
      >
        <section
          className={classnames('sidebar-header', {
            'bg-color-dark': ['11', '31'].indexOf(colorOption) >= 0,
            'bg-color-light': colorOption === '21',
            'bg-color-primary': ['12', '22', '32'].indexOf(colorOption) >= 0,
            'bg-color-success': ['13', '23', '33'].indexOf(colorOption) >= 0,
            'bg-color-info': ['14', '24', '34'].indexOf(colorOption) >= 0,
            'bg-color-warning': ['15', '25', '35'].indexOf(colorOption) >= 0,
            'bg-color-danger': ['16', '26', '36'].indexOf(colorOption) >= 0,
          })}
        >
          <img src="/assets/images/wakilni-moto.png" height="22" />
          <Link
            to={
              process.env.REACT_APP_IS_SHOPIFY === 'true'
                ? '/app/settings'
                : isCustomer(this.props.user)
                ? '/'
                : '/app/orders/pending'
            }
            className="brand"
          >
            <img
              src="/assets/images/wakilni-logo-v2-white.png"
              height="22"
              alt="{APPCONFIG.brand}"
            />
          </Link>
          <a
            className="collapsednav-toggler"
            onClick={this.onToggleCollapsedNav}
            style={{ cursor: 'pointer' }}
          >
            {toggleIcon}
          </a>
        </section>

        <section className="sidebar-content">
          <SidenavContent />
        </section>

        <section className="sidebar-footer">
          <ul className="nav">
            {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
              isCustomer(this.props.user) && (
                <li>
                  <Link to="/app/feedback">
                    <i className="nav-icon material-icons">feedback</i>
                    <span className="nav-text">
                      <span>Feedback</span>
                    </span>
                  </Link>
                </li>
              )}

            {(process.env.REACT_APP_IS_SHOPIFY === 'true' ||
              isAdmin(this.props.user) ||
              isCustomer(this.props.user) ||
              isOperator(this.props.user) ||
              isAccountant(this.props.user)) && (
              <li>
                <Link to="/app/settings">
                  <i className="nav-icon material-icons">help</i>
                  <span className="nav-text">
                    <span>Settings</span>
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </section>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  navCollapsed: state.settings.navCollapsed,
  colorOption: state.settings.colorOption,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleToggleCollapsedNav: (isNavCollapsed) => {
    dispatch(toggleCollapsedNav(isNavCollapsed));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar),
);
