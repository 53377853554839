import * as ordersActions from "../stores/actions/orders";
import * as modalsActions from "../stores/actions/modals";
import Http from "../utils/Http";
import { order } from "stores/models/Order";
import { orderImport } from "stores/models/OrderImport";
import * as authActions from "../stores/actions/auth";
import { serializeSearchInput, serializeInput } from "utils/helpers";
import * as thirdPartyActions from "../stores/actions/third-party";
import Blob from "blob";
import axios from "axios";
import * as statuses from "constants/Statuses";
import momentTz from "moment-timezone";
import { auditedOrderCollection } from "stores/models/AuditedOrderCollection";

let Search_page = 1;
let Critical_page = 2;
let History_page = 3;
let Report_page = 4;
let Pickup_page = 5;
let Return_page = 6;
let Express_page = 7;
let Bulk_Return_Form = 8;
let Cashbox_orders_page = 9;

export function fetchOrders(
  input = [],
  other_input = [],
  customer = null,
  pageNumber = null,
  type = Search_page,
  orderBy = "id",
  sortedBy = "asc"
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `orders${input.length > 0 ? serializeSearchInput(input) : ""}${
        other_input.length > 0 ? (input.length > 0 ? "&" : "?") : ""
      }${serializeInput(other_input)}`;
      Http.get(
        url +
          (input.length > 0 || other_input.length > 0 ? "&" : "?") +
          `orderBy=${orderBy}&sortedBy=${sortedBy}` +
          (pageNumber ? "&page=" + pageNumber : "")
      )
        .then(res => {
          var orders = [];
          res.data.data.forEach(element => {
            orders.push(order(element));
          });

          if (customer) {
            customer = {
              ...customer,
              total: res.data.meta.pagination
                ? res.data.meta.pagination.total
                : 0,
              count: res.data.meta.pagination
                ? res.data.meta.pagination.count
                : 0
            };
            dispatch(ordersActions.fetchActiveOrdersResolved(orders, customer));
          } else {
            if (type === Critical_page) {
              dispatch(
                ordersActions.CriticalOrdersResolved(orders, pageNumber)
              );
            } else if (type === History_page) {
              dispatch(ordersActions.fetchOrdersResolved(orders));
            } else if (type === Report_page) {
              dispatch(ordersActions.fetchOrdersReportResolved(orders));
            } else if (type === Pickup_page) {
              dispatch(ordersActions.fetchPickupOrdersResolved(orders));
            } else if (type === Return_page) {
              dispatch(ordersActions.fetchReturnOrdersResolved(orders));
            } else if (type === Express_page) {
              dispatch(ordersActions.fetchExpressOrdersResolved(orders));
            } else if (type === Bulk_Return_Form) {
              dispatch(ordersActions.fetchBulkReturnFormOrdersResolved(orders));
            } else if (type === Cashbox_orders_page) {
              dispatch(ordersActions.fetchCashboxesOrdersResolved(orders));
            } else {
              dispatch(
                ordersActions.SearchOrdersResolved(
                  orders,
                  res.data.meta,
                  input.concat(other_input)
                )
              );
            }
          }
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function fetchOrdersCount(
  input = [],
  other_input = [],
  customer = null,
  pageNumber = null,
  type = Search_page,
  orderBy = "id",
  sortedBy = "asc"
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `orders${input.length > 0 ? serializeSearchInput(input) : ""}${other_input.length > 0 ? (input.length > 0 ? "&" : "?") : ""
        }${serializeInput(other_input)}`;
      Http.get(
        url +
        (input.length > 0 || other_input.length > 0 ? "&" : "?") +
        `orderBy=${orderBy}&sortedBy=${sortedBy}` +
        (pageNumber ? "&page=" + pageNumber : "")
      )
        .then(res => {
          var orders = [];
          res.data.data.forEach(element => {
            orders.push(order(element));
          });
          const total = res.data.meta.pagination ? res.data.meta.pagination.total : 0;
          return resolve(total);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function fetchPaginateOrders(
  input = [],
  other_input = [],
  pageNumber = null,
  type = Search_page,
  orderBy = "customer_id",
  sortedBy = "asc"
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `customerOrdersPaginate${input.length > 0 ? serializeSearchInput(input) : ""}${other_input.length > 0 ? (input.length > 0 ? "&" : "?") : ""}${serializeInput(other_input)}`;
      Http.get(
        url +
        (input.length > 0 || other_input.length > 0 ? "&" : "?") +
        `orderBy=${orderBy}&sortedBy=${sortedBy}` +
        (pageNumber ? "&page=" + pageNumber : "")
      )
        .then(res => {
          if (res.data.data) {
            dispatch(ordersActions.clearOrdersResolved());
          }

          const ordersByCustomerId = new Map();
          const customers = [];

          res.data.data.forEach(element => {
            const orderItem = order(element);
            const customerId = orderItem.orderDetails.customer.id;

            if (!ordersByCustomerId.has(customerId)) {
              ordersByCustomerId.set(customerId, []);
            }

            ordersByCustomerId.get(customerId).push(orderItem);
          });

          ordersByCustomerId.forEach((orders, customerId) => {
            const customer = {
              id: customerId,
              name: orders[0].orderDetails.customer.name,
              account_manager_id: orders[0].orderDetails.customer.account_manager_id,
              total: orders.length,
              count: orders.length,
              orders: orders,
            };

            customers.push(customer);
          });

          customers.forEach(customer => {
            dispatch(ordersActions.fetchActiveOrdersResolved(customer.orders, customer));
          });

          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function fetchActivityLogsForOrder(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("orders/" + id + "/activity")
        .then(res => {
          dispatch(ordersActions.fetchActivityLogResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function downloadWaybill(id, only_barcode = false) {
  var timezone = momentTz.tz.guess();
  return dispatch =>
    new Promise((resolve, reject) => {
      axios({
        url: `orders/${id}/waybill?timezone=${timezone}${
          only_barcode ? "&only_barcode=true" : ""
        }`,
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          if (only_barcode) {
            var file = new Blob([response.data], { type: "text/html" });
            var fileURL = URL.createObjectURL(file);
            document.querySelector("#pdf-frame").src = fileURL;
            window.setTimeout(function() {
              document.querySelector("#pdf-frame").contentWindow.print();
            }, 500);
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "order_" + id + ".pdf");
            document.body.appendChild(link);
            link.click();
          }

          return resolve(response);
        })
        .catch(err => {
          dispatch(authActions.serverError('Failed to generate' + only_barcode? ' barcode' : ' waybill'));

          return reject(err);
        });
    });
}

export function downloadDHLWaybill(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      axios({
        url: `shipping/${id}/waybill`,
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "international_order_" + id + ".pdf");
            document.body.appendChild(link);
            link.click();
          return resolve(response);
        })
        .catch(err => {
          dispatch(authActions.serverError('Failed to generate waybill'));

          return reject(err);
        });
    });
}

export function editOrderDeliveryPrice(data) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("orders/" + data.id + "/update_price", data.input)
        .then(res => {
          dispatch(ordersActions.editOrderDeliveryPrice({data: data.input}));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}

export function viewWaybill(id) {
  var timezone = momentTz.tz.guess();

  return dispatch =>
    new Promise((resolve, reject) => {
      axios({
        url: `orders/waybill?timezone=${timezone}&orders_ids=${id}`,
        method: "POST",
        responseType: "blob"
      })
        .then(response => {
          var file = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = fileURL;
          a.target = "_blank";
          a.click();

          return resolve(response);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function fetchOrder(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("orders/" + id)
        .then(res => {
          dispatch(ordersActions.fetchOrderResolved(order(res.data.data)));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}

export function fetchOrderDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("enhanced/orders/" + id)
        .then(res => {
          let orderObject = order(res.data.data);
          dispatch(ordersActions.fetchOrderDetailsResolved(orderObject));
          return resolve(orderObject);
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}

export function fetchOrdersRoute(number, date) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("map/" + number + "?search=today:" + date)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function fetchTrackingOrder(number, code = null) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(
        code ? "tracking/" + number + "?code=" + code : "tracking/" + number
      )
        .then(res => {
          dispatch(ordersActions.fetchOrderResolved(order(res.data.data)));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}

export function exportOrdersReports(input = [], fields = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `export/reports/orders${
        input.length > 0
          ? "?" +
            (fields.length > 0
              ? serializeInput(input) + "&fields=" + fields
              : serializeInput(input))
          : fields.length > 0
            ? "?fields=" + fields
            : ""
      }`;
      Http.get(url)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function exportDashboardOrders(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `export/dashboard/orders${
        input.length > 0 ? "?" + serializeInput(input) : ""
      }`;
      Http.get(url)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function exportPiggyBankTaskReports(piggybank_id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = "export/piggy_banks/" + piggybank_id;
      Http.get(url)
        .then(res => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res);
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}

export function updateOrder(input, remove = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("orders/" + input.id, input.data)
        .then(res => {
          dispatch(
            ordersActions.updateOrderResolved(order(res.data.data), remove)
          );
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function cancelMultipleOrders(input, remove = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("orders/cancel-multiple", input.data)
        .then(res => {
          var orders = [];
          res.data.orders.forEach(element => {
            orders.push(order(element));
          });
            dispatch(ordersActions.updateMultipleOrdersResolved(orders, remove));
          return resolve(res.data.meta.message);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });

  
}     
export function importAuditedOrders(input, remove = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("orders/import-audited", input.data)
        .then(res => {
          var orders = [];
          res.data.orders.forEach(element => {
            orders.push(order(element));
          });
            dispatch(ordersActions.updateMultipleOrdersResolved(orders, remove));
          return resolve(res.data.meta.message);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });

  
}  
export function getNewPrice(id,input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(`orders/${id}/newPrice${input.length > 0 ?  "?"+ serializeInput(input) : ""}`)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}


export function updateImportantComment(input, make_important) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("orders/" + input.order_id + "/comments/" + input.id, input.info)
        .then(res => {
          dispatch(
            ordersActions.updateEnhancedImpCommentResolved(
              res.data.data,
              make_important,
              input.customer_id ? input.customer_id : null
            )
          );
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createBulk(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post('orders/bulk', info.input)
        .then((res) => {
          return resolve(res.data.data);
        })
        .catch((err) => {
          if (err.statusCode == -2) {
            return resolve();
          }
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createBulkReturn(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post('orders/bulk-return', info)
        .then((res) => {
          return resolve(res.data.data);
        })
        .catch((err) => {
          if (err.statusCode == -2) {
            return resolve();
          }
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editBulkReturn(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("orders/bulk-return/edit", info)
        .then((res) => {
          return resolve(res.data.data);
        })
        .catch((err) => {
          if (err.statusCode == -2) {
            return resolve();
          }
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function create(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("orders", info.input)
        .then(res => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createPiggyBank(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("orders/piggy_banks", info.input)
        .then(res => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          this.props.dispatch(
            customersActions.updatePiggyBankList(
              res.data.data,
              info.input.piggyBank_id
            )
          );
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createComment(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("orders/" + info.order_id + "/comments", info.input)
        .then(res => {
          dispatch(
            ordersActions.addEnhancedCommentResolved(
              res.data.data,
              info.customer_id ? info.customer_id : 0
            )
          );
          dispatch(authActions.serverSuccess("Comment added successfully"));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchComments(order_id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("orders/" + order_id + "/comments")
        .then(res => {
          if (!Array.isArray(res.data.data)) {
            let last_updated_comment = [];
            last_updated_comment.push(res.data.data);
            dispatch(ordersActions.fetchCommentsResolved(last_updated_comment));
          } else {
            dispatch(ordersActions.fetchCommentsResolved(res.data.data));
          }
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteComment(comment_id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.delete(`comments/${comment_id}`)
        .then(res => {
          dispatch(ordersActions.deleteComment(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editComment(data) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put(`orders/${data.order_id}/comments/${data.comment_id}`, data.info)
        .then(res => {
          dispatch(ordersActions.editComment(res.data.data));
          dispatch(authActions.serverSuccess("Comment edited successfully"));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchUnPaidOrders(customer_id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("orders/unpaid/" + customer_id)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchTasksForOrder(order_id, input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(`orders/${order_id}/tasks${serializeSearchInput(input, false)}`)
        .then(res => {
          dispatch(ordersActions.fetchTasksForOrderResolved(res.data.data));
          return resolve(res.data.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function exportOrders(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `export/orders${
        input.length > 0 ? "?" + serializeInput(input) : ""
      }`;
      Http.get(url)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function getOrderStatus(order_id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `order_status/${order_id}`;
      Http.get(url)
        .then(res => {
          dispatch(authActions.serverSuccess(res.data.message));
          return resolve(res);
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}

export function fetchOrderImports(pageNumber = null) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `imports/orders` + (pageNumber ? "?page=" + pageNumber : "");
      Http.get(url)
        .then((res) => {
          var imports = [];
          res.data.data.forEach((element) => {
            imports.push(orderImport(element));
          });

          dispatch(ordersActions.fetchOrderImportsResolved(imports));

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function fetchCashboxOrders(
  cashbox_id,
  input = [],
  other_input = [],
  customer = null,
  pageNumber = null,
  type = Search_page,
  orderBy = "created_at",
  sortedBy = "asc"
) {
  return dispatch =>
  new Promise((resolve, reject) => {
    let urlBase = `cashbox/${cashbox_id}/orders`;
    let url =
    input.length > 0
      ? `${urlBase}${serializeSearchInput(input, false)}`
      : urlBase;
    Http.get(
      url + 
      (input.length == 0 || other_input.length == 0 ?  '?' : '&') +
      `orderBy=${orderBy}&sortedBy=${sortedBy}` +
      (pageNumber ? "&page=" + pageNumber : "")
      )
      .then(res => {
        var orders = [];
        res.data.data.forEach(element => {
          orders.push(order(element));
        });
        dispatch(ordersActions.fetchCashboxOrdersResolved(orders, customer));
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function revertOrderImport(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `imports/orders/revert/${input.data.import_id}`;
      Http.get(url)
        .then((res) => {
          dispatch(
            ordersActions.fetchRevertedOrderImportResolved(
              orderImport(res.data.import),
            ),
          );
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchAuditedPrices(order_id){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `orders/${order_id}/audited-prices`;
      Http.get(url)
        .then((res) => {
           
            let audited_collections = [];
            res.data.data.forEach(element => {
              audited_collections.push(auditedOrderCollection(element));
            });
            dispatch(ordersActions.fetchAuditedOrderPrices(audited_collections));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createAuditedPrices(input){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `orders/${input.order_id}/audited-prices`;
      Http.post(url, input.data)
        .then((res) => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editAuditedPrices(input){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `orders/${input.order_id}/audited-prices`;
      Http.put(url, input.data)
        .then((res) => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchAuditedCollectionAmounts(order_id){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `orders/${order_id}/audited-collection-amounts`;
      Http.get(url)
        .then((res) => {
           
            let audited_collections = [];
            res.data.data.forEach(element => {
              audited_collections.push(auditedOrderCollection(element));
            });
            dispatch(ordersActions.fetchAuditedOrderCollectionAmounts(audited_collections));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editAuditedCollectionAmounts(input){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `orders/${input.order_id}/audited-collection-amounts`;
      Http.put(url, input.data)
        .then((res) => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createAuditedCollectionAmounts(input){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `orders/${input.order_id}/audited-collection-amounts`;
      Http.post(url, input.data)
        .then((res) => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function getPrice(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("shipping/delivery-price", input)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}