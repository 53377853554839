/* import React from 'react';
import PhoneInput, {
  isValidPhoneNumber,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import _ from 'lodash';

class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);
    let initialCountry = process.env.REACT_APP_COUNTRY? process.env.REACT_APP_COUNTRY: 'LB';
    let phoneNumber = props.value;
    let parsedPhone = phoneNumber ? parsePhoneNumber(phoneNumber) : null;

    if (parsedPhone && parsedPhone.isValid) {
      initialCountry = parsedPhone.country;
    }

    const defaultCountryCode = initialCountry ? getCountryCallingCode(initialCountry): null;
    phoneNumber = phoneNumber || (defaultCountryCode?`+${defaultCountryCode}`: null);

    this.state = {
      phoneNumber: phoneNumber,
      country: initialCountry,
      defaultCountryCode: defaultCountryCode,
    };

    // Debounce handleChangeValue method
    this.debouncedHandleChangeValue = _.debounce(this.handleChangeValue, 600);

    this.debouncedHandleCountryChange = _.debounce(this.handleCountryChange, 600);
  }
  debouncedCountryChange = (selectedCountry) => {
    this.debouncedHandleCountryChange(selectedCountry);
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    let phoneNumber = String(nextProps.value);
    if (phoneNumber && !phoneNumber.startsWith('+')) {
      const defaultCountryCode = getCountryCallingCode(prevState.country || process.env.REACT_APP_COUNTRY || 'LB');
      phoneNumber = `+${defaultCountryCode}${phoneNumber}`;
    }
  
    const parsedPhone = phoneNumber ? parsePhoneNumber(phoneNumber) : null;
    if (phoneNumber && parsedPhone && parsedPhone.isValid) {
      return {
        phoneNumber: nextProps.value, // Keep the original value without the appended country code
        country: parsedPhone.country,
      };
    }
    return null;
  }

  componentWillUnmount() {
    // Cancel any pending debounced calls to prevent memory leaks
    this.debouncedHandleChangeValue.cancel();
  }

  handleChangeValue = (new_value) => {
    let is_valid_new_value = true;

    if (!new_value && this.props.isFilter) {
      new_value = "+" + this.state.defaultCountryCode;
      this.setState({ phoneNumber: new_value });
    }
    if (new_value && !new_value.includes('+') && !isNaN(new_value)) {
      new_value = `+${new_value}`;
    }
    if (this.state.country === 'LB' && new_value && !this.props.isFilter) {
      new_value = new_value.replace(/^(\+961)0?/, "$1");
    }
  
    this.setState({ phoneNumber: new_value });
    
    if(new_value && !isValidPhoneNumber(new_value)) {
      is_valid_new_value = false;
    }

    this.props.handleAddValidationErrorChange(
      this.props.name,
      is_valid_new_value ? null : 'Phone number format is invalid.',
    );
    this.props.handleChange(this.props.name, new_value);
  };

  handleInputChange = (new_value) => {
    if (new_value === '') {
      // If input is cleared, update state immediately
      this.handleChangeValue(new_value);
    } else {
      // Otherwise, use the debounced version
      this.debouncedHandleChangeValue(new_value);
    }
  };

  handleCountryChange = (selectedCountry) => {
    if (
      typeof selectedCountry === 'undefined' ||
      selectedCountry === this.state.country
    )
      return;
    const newCountryCode = `+${getCountryCallingCode(selectedCountry)}`;
    let { phoneNumber } = this.state;
  
    // Remove the current country code if present
    if (phoneNumber) {
      const currentParsedPhone = parsePhoneNumber(String(phoneNumber));
      if (currentParsedPhone && currentParsedPhone.country) {
        // Extract the numeric part without the country code
        let numericPart = phoneNumber.slice(currentParsedPhone.countryCallingCode.length + 1); // +1 for the '+' sign
        // Combine the new country code with the numeric part
        phoneNumber = newCountryCode + numericPart;
      } else {
        // If unable to parse, just prepend the new country code
        phoneNumber = newCountryCode + phoneNumber;
      }
    } else {
      // If the phone number is empty, set it to just the new country code
      phoneNumber = newCountryCode;
    }
    if (selectedCountry === 'LB') {
      // Remove the leading '0' from specific area codes after the country code
      phoneNumber = phoneNumber.replace(/^(\+961)0?/, "$1");
    }
    this.setState({
      country: selectedCountry,
      phoneNumber: phoneNumber,
    });
   
    // Update parent component
    
    const isValidNewValue = isValidPhoneNumber(phoneNumber);
    this.props.handleAddValidationErrorChange(
      this.props.name,
      isValidNewValue ? null : 'Phone number format is invalid.',
    );
    this.props.handleChange(this.props.name, phoneNumber);
  };

  render() {
    const { name, label_text, errorText } = this.props;
    const { phoneNumber, country } = this.state;
    let country_list = getCountries().filter((item) => item !== 'IL');

    return (
      <div className={errorText ? 'phone_number_error' : ''}>
        {label_text && (
          <label htmlFor={name} className="react-phone-number-label">
            {label_text}
          </label>
        )}
        <PhoneInput
          key={country}
          placeholder="+000 00 000 0000"
          value={phoneNumber}
          onChange={this.handleInputChange} // Changed to debounced method
          onCountryChange={this.debouncedCountryChange}
          id={name}
          defaultCountry={country}
          countries={country_list}
          flags={flags}
        />
        {errorText && (
          <div className={'phone_number_error_msg'}>{errorText}</div>
        )}
      </div>
    );
  }
}

export default PhoneNumberInput;
 */

import React from 'react';
import PhoneInput, {
  isValidPhoneNumber,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import _ from 'lodash';
import './index.css';

class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: props.value,
    };

    // Debounce the handleChangeValue method
    this.debouncedHandleChangeValue = _.debounce(this.handleChangeValue, 600);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let phoneNumber = nextProps.value;
    if (phoneNumber && !String(phoneNumber).startsWith('+')) {
      const defaultCountryCode = getCountryCallingCode(process.env.REACT_APP_COUNTRY || 'LB');
      phoneNumber = `+${defaultCountryCode}${phoneNumber}`;
    }

    return {
      phoneNumber: phoneNumber,
    };
  }

  componentWillUnmount() {
    // Cancel any pending debounced calls to prevent memory leaks
    this.debouncedHandleChangeValue.cancel();
  }

/*   checkIsValid = (phoneNumber) => {
    const phoneNumberDetails = parsePhoneNumber(String(phoneNumber));
    if (phoneNumberDetails && phoneNumberDetails?.country == 'CI') {
      if(phoneNumberDetails.nationalNumber.length == 8 || phoneNumberDetails.nationalNumber.length == 10){
        return true;
      }else{
        return false;
      }
    } else {
      if(isValidPhoneNumber(phoneNumber)){
        return true;
      }else{
        return false;
      }
    }
  } */

  handleChangeValue = (new_value) => {
    let is_valid_new_value = true;
    
    if(new_value !== '' && new_value !== undefined && new_value !== null && !isValidPhoneNumber(new_value)) {
      is_valid_new_value = false;
    }
    this.props.handleAddValidationErrorChange(
      this.props.name,
      is_valid_new_value ? null : 'Phone number format is invalid.',
    );

    this.setState({ phoneNumber: new_value });
    this.props.handleChange(this.props.name, new_value);
  }

  handleInputChange = (new_value) => {
    if (new_value === '') {
      // If input is cleared, update state immediately
      this.handleChangeValue(new_value);
    } else {
      // Otherwise, use the debounced version
      this.debouncedHandleChangeValue(new_value);
    }
  };

  render() {
    const { name, label_text, errorText } = this.props;
    const { phoneNumber } = this.state;
    let country_list = getCountries().filter((item) => item !== 'IL');

    return (
      <div className={errorText ? 'phone_number_error' : ''}>
        {label_text && (
          <label htmlFor={name} className="react-phone-number-label2">
            {label_text}
          </label>
        )}
        <PhoneInput
          international
          id={name}
          placeholder="+000 00 000 0000"
          defaultCountry={process.env.REACT_APP_COUNTRY ? process.env.REACT_APP_COUNTRY : 'LB'}
          value={phoneNumber || ''}
          onChange={this.handleInputChange}
          countries={country_list}
          flags={flags}
        />
        {errorText && (
          <div className={'phone_number_error_msg'}>{errorText}</div>
        )}
      </div>
    );
  }
}

export default PhoneNumberInput;
