import React from "react";
import APPCONFIG from "constants/Config";
import QueueAnim from "rc-queue-anim";
import CreateForm from "../app/routes/customers-page/create-customer/components/CreateForm";
import FlatButton from "@mui/material/Button";
import { Link } from "react-router-dom";
import * as authService from "services/authService";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "utils/helpers";

class SignUp extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      brand: APPCONFIG.brand,
      isLoading: false,
      data: props.shop
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.shop) && this.props.location_Search) {
      this.fetchStoreData();
    }
  }

  fetchStoreData() {
    this.setState({
      isLoading: true
    });
    const searchParams = new URLSearchParams(this.props.location_Search);
    let shop = searchParams.get('shop');
    let code = searchParams.get('code');

    this.props
      .dispatch(authService.getShopifyShop(code, shop))
      .then(data => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState({
          isLoading: false,
          data: data.data.shop
        });
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState({
          isLoading: false,
          responseError
        });
      });
  }

  render() {
    return (
      <div className="page-login">
        <div
          className="main-body"
          style={{ maxWidth: "1000px", paddingTop: "15px" }}
        >
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              <div className="body-inner">
                <div className="card bg-white" style={{ overflow: "visible" }}>
                  <div className="card-content">
                    <section className="logo text-center">
                      <h1>
                        <a href="https://wakilni.com/">
                          <img
                            src="/assets/images/wakilni-logo-v2.png"
                            height="40"
                            alt={this.state.brand}
                          />
                        </a>
                      </h1>
                      <h3>Sign Up</h3>
                    </section>

                    <CreateForm shopify_data={this.state.data} />
                  </div>
                </div>
                <div className="additional-info">
                  <Link
                    to={`login${this.props.location_Search}`}
                    style={{ textDecoration: "none" }}
                  >
                    Back to login
                  </Link>
                </div>
              </div>
            </div>
          </QueueAnim>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shop: state.auth.shop,
  location_Search: state.router.location.search
});

export default connect(mapStateToProps)(SignUp);
