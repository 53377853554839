export const SHOW_ORDER_CHILDREN = "SHOW_ORDER_CHILDREN";
export const HANDLE_SELECT_ORDER = "HANDLE_SELECT_ORDER";
export const HANDLE_SELECT_ALL_ORDER = "HANDLE_SELECT_ALL_ORDER";
export const UPDATE_TASK_LOCATION_RESOLVED = "UPDATE_TASK_LOCATION_RESOLVED";
export const FETCH_ORDERS_RESOLVED = "FETCH_ORDERS_RESOLVED";
export const FETCH_BULK_RETURN_FORM_ORDERS_RESOLVED = "FETCH_BULK_RETURN_FORM_ORDERS_RESOLVED";
export const FETCH_CASHBOXES_ORDERS_RESOLVED = "FETCH_CASHBOXES_ORDERS_RESOLVED";
export const FETCH_ORDER_RESOLVED = "FETCH_ORDER_RESOLVED";
export const UPDATE_TASK_RESOLVED = "UPDATE_TASK_RESOLVED";
export const UPDATE_ORDER_RESOLVED = "UPDATE_ORDER_RESOLVED";
export const UPDATE_MULTIPLE_ORDERS_RESOLVED =
  "UPDATE_MULTIPLE_ORDERS_RESOLVED";
export const ADD_ORDER_RESOLVED = "ADD_ORDER_RESOLVED";
export const ADD_ENHANCED_COMMENT_RESOLVED = "ADD_ENHANCED_COMMENT_RESOLVED";
export const FETCH_ORDERS_REPORTS_RESOLVED = "FETCH_ORDERS_REPORTS_RESOLVED";
export const CHOSEN_PACKAGE_TYPE = "CHOSEN_PACKAGE_TYPE";
export const FETCH_ACTIVE_ORDERS_RESOLVED = "FETCH_ACTIVE_ORDERS_RESOLVED";
export const FETCH_CASHBOX_ORDERS_RESOLVED = "FETCH_CASHBOX_ORDERS_RESOLVED";
export const LISTENER_ORDER_CREATED = "LISTENER_ORDER_CREATED";
export const SEEN_NEW_ORDERS = "SEEN_NEW_ORDERS";
export const SEEN_NEW_ACTIVE_ORDERS = "SEEN_NEW_ACTIVE_ORDERS";
export const LISTENER_UPDATE_ORDER_RESOLVED = "LISTENER_UPDATE_ORDER_RESOLVED";
export const LISTENER_UPDATE_MULTIPLE_ORDERS_RESOLVED = "LISTENER_UPDATE_MULTIPLE_ORDERS_RESOLVED";
export const FETCH_ACTIVITY_LOG_RESOLVED = "FETCH_ACTIVITY_LOG_RESOLVED";
export const SEARCH_ORDERS_RESOLVED = "SEARCH_ORDERS_RESOLVED";
export const CLEAR_ORDERS_RESOLVED = "CLEAR_ORDERS_RESOLVED";
export const FETCH_COMMENTS_RESOLVED = "FETCH_COMMENTS_RESOLVED";
export const UPDATE_ENHANCED_IMP_COMMENT_RESOLVED =
  "UPDATE_ENHANCED_IMP_COMMENT_RESOLVED";
export const FETCH_ORDER_DETAILS_RESOLVED = "FETCH_ORDER_DETAILS_RESOLVED";
export const CLEAR_COMMENTS_RESOLVED = "CLEAR_COMMENTS_RESOLVED";
export const CLEAR_TASKS_RESOLVED = "CLEAR_TASKS_RESOLVED";
export const FETCH_UNPAID_ORDERS = "FETCH_UNPAID_ORDERS";
export const FETCH_TASKS_FOR_ORDER = "FETCH_TASKS_FOR_ORDER";
export const DELETE_COMMENT_RESOLVED = "DELETE_COMMENT_RESOLVED";
export const EDIT_COMMENT_RESOLVED = "EDIT_COMMENT_RESOLVED";
export const EDIT_ORDER_DELIVERY_PRICE_RESOLVED = "EDIT_ORDER_DELIVERY_PRICE_RESOLVED";
export const CRITICAL_ORDERS_RESOLVED = "CRITICAL_ORDERS_RESOLVED";
export const CLEAR_CRITICAL_ORDERS_RESOLVED = "CLEAR_CRITICAL_ORDERS_RESOLVED";
export const FETCH_PICKUP_ORDERS_RESOLVED = "FETCH_PICKUP_ORDERS_RESOLVED";
export const FETCH_RETURN_ORDERS_RESOLVED = "FETCH_RETURN_ORDERS_RESOLVED";
export const FETCH_EXPRESS_ORDERS_RESOLVED = "FETCH_EXPRESS_ORDERS_RESOLVED";
export const CLEAR_ORDER_LIST = "CLEAR_ORDER_LIST";
export const FETCH_ORDER_IMPORTS_RESOLVED = "FETCH_ORDER_IMPORTS_RESOLVED";
export const FETCH_REVERTED_ORDER_IMPORT_RESOLVED = "FETCH_REVERTED_ORDER_IMPORT_RESOLVED";
export const FETCH_AUDITED_ORDER_PRICE_RESOLVED = 'FETCH_AUDITED_ORDER_PRICE_RESOLVED';
export const EDIT_AUDITED_ORDER_PRICE_RESOLVED = 'EDIT_AUDITED_ORDER_PRICE_RESOLVED';
export const FETCH_AUDITED_ORDER_COLLECTION_RESOLVED = 'FETCH_AUDITED_ORDER_COLLECTION_RESOLVED';

