/* eslint-disable no-redeclare */
/* eslint-disable no-case-declarations */
import APPCONFIG from "constants/Config";
import {
  TOGGLE_BOXED_LAYOUT,
  TOGGLE_COLLAPSED_NAV,
  TOGGLE_NAV_BEHIND,
  TOGGLE_FIXED_HEADER,
  CHANGE_SIDEBAR_WIDTH,
  CHANGE_COLOR_OPTION,
  CHANGE_THEME,
  FETCH_APP_TOKENS,
  FETCH_APPS,
  CREATE_APP_RESOLVED,
  DELETE_APP_RESOLVED,
  CREATE_APP_TOKEN,
  DELETE_APP_TOKEN,
  EDIT_APP_TOKEN,
  CREATE_WEBHOOK,
  FETCH_WEBHOOKS,
  DELETE_WEBHOOK,
  EDIT_WEBHOOK,
  FETCH_WEBHOOK_EVENTS,
  UPDATE_APP_RESOLVED,
  FETCH_CONFIGURATIONS,
  LINK_CONFIGURATION_RESOLVED,
  EDIT_CONFIGURATION_RESOLVED,
  FETCH_CURRENCIES_RESOLVED,
  UPDATE_CURRENCY_RESOLVED,
  DELETE_CURRENCY_RESOLVED,
  CREATE_CURRENCY_RESOLVED,
  LINK_PREFERENCES_RESOLVED,
  FETCH_PREFERENCES_RESOLVED,
  LINK_CURRENCY_RESOLVED,
  FETCH_CUSTOM_MESSAGES,
  FETCH_SETTINGS_AREAS_RESOLVED,
  UPDATE_AREA_RESOLVED,
  DELETE_AREA_RESOLVED,
  CREATE_AREA_RESOLVED,
  FETCH_STOCKS_RESOLVED,
  UPDATE_STOCK_RESOLVED,
  DELETE_STOCK_RESOLVED,
  CREATE_STOCK_RESOLVED,
  FETCH_VEHICLES_RESOLVED,
  UPDATE_VEHICLE_RESOLVED,
  DELETE_VEHICLE_RESOLVED,
  CREATE_VEHICLE_RESOLVED,
  FETCH_ZONES_RESOLVED,
  UPDATE_ZONE_RESOLVED,
  DELETE_ZONE_RESOLVED,
  CREATE_ZONE_RESOLVED,
  FETCH_OFFICES_RESOLVED,
  UPDATE_OFFICE_RESOLVED,
  DELETE_OFFICE_RESOLVED,
  CREATE_OFFICE_RESOLVED,
  FETCH_STORE_CURRENCIES_RESOLVED,
  UPDATE_STORE_CURRENCY_RESOLVED,
  DELETE_STORE_CURRENCY_RESOLVED,
  CREATE_STORE_CURRENCY_RESOLVED,
  EDIT_CUSTOM_MESSAGE_RESOLVED,
  FETCH_SERVICES_RESOLVED,
  CREATE_SERVICE_RESOLVED,
  UPDATE_SERVICE_RESOLVED,
  DELETE_SERVICE_RESOLVED,
  FETCH_BANNERS_RESOLVED,
  CREATE_BANNER_RESOLVED,
  UPDATE_BANNER_RESOLVED,
  DELETE_BANNER_RESOLVED,
  FETCH_INTERNAL_BANNERS_RESOLVED,
  CREATE_INTERNAL_BANNER_RESOLVED,
  UPDATE_INTERNAL_BANNER_RESOLVED,
  DELETE_INTERNAL_BANNER_RESOLVED,
  FETCH_SURVEY_LIST_RESOLVED,
  FETCH_ACTIVE_SURVEY_RESOLVED,
  FETCH_SETTINGS_AREA_ROUTES_RESOLVED,
  UPDATE_AREA_ROUTE_RESOLVED,
  DELETE_AREA_ROUTE_RESOLVED,
  CREATE_AREA_ROUTE_RESOLVED,
  CREATE_DISTRICT_RESOLVED,
  FETCH_DISTRICT_RESOLVED,
  UPDATE_DISTRICT_RESOLVED,
  DELETE_DISTRICT_RESOLVED,
  CREATE_GOVERNORATE_RESOLVED,
  FETCH_GOVERNORATE_RESOLVED,
  UPDATE_GOVERNORATE_RESOLVED,
  DELETE_GOVERNORATE_RESOLVED,
  FETCH_HOLIDAYS_RESOLVED,
  GET_HOLIDAY_RESOLVED,
  UPDATE_HOLIDAY_RESOLVED,
  DELETE_HOLIDAY_RESOLVED,
  CREATE_HOLIDAY_RESOLVED,
} from "../ActionTypes/settings";
import _ from "lodash";

const initialSettings = {
  ...APPCONFIG.settings,
  currencyList: [],
  officeList: [],
  areaList: [],
  zoneList: [],
  stockList: [],
  vehicleList: [],
  tokenList: [],
  appList: [],
  webhookList: [],
  webhookEventList: [],
  configurationList: [],
  customMessagesList: [],
  serviceList: [],
  bannerList: [],
  internalBannerList: [],
  preferences: {},
  surveyList: [],
  districtList: [],
  governorateList: [],
  holidayList:[],
  areaRouteList: []
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case EDIT_CUSTOM_MESSAGE_RESOLVED: {
      let messages = JSON.parse(JSON.stringify(state.customMessagesList));
      messages.forEach((item, index) => {
        if (item.id == action.payload.id) {
          messages[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        customMessagesList: messages,
      });
    }
    case FETCH_CUSTOM_MESSAGES:
      return Object.assign({}, state, {
        customMessagesList: action.payload.data,
      });
    case EDIT_CONFIGURATION_RESOLVED: {
      let settings = JSON.parse(JSON.stringify(state.configurationList));
      settings.forEach((item, index) => {
        if (item.id == action.payload.id) {
          settings[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        configurationList: settings,
      });
    }
     
    case LINK_CONFIGURATION_RESOLVED: {
      let settings = JSON.parse(JSON.stringify(state.configurationList));
      settings.push(action.payload);
      return Object.assign({}, state, {
        configurationList: settings,
      });
    }
    case FETCH_CONFIGURATIONS:
      return Object.assign({}, state, {
        configurationList: action.payload.data,
      });
    case FETCH_APP_TOKENS:
      return Object.assign({}, state, {
        tokenList: action.payload.data,
      });
    case FETCH_APPS:
      return Object.assign({}, state, {
        appList: action.payload.data,
      });
    case CREATE_APP_RESOLVED: {
      let appList = JSON.parse(JSON.stringify(state.appList));
      appList.push(action.payload);
      return Object.assign({}, state, {
        appList: appList,
      });
    }
      
    case UPDATE_APP_RESOLVED: {
      let appList = JSON.parse(JSON.stringify(state.appList));
      appList.forEach((item, index) => {
        if (item.id == action.payload.id) {
          appList[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        appList: appList,
      });
    }
      
    case DELETE_APP_RESOLVED: {
      let appList = JSON.parse(JSON.stringify(state.appList));
      _.remove(appList, function (o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        appList: appList,
      });
    }
     
    case CREATE_APP_TOKEN: {
      let tokenList = JSON.parse(JSON.stringify(state.tokenList));
      tokenList.push(action.payload);
      return Object.assign({}, state, {
        tokenList: tokenList,
      });
    }
    
    case DELETE_APP_TOKEN: {
      let app_tokens = JSON.parse(JSON.stringify(state.tokenList));
      let new_tokens = _.remove(app_tokens, function (o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        tokenList: app_tokens,
      });
    }
     
    case EDIT_APP_TOKEN: {
      let tokens = JSON.parse(JSON.stringify(state.tokenList));
      tokens.forEach((item, index) => {
        if (item.id == action.payload.id) {
          tokens[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        tokenList: tokens,
      });
    }
    case FETCH_WEBHOOKS:
      return Object.assign({}, state, {
        webhookList: action.payload.data,
      });
    case FETCH_WEBHOOK_EVENTS:
      return Object.assign({}, state, {
        webhookEventList: action.payload.data,
      });
    case CREATE_WEBHOOK:
      var webhookList = JSON.parse(JSON.stringify(state.webhookList));
      webhookList.push(action.payload);
      return Object.assign({}, state, {
        webhookList: webhookList,
      });
    case DELETE_WEBHOOK:
      var webhooks = JSON.parse(JSON.stringify(state.webhookList));
      webhooks.forEach((item, index) => {
        if (item.id == action.payload.id) {
          webhooks.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        webhookList: webhooks,
      });
    case EDIT_WEBHOOK:
      var webhooks = JSON.parse(JSON.stringify(state.webhookList));
      webhooks.forEach((item, index) => {
        if (item.id == action.payload.id) {
          webhooks[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        webhookList: webhooks,
      });
    case TOGGLE_BOXED_LAYOUT:
      return {
        ...state,
        layoutBoxed: action.isLayoutBoxed,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed,
      };
    case TOGGLE_NAV_BEHIND:
      return {
        ...state,
        navBehind: action.isNavBehind,
      };
    case TOGGLE_FIXED_HEADER:
      return {
        ...state,
        fixedHeader: action.isFixedHeader,
      };
    case CHANGE_SIDEBAR_WIDTH:
      return {
        ...state,
        sidebarWidth: action.sidebarWidth,
      };
    case CHANGE_COLOR_OPTION:
      return {
        ...state,
        colorOption: action.colorOption,
      };
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.theme,
      };

    //CURRENCIES
    case FETCH_CURRENCIES_RESOLVED:
      return Object.assign({}, state, {
        currencyList: action.payload,
      });
    case UPDATE_CURRENCY_RESOLVED: {
      let currencies = JSON.parse(JSON.stringify(state.currencyList));
      currencies.forEach((item, index) => {
        if (item.id == action.payload.id) {
          currencies[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        currencyList: currencies,
      });
    }
     
    case DELETE_CURRENCY_RESOLVED: {
      let currencies = JSON.parse(JSON.stringify(state.currencyList));
      currencies.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          currencies.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        currencyList: currencies,
      });
    }
     
    case CREATE_CURRENCY_RESOLVED: {
      let currencies = JSON.parse(JSON.stringify(state.currencyList));
      currencies.push(action.payload);
      return Object.assign({}, state, {
        currencyList: currencies,
      });
    }

    //AREAS
    case FETCH_SETTINGS_AREAS_RESOLVED:
      return Object.assign({}, state, {
        areaList: action.payload.data,
      });
    case UPDATE_AREA_RESOLVED: {
      let areas = JSON.parse(JSON.stringify(state.areaList));
      areas.forEach((item, index) => {
        if (item.id == action.payload.id) {
          areas[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        areaList: areas,
      });
    }

    case DELETE_AREA_RESOLVED: {
      let areas = JSON.parse(JSON.stringify(state.areaList));
      let new_areas = _.remove(areas, function (o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        areaList: areas,
      });
    }

    case CREATE_AREA_RESOLVED: {
      let areas = JSON.parse(JSON.stringify(state.areaList));
      areas.push(action.payload.data);
      return Object.assign({}, state, {
        areaList: areas,
      });
    }

    //STOCKS
    case FETCH_STOCKS_RESOLVED:
      return Object.assign({}, state, {
        stockList: action.payload.data,
      });
    case UPDATE_STOCK_RESOLVED: {
      let stocks = JSON.parse(JSON.stringify(state.stockList));
      stocks.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          stocks[index] = action.payload.data;
        }
      });
      return Object.assign({}, state, {
        stockList: stocks,
      });
    }

    case DELETE_STOCK_RESOLVED: {
      let stocks = JSON.parse(JSON.stringify(state.stockList));
      stocks.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          stocks.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        stockList: stocks,
      });
    }

    case CREATE_STOCK_RESOLVED: {
      let stocks = JSON.parse(JSON.stringify(state.stockList));
      stocks.push(action.payload.data);
      return Object.assign({}, state, {
        stockList: stocks,
      });
    }

    //VEHICLES
    case FETCH_VEHICLES_RESOLVED:
      return Object.assign({}, state, {
        vehicleList: action.payload.data,
      });
    case UPDATE_VEHICLE_RESOLVED: {
      let vehicles = JSON.parse(JSON.stringify(state.vehicleList));
      vehicles.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          vehicles[index] = action.payload.data;
        }
      });
      return Object.assign({}, state, {
        vehicleList: vehicles,
      });
    }

    case DELETE_VEHICLE_RESOLVED: {
      let vehicles = JSON.parse(JSON.stringify(state.vehicleList));
      vehicles.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          vehicles.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        vehicleList: vehicles,
      });
    }

    case CREATE_VEHICLE_RESOLVED: {
      let vehicles = JSON.parse(JSON.stringify(state.vehicleList));
      vehicles.push(action.payload.data);
      return Object.assign({}, state, {
        vehicleList: vehicles,
      });
    }

    //ZONES
    case FETCH_ZONES_RESOLVED:
      return Object.assign({}, state, {
        zoneList: action.payload,
      });

    case UPDATE_ZONE_RESOLVED: {
      let zones = JSON.parse(JSON.stringify(state.zoneList));
      zones.forEach((item, index) => {
        if (item.id == action.payload.id) {
          zones[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        zoneList: zones,
      });
    }

    case DELETE_ZONE_RESOLVED: {
      let zones = JSON.parse(JSON.stringify(state.zoneList));
      let new_zones = _.remove(zones, function (o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        zoneList: zones,
      });
    }

    case CREATE_ZONE_RESOLVED: {
      let zones = JSON.parse(JSON.stringify(state.zoneList));
      zones.push(action.payload);
      return Object.assign({}, state, {
        zoneList: zones,
      });
    }

    //OFFICES
    case FETCH_OFFICES_RESOLVED:
      return Object.assign({}, state, {
        officeList: action.payload.data,
      });
    case UPDATE_OFFICE_RESOLVED: {
      let offices = JSON.parse(JSON.stringify(state.officeList));
      offices.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          offices[index] = action.payload.data;
        }
      });
      return Object.assign({}, state, {
        officeList: offices,
      });
    }

    case DELETE_OFFICE_RESOLVED: {
      let offices = JSON.parse(JSON.stringify(state.officeList));
      offices.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          offices.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        officeList: offices,
      });
    }

    case CREATE_OFFICE_RESOLVED: {
      let offices = JSON.parse(JSON.stringify(state.officeList));
      offices.push(action.payload.data);
      return Object.assign({}, state, {
        officeList: offices,
      });
    }

    //SERVICES
    case FETCH_SERVICES_RESOLVED:
      return Object.assign({}, state, {
        serviceList: action.payload.data,
      });

    case CREATE_SERVICE_RESOLVED: {
      let services = JSON.parse(JSON.stringify(state.serviceList));
      services.push(action.payload.data);

      return Object.assign({}, state, {
        serviceList: services,
      });
    }

    case UPDATE_SERVICE_RESOLVED:
      let services = JSON.parse(JSON.stringify(state.serviceList));
      services.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          services[index] = action.payload.data;
        }
      });

      return Object.assign({}, state, {
        serviceList: services,
      });

    case DELETE_SERVICE_RESOLVED: {
      let services = JSON.parse(JSON.stringify(state.serviceList));
      services.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          services.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        serviceList: services,
      });
    }

    //BANNERS
    case FETCH_BANNERS_RESOLVED:
      return Object.assign({}, state, {
        bannerList: action.payload.data,
      });

    case CREATE_BANNER_RESOLVED: {
      let banners = JSON.parse(JSON.stringify(state.bannerList));
      banners.push(action.payload.data);

      return Object.assign({}, state, {
        bannerList: banners,
      });
    }

    case UPDATE_BANNER_RESOLVED:
      let banners = JSON.parse(JSON.stringify(state.bannerList));
      banners.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          banners[index] = action.payload.data;
        }
      });

      return Object.assign({}, state, {
        bannerList: banners,
      });

    case DELETE_BANNER_RESOLVED: {
      let banners = JSON.parse(JSON.stringify(state.bannerList));
      banners.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          banners.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        bannerList: banners,
      });
    }

    //internal banners
    case FETCH_INTERNAL_BANNERS_RESOLVED:
      return Object.assign({}, state, {
        internalBannerList: action.payload.data,
      });

    case CREATE_INTERNAL_BANNER_RESOLVED: {
      let internalbanners = JSON.parse(
        JSON.stringify(state.internalBannerList),
      );
      internalbanners.push(action.payload.data);

      return Object.assign({}, state, {
        internalBannerList: internalbanners,
      });
    }

    case UPDATE_INTERNAL_BANNER_RESOLVED:
      let internalbanners = JSON.parse(
        JSON.stringify(state.internalBannerList),
      );
      internalbanners.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          internalbanners[index] = action.payload.data;
        }
      });

      return Object.assign({}, state, {
        internalBannerList: internalbanners,
      });

    case DELETE_INTERNAL_BANNER_RESOLVED: {
      let internalbanners = JSON.parse(
        JSON.stringify(state.internalBannerList),
      );
      internalbanners.forEach((item, index) => {
        if (item.id == action.payload.data.id) {
          internalbanners.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        internalBannerList: internalbanners,
      });
    }

    //PREFERENCES
    case LINK_PREFERENCES_RESOLVED:
      return Object.assign({}, state, {
        preferences: action.payload,
      });
    case FETCH_PREFERENCES_RESOLVED:
      return Object.assign({}, state, {
        preferences: action.payload,
      });

    case FETCH_SURVEY_LIST_RESOLVED:
      return Object.assign({}, state, {
        surveyList: action.payload,
      });
    case FETCH_ACTIVE_SURVEY_RESOLVED:
      return Object.assign({}, state, {
        activeSurvey: action.payload,
      });

    //AREAS Routes
    case FETCH_SETTINGS_AREA_ROUTES_RESOLVED:
      return Object.assign({}, state, {
        areaRouteList: action.payload.data,
      });
    case UPDATE_AREA_ROUTE_RESOLVED: {
      let areas = JSON.parse(JSON.stringify(state.areaRouteList));
      areas.forEach((item, index) => {
        if (item.id == action.payload.id) {
          areas[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        areaRouteList: areas,
      });
    }

    case DELETE_AREA_ROUTE_RESOLVED: {
      let areas = JSON.parse(JSON.stringify(state.areaRouteList));
      let new_areas = _.remove(areas, function (o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        areaRouteList: areas,
      });
    }

    case CREATE_AREA_ROUTE_RESOLVED: {
      let areaRoute = JSON.parse(JSON.stringify(state.areaRouteList));
      areaRoute.push(action.payload.data);
      return Object.assign({}, state, {
        areaRouteList: areaRoute,
      });
    }

    //DISTRICT

    case CREATE_DISTRICT_RESOLVED: {
      let district = JSON.parse(JSON.stringify(state.districtList));
      district.push(action.payload.data);
      return Object.assign({}, state, {
        districtList: district,
      });
    }

    case FETCH_DISTRICT_RESOLVED:
      return Object.assign({}, state, {
        districtList: action.payload.data,
      });

    case UPDATE_DISTRICT_RESOLVED:
      let districts = JSON.parse(JSON.stringify(state.districtList));
      districts.forEach((item, index) => {
        if (item.id == action.payload.id) {
          districts[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        districtList: districts,
      });

    case DELETE_DISTRICT_RESOLVED: {
      let districts = JSON.parse(JSON.stringify(state.districtList));
      let new_districts = _.remove(districts, function (o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        districtList: districts,
      });
    }

    // GOVERNORATE
    case CREATE_GOVERNORATE_RESOLVED: {
      let governorate = JSON.parse(JSON.stringify(state.governorateList));
      governorate.push(action.payload.data);
      return Object.assign({}, state, {
        governorateList: governorate,
      });
    }

    case FETCH_GOVERNORATE_RESOLVED:
      return Object.assign({}, state, {
        governorateList: action.payload.data,
      });

    case UPDATE_GOVERNORATE_RESOLVED:
      let governorates = JSON.parse(JSON.stringify(state.governorateList));
      governorates.forEach((item, index) => {
        if (item.id == action.payload.id) {
          governorates[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        governorateList: governorates,
      });

    case DELETE_GOVERNORATE_RESOLVED: {
      let governorates = JSON.parse(JSON.stringify(state.governorateList));
      let new_governorates = _.remove(governorates, function (o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        governorateList: governorates,
      });
    }
      //Holidays
      case FETCH_HOLIDAYS_RESOLVED:
        return Object.assign({}, state, {
          holidayList: action.payload.data,
        });
      case UPDATE_HOLIDAY_RESOLVED:
        var holidays = JSON.parse(JSON.stringify(state.holidayList));
        holidays.forEach((item, index) => {
          if (item.id == action.payload.id) {
            holidays[index] = action.payload;
          }
        });
        return Object.assign({}, state, {
          holidayList: holidays,
        });
  
      case DELETE_HOLIDAY_RESOLVED:
        var holidays = JSON.parse(JSON.stringify(state.holidayList));
        var new_holidays = _.remove(holidays, function (o) {
          return o.id == action.payload;
        });
        return Object.assign({}, state, {
          holidayList: holidays,
        });
  
      case CREATE_HOLIDAY_RESOLVED:
        var holidays = JSON.parse(JSON.stringify(state.holidayList));
        holidays.push(action.payload.data);
        return Object.assign({}, state, {
          holidayList: holidays,
        });

    default:
      return state;
  }
};

export default settings;
