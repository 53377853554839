import React from "react";
import APPCONFIG from "constants/Config";

class Footer extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <section className="app-footer">
        <div className="container-fluid">
          <span className="float-left">
            <span>
              Copyright ©{" "}
              <a className="brand" target="_blank" href={APPCONFIG.productLink}>
                {APPCONFIG.brand}
              </a>{" "}
              {APPCONFIG.year}
            </span>
          </span>
          <span className="float-right">
            {/* <span>
              Developed by{" "}
              <a
                className="brand"
                target="_blank"
                href="https://www.pixel38.com"
              >
                Pixel38
              </a>{" "}
              with Love <i className="material-icons">favorite_border</i>
            </span> */}
          </span>
        </div>
      </section>
    );
  }
}

export default Footer;
