// import {
//   yellow500,
//   yellow700,
//   green400,
//   grey100,
//   grey300,
//   grey400,
//   grey500,
//   white,
//   darkBlack,
//   fullBlack
// } from "material-ui/styles/colors";
// import { fade } from "material-ui/utils/colorManipulator";
// import spacing from "material-ui/styles/spacing";

// /**
//  *  Light Theme is the default theme used in material-ui. It is guaranteed to
//  *  have all theme variables needed for every component. Variables not defined
//  *  in a custom theme will default to these values.
//  */
// export default {
//   spacing,
//   fontFamily: "Roboto, sans-serif",
//   borderRadius: 2,
//   palette: {
//     primary1Color: "#ffc107",
//     primary2Color: "#ffc107",
//     primary3Color: grey400,
//     accent1Color: green400,
//     accent2Color: grey100,
//     accent3Color: grey500,
//     textColor: darkBlack,
//     secondaryTextColor: fade(darkBlack, 0.54),
//     alternateTextColor: "#333C44",
//     canvasColor: white,
//     borderColor: grey300,
//     disabledColor: fade(darkBlack, 0.3),
//     pickerHeaderColor: "#ffc107",
//     clockCircleColor: fade(darkBlack, 0.07),
//     shadowColor: fullBlack
//   }
// };

import { yellow, green, grey } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

export default {
  spacing: 8, // Default MUI spacing
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  shape: {
    borderRadius: 2,
  },
  palette: {
    primary: {
      main: "#ffc107",  // primary1Color
      dark: '#ffc107',  // primary2Color
      light: grey[400],   // primary3Color
    },
    secondary: {
      main: green[400],  // accent1Color
      light: grey[100],  // accent2Color
      dark: grey[500],   // accent3Color
    },
    text: {
      primary: "#212121", // darkBlack (as textColor)
      secondary: alpha("#212121", 0.54), // secondaryTextColor using alpha
    },
    background: {
      default: "#FFFFFF",  // canvasColor
      paper: '#FFFFFF',
    },
    divider: grey[300],   // borderColor
    action: {
      disabled: alpha("#212121", 0.3), // disabledColor
    },
    custom: {
      alternateText: "#333C44", // alternateTextColor
    },
  },
  //shadows: ['none', '0px 1px 3px rgba(0, 0, 0, 0.2)', '0px 1px 5px rgba(0, 0, 0, 0.12)', ...Array(23).fill('none')],
  shadows: [
    "none",
    "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",  // Shadow 1 (elevation 1)
    "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",  // Shadow 2 (elevation 2)
    // Add more shadows here as needed
    ...Array(22).fill("none"), // Fill the rest if you don't want more shadows
  ],
};
