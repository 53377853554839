import React from "react";
import { connect } from "react-redux";
import { fetchDashboardBlocksData } from "services/authService";
import { getFilterData } from "utils/helpers";
import FilterComponent from "./FilterComponent";
import { withStyles } from "@mui/styles";
import { styles } from "../styles";
import CircularProgress from "components/EnhancedCircularProgress";
import { fetchOrders } from "services/orderService";
import {
  ORDER_STATUS_PENDING,
  ORDER_STATUS_SUCCESS,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_CONFIRMED,
  ORDER_STATUS_DECLINED,
  ORDER_STATUS_CLOSED_FAILED,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_PENDING_CANCELLATION
} from "constants/Statuses";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { dashboard_orders_page_path } from "constants/Paths";
import { push } from 'connected-react-router';

class OrderStatisticsBlock extends React.Component {

  constructor(props, context) {
    super(props, context);
    var date = new Date();
    date.setDate(date.getDate() - 7);

    this.state = {
      isLoading: false,
      dwell_data: [],

      ...getFilterData({
        receiverZone: null,
        fromCreatedDate: date,
        toCreatedDate: new Date(),
        fromCompletedDate: null,
        toCompletedDate: null,
        paymentCurrencyType: null
      })
    };
  }

  componentDidMount() {
    this.loadData();
  }

  setLoading = isLoading => {
    this.setState({ ...this.state, isLoading: isLoading });
  };

  loadData = () => {
    this.setLoading(true);

    let customized_search_input_here = this.state.customized_search_input.concat(
      this.state.search_input
    );

    this.props
      .dispatch(
        fetchDashboardBlocksData(
          "order_statistics",
          customized_search_input_here
        )
      )
      .then(return_data => {
        this.setState({
          isLoading: false,
          pending_orders: return_data.pending_orders,
          placed_orders: return_data.placed_orders,
          delivered_orders: return_data.delivered_orders,
          order_on_the_way: return_data.order_on_the_way,
          declined_failed_canceled_orders:
            return_data.declined_failed_canceled_orders
        });
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
      });
  };

  handleOrderFilter = (data, search_input, customized_search_input) => {
    this.setState(
      {
        data,
        customized_search_input,
        search_input
      },
      () => this.loadData()
    );
  };

  viewOrdersList = page_type => {
    let search_input_here = this.state.search_input;
    let customized_search_input_here = this.state.customized_search_input;

    switch (page_type) {
      case "pending_orders":
        search_input_here.push({
          key: "status",
          element: [ORDER_STATUS_PENDING]
        });
        break;
      case "delivered_orders":
        search_input_here.push({
          key: "status",
          element: [ORDER_STATUS_SUCCESS]
        });
        break;
      case "order_on_the_way":
        search_input_here.push({
          key: "status",
          element: [
            ORDER_STATUS_PROCESSING,
            ORDER_STATUS_FAILED,
            ORDER_STATUS_CONFIRMED,
            ORDER_STATUS_PENDING_CANCELLATION
          ]
        });
        break;
      case "declined_failed_canceled_orders":
        search_input_here.push({
          key: "status",
          element: [
            ORDER_STATUS_DECLINED,
            ORDER_STATUS_CLOSED_FAILED,
            ORDER_STATUS_CANCELED
          ]
        });
        break;
    }

    this.props
      .dispatch(fetchOrders(search_input_here, customized_search_input_here))
      .then(data => {
        this.setLoading(true);
        this.props.dispatch(push({
          pathname: dashboard_orders_page_path,
          state: {
            search_input: search_input_here,
            customized_search_input: customized_search_input_here,
            meta: data.meta
          }
        }));
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
      });
  };

  render() {
    let {
      isLoading,
      data,
      components,
      pending_orders,
      placed_orders,
      delivered_orders,
      order_on_the_way,
      declined_failed_canceled_orders
    } = this.state;
    let { classes } = this.props;
    return (
      <div className="box box-default">
        <div className={classes.block_header}>Order Statistics</div>
        <div className="box-body">
          <FilterComponent
            isLoading={isLoading}
            handleOrderFilter={this.handleOrderFilter}
            data={data}
            components={components}
          />
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div className="row text-center metrics">
              <div className="col-xs-6 col-md-2 metric-box">
                <Button onClick={() => this.viewOrdersList("pending_orders")}>
                  <div className="row">
                    <div className="col-md-12 metric">{pending_orders}</div>
                    <div className="col-md-12 metric-info">Pending Orders</div>
                  </div>
                </Button>
              </div>
              <div className="col-xs-6 col-md-2 metric-box">
                <Button onClick={() => this.viewOrdersList("placed_orders")}>
                  <div className="row">
                    <div className="col-md-12 metric">{placed_orders}</div>
                    <div className="col-md-12 metric-info">Placed Orders</div>
                  </div>
                </Button>
              </div>
              <div className="col-xs-6 col-md-2 metric-box">
                <Button onClick={() => this.viewOrdersList("delivered_orders")}>
                  <div className="row">
                    <div className="col-md-12 metric">{delivered_orders}</div>
                    <div className="col-md-12 metric-info">
                      Delivered Orders
                    </div>
                  </div>
                </Button>
              </div>
              <div className="col-xs-6 col-md-2 metric-box">
                <Button onClick={() => this.viewOrdersList("order_on_the_way")}>
                  <div className="row">
                    <div className="col-md-12 metric">{order_on_the_way}</div>
                    <div className="col-md-12 metric-info">
                      On the Way Orders
                    </div>
                  </div>
                </Button>
              </div>
              <div className="col-xs-6 col-md-4 metric-box">
                <Button
                  onClick={() =>
                    this.viewOrdersList("declined_failed_canceled_orders")
                  }
                >
                  <div className="row">
                    <div className="col-md-12 metric">
                      {declined_failed_canceled_orders}
                    </div>
                    <div className="col-md-12 metric-info">
                      Declined, Failed or Canceled Orders
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(connect()(OrderStatisticsBlock));
