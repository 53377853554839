/* import React, { Component } from "react";
import { compose, withProps, withHandlers, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { VEHICLE_TYPE_MOTORCYCLE } from "constants/Types";

const MapPins = compose(
  withProps(props => {
    return {
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyD0rokHYZxa0lT94aoKtSgbNXofpR14cZA&v=3.31&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: (
        <div
          style={{
            height: props.height ? props.height : `100vh`,
            width: props.width ? props.width : "100%"
          }}
        />
      ),
      mapElement: <div style={{ height: `100%` }} />
    };
  }),
  lifecycle({
    componentWillMount() {
      this.setState({
        center:
          this.props.driver &&
          this.props.driver.longitude != 0 &&
          this.props.driver.latitude != 0
            ? {
                lng: this.props.driver.longitude,
                lat: this.props.driver.latitude
              }
            : {
                lng: 35.7588642,
                lat: 33.8221042
              },
        map: null,
        icon_moto_path: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19.44 9.03L15.41 5H11v2h3.59l2 2H5c-2.8 0-5 2.2-5 5s2.2 5 5 5c2.46 0 4.45-1.69 4.9-4h1.65l2.77-2.77c-.21.54-.32 1.14-.32 1.77 0 2.8 2.2 5 5 5s5-2.2 5-5c0-2.65-1.97-4.77-4.56-4.97zM7.82 15C7.4 16.15 6.28 17 5 17c-1.63 0-3-1.37-3-3s1.37-3 3-3c1.28 0 2.4.85 2.82 2H5v2h2.82zM19 17c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
          </svg>
        ),
        icon_car_path: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z" />
          </svg>
        ),
        onMapMounted: ref => {
          this.state.map = ref;
        }
      });
    },
    componentWillReceiveProps(nextProps) {
      if (
        nextProps.driver &&
        nextProps.driver.longitude != 0 &&
        nextProps.driver.latitude != 0
      ) {
        this.setState(
          {
            center: {
              lat: parseFloat(nextProps.driver.latitude),
              lng: parseFloat(nextProps.driver.longitude)
            }
          },
          () => {
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(this.state.center);
            // bounds.extend({lng: 35.7588642, lat: 33.8221042});
            // this.state.map.fitBounds(bounds);
            this.state.map.panTo({
              lat: parseFloat(nextProps.driver.latitude),
              lng: parseFloat(nextProps.driver.longitude)
            });
          }
        );
      }
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={15}
    defaultCenter={props.center}
  >
    {props.driver &&
      props.driver.latitude &&
      props.driver.longitude && (
        <MarkerWithLabel
          key={props.driver.id}
          position={{ lat: props.driver.latitude, lng: props.driver.longitude }}
          labelAnchor={new google.maps.Point(0, 0)}
          title={`${props.driver.name}`}
          labelStyle={{
            color: "white",
            paddingLeft: "5px",
            paddingTop: "5px",
            fill: "white"
          }}
          icon={{
            path:
              "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z",
            fillColor: "rgb(226, 170, 1)",
            fillOpacity: 1,
            strokeColor: "rgb(226, 170, 1)",
            strokeWeight: 2,
            scale: 1.5
          }}
        >
          {props.driver.now_driving &&
          props.driver.now_driving.type.id == VEHICLE_TYPE_MOTORCYCLE
            ? props.icon_moto_path
            : props.icon_car_path}
        </MarkerWithLabel>
      )}
  </GoogleMap>
));

module.exports = MapPins;
 */
import React, { Component } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { VEHICLE_TYPE_MOTORCYCLE } from "constants/Types";

// Constants for your API key and default center
const API_KEY = "AIzaSyD0rokHYZxa0lT94aoKtSgbNXofpR14cZA&v=3.31&libraries=geometry,drawing,places";
const DEFAULT_CENTER = { lat: 33.8221042, lng: 35.7588642 };

const MapPins = (props) => {
  const { driver, height, width } = props;
  
  // Load Google Maps script
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: ["geometry", "drawing", "places"],
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  // Set map center based on driver location or default location
  const center = driver && driver.longitude !== 0 && driver.latitude !== 0
    ? { lat: parseFloat(driver.latitude), lng: parseFloat(driver.longitude) }
    : DEFAULT_CENTER;

  return (
    <div style={{ height: height || "100vh", width: width || "100%" }}>
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        center={center}
        zoom={15}
      >
        {driver && driver.latitude && driver.longitude && (
          <Marker
            key={driver.id}
            position={{ lat: driver.latitude, lng: driver.longitude }}
            label={{
              text: `${driver.name}`,
              color: "white",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            icon={{
              path: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z",
              fillColor: "rgb(226, 170, 1)",
              fillOpacity: 1,
              strokeColor: "rgb(226, 170, 1)",
              strokeWeight: 2,
              scale: 1.5,
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default MapPins;