import moment from 'moment';
import { dateFormatter, datetimeFormatter } from 'utils';

/**
 * Create a new cashbox object
 */
export function cashbox(props, page = null) {

  let scheduled_date = moment(props.scheduled_date).isValid()? dateFormatter(moment.utc(props.scheduled_date, 'YYYY-MM-DD').local()) : 'Not Specified';
  let from_date = moment(props.from_date).isValid()? dateFormatter(moment.utc(props.from_date, 'YYYY-MM-DD').local()) : 'Not Specified';
  let to_date = moment(props.to_date).isValid()? dateFormatter(moment.utc(props.to_date, 'YYYY-MM-DD').local()) : 'Not Specified';
  const filterZeroAndNullValues = (details, valueKey) => {
    return details
      .filter(detail => detail[valueKey] !== null && parseFloat(detail[valueKey]) !== 0)
      .map(detail => `${
        detail.collection_type ? 
        detail.collection_type.label + ":": 
        (valueKey == 'value' ? '' : "Cash:")} ${detail[valueKey]} ${detail.currency.code}`)
      .join("\n and \n");
  };

  const expected_amount = props.cashbox_details.length > 0 
    ? filterZeroAndNullValues(props.cashbox_details, 'expected_amount') || 'N/A'
    : 'N/A';

  const total_orders_delivery_charge = props.cashbox_details.length > 0 
    ? filterZeroAndNullValues(props.cashbox_details, 'total_orders_delivery_charge') || 'N/A'
    : 'N/A';

  const cashbox_table_balance = props.balances.length > 0 
    ? filterZeroAndNullValues(props.balances, 'value') || 'N/A'
    : 'N/A';
  return {
    page_index: page,
    id: props.id,
    status: props.status? props.status : "Pending",
    customer: props.customer,
    client_name: props.customer.name,
    account_number:  props.customer.accounting_ref ? props.customer.accounting_ref : 'N/A',
    email: props.cashbox_setting.email,
    contact_name: props.cashbox_setting.contact_name,
    scheduled_date: scheduled_date ? scheduled_date : null,
    from_date: from_date ? from_date : null,
    to_date: to_date ? to_date : [],
    customer_location: props.location,
    all_customer_locations : props.all_customer_locations ? props.all_customer_locations : [],
    customer_location_label: props.location? `${props.customer.name} - ${props.location.type_label ? props.location.type_label + " - ": "" }  ${props.location.area.name}` : null,
    office: props.office,
    wakilni_branch : props.office? props.office.name : null,
    notes: props.notes ? props.notes: 'N/A',
    office_location : props.office_location? props.office_location : null,
    office_location_label:  props.office? props.office.name : null,
    customer_default_currency: props.customer_default_currency,
    customer_currency_rates: props.customer_currency_rates,
    status_code: props.status_code,
    cashbox_details: props.cashbox_details,
    expected_amount: expected_amount,
    official_invoice: props.cashbox_setting.official_invoice ? 'Yes' : 'No',
    total_orders_delivery_charge: total_orders_delivery_charge,
    balances: props.balances,
    cashbox_table_balance: cashbox_table_balance,
    is_creating_order : props.is_creating_order,
    is_cashbox_cancelling : props.is_cashbox_cancelling
  };
}
