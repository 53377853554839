import { FETCH_QUEUE_REQUESTS_RESOLVED } from "../ActionTypes/queue-requests";
import _ from "lodash";

const initialState = {
  queueRequestList: []
};

const queueRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUEUE_REQUESTS_RESOLVED:
      return Object.assign({}, state, {
        queueRequestList: action.payload
      });
    default:
      return state;
  }
};

export default queueRequestReducer;
