export const MONEY_CHECK = 36,
  MONEY_CASH = 35,
  MONEY_CARD = 65;

export const TASK_TYPE_PICKUP = 14;
export const TASK_TYPE_DELIVER = 15;

export const MONEY = [
  { id: 36, type: "MONEY", label: "Check" },
  { id: 35, type: "MONEY", label: "Cash" },
  { id: 65, type: "MONEY", label: "Card" } //todo: change this when card is added to db
];
export const CASH_COLLECTION_TYPE_COLLECT_ON_DELIVERY = 52,
  CASH_COLLECTION_TYPE_COLLECT_ON_PICKUP = 53,
  CASH_COLLECTION_TYPE_PAID = 54;

export const COLLECTION = [
  {
    id: 52,
    type: "CASH_COLLECTION_TYPE_COLLECT_ON_DELIVERY",
    label: "Collect on Delivery"
  },
  {
    id: 53,
    type: "CASH_COLLECTION_TYPE_COLLECT_ON_PICKUP",
    label: "Collect on Pickup"
  },
  { id: 54, type: "CASH_COLLECTION_TYPE_PAID", label: "Paid" }
];

export const PACKAGE_TYPE_REGULAR_BOX = 58;

export const PACKAGE = [
  { id: 57, type: "TYPE_PAPER", label: "Paper" },
  { id: 58, type: "TYPE_REGULAR_BOX", label: "Regular Box" },
  { id: 59, type: "TYPE_SMALL_BOX", label: "Small Box" },
  { id: 60, type: "TYPE_LARGE_BOX", label: "Large Box" },
  { id: 61, type: "TYPE_VERY_LARGE_BOX", label: "Very Large Box" }
];

export const ORDER_TYPE_ONE_WAY = 10,
  ORDER_TYPE_BULK = 11,
  ORDER_TYPE_ROUND = 12,
  ORDER_TYPE_PIGGY_BANK = 13,
  ORDER_TYPE_BULK_PICKUP = 14, // this is virtual order type, it's not included in the db nor viewed in the orders list
  ORDER_TYPE_BUY_SOMETHING = 126,
  ORDER_TYPE_ROUND_DELIVERY = 128,
  ORDER_TYPE_EXPRESS_SINGLE = 129,
  ORDER_TYPE_EXPRESS_ROUND = 130,
  ORDER_TYPE_BULK_RETURN = 152;

  export const AREAS = [
    {
      id: 1,
      type: "Other",
      label: "Other",
    },
  ];

export const ORDER = [
  {
    id: 10,
    type: "ORDER_TYPE_ONE_WAY",
    label: "One Way",
    notes: "Pickup, Deliver"
  },
  {
    id: 11,
    type: "ORDER_TYPE_BULK",
    label: "Bulk",
    notes: "Pickup multiple packages, Deliver to multiple locations"
  },
  {
    id: 12,
    type: "ORDER_TYPE_ROUND",
    label: "Round",
    notes: "Pickup, Deliver, Return"
  },
  // { id: 13, type: "ORDER_TYPE_PIGGY_BANK", label: "Piggy Bank" },
  {
    id: 129,
    type: "ORDER_TYPE_EXPRESS_SINGLE",
    label: "Express Single",
    notes: "Express Pickup, Deliver"
  },
  {
    id: 130,
    type: "ORDER_TYPE_EXPRESS_ROUND",
    label: "Express Round",
    notes: "Express Pickup, Deliver, Return"
  },
  {
    id: 128,
    type: "ORDER_TYPE_ROUND_DELIVERY",
    label: "Return Delivery",
    notes: "Return Delivery Order"
  },
];

export const RETURN_ORDER = [
  {
    id: 128,
    type: "ORDER_TYPE_ROUND_DELIVERY",
    label: "Return Delivery",
    notes: "Return Delivery Order"
  },
  {
    id: 12,
    type: "ORDER_TYPE_ROUND",
    label: "Round",
    notes: "Pickup, Deliver, Return"
  },
  {
    id: 130,
    type: "ORDER_TYPE_EXPRESS_ROUND",
    label: "Express Round",
    notes: "Express Pickup, Deliver, Return"
  },
  {
    id: 152,
    type: "ORDER_TYPE_BULK_RETURN",
    label: "Bulk Return",
    notes: "Bulk Return Parent"
  },
]
export const CREATE_TYPE_ORDER = [
  {
    id: 10,
    type: "ORDER_TYPE_ONE_WAY",
    label: "One Way",
    notes: "Pickup, Deliver"
  },
  {
    id: 11,
    type: "ORDER_TYPE_BULK",
    label: "Bulk",
    notes: "Pickup multiple packages, Deliver to multiple locations"
  },
  {
    id: 12,
    type: "ORDER_TYPE_ROUND",
    label: "Round",
    notes: "Pickup, Deliver, Return"
  },
  { id: 14, type: "ORDER_TYPE_BULK_PICKUP", label: "Pickup", notes: "Pickup" },
  {
    id: 129,
    type: "ORDER_TYPE_EXPRESS_SINGLE",
    label: "Express Single",
    notes: "Express Pickup, Deliver"
  },
  {
    id: 130,
    type: "ORDER_TYPE_EXPRESS_ROUND",
    label: "Express Round",
    notes: "Express Pickup, Deliver, Return"
  },
  {
    id: 128,
    type: "ORDER_TYPE_ROUND_DELIVERY",
    label: "Return Delivery",
    notes: "Return Delivery Order"
  },
];

export const WAKILNI_CREATE_TYPE_ORDER = [
  {
    id: 10,
    type: "ORDER_TYPE_ONE_WAY",
    label: "One Way",
    notes: "Pickup, Deliver"
  },
  {
    id: 11,
    type: "ORDER_TYPE_BULK",
    label: "Bulk",
    notes: "Pickup multiple packages, Deliver to multiple locations"
  },
  {
    id: 12,
    type: "ORDER_TYPE_ROUND",
    label: "Round",
    notes: "Pickup, Deliver, Return"
  },
  { id: 14, type: "ORDER_TYPE_BULK_PICKUP", label: "Pickup", notes: "Pickup" },
  {
    id: 129,
    type: "ORDER_TYPE_EXPRESS_SINGLE",
    label: "Express Single",
    notes: "Express Pickup, Deliver"
  },
  {
    id: 130,
    type: "ORDER_TYPE_EXPRESS_ROUND",
    label: "Express Round",
    notes: "Express Pickup, Deliver, Return"
  },
  {
    id: 128,
    type: "ORDER_TYPE_ROUND_DELIVERY",
    label: "Return Delivery",
    notes: "Return Delivery Order"
  },
];

export const PAYMENT_METHOD_TYPE_ON_ACCOUNT = 20,
  PAYMENT_METHOD_TYPE_PAYMENT_ON_DELIVERY = 21,
  PAYMENT_METHOD_TYPE_PAYMENT_ON_PICKUP = 22,
  PAYMENT_METHOD_TYPE_CREDIT_CARD_ON_SPOT = 23;

export const PAYMENT_METHOD = [
  {
    id: 20,
    type: "PAYMENT_METHOD_TYPE_ON_ACCOUNT",
    label: "On Account",
    notes: ""
  },
  {
    id: 21,
    type: "PAYMENT_METHOD_TYPE_PAYMENT_ON_DELIVERY",
    label: "On Delivery",
    notes: "Collect fees from client at Delivery"
  },
  {
    id: 22,
    type: "PAYMENT_METHOD_TYPE_PAYMENT_ON_PICKUP",
    label: "On Pickup",
    notes: "Pay at Pickup"
  }
  // { id: 23, type: 'PAYMENT_METHOD_TYPE_CREDIT_CARD_ON_SPOT', label: 'Card on Spot', notes:'Pickup, Deliver' },
];

export const BUSINESS_PAYMENT_METHOD = [
  {
    id: 20,
    type: "PAYMENT_METHOD_TYPE_ON_ACCOUNT",
    label: "On Account",
    notes: ""
  }
];

export const PERSONAL_PAYMENT_METHOD = [
  {
    id: 21,
    type: "PAYMENT_METHOD_TYPE_PAYMENT_ON_DELIVERY",
    label: "On Delivery",
    notes: "Collect fees from client at Delivery"
  },
  {
    id: 22,
    type: "PAYMENT_METHOD_TYPE_PAYMENT_ON_PICKUP",
    label: "On Pickup",
    notes: "Pay at Pickup"
  }
];

export const CUSTOMER_TYPE_OFFLINE_BUSINESS = 17;
export const CUSTOMER_TYPE_ONLINE_BUSINESS = 18;
export const CUSTOMER_TYPE_CUSTOMER = 19;
export const CUSTOMER_TYPE_OTHER = 119;
export const CUSTOMER_TYPE_MARKETPLACE = 156;
// export const   CUSTOMER_TYPE_ONLINE_AND_OFFLINE_BUSINESS = 120;

export const CUSTOMER = [
  {
    id: 17,
    type: "CUSTOMER_TYPE_OFFLINE_BUSINESS",
    label: "Corporate / Offline"
  },
  {
    id: 18,
    type: "CUSTOMER_TYPE_ONLINE_BUSINESS",
    label: "Ecommerce / Online"
  },
  { id: 19, type: "CUSTOMER_TYPE_CUSTOMER", label: "Individual Client" },
  { id: 119, type: "CUSTOMER_TYPE_OTHER", label: "Other" },
  { id:156, type: "CUSTOMER_TYPE_MARKETPLACE", label: "Marketplace Client" }
  // { id: 120, type: 'CUSTOMER_TYPE_ONLINE_AND_OFFLINE_BUSINESS', label: 'Online and Offline Business' }
];

export const LARGE_PRODUCTS_REQUIRING_CAR_NO = 1;
export const LARGE_PRODUCTS_REQUIRING_CAR_YES = 2;
export const LARGE_PRODUCTS_REQUIRING_CAR_SOME = 3;

export const LARGE_PRODUCTS_REQUIRING_CAR = [
  { id: 1, label: "No" },
  { id: 2, label: "Yes" },
  { id: 3, label: "Some" }
];

export const IS_PRODUCT_DELICATE_NO = 0;
export const IS_PRODUCT_DELICATE_YES = 1;
export const IS_PRODUCT_DELICATE_SOME = 2;

export const PRODUCT_DELICATE = [
  { id: 0, label: "No" },
  { id: 1, label: "Yes" },
  { id: 2, label: "Some of them" }
];

export const PICKUP_PREFERENCE_MORNING = 1;
export const PICKUP_PREFERENCE_AFTERNOON = 2;
export const PICKUP_PREFERENCE_FLEXIBLE = 3;

export const PICKUP_PREFERENCE = [
  { id: 1, label: "Morning Pickup (8-10 am)" },
  { id: 2, label: "Afternoon Pickup (3-5 pm)" },
  { id: 3, label: "Flexible Pickup" }
];

export const ORDERS_PER_MONTH_BELOW_10 = 1;
export const ORDERS_PER_MONTH_BETWEEN_10_20 = 2;
export const ORDERS_PER_MONTH_BETWEEN_20_50 = 3;
export const ORDERS_PER_MONTH_ABOVE_50 = 4;

export const ORDERS_PER_MONTH = [
  { id: 1, label: "< 10 orders" },
  { id: 2, label: "10-20 orders" },
  { id: 3, label: "20-50 orders" },
  { id: 4, label: "50-1000 orders" },
  { id: 5, label: "> 1000 orders" }
];

export const ORDER_AVERAGE_VALUE_BELOW_300000_LBP = 1;
export const ORDER_AVERAGE_VALUE_BETWEEN_300000_500000_LBP = 2;
export const ORDER_AVERAGE_VALUE_BETWEEN_500000_1000000_LBP = 3;
export const ORDER_AVERAGE_VALUE_BETWEEN_1000000_5000000_LBP = 4;
export const ORDER_AVERAGE_VALUE_ABOVE_5000000_LBP = 5;

export const ORDER_AVERAGE_VALUE = [
  { id: 1, label: "< 300,000 LBP" },
  { id: 2, label: "300,000 - 500,000 LBP" },
  { id: 3, label: "500,000 - 1,000,000 LBP" },
  { id: 4, label: "1,000,000 - 5,000,000 LBP" },
  { id: 5, label: "> 5,000,000 LBP" }
];

export const RETURN_PRODUCTS_NO = 0;
export const RETURN_PRODUCTS_YES = 1;
export const RETURN_PRODUCTS_MESSAGE_ME_EACH_TIME = 2;

export const ALLOW_RETURN_PRODUCTS = [
  { id: 0, label: "No" },
  { id: 1, label: "Yes" },
  { id: 2, label: "Message me each time" }
];

export const LOCATION_TYPE_HOME = 31,
  LOCATION_TYPE_OFFICE = 33,
  LOCATION_TYPE_HOME2 = 32,
  LOCATION_TYPE_OFFICE2 = 34,
  LOCATION_TYPE_OTHER = 121;

export const LOCATION = [
  { id: 31, type: "LOCATION_TYPE_HOME", label: "Home" },
  { id: 32, type: "LOCATION_TYPE_HOME2", label: "Home2" },
  { id: 33, type: "LOCATION_TYPE_OFFICE", label: "Office" },
  { id: 34, type: "LOCATION_TYPE_OFFICE2", label: "Office2" },
  { id: 121, type: "LOCATION_TYPE_OTHER", label: "Other" }
];

export const DRIVER_TYPE_FULL_TIME = 1,
  DRIVER_TYPE_PART_TIME = 2,
  DRIVER_TYPE_ON_CALL = 3;

export const DRIVER = [
  { id: 1, type: "DRIVER_TYPE_FULL_TIME", label: "Full Time" },
  { id: 2, type: "DRIVER_TYPE_PART_TIME", label: "Part Time" },
  { id: 3, type: "DRIVER_TYPE_ON_CALL", label: "On Call" }
];

export const MASTER_BARCODE = [
  { id: 1, type: "MASTER_BARCODE_TYPE_OTHER", label: "Other" },
  { id: 2, type: "MASTER_BARCODE_TYPE_RETURN", label: "Return" },
];

const STOCK_TYPE_T_SHIRT = 37;
const STOCK_TYPE_SWEATSHIRT = 38;
const STOCK_TYPE_VEST = 39;
const STOCK_TYPE_JACKET = 40;
const STOCK_TYPE_CAPS = 41;

export const STOCK = [
  { id: 37, type: "STOCK_TYPE_T_SHIRT", label: "T Shirt" },
  { id: 38, type: "STOCK_TYPE_SWEATSHIRT", label: "Sweatshirt" },
  { id: 39, type: "STOCK_TYPE_VEST", label: "Vest" },
  { id: 40, type: "STOCK_TYPE_JACKET", label: "Jacket" },
  { id: 41, type: "STOCK_TYPE_CAPS", label: "Caps" }
];

export const VEHICLE_TYPE_CAR = 6,
  VEHICLE_TYPE_MOTORCYCLE = 7,
  VEHICLE_TYPE_TRUCK = 8,
  VEHICLE_TYPE_BICYCLE = 9;


export const VEHICLE_ID_TYPE_CAR = 1,
VEHICLE_ID_TYPE_MOTORCYCLE = 2;

export const VEHICLE = [
  { id: 6, type: "VEHICLE_TYPE_CAR", label: "Car" },
  { id: 7, type: "VEHICLE_TYPE_MOTORCYCLE", label: "Motorcycle" },
  { id: 8, type: "VEHICLE_TYPE_TRUCK", label: "Truck" },
  { id: 9, type: "VEHICLE_TYPE_BICYCLE", label: "Bicycle" }
];

const STOCK_SIZE_SMALL = 68,
  STOCK_SIZE_MEDIUM = 69,
  STOCK_SIZE_LARGE = 70;

export const SIZE = [
  { id: 68, type: "STOCK_SIZE_SMALL", label: "Small" },
  { id: 69, type: "STOCK_SIZE_MEDIUM", label: "Medium" },
  { id: 70, type: "STOCK_SIZE_LARGE", label: "Large" }
];

export const DEVICE_TYPE_IOS = 1;
export const DEVICE_TYPE_ANDROID = 2;
export const DEVICE_TYPE_WEB = 3;

export const DEVICE = [
  { id: 1, type: "DEVICE_TYPE_IOS", label: "Ios device" },
  { id: 2, type: "DEVICE_TYPE_ANDROID", label: "Android device" },
  { id: 3, type: "DEVICE_TYPE_WEB", label: "Web device" }
];

export const PAYMENT_CASH = 71,
  PAYMENT_CARD = 72,
  PAYMENT_CHECK = 76,
  PAYMENT_ON_DELIVERY = 73,
  PAYMENT_PIGGY_BANK = 74;

export const ORDER_PAYMENT_TYPES = [
  { id: 71, type: "PAYMENT_CASH", label: "Cash" },
  { id: 72, type: "PAYMENT_CARD", label: "Card" },
  { id: 76, type: "PAYMENT_CHECK", label: "Check" }
];

export const ORDER_PAYMENT_TYPES_CURRENCY = [
  {
    id: 1,
    type: "PAYMENT_CASH_USD",
    label: "Cash collection - USD",
    payment_type_id: 35,
    currency_id: 1
  },
  {
    id: 2,
    type: "PAYMENT_CARD_USD",
    label: "Card collection - USD",
    payment_type_id: 65,
    currency_id: 1
  },
  {
    id: 3,
    type: "PAYMENT_CHECK_USD",
    label: "Check collection - USD",
    payment_type_id: 36,
    currency_id: 1
  },
  {
    id: 4,
    type: "PAYMENT_CASH_LBP",
    label: "Cash collection - LBP",
    payment_type_id: 35,
    currency_id: 2
  },
  {
    id: 5,
    type: "PAYMENT_CARD_LBP",
    label: "Card collection - LBP",
    payment_type_id: 65,
    currency_id: 2
  },
  {
    id: 6,
    type: "PAYMENT_CHECK_LBP",
    label: "Check collection - LBP",
    payment_type_id: 36,
    currency_id: 2
  },
  { id: 7, type: "NO_COLLECTION", label: "No collection" }
];

export const RECRUITMENT_REQUEST_REASON_FOR_NEED_REPLACEMENT = 1;
export const RECRUITMENT_REQUEST_REASONS_FOR_NEED = [
  { id: 1, label: "Replacement" },
  { id: 2, label: "Manpower Planning" },
  { id: 3, label: "Temporary Position" }
];

export const RECRUITMENT_REQUEST_SHIFT_DAY = 1;
export const RECRUITMENT_REQUEST_SHIFTS = [
  { id: 1, label: "Day" },
  { id: 2, label: "Night" }
];

export const LEAVE_REQUEST_VACATION_LEAVE_TYPE = 1;
export const LEAVE_REQUEST_LEAVE_TYPES = [
  { id: 1, label: "Vacation" },
  { id: 2, label: "Sick Leave" },
  { id: 3, label: "Unpaid Leave" },
  { id: 4, label: "Maternity or Paternity" },
  { id: 5, label: "Others" }
];

export const REQUEST_DEPARTMENT_TYPES = [
  { id: 1, label: "Warehouse" },
  { id: 2, label: "Fleet" },
  { id: 3, label: "Client Partnership" },
  { id: 4, label: "On Boarding" },
  { id: 5, label: "HR" },
  { id: 6, label: "Accounting" },
  { id: 7, label: "Strategy" },
  { id: 8, label: "Other" }
];

export const PENDING_BARCODE_TYPE_PICKUP = 0,
  PENDING_BARCODE_TYPE_RETURN = 1;

export const QUEUE_TYPES = [
  { id: 1, label: "Task Export" },
  { id: 2, label: "Order Export" },
  { id: 3, label: "Review Export" }
];

export const COMPLETED_REJECTED_TYPES = [
  { id: 1, label: "Rejected", value: "true"},
  { id: 2, label: "Delivered", value: "false"},
];

export const CASHBOX_EXTRA_FEE_TYPE_COLLECTION = 154,
CASHBOX_EXTRA_FEE_TYPE_DELIVERY = 155

export const CASHBOX_EXTRA_FEE_TYPES = [
  { id: 1, label: "Collection amount fees", type_id: 154},
  { id: 2, label: "Delivery fees", type_id: 155 }
];

export const SHIPPING_TYPE_PACKAGES = 175,
SHIPPING_TYPE_DOCUMENTS = 176

export const order_shipment_TYPES = [
  { id: 1, label: "Local", value: "local"},
  { id: 2, label: "International", value: "international"},
];