import * as types from "../ActionTypes/cashbox";

export const fetchCashboxesResolved = (payload) => (
{
  type: types.FETCH_CASHBOXES_RESOLVED,
  payload
});

export const fetchCashboxesFailed = (payload) => ({
  type: types.FETCH_CASHBOXES_FAILED,
  payload
});

export const fetchCashboxResolved = (payload) => (
  {
    type: types.FETCH_CASHBOX_RESOLVED,
    payload,
  });

export const updateCashboxResolved = (payload) => ({
  type: types.UPDATE_CASHBOX_RESOLVED,
  payload,
});
export const updateSettlementResolved = (payload) => ({
  type: types.UPDATE_CASHBOX_SETTLEMENT_RESOLVED,
  payload,
});

export const addCashboxExtraFeesResolved = (payload) => ({
  type: types.ADD_CASHBOX_EXTRA_FEES_RESOLVED,
  payload,
});

export const updateCashboxExtraFeeResolved = (payload) => ({
  type: types.UPDATE_CASHBOX_EXTRA_FEE_RESOLVED,
  payload,
});

export const fetchCashboxExtraFeeResolved = (payload) => ({
  type: types.FETCH_CASHBOX_EXTRA_FEE_RESOLVED,
  payload,
});
export const fetchCashboxExtraFeesHistory = (payload) => ({
  type: types.FETCH_CASHBOX_EXTRA_FEES_HISTORY_RESOLVED,
  payload,
});
export const fetchCashboxHistory = (payload) => ({
  type: types.FETCH_CASHBOX_HISTORY_RESOLVED,
  payload,
});
export const createMultipleOrdersResolved = (payload) => ({
  type: types.CREATE_MULTIPLE_CASHBOX_ORDERS_RESOLVED,
  payload,
});

export const cancelCashboxesResolved = (payload) => ({
  type: types.CANCEL_CASHBOXES_RESOLVED,
  payload,
});

export const AddCashboxResolved = (payload) => ({
  type: types.ADD_CASHBOX_RESOLVED,
  payload,
});

export const fetchCashboxDetailsResolved = (payload) => ({
  type: types.FETCH_CASHBOX_DETAILS_RESOLVED,
  payload,
});

export const fetchCashboxDetailsFailed = (payload) => ({
  type: types.FETCH_CASHBOX_DETAILS_FAILED,
  payload,
});
