import * as internalBannersActions from "stores/actions/internalBanners";
import Http from "utils/Http";
import * as authActions from "stores/actions/auth";
import { serializeSearchInput } from "utils/helpers";

export function fetchActiveInternalBanner(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url =
        input.length > 0
          ? `internal_banners${serializeSearchInput(input, false)}`
          : "internal_banners";
      Http.get(url)
        .then(res => {
          dispatch(internalBannersActions.fetchActiveInternalBannerResolved(res.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}