import React, { Component } from "react";
import PropTypes from "prop-types";
import SelectField from "components/EnhancedSelectField";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "components/EnhancedDatePicker";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import { styles } from "../../styles";
import Button from "@mui/material/Button";
import TextField from "components/EnhancedTextField";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import RaisedButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ReeValidate from "ree-validate";
import * as dataService from "services/dataService";
import * as customerService from "services/customerService";
import { connect } from "react-redux";
import moment from "moment";
import { CirclePicker } from "react-color";
import FlatButton from "@mui/material/Button";
import * as types from "constants/Types";
import AutoComplete from "components/EnhancedAutoComplete";
import AreaAutoComplete from "components/EnhancedAreaAutoComplete";
import PinLocation from "components/PinLocation";
import classnames from "classnames";
import { ErrorBag } from "ree-validate";
import _ from "lodash";
import { isEmpty, isFeatureEnabled } from "utils/helpers";
import PhoneNumberInput from "components/PhoneNumberInput";
import { DEFAULT_COUNTRY } from "constants/Data";
import * as countryService from "services/countryService";
import CashboxSetup from "../../../cashbox-setup/components/CashboxSetup/CashboxSetup";
import * as authActions from "stores/actions/auth";
import { isAccountant, isAdmin, isOperator } from "utils/helpers";
import { push } from 'connected-react-router';
import { withRouter } from "react-router-dom";
import { ListItemText } from "@mui/material";
import { checkIfNational } from 'utils/helpers';

class CreateForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.validator = new ReeValidate({});

    this.state = {
      responseError: {
        isError: false,
        code: "",
        text: "",
      },
      isLoading: false,
      open: false,
      errors: this.validator.errors,
      data: {
        area: null,
        country_id: DEFAULT_COUNTRY,
        areaText: "",
        building: "",
        latitude: 0,
        longitude: 0,
        floor: "",
        postal_code: "",
        directions: "",
        location_type_id: types.LOCATION_TYPE_HOME,
        location_type_label: "",
        first_name: "",
        shop_name: "",
        image_name: null,
        image_file: null,
        image_size: 0,
        image_preview: null,
        shop_phone_number: "",
        last_name: "",
        date_of_birth: null,
        phone_number: "",
        email: "",
        gender: 1,
        golden_rule: "",
        shop_note: "",
        type_id: types.CUSTOMER_TYPE_ONLINE_BUSINESS,
        type_other_label: "",
        mof: "",
        vat: "",
        accounting_ref: "",
        payment_method: types.PAYMENT_METHOD_TYPE_ON_ACCOUNT,
        password: "",
        token: "",
        confirm_password: "",
        resetArea: false,
        office_id: null,
        office_name: ''
      },
      cashboxData: null,
      validCashbox: true,
      isNational: true,
    };
  }

  handleChangeMarker = coordinates => {
    this.setState({
      data: {
        ...this.state.data,
        latitude: coordinates.lat,
        longitude: coordinates.lng
      }
    });
  };

  componentDidMount() {
    this.loadCountries();

    if (this.props.shopify_data && !isEmpty(this.props.shopify_data)) {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          areaText: this.props.shopify_data.city,
          shop_name: this.props.shopify_data.name,
          first_name: this.props.shopify_data.first_name,
          last_name: this.props.shopify_data.last_name,
          shop_phone_number: this.props.shopify_data.phone,
          phone_number: this.props.shopify_data.phone,
          email: this.props.shopify_data.email,
          token: this.props.shopify_data.token
        }
      });
    }
  }

  loadCountries = () => {
    this.setState(state => ({ isLoading: true }));

    this.props
      .dispatch(countryService.fetchCountries())
      .then(response => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState(state => ({ isLoading: false, responseError }));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState(state => ({ isLoading: false, responseError }));
      });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.shopify_data && !isEmpty(nextProps.shopify_data)) {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          areaText: nextProps.shopify_data.city,
          shop_name: nextProps.shopify_data.name,
          first_name: nextProps.shopify_data.first_name,
          last_name: nextProps.shopify_data.last_name,
          shop_phone_number: nextProps.shopify_data.phone,
          phone_number: nextProps.shopify_data.phone,
          email: nextProps.shopify_data.email,
          token: nextProps.shopify_data.token
        }
      });
    }
  }

  handleReset = () => {
    this.setState({
      ...this.state,
      data: {
        area: null,
        areaText: "",
        building: "",
        latitude: 0,
        longitude: 0,
        location_type_id: 31,
        location_type_label: "",
        floor: "",
        postal_code: "",
        directions: "",
        first_name: "",
        shop_name: "",
        shop_phone_number: "",
        last_name: "",
        date_of_birth: null,
        phone_number: "",
        email: "",
        gender: 1,
        golden_rule: "",
        shop_note: "",
        type_id: 18,
        type_other_label: "",
        mof: "",
        vat: "",
        accounting_ref: "",
        account_manager_id: null,
        payment_method: 22,
        password: "",
        confirm_password: "",
        resetArea: true,
        office_id: null,
        office_name: ''
      }
    });
  };

  handleClearPin = () => {
    this.setState({
      data: {
        ...this.state.data,
        longitude: 0,
        latitude: 0
      }
    });
  };

  handleSelectChange = (value, name) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ data });
    if(name == 'country_id'){
      this.setState({ isNational : checkIfNational(value)});
    }
  };

  handleCashboxCreate = (cashboxData) => {
    this.setState({ cashboxData });
    const isMonthlyOfficialInvoice = cashboxData && cashboxData.officialInvoice;
    const validationRule = isMonthlyOfficialInvoice ? 'required' : '';
    this.validator.rules.mof = validationRule;
    this.setState({
      errors: this.validator.errors,
    });
  };

  handleDateChange = (date, name) => {
    const { data } = this.state;
    data[name] = date;
    this.setState({ data });
  };

  handleChange = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data: { ...data } });
  };

  handleAddValidationErrorChange = (name, value) => {
    if (value) {
      this.state.errors.add({
        field: name,
        msg: value
      });
    } else {
      this.state.errors.remove(name);
    }
  };

  handleTextChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    const { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  onLogoUpload = e => {
    let files = e.target.files;
    if (!files.length) return;

    const { data } = this.state;
    data["image_name"] = files[0].name;
    data["image_size"] = files[0].size;
    this.setState({ data });

    this.createImage(files[0]);
  };

  createImage = file => {
    let reader = new FileReader();
    reader.onload = e => {
      const { data } = this.state;
      data["image_preview"] = e.target.result;
      data["image_file"] = e.target.result.split(",")[1];

      this.setState({ data });
    };

    reader.readAsDataURL(file);
  };

  handleSubmit = reset => {
    const { cashboxData } = this.state;
    const validate = new ReeValidate(
      this.props.isAuthenticated
        ? {
            mof: cashboxData && cashboxData.officialInvoice ? 'required' : '',
            area: "required",
            directions: "required",
            floor: this.state.isNational ? "" : "required",
            building: this.state.isNational ? "" : "required",
            country_id: "required",
            email: "required|email",
            postal_code: this.state.isNational ? "max:12" : "max:12|required",
            shop_name: {
              required:
                this.state &&
                this.state.data &&
                this.state.data.type_id == types.CUSTOMER_TYPE_CUSTOMER
                  ? false
                  : true
            },
            shop_phone_number: {
              required:
                this.state &&
                this.state.data &&
                this.state.data.type_id == types.CUSTOMER_TYPE_CUSTOMER
                  ? false
                  : true
            },
            first_name: "required",
            last_name: "required",
            longitude: "decimal",
            latitude: "decimal",
            phone_number: "required"
          }
        : {
            mof: cashboxData && cashboxData.officialInvoice ? 'required' : '',
            area: "required",
            directions: "required",
            floor: this.state.isNational ? "" : "required",
            building: this.state.isNational ? "" : "required",
            country_id: "required",
            email: "required|email",
            first_name: "required",
            longitude: "decimal",
            latitude: "decimal",
            postal_code: this.state.isNational ? "max:12" : "max:12|required",
            shop_name: {
              required:
                this.state &&
                this.state.data &&
                this.state.data.type_id == types.CUSTOMER_TYPE_CUSTOMER
                  ? false
                  : true
            },
            shop_phone_number: {
              required:
                this.state &&
                this.state.data &&
                this.state.data.type_id == types.CUSTOMER_TYPE_CUSTOMER
                  ? false
                  : true
            },
            last_name: "required",
            phone_number: "required",
            password: "required|min:6",
            confirm_password: "required|min:6"
          }
    );
    let has_phone_number_error = this.validator.errors.has("phone_number")
      ? this.validator.errors.first("phone_number")
      : null;
    let has_shop_phone_number_error = this.validator.errors.has(
      "shop_phone_number"
    )
      ? this.validator.errors.first("shop_phone_number")
      : null;

    const { data } = this.state;
    if (
      !(this.state.data.type_id === types.CUSTOMER_TYPE_CUSTOMER || this.state.data.type_id === types.CUSTOMER_TYPE_OFFLINE_BUSINESS)
      && cashboxData && cashboxData.createUponClientRequest == false
    ) {
      this.setState({
        validCashbox: false,
      });
      if (cashboxData.officeId == "") {
        this.props.dispatch(
          authActions.serverError(
            "Fill out Wakilni Branch field in Cashbox Setup!",
          ),
        );
      }
       else if (cashboxData.cashboxFrequency == null) {
        this.props.dispatch(
          authActions.serverError("Fill out Frequency field in Cashbox Setup!"),
        );
      } else if (cashboxData.auditingDays < 0) {
        this.props.dispatch(
          authActions.serverError(
            "Auditing Days are invalid!",
          ),
        );
      } else if (
        cashboxData.cashboxFrequency != 1 &&
        cashboxData.cashboxFrequency != 6
      ) {
        if (
          (cashboxData.cashboxFrequency == 2 ||
            cashboxData.cashboxFrequency == 5) &&
          cashboxData.selectedWeekDays.length < 1
        ) {
          this.props.dispatch(
            authActions.serverError("You need to select 1 weekday!"),
          );
        } else if (
          cashboxData.cashboxFrequency == 3 &&
          cashboxData.selectedWeekDays.length < 2
        ) {
          this.props.dispatch(
            authActions.serverError("You need to select 2 weekdays!"),
          );
        } else if (
          cashboxData.cashboxFrequency == 4 &&
          cashboxData.selectedWeekDays.length < 3
        ) {
          this.props.dispatch(
            authActions.serverError("You need to select 3 weekdays!"),
          );
        } else {
          this.setState({
            validCashbox: true,
          });
        }
      } else if (
        cashboxData.cashboxFrequency == 6 &&
        cashboxData.selectedDate == null
      ) {
        this.props.dispatch(
          authActions.serverError(
            "Fill out Scheduled Date field in Cashbox Setup!",
          ),
        );
      } else {
        this.setState({
          validCashbox: true,
        });
      }
    }
    if(cashboxData && cashboxData.createUponClientRequest == true){
      this.setState({
        validCashbox: true,
      });
    }
    validate.validateAll(data).then(success => {
      if (success && this.state.validCashbox) {
        if (has_phone_number_error || has_shop_phone_number_error) {
          if (has_phone_number_error) {
            this.validator.errors.add({
              field: "phone_number",
              msg: has_phone_number_error
            });
          }

          if (has_shop_phone_number_error) {
            this.validator.errors.add({
              field: "shop_phone_number",
              msg: has_shop_phone_number_error
            });
          }
        } else {
          this.setState({
            isLoading: true
          });

          this.submit(reset, this.props.token);
        }
      } else {
        const { errors } = validate;
        this.setState({ errors: errors, data });
      }
    });
  };

  submit = (reset, token = null) => {
    this.setState({
      isLoading: true
    });

    var params = {};
    if (this.props.location_Search) {
      const searchParams = new URLSearchParams(this.props.location_Search);
      let shop = searchParams.get('shop');
      let code = searchParams.get('code');

      if (token || token != "") {
        params = {
          thirdparty_code: code,
          thirdparty_token: this.state.data.token,
          thirdparty_shop: shop,
          device_token: token,
          device_type: types.DEVICE_TYPE_WEB
        };
      } else {
        params = {
          thirdparty_code: code,
          thirdparty_shop: shop,
          thirdparty_token: this.state.data.token
        };
      }
    } else {
      if (token || token != "") {
        params = {
          device_token: token,
          device_type: types.DEVICE_TYPE_WEB
        };
      }
    }

    let picture = {
      name: this.state.data.image_name,
      file: this.state.data.image_file,
      size: this.state.data.image_size
    };

    var info = {
      input: {
        ...params,
        customer: {
          golden_rule: this.state.data.golden_rule,
          shop_note: this.state.data.shop_note,
          phone_number: this.state.data.shop_phone_number,
          shop_name: this.state.data.type_id !== types.CUSTOMER_TYPE_CUSTOMER ? this.state.data.shop_name : null,
          mof: this.state.data.mof,
          vat: this.state.data.vat,
          accounting_ref: this.state.data.accounting_ref,
          account_manager_id: this.state.data.account_manager_id,
          payment_method:
            this.state.data.type_id !== types.CUSTOMER_TYPE_CUSTOMER
              ? this.state.data.payment_method
              : null,
          type_id: this.state.data.type_id,
          type_other_label: this.state.data.type_other_label
        },
        logo: picture,
        users: {
          email: this.state.data.email,
          password: !this.props.isAuthenticated
            ? this.state.data.password
            : null,
          password_confirmation: this.state.data.confirm_password,
          new: true,
          contact: {
            first_name: this.state.data.first_name,
            last_name: this.state.data.last_name,
            gender: this.state.data.gender,
            date_of_birth: this.state.data.date_of_birth
              ? moment(this.state.data.date_of_birth).format(
                  "YYYY-MM-D h:mm:ss"
                )
              : null,
            phone_number: this.state.data.phone_number,
            new: true
          }
        },
        location: {
          latitude: this.state.data.latitude,
          longitude: this.state.data.longitude,
          area_id: this.state.data.area.id,
          country_id: this.state.data.country_id,
          building: this.state.data.building,
          floor: this.state.data.floor,
          postal_code: this.state.data.postal_code,
          directions: this.state.data.directions,
          type_id: this.state.data.location_type_id,
          type_label: this.state.data.location_type_label
        },
        cashboxData: this.state.cashboxData
      }
    };

    this.props
      .dispatch(customerService.create(info, !this.props.isAuthenticated))
      .then(data => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState({
          isLoading: false,
          responseError,
          open: true
        });
        if (reset) {
          this.handleReset();
        } else {
          this.handleGoBack(data);
        }
      })
      .catch(({ error, statusCode, errors }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState({
          errors: new ErrorBag()
        });
        if (errors) {
          Object.keys(errors).map((key, value) => {
            this.state.errors.add({
              field:
                key == "phone_number"
                  ? "shop_phone_number"
                  : _.last(_.split(key, ".")),
              msg: errors[key][0]
            });
          });
        }
        this.setState({ responseError });
        this.setState({
          isLoading: false
        });
      });
  };

  updateAreaObject = area_object => {
    const { data } = this.state;
    data["area"] = area_object;
    data['office_name'] = area_object && area_object.office ? area_object.office.name : '';
    data['office_id'] = area_object && area_object.office ? area_object.office.id : null
    this.setState({ data });
  };

  handleResetArea = (value) => {
    this.setState({
      data: { ...this.state.data, resetArea: value },
    });
  };


  handleOnUpdateInput = (searchText, dataSource, name, name_id) => {
    var chosenRequest = dataSource.filter(
      elem => _.toLower(elem.name) == _.toLower(searchText)
    );
    const { data } = this.state;
    data[name] = searchText;
    data[name_id] = chosenRequest.length > 0 ? chosenRequest[0] : null;
    this.setState({ data });
  };

  handleAutoCompleteChange = (chosenRequest, name) => {
    const { data } = this.state;
    data[name] = chosenRequest;
    this.setState({ data });
  };

  handleGoBack = data => {
    this.props.isAuthenticated &&
    this.props.match.url == "/sign-up"
      ? this.props.dispatch(push(`/login`))
      : this.props.dispatch(push(`/app/clients/` + data.data.id));
  };

  render() {
    const { errors } = this.state;
    const {
      classes,
      zoneList,
      vehicleList,
      typeList,
      operatorsNames,
      countryList,
      officeList
    } = this.props;
    const areaConfig = {
      text: "name",
      value: "id"
    };
    const input_password = !this.props.isAuthenticated
      ? [
          <div className="col-md-4" key={1}>
            <TextField
              name="password"
              floatingLabelText="Password *"
              value={this.state.data.password}
              errorText={errors.has("password") && errors.first("password")}
              onChange={event => this.handleTextChange(event)}
              type="password"
              fullWidth
            />
          </div>,
          <div className="col-md-4" key={2}>
            <TextField
              name="confirm_password"
              floatingLabelText="Confirm Password *"
              value={this.state.data.confirm_password}
              errorText={
                errors.has("confirm_password") &&
                errors.first("confirm_password")
              }
              onChange={event => this.handleTextChange(event)}
              type="password"
              fullWidth
            />
          </div>
        ]
      : null;
    const actions = this.props.isAuthenticated
      ? [
          <RaisedButton
            variant="contained"
            label="Cancel"
            key={1}
            color="primary"
            onClick={this.handleGoBack}
            className={classes.raised_buttons}
          >Cancel</RaisedButton>,
          <RaisedButton
            variant="contained"
            label="Reset"
            key={2}
            color="primary"
            onClick={this.handleReset}
            className={classes.raised_buttons}
          >Reset</RaisedButton>,
          <RaisedButton
            variant="contained"
            label="Create"
            key={3}
            color="primary"
            onClick={() => this.handleSubmit(false)}
            className={classes.raised_buttons}
          >Create</RaisedButton>,
          <RaisedButton
            variant="contained"
            label="Create & New"
            key={4}
            color="primary"
            onClick={() => this.handleSubmit(true)}
            className={classes.raised_buttons}
          >Create & New</RaisedButton>
        ]
      : [
          <div
            key={1}
            className="card-action no-border text-right"
            style={{ paddingRight: "0" }}
          >
            <FlatButton
              variant="text"
              label="Register"
              color="primary"
              onClick={() => this.handleSubmit(false)}
            >Register</FlatButton>
          </div>
        ];
    return (
      <article className="article">
        <section
          className={this.props.isAuthenticated ? 'box box-default' : null}
        >
          <div className={this.props.isAuthenticated ? 'box-body' : null}>
            <div className="col-lg-12">
              {this.state.responseError.isError && (
                <p className="text-center m-b-3" style={{ color: 'red' }}>
                  {this.state.responseError.text}
                </p>
              )}
              <div className="row">
                <div style={{ height: '50vh', width: '100%' }}>
                  <PinLocation
                    new={true}
                    coordinates={{
                      lat: this.state.data.latitude,
                      lng: this.state.data.longitude,
                    }}
                    handleChangeMarker={(coordinates) =>
                      this.handleChangeMarker(coordinates)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <h5 style={{ marginTop: '44px', marginBottom: 'none' }}>
                    Pin Location:
                  </h5>
                </div>

                <div className="col-md-3">
                  <TextField
                    value={
                      this.state.data.latitude != 0
                        ? this.state.data.latitude
                        : ''
                    }
                    hintText="33.8892171"
                    floatingLabelText="Latitude"
                    onChange={(event) => this.handleTextChange(event)}
                    name="latitude"
                    errorText={
                      errors.has('latitude') && errors.first('latitude')
                    }
                    fullWidth={true}
                  />
                </div>
                <div className="col-md-3">
                  <TextField
                    value={
                      this.state.data.longitude && this.state.data.longitude != 0
                        ? this.state.data.longitude
                        : ''
                    }
                    onChange={(event) => this.handleTextChange(event)}
                    name="longitude"
                    hintText="35.4867727"
                    floatingLabelText="Longitude"
                    errorText={
                      errors.has('longitude') && errors.first('longitude')
                    }
                    fullWidth={true}
                  />
                </div>
                <div
                  className={classnames(classes.filter_div, 'col-md-3')}
                  style={{ marginTop: '20px' }}
                >
                  <Button
                    variant="contained"
                    onClick={this.handleClearPin}
                    className={classes.filter_button}
                    style={{
                      backgroundColor: 'rgb(239, 83, 80)',
                      color: 'white',
                    }}
                  >
                    Clear Pin
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <SelectField
                    floatingLabelText="Type *"
                    value={this.state.data.type_id}
                    onChange={(event) =>
                      this.handleSelectChange(event.target.value, 'type_id')
                    }
                    fullWidth={true}
                  >
                    {types.CUSTOMER.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                      >{item.label}</MenuItem>
                    ))}
                  </SelectField>
                </div>
                {this.state.data.type_id === types.CUSTOMER_TYPE_OTHER && (
                  <div className="col-md-4">
                    <TextField
                      name="type_other_label"
                      floatingLabelText="Other Type *"
                      value={this.state.data.type_other_label}
                      onChange={(event) => this.handleTextChange(event)}
                      fullWidth
                    />
                  </div>
                )}
                {this.state.data.type_id ==
                types.CUSTOMER_TYPE_CUSTOMER ? null : (
                  <div className="col-md-4">
                    <TextField
                      name="shop_name"
                      floatingLabelText="Shop Name *"
                      value={this.state.data.shop_name}
                      errorText={
                        errors.has('shop_name') && errors.first('shop_name')
                      }
                      onChange={(event) => this.handleTextChange(event)}
                      fullWidth
                    />
                  </div>
                )}
                {this.state.data.type_id ==
                types.CUSTOMER_TYPE_CUSTOMER ? null : (
                  <div className="col-md-4">
                    <PhoneNumberInput
                      name="shop_phone_number"
                      label_text="Shop's Phone Number *"
                      value={this.state.data.shop_phone_number}
                      handleChange={this.handleChange}
                      handleAddValidationErrorChange={
                        this.handleAddValidationErrorChange
                      }
                      errorText={
                        errors.has('shop_phone_number') &&
                        errors.first('shop_phone_number')
                      }
                    />
                  </div>
                )}
                <div className="col-md-4">
                  <TextField
                    name="first_name"
                    floatingLabelText="First Name *"
                    value={this.state.data.first_name}
                    errorText={
                      errors.has('first_name') && errors.first('first_name')
                    }
                    onChange={(event) => this.handleTextChange(event)}
                    fullWidth
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    name="last_name"
                    floatingLabelText="Last Name *"
                    value={this.state.data.last_name}
                    errorText={
                      errors.has('last_name') && errors.first('last_name')
                    }
                    onChange={(event) => this.handleTextChange(event)}
                    fullWidth
                  />
                </div>
                <div className="col-md-4">
                  <PhoneNumberInput
                    name="phone_number"
                    label_text="Phone Number *"
                    value={this.state.data.phone_number}
                    handleChange={this.handleChange}
                    handleAddValidationErrorChange={
                      this.handleAddValidationErrorChange
                    }
                    errorText={
                      errors.has('phone_number') && errors.first('phone_number')
                    }
                  />
                </div>
                <div className="col-md-4">
                  <SelectField
                    floatingLabelText="Gender *"
                    value={this.state.data.gender}
                    onChange={(event) =>
                      this.handleSelectChange(event.target.value, 'gender')
                    }
                    fullWidth={true}
                  >
                    <MenuItem value={2}>Female</MenuItem>
                    <MenuItem value={1}>Male</MenuItem>
                  </SelectField>
                </div>
                <div className="col-md-4">
                  <DatePicker
                    floatingLabelText="Date of Birth"
                    openToYearSelection={true}
                    value={this.state.data.date_of_birth}
                    errorText={
                      errors.has('date_of_birth') &&
                      errors.first('date_of_birth')
                    }
                    onChange={(newValue) =>
                      this.handleDateChange(newValue, 'date_of_birth')
                    }
                    fullWidth={true}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    name="email"
                    floatingLabelText="Email *"
                    value={this.state.data.email}
                    errorText={errors.has('email') && errors.first('email')}
                    onChange={(event) => this.handleTextChange(event)}
                    fullWidth
                  />
                </div>
                {input_password}
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-10">
                      <TextField
                        name="golden_rule"
                        floatingLabelText="Golden Rule for Drivers"
                        value={this.state.data.golden_rule}
                        errorText={
                          errors.has('golden_rule') &&
                          errors.first('golden_rule')
                        }
                        onChange={(event) => this.handleTextChange(event)}
                        fullWidth
                      />
                    </div>
                    <div className="col-md-2">
                      <Tooltip
                        id="defined-tooltipo"
                        className={classes.table_cell_break}
                        title="Please input here your instructions you would like us to abide by such as clients are not allowed to try the items, always contact clients before delivering, etc..."
                      >
                        <i
                          className="material-icons"
                          style={{ marginTop: '35px' }}
                        >
                          info
                        </i>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-10">
                      <TextField
                        name="shop_note"
                        floatingLabelText="Shop's Note to Customers"
                        value={this.state.data.shop_note}
                        errorText={
                          errors.has('shop_note') &&
                          errors.first('shop_note')
                        }
                        onChange={(event) => this.handleTextChange(event)}
                        fullWidth
                      />
                    </div>
                    <div className="col-md-2">
                      <Tooltip
                        id="defined-tooltipo"
                        className={classes.table_cell_break}
                        title="Please input here your notes you'd like us to include in the WhatsApp messages when contacting customers to deliver their orders."
                      >
                        <i
                          className="material-icons"
                          style={{ marginTop: '35px' }}
                        >
                          info
                        </i>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {this.state.data.type_id !== types.CUSTOMER_TYPE_CUSTOMER && (
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-10">
                        <SelectField
                          floatingLabelText="Delivery Payment Method *"
                          value={this.state.data.payment_method}
                          onChange={(event) =>
                            this.handleSelectChange(
                              event.target.value,
                              'payment_method',
                            )
                          }
                          autoWidth={false}
                          fullWidth={true}
                          menuStyle={{ width: '375px' }}
                          iconStyle={{ right: '115px' }}
                          renderValue={(value) => {
                            const selectedItem = types.BUSINESS_PAYMENT_METHOD.find(item => item.id === value);
                            return selectedItem ? selectedItem.label : '';
                          }}
                        >
                          {types.BUSINESS_PAYMENT_METHOD.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                            >
                              <ListItemText primary={item.label} secondary={item.notes} />
                            </MenuItem>
                          ))}
                        </SelectField>
                      </div>
                      <div className="col-md-2">
                        <Tooltip
                          id="defined-tooltipo"
                          className={classes.table_cell_break}
                          title="How will you be paying your delivery fees toward Wakilni?"
                        >
                          <i
                            className="material-icons"
                            style={{ marginTop: '35px' }}
                          >
                            info
                          </i>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-4">
                  <TextField
                    name="mof"
                    floatingLabelText="MOF"
                    value={this.state.data.mof}
                    onChange={(event) => this.handleTextChange(event)}
                    fullWidth
                    errorText={
                      errors.has('mof') &&
                      errors.first('mof')
                    }
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    name="vat"
                    floatingLabelText="VAT"
                    value={this.state.data.vat}
                    onChange={(event) => this.handleTextChange(event)}
                    fullWidth
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    name="accounting_ref"
                    floatingLabelText="Accounting reference number"
                    value={this.state.data.accounting_ref}
                    onChange={(event) => this.handleTextChange(event)}
                    fullWidth
                  />
                </div>
                {this.props.isAuthenticated && (
                  <div className="col-md-4">
                    <SelectField
                      floatingLabelText="Account Manager"
                      value={this.state.data.account_manager_id}
                      onChange={(event) =>
                        this.handleSelectChange(
                          event.target.value,
                          'account_manager_id',
                        )
                      }
                      errorText={
                        errors.has('account_manager_id') &&
                        errors.first('account_manager_id')
                      }
                      fullWidth={true}
                    >
                      {operatorsNames.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                        >{item.name}</MenuItem>
                      ))}
                    </SelectField>
                  </div>
                )}
                <div className="col-md-4">
                  <SelectField
                    floatingLabelText="Location Type *"
                    value={this.state.data.location_type_id}
                    onChange={(event) =>
                      this.handleSelectChange(
                        event.target.value,
                        'location_type_id',
                      )
                    }
                    fullWidth={true}
                  >
                    {typeList.locationTypes
                      ? typeList.locationTypes.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                          >{item.label}</MenuItem>
                        ))
                      : types.LOCATION.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                          >{item.label}</MenuItem>
                        ))}
                  </SelectField>
                </div>
                {this.state.data.location_type_id ===
                  types.LOCATION_TYPE_OTHER && (
                  <div className="col-md-4">
                    <TextField
                      name="location_type_label"
                      floatingLabelText="Other Location *"
                      value={this.state.data.location_type_label}
                      onChange={(event) => this.handleTextChange(event)}
                      fullWidth
                    />
                  </div>
                )}
                <div className="col-md-4">
                  <SelectField
                    floatingLabelText="Country *"
                    value={this.state.data.country_id}
                    onChange={(event) =>
                      this.handleSelectChange(event.target.value, "country_id")
                    }
                    fullWidth={true}
                  >
                    {countryList
                      ? countryList.map(item => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                          >{item.name}</MenuItem>
                        ))
                      : null}
                  </SelectField>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-10">
                      <AreaAutoComplete
                        floatingLabelText="Area *"
                        updateAreaObject={this.updateAreaObject}
                        errorText={errors.has('area') && errors.first('area')}
                        fullWidth={true}
                        filter_by_country={this.state.data.country_id}
                        resetArea={this.state.data.resetArea}
                        onReset={this.handleResetArea}
                      />
                    </div>
                    <div className="col-md-2">
                      <Tooltip
                        id="defined-tooltipo"
                        className={classes.table_cell_break}
                        title="If the area you want is not in our list, choose 'other' and specify the area in the directions field."
                      >
                        <i
                          className="material-icons"
                          style={{ marginTop: '35px' }}
                        >
                          info
                        </i>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <SelectField
                    floatingLabelText="Wakilni Branch"
                    value={this.state.data.office_id}
                    onChange={(event) =>
                      this.handleSelectChange(event.target.value, "office_id")
                    }
                    disabled={true}
                    fullWidth={true}
                  >
                    {officeList
                      ? officeList.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                          >{item.name}</MenuItem>
                        ))
                      : null}
                  </SelectField>
                </div>
                <div className="col-md-4">
                  <TextField
                    name="building"
                    floatingLabelText={this.state.isNational ? "Building" : "Building *" }
                    value={this.state.data.building}
                    onChange={(event) => this.handleTextChange(event)}
                    errorText={
                      errors.has('building') && errors.first('building')
                    }
                    fullWidth
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    name="floor"
                    floatingLabelText={this.state.isNational ? "Floor" : "Floor *" }
                    value={this.state.data.floor}
                    onChange={(event) => this.handleTextChange(event)}
                    errorText={errors.has('floor') && errors.first('floor')}
                    fullWidth
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    name="postal_code"
                    floatingLabelText={this.state.isNational ? "Postal Code" : "Postal Code *" }
                    value={this.state.data.postal_code}
                    onChange={(event) => this.handleTextChange(event)}
                    errorText={errors.has('postal_code') && errors.first('postal_code')}
                    fullWidth
                  />
                </div>
                <div className="col-md-12">
                  <TextField
                    name="directions"
                    floatingLabelText="Directions *"
                    value={this.state.data.directions}
                    multiLine
                    rows={2}
                    rowsMax={4}
                    onChange={(event) => this.handleTextChange(event)}
                    errorText={
                      errors.has('directions') && errors.first('directions')
                    }
                    fullWidth
                  />
                </div>
                {this.state.data.type_id !== types.CUSTOMER_TYPE_CUSTOMER && (
                  <div className="col-md-12" style={{ marginTop: '40px' }}>
                    <RaisedButton
                      variant="contained"
                      aria-label="Upload Logo for shop"
                      component="label"
                      color="primary"
                      //className={classes.UploadLogoButton}
                    >
                      Upload Logo for shop
                      <input
                        name="logo"
                        type="file"
                        onChange={this.onLogoUpload}
                        className={classes.UploadLogoInput}
                      />
                    </RaisedButton>
                    <span className="ml-3">
                      {this.state.data.image_file
                        ? 'Logo Uploaded Successfully.'
                        : '( Optional )'}
                    </span>
                    <div className="col-md-6" style={{ marginTop: '10px' }}>
                      {this.state.data.image_preview ? (
                        <img
                          src={this.state.data.image_preview}
                          height="100"
                          alt="Image preview..."
                        />
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
              {!(this.state.data.type_id === types.CUSTOMER_TYPE_CUSTOMER) && (isAdmin(this.props.user) || isAccountant(this.props.user) || isOperator(this.props.user)) && (
                <CashboxSetup onCreate={this.handleCashboxCreate} phoneNumber={this.state.data.shop_phone_number} email={this.state.data.email} />
              )}
              <div className="row" style={{ float: "right" }}>
                <div className="col-md-12">
                  {this.state.isLoading ? <CircularProgress /> : actions}
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    );
  }
}
CreateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  zoneList: PropTypes.array.isRequired,
  vehicleList: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  countryList: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    zoneList: state.data.zoneList,
    vehicleList: state.data.vehicleList,
    isAuthenticated: state.auth.isAuthenticated,
    typeList: state.data.typeList,
    token: state.auth.token,
    operatorsNames: state.data.operatorsNames,
    user: state.auth.user,
    countryList: state.countries.countryList,
    officeList: state.settings.officeList,
    location_Search: state.router.location.search,
  };
};

export default withRouter(withStyles(styles)(connect(mapStateToProps)(CreateForm)));
