import React from "react";
import QueueAnim from "rc-queue-anim";
import AquisitionChart from "./AquisitionChart";
import StatBoxes from "./StatBoxes";
import { List, ListItem, ListItemText } from "@mui/material";
import { connect } from "react-redux";
import moment from "moment";
import { getRole } from "utils/helpers";
import PropTypes from "prop-types";
import { push } from 'connected-react-router';

class Notifications extends React.Component {

  handleNotificationSelect(notification) {
    if (
      notification.type &&
      notification.type.id == 28 &&
      !notification.object_id
    ) {
      this.props.dispatch(push(`/app/tasks`));
    } else if (
      notification.type &&
      notification.type.id == 28 &&
      notification.object_id
    ) {
      this.props.dispatch(push(`/app/orders/` + notification.object_id));
    } else if (
      !notification.type &&
      notification.object_type == "App\\Models\\Order"
    ) {
      this.props.dispatch(push(`/app/orders/` + notification.object_id));
    } else if (
      !notification.type &&
      notification.object_type == "App\\Models\\Message"
    ) {
      this.props.dispatch(push(
        `/app/requests/` + notification.object_id
      ));
    } else if (
      !notification.type &&
      notification.object_type == "App\\Models\\DriverSubmission"
    ) {
      this.props.dispatch(push(`/app/accounting/submissions`));
    }
  }

  render() {
    const { user, data } = this.props;
    const user_role = getRole(user);
    const customer_and_operator = [
      "customer",
      "admin",
      "operator",
      "accountant"
    ];
    return (
      <div className="row">
        <div className="col-xl-6">
          <div className="box box-default">
            <div className="box-header">Notifications</div>
            {this.props.notifications.length == 0 ? (
              <div
                className="box-body"
                style={{
                  height: "393px",
                  overflowX: "hidden",
                  overflowY: "auto"
                }}
              >
                You have 0 Notifications
              </div>
            ) : (
              <div
                className="box-body"
                style={{
                  height: "393px",
                  overflowX: "hidden",
                  overflowY: "auto"
                }}
              >
                <List>
                  {this.props.notifications.map(notification => {
                    return (
                      <ListItem
                        key={notification.id}
                        onClick={() =>
                          this.handleNotificationSelect(notification)
                        }
                      >
                        <ListItemText 
                          primary={notification.data} 
                          secondary={moment(
                            notification.created_at
                          ).fromNow()}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            )}
          </div>
        </div>
        {customer_and_operator.includes(user_role) &&
          data &&
          data.dwell_time && (
            <div className="col-xl-6">
              <div className="box box-default">
                <div className="box-body">
                  <AquisitionChart data={data.dwell_time} />
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

Notifications.propTypes = {
  data: PropTypes.any,
  user: PropTypes.object.isRequired,
  notifications: PropTypes.array
};

const mapStateToProps = state => {
  return {
    notifications: state.auth.notifications,
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(Notifications);
