import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRole } from "utils/helpers";
import moment from "moment";

class StatBoxesMonthly extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true
    };
  }

  render() {
    const { data, user } = this.props;
    const user_role = getRole(user);
    const customer = ["customer"];
    const operator = ["admin", "operator", "accountant"];
    const accountant = ["admin", "accountant"];
    const customer_and_operator = [
      "customer",
      "admin",
      "operator",
      "accountant"
    ];
    return (
      <article className="article">
        <h2 className="article-title">{moment().format("MMMM")} Statistics</h2>
        <div className="row">
          {customer_and_operator.includes(user_role) && data.orders_placed ? (
            <div className="col-xl-3 col-sm-6">
              <div className="box box-default">
                <div className="box-top">
                  <span>{data.orders_placed}</span>
                </div>
                <div className="box-info">
                  <span>Placed Orders</span>
                </div>
                <div className="box-bottom">
                  <i className="material-icons color-warning">
                    add_shopping_cart
                  </i>
                </div>
              </div>
            </div>
          ) : null}
          {operator.includes(user_role) && data.customers_registered ? (
            <div className="col-xl-3 col-sm-6">
              <div className="box box-default">
                <div className="box-top">
                  <span>{data.customers_registered}</span>
                </div>
                <div className="box-info">
                  <span>Registered Customers</span>
                </div>
                <div className="box-bottom">
                  <i className="material-icons color-success">face</i>
                </div>
              </div>
            </div>
          ) : null}
          {accountant.includes(user_role) && data.delivery_fees ? (
            <div className="col-xl-3 col-sm-6">
              <div className="box box-default">
                <div className="box-top">
                  <span>
                    {data.delivery_fees}
                    <span className="size-h5">$</span>
                  </span>
                </div>
                <div className="box-info">
                  <span>Delivery Fees</span>
                </div>
                <div className="box-bottom">
                  <i className="material-icons color-info">payment</i>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </article>
    );
  }
}

StatBoxesMonthly.propTypes = {
  data: PropTypes.any,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};
export default connect(mapStateToProps)(StatBoxesMonthly);
