const date = new Date();
const year = date.getFullYear();



const APPCONFIG = {
  firebase: {
    apiKey: 'AIzaSyD-FJCl-5utZmdJW4jpYHWWfSbNefxPDnA',
    authDomain: 'wakilni-3ccfc.firebaseapp.com',
    databaseURL: 'https://wakilni-3ccfc.firebaseio.com',
    projectId: 'wakilni-3ccfc',
    storageBucket: 'wakilni-3ccfc.appspot.com',
    messagingSenderId: '181452121062',
  },
  brand: 'Wakilni',
  user: 'Lisa',
  year,
  productLink: 'http://www.wakilni.com/',
  AutoCloseMobileNav: true, // true, false. Automatically close sidenav on route change (Mobile only)
  color: {
    primary: '#00BCD4',
    success: '#8BC34A',
    info: '#66BB6A',
    infoAlt: '#7E57C2',
    warning: '#FFCA28',
    danger: '#F44336',
    text: '#3D4051',
    gray: '#EDF0F1',
  },
  settings: {
    layoutBoxed: false, // true, false
    navCollapsed: true, // true, false
    navBehind: false, // true, false
    fixedHeader: true, // true, false
    sidebarWidth: 'small', // small, middle, large
    colorOption: '15', // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: 'light', // light, gray, dark
  },
  recaptcha: {
    site_key: '6LcbU3gUAAAAAJ8dKekPBd3uby-wPnHocp5rApEV',
  },
  pusher_key: process.env.REACT_APP_PUSHER_KEY,
  pusher_cluster: 'us2',
  socket_api: process.env.REACT_APP_SOCKET_API,
  api_url: process.env.REACT_APP_API_URL,
  api_url_alt: {
    'LB': process.env.REACT_APP_API_URL_LB,
    'JO': process.env.REACT_APP_API_URL_JO,
  },
};

module.exports = APPCONFIG;
