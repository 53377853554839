import React from 'react';
import APPCONFIG from 'constants/Config';
import FlatButton from '@mui/material/Button';
import SelectField from '../../components/EnhancedSelectField';
import MenuItem from "@mui/material/MenuItem";
import TextField from 'components/EnhancedTextField';
import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReeValidate from 'ree-validate';
import * as authService from '../../services/authService';
import CircularProgress from '@mui/material/CircularProgress';
import * as authActions from 'stores/actions/auth';
import * as types from 'constants/Types';
import ReCaptchaComponent from 'components/Recaptcha';
import EnhancedSnackBar from 'components/EnhancedSnackBar';
import {LB, JO } from 'country-flag-icons/react/3x2';
import { isEmpty } from 'utils/helpers';
import { HotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';
import {
  isTracker,
  isAdmin,
  isOperator,
  isAccountant,
  isCustomer,
  isExternalViewer,
} from 'utils/helpers';
import { push } from 'connected-react-router';

class Login extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.validator = new ReeValidate({
      email: 'required|email',
      password: 'required|min:6',
    });

    this.state = {
      brand: APPCONFIG.brand,
      credentials: {
        email: '',
        password: '',
      },
      responseError: {
        isError: false,
        code: '',
        text: '',
      },
      isLoading: false,
      errors: this.validator.errors,
      country: 'LB',
    };
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const { credentials } = this.state;
    credentials[name] = value;
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.setState({ errors: errors, credentials });
    });
  };

  keyMap = {
    SUBMIT: 'enter',
  };

  handlers = {
    SUBMIT: (event) => {
      this.handleSubmit();
    },
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const { credentials } = this.state;
    this.validator.validateAll(credentials).then((success) => {
      if (success) {
        if (process.env.DISABLE_RECAPTCHA === "true") {
          this.setState({
            isLoading: true,
          });
          this.submit(credentials); // Submit without reCAPTCHA verification
        } else {
        if (this.props.recaptcha) {
          this.setState({
            isLoading: true,
          });
          this.submit(
            { ...credentials, recaptcha_token: this.props.recaptcha },
            this.props.token,
          );
        } else {
          this.props.dispatch(
            authActions.serverError('Recaptcha Requires Verification.'),
          );
        }
      }
      } else {
        const { errors } = this.validator;
        this.setState({ errors: errors, credentials });
      }
    });
  };

  submit = (credentials, token = null) => {
    let input = {};
    if (this.props.location_Search) {
      const searchParams = new URLSearchParams(this.props.location_Search);
      let shop = searchParams.get('shop');
      let code = searchParams.get('code');
      if (token || token != '') {
        input = {
          ...credentials,
          thirdparty_code: code,
          thirdparty_shop: shop,
          thirdparty_token: !isEmpty(this.props.shop)
            ? this.props.shop.token
            : '',
          device_token: token,
          device_type: types.DEVICE_TYPE_WEB,
        };
      } else {
        input = {
          ...credentials,
          thirdparty_code: code,
          thirdparty_shop: shop,
          thirdparty_token: !isEmpty(this.props.shop)
            ? this.props.shop.token
            : '',
        };
      }
    } else {
      if (token) {
        input = {
          ...credentials,
          device_token: token,
          device_type: types.DEVICE_TYPE_WEB,
        };
      } else {
        input = {
          ...credentials,
        };
      }
    }

    if (!!localStorage.getItem('jwt_token')) {
      this.checkAuth();
    } else {
      localStorage.setItem("country", this.state.country);
      this.props
        .dispatch(authService.login(input))
        .catch(({ error, statusCode }) => {
          const responseError = {
            isError: true,
            code: statusCode,
            text: error,
          };
          this.setState({ responseError });
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  componentDidMount() {
    this.setState({ isLoading: false });
    this.checkAuth();
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    localStorage.setItem("country", this.state.country);
    this.checkAuth(nextProps);
  }

  checkAuth = (props) => {
    const { from } = this.props.location.state || {
      from: { pathname: '/login' },
    };
    let isAuthenticated = !!localStorage.getItem('jwt_token');

    if (
      process.env.REACT_APP_IS_SHOPIFY === 'true' &&
      !this.state.credentials.email
    ) {
      isAuthenticated = false;
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('auth');
    }
    if (isAuthenticated) {
      if (from.pathname === '/login') {
        if (isTracker(props.user)) {
          from.pathname = '/app/map';
        } else if (
          isAdmin(props.user) ||
          isOperator(props.user) ||
          isAccountant(props.user)
        ) {
          from.pathname = '/app/orders/pending';
        } else if (isExternalViewer(props.user)) {
          from.pathname = '/app/orders/express/active';
        } else if (process.env.REACT_APP_IS_SHOPIFY === 'true') {
          from.pathname = '/shopify/login';
        } else {
          from.pathname = localStorage.getItem('lastPageVisited')
            ? localStorage.getItem('lastPageVisited')
            : '/app/orders';
        }
      }
      this.props.dispatch(push(from.pathname));
    }
  };

  handleRequestClose = () => {
    this.props.dispatch(authActions.serverSuccessReset());
  };

  componentDidMount() {
    if (!isEmpty(this.props.shop)) {
      this.setState({
        ...this.state,
        credentials: {
          password: this.state.credentials.password,
          email: this.props.shop.email ? this.props.shop.email : '',
        },
      });
    } else {
      if (this.props.location_Search) {
        this.fetchStoreData();
      }
    }
  }

  fetchStoreData() {
    this.setState({
      isLoading: true,
    });
    const searchParams = new URLSearchParams(this.props.location_Search);
    let shop = searchParams.get('shop');
    let code = searchParams.get('code');

    this.props
      .dispatch(authService.getShopifyShop(code, shop))
      .then((data) => {
        const responseError = {
          isError: false,
          code: '',
          text: '',
        };
        this.setState({
          ...this.state,
          isLoading: false,
          credentials: {
            password: this.state.credentials.password,
            email: data.data.shop.email,
          },
        });
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error,
        };
        this.setState({
          isLoading: false,
          responseError,
        });
      });
  }

  render() {
    const { errors } = this.state;
    const { open, successMsg } = this.props;
    return (
      <div className="page-login">
        <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <div className="main-body">
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              <div className="body-inner">
                <div className="card bg-white">
                  <div className="card-content">
                    <section className="logo text-center">
                      <h1>
                        <a href="https://wakilni.com/">
                          <img
                            src="/assets/images/wakilni-logo-v2.png"
                            height="40"
                            alt={this.state.brand}
                          />
                        </a>
                      </h1>
                      <h3>Login</h3>
                      {this.state.responseError.isError && (
                        <p
                          className="text-center m-b-3"
                          style={{ color: 'red' }}
                        >
                          {this.state.responseError.text}
                        </p>
                      )}
                    </section>
                    <form className="form-horizontal">
                      <fieldset>
                        {process.env.REACT_APP_IS_SHOPIFY === 'true' ? 
                        <div className="form-group">
                          <SelectField
                            floatingLabelText="Country *"
                            value={this.state.country}
                            onChange={(event) =>
                              this.setState({
                                country: event.target.value,
                              })
                            }
                            fullWidth={true}
                          >
                            <MenuItem value={'LB'} leftIcon={<LB title="United States"/>} >Lebanon</MenuItem>
                            <MenuItem value={'JO'} leftIcon={<JO title="United States"/>}>Jordan</MenuItem>
                          </SelectField>
                        </div>
                        :null}
                        <div className="form-group">
                          <TextField
                            name="email"
                            value={this.state.credentials.email}
                            floatingLabelText="Email"
                            errorText={
                              errors.has('email') && errors.first('email')
                            }
                            onChange={this.handleChange}
                            onKeyPress={this.handleSubmit}
                            fullWidth
                          />
                        </div>
                        <div className="form-group">
                          <TextField
                            name="password"
                            value={this.state.credentials.password}
                            errorText={
                              errors.has('password') && errors.first('password')
                            }
                            floatingLabelText="Password"
                            type="password"
                            onChange={this.handleChange}
                            onKeyPress={this.handleSubmit}
                            fullWidth
                          />
                        </div>
                        <div className="form-group">
                          <ReCaptchaComponent />
                        </div>
                      </fieldset>
                    </form>
                  </div>
                  <div className="card-action no-border text-right">
                    {this.state.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FlatButton
                        variant="text"
                        label="Login"
                        color="primary"
                        onClick={this.handleSubmit}
                      >Login</FlatButton>
                    )}
                  </div>
                </div>

                <div className="additional-info">
                  {/*<Link to={`sign-up${this.props.location_Search}`} style={{textDecoration: 'none'}}>*/}
                  {/*  Register as customer*/}
                  {/*</Link>*/}
                  {/*<span className="divider-h" />*/}
                  <Link
                    to={`forgot-password`}
                    style={{ textDecoration: 'none' }}
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
            </div>
          </QueueAnim>
        </div>
        </HotKeys>
        <EnhancedSnackBar />
      </div>
    );
  }
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  successMsg: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  recaptcha: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    location_Search: state.router.location.search
  };
};

export default connect(mapStateToProps)(Login);
