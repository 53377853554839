import * as types from "../ActionTypes/orders";
import { order } from "stores/models/Order";

export function showOrderChildren(id) {
  return { type: types.SHOW_ORDER_CHILDREN, payload: id };
}
export function handleSelectOrders(id) {
  return { type: types.HANDLE_SELECT_ORDER, id };
}
export function handleSelectAllOrders() {
  return { type: types.HANDLE_SELECT_ALL_ORDER };
}
export function fetchOrdersResolved(payload) {
  return {
    type: types.FETCH_ORDERS_RESOLVED,
    payload
  };
}
export function fetchBulkReturnFormOrdersResolved(payload) {
  return {
    type: types.FETCH_BULK_RETURN_FORM_ORDERS_RESOLVED,
    payload
  };
}

export function fetchCashboxesOrdersResolved(payload) {
  return {
    type: types.FETCH_CASHBOXES_ORDERS_RESOLVED,
    payload
  };
}
export function SearchOrdersResolved(payload, meta, info) {
  return {
    type: types.SEARCH_ORDERS_RESOLVED,
    payload,
    info,
    meta
  };
}
export function CriticalOrdersResolved(payload, page = null) {
  return {
    type: types.CRITICAL_ORDERS_RESOLVED,
    payload,
    page
  };
}
export function fetchPickupOrdersResolved(payload) {
  return {
    type: types.FETCH_PICKUP_ORDERS_RESOLVED,
    payload
  };
}

export function fetchReturnOrdersResolved(payload) {
  return {
    type: types.FETCH_RETURN_ORDERS_RESOLVED,
    payload
  };
}

export function fetchExpressOrdersResolved(payload) {
  return {
    type: types.FETCH_EXPRESS_ORDERS_RESOLVED,
    payload
  };
}

export function fetchActiveOrdersResolved(payload, customer, page = null) {
  return {
    type: types.FETCH_ACTIVE_ORDERS_RESOLVED,
    payload,
    customer,
    page
  };
}

export function fetchCashboxOrdersResolved(payload, customer, page = null) {
  return {
    type: types.FETCH_CASHBOX_ORDERS_RESOLVED,
    payload,
    customer,
    page
  };
}
export function fetchOrderImportsResolved(payload, page = null) {
  return {
    type: types.FETCH_ORDER_IMPORTS_RESOLVED,
    payload,
    page
  };
}

export function fetchRevertedOrderImportResolved(payload, page = null) {
  return {
    type: types.FETCH_REVERTED_ORDER_IMPORT_RESOLVED,
    payload,
    page
  };
}
export function fetchCommentsResolved(payload) {
  return {
    type: types.FETCH_COMMENTS_RESOLVED,
    payload
  };
}
export function clearOrdersResolved() {
  return {
    type: types.CLEAR_ORDERS_RESOLVED
  };
}
export function clearCriticalOrdersResolved() {
  return {
    type: types.CLEAR_CRITICAL_ORDERS_RESOLVED
  };
}
export function seenNewOrders() {
  return {
    type: types.SEEN_NEW_ORDERS
  };
}
export function seenNewActiveOrders() {
  return {
    type: types.SEEN_NEW_ACTIVE_ORDERS
  };
}
export function listenerOrderCreated(payload) {
  return {
    type: types.LISTENER_ORDER_CREATED,
    payload: order(payload)
  };
}
export function fetchOrderResolved(payload) {
  return {
    type: types.FETCH_ORDER_RESOLVED,
    payload
  };
}
export function fetchOrdersReportResolved(payload) {
  return {
    type: types.FETCH_ORDERS_REPORTS_RESOLVED,
    payload
  };
}
export function updateTaskResolved(payload) {
  return {
    type: types.UPDATE_TASK_RESOLVED,
    payload
  };
}
export function updateOrderResolved(payload, remove) {
  return {
    type: types.UPDATE_ORDER_RESOLVED,
    payload,
    remove
  };
}
export function updateMultipleOrdersResolved(payload, remove) {
  return {
    type: types.UPDATE_MULTIPLE_ORDERS_RESOLVED,
    payload,
    remove
  };
}
export function listenerUpdateOrderResolved(payload) {
  return {
    type: types.LISTENER_UPDATE_ORDER_RESOLVED,
    payload: order(payload)
  };
}

export function listenerUpdateMultipleOrdersResolved(payload) {
  //not tested
  return {
    type: types.LISTENER_UPDATE_MULTIPLE_ORDERS_RESOLVED,
    payload: payload
  };
}


export function addOrderResolved(payload) {
  return {
    type: types.ADD_ORDER_RESOLVED,
    payload
  };
}
export function addEnhancedCommentResolved(payload, customer_id = 0) {
  return {
    type: types.ADD_ENHANCED_COMMENT_RESOLVED,
    customer_id,
    payload
  };
}
export function updateEnhancedImpCommentResolved(
  payload,
  make_important,
  customer_id
) {
  return {
    type: types.UPDATE_ENHANCED_IMP_COMMENT_RESOLVED,
    payload,
    make_important,
    customer_id
  };
}
export function fetchOrderDetailsResolved(payload) {
  return {
    type: types.FETCH_ORDER_DETAILS_RESOLVED,
    payload
  };
}
export function fetchActivityLogResolved(payload) {
  return {
    type: types.FETCH_ACTIVITY_LOG_RESOLVED,
    payload
  };
}
export function updateLocationResolved(payload) {
  return {
    type: types.UPDATE_TASK_LOCATION_RESOLVED,
    payload
  };
}

export function chosenPackageType(payload) {
  return {
    type: types.CHOSEN_PACKAGE_TYPE,
    payload
  };
}
export function clearComments() {
  return {
    type: types.CLEAR_COMMENTS_RESOLVED
  };
}
export function clearTasks() {
  return {
    type: types.CLEAR_TASKS_RESOLVED
  };
}

export function fetchUnPaidOrdersResolved(payload) {
  return {
    type: types.FETCH_UNPAID_ORDERS,
    payload
  };
}

export function fetchTasksForOrderResolved(payload) {
  return {
    type: types.FETCH_TASKS_FOR_ORDER,
    payload
  };
}

export function deleteComment(payload) {
  return {
    type: types.DELETE_COMMENT_RESOLVED,
    payload
  };
}

export function editComment(payload) {
  return {
    type: types.EDIT_COMMENT_RESOLVED,
    payload
  };
}

export function editOrderDeliveryPrice(payload) {
  return {
    type: types.EDIT_ORDER_DELIVERY_PRICE_RESOLVED,
    payload
  };
}

export function clearOrderList() {
  return {
    type: types.CLEAR_ORDER_LIST
  };
}

export function fetchAuditedOrderPrices(payload) {
  return {
    type: types.FETCH_AUDITED_ORDER_PRICE_RESOLVED,
    payload,
  };
}


export function fetchAuditedOrderCollectionAmounts(payload) {
  return {
    type: types.FETCH_AUDITED_ORDER_COLLECTION_RESOLVED,
    payload,
  };
}
