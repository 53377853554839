import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import classnames from "classnames";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";
import moment from "moment";

class EnhancedTimePicker extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state={ currentValue: null };
    this.onFocus = this.onFocus.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onFocus() {
    if (!this.props.value) {
      var date = new Date();
      date.setMinutes(0);
      this.props.onChange(date, this.props.name);
    }
  }

  onClear() {
    this.props.onChange(null, this.props.name);
    this.setState({ currentValue: null });
  }

  onAccept(date) {
    this.props.onChange(date);
    this.setState({ currentValue: date });
  }
  
  handleOpen() {
    setTimeout(() => {
      if(!this.props.value){
        const currentDate = moment();
        this.setState({ currentValue: currentDate });
      }else{
        this.setState({ currentValue: this.props.value });
      }
    }, 1);
  }

  handleClose() {
    if(!this.props.value){
      this.setState({ currentValue: null });
    }
  }

  render() {
    const {
      floatingLabelText,
      classes,
      value,
      onChange,
      fullWidth,
      disabled,
      page
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-10 mobile-width" style={{ paddingRight: "0px" }}>
          <MobileTimePicker
            label={floatingLabelText}
            // format="24hr"
            format="ampm"
            value={this.state.currentValue || value}
            onChange={(newValue) => this.setState({ currentValue: newValue })}
            onAccept={this.onAccept}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            floatingLabelStyle={{ fontSize: "13px" }}
            fullWidth={fullWidth}
            disabled={disabled}
            onFocus={this.onFocus}
            renderInput={(params) => 
              <TextField 
                {...params} 
                fullWidth
                variant="standard"
              />
            }
          />
        </div>
        {page == "map page" ? null : (
          <div className="col-md-2 mobile-width" style={{ paddingLeft: "0px" }}>
            <Tooltip
              id="tooltip-clear"
              title="Clear Time"
              className="clear_action_tooltip"
            >
              <IconButton
                onClick={this.onClear}
                style={{ marginTop: "37px", width: "20px", padding: "0" }}
              >
                {" "}
                <i
                  className="material-icons grey-icon"
                >
                  clear
                </i>
                {" "}
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

EnhancedTimePicker.propTypes = {
  floatingLabelText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string
};

export default withStyles(styles)(EnhancedTimePicker);
