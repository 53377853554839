export const NOTES = [
  { id: 1, type: 'Call_before_delivery', label: 'Call before delivery' },
  { id: 2, type: 'Call_to_schedule', label: 'Call to schedule' },
  { id: 3, type: 'Leave_with_concierge', label: 'Leave with concierge' },
  { id: 4, type: 'Leave_at_the_door', label: 'Leave at the door' },
  {
    id: 5,
    type: 'Always_someone_available',
    label: 'Someone’s always available',
  },
  { id: 6, type: 'Meet_at_the_car', label: 'Meet at the car' },
  { id: 7, type: 'Leave_with_neighbor', label: 'Leave with neighbor' },
  { id: 8, type: 'Other', label: 'Other: please specify' },
];

export const OTHER_NOTES = 8;

export const customers = [
  'Asia',
  'Jamila',
  'Zeinab',
  'Neam',
  'Ayham',
  'James',
  'Samah',
  'Dalia',
];

export const types = ['B2B', 'B2C', 'Customer'];

export const objectTypes = {
  location: {
    label: 'LOCATION',
    type: 'locationTypes',
  },
  package: {
    label: 'PACKAGE',
    type: 'packageTypes',
  },
  industries: {
    label: 'INDUSTRY',
    type: 'industryTypes',
  },
};

export const zones = [
  'Beirut',
  'Bent Jbayl',
  'Nabatiyeh',
  'Beirut-three',
  'Zahleh',
  'Aley',
  'Baabda',
  'Chouf',
  'Jbayl',
  'Kesrouan',
  'Matn',
  'Akkar',
  'Batroun',
  'Bcharreh',
  'Koura',
  'Miniyeh-Danniyeh',
  'Tripoli',
  'Zgharta',
  'Jezzine',
  'Saida',
  'Sour',
  'Zahrany',
  'Beirut-two',
  'Beirut-one',
  'Baalbek-Hermel',
  'WestBekaa-Rachaya',
];

export const orders = [];

export const CURRENCY_USD = 1;

export const SHOPIFY_APP_ID = 1;
export const ECOMZ_APP_ID = 3;
export const WOOCOMMERCE_APP_ID = 4;
export const MARKETPLACE_APP_ID = 6;
export const CUSTOM_APP_ID = 5;

export const drivers = [];

export const contacts = [];

export const recipients = [];

export const locations = [];

export const location = {};

export const notifications = [];

export const tasks = [];

export const SETTINGS_CHECK_IN_OUT_CONFIRMATION = 1;
export const SETTINGS_SERVICE_REQUEST_EMAIL = 2;

export const SETTINGS = [
  { id: 1, name: 'Check in/out confirmation' },
  { id: 2, name: 'Send service request to email' },
];

export const SERVICE_PICKUP_OR_DELIVER_ITEM = 8;
export const SERVICE_BUY_SOMETHING = 9;
export const SERVICE_RUN_BUSINESS = 10;
export const SERVICE_DONATE = 11;

export const SERVICES = [
  { id: 8, name: 'I want to pickup or deliver an item' },
  { id: 9, name: 'I want to buy something' },
  { id: 10, name: 'Run a business' },
  { id: 11, name: 'I want to check initiatives and donate' },
];

export const ROLE_ADMIN = 1;
export const ROLE_OPERATOR = 4;
export const ROLE_ACCOUNTANT = 5;
export const ROLE_TRACKER = 6;
export const ROLE_EXTERNAL_VIEWER = 7;

export const wakilni_user_roles = [
  {
    id: 1,
    label: 'Admin',
  },
  {
    id: 4,
    label: 'Operator',
  },
  {
    id: 5,
    label: 'Accountant',
  },
  {
    id: 6,
    label: 'Tracker',
  },
  {
    id: 7,
    label: 'External Viewer'
  },
];

export const REVIEWS = [
  { id: 1, label: 'Great' },
  { id: 2, label: 'Good' },
  { id: 3, label: 'Ok' },
  { id: 4, label: 'Bad' },
];

export const SHOP_REVIEWS = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },

];
export const ORDER_CRITICALNESS = [
  { id: 1, label: 'Critical' },
  { id: 2, label: 'Double Critical' },
];
export const DEFAULT_COUNTRY = process.env.REACT_APP_COUNTRY == "JO" ? 101 : 115;
export const COUNTRY_LEBANON = 115;
export const COUNTRY_JORDAN = 101;
export const ORDER_SOURCES = [
  {
    id: 1,
    label: "Portal"
  },
  {
    id: 2,
    label: "Mobile"
  },
  {
    id: 3,
    label: "Marketplace"
  },
  {
    id: 4,
    label: "API"
  },
  {
    id: 5,
    label: "Cashbox"
  }
];

export const ORDER_SOURCE_CASHBOX = 5;
export const ORDER_SOURCE_MARKETPLACE = 3;

export const countries= ['LB','JO']

export const OFFICIALINVOICE = [
  { id: 1, label: 'Without Official Invoice' },
  { id: 2, label: 'With Official Invoice' },
  { id: 3, label: 'Paid Separately' },
  { id: 4, label: 'Deducted from next cashbox'}
];

export const MathOps = [
  { id: '=', label: 'Equal (=)' },
  { id: '>', label: 'Greater than (>)' },
  { id: '<', label: 'Less than (<)' },
  { id: '>=', label: 'Greater than or equal (>=)' },
  { id: '<=', label: 'Less than or equal (<=)' },
];

export const ALLOWEDUSERS =
  process.env.REACT_APP_COUNTRY == "JO" ? [82] : [21989, 19156];
