import { FETCH_COUNTRIES_RESOLVED } from "../ActionTypes/countries";

const initialState = {
  countryList: []
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_RESOLVED:
      return Object.assign({}, state, {
        countryList: action.payload.data
      });

    default:
      return state;
  }
};

export default countryReducer;
