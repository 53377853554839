import React from "react";
import APPCONFIG from "constants/Config";
import TextField from "components/EnhancedTextField";
import QueueAnim from "rc-queue-anim";
import ReeValidate, {ErrorBag} from "ree-validate";
import CircularProgress from "@mui/material/CircularProgress";
import FlatButton from "@mui/material/Button";
import * as authService from "services/authService";
import PropTypes from "prop-types";
import { authLogout } from "stores/actions/auth";
import { push } from 'connected-react-router';

class ResetPassword extends React.Component {
  constructor() {
    super();

    this.validator = new ReeValidate({
      password: {
        required: true,
        regex: RegExp(/^(?=.*[A-Z])(?=.*\d).+$/),
        min:8,
        max:16
      },
      password_confirmation: {
        required: true,
        regex: RegExp(/^(?=.*[A-Z])(?=.*\d).+$/),
        min:8,
        max:16
      }
    });

    this.state = {
      brand: APPCONFIG.brand,
      credentials: { password: "", password_confirmation: "" },
      responseError: {
        isError: false,
        code: "",
        text: ""
      },
      isLoading: false,
      errors: this.validator.errors
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: false });
    if (this.props.isAuthenticated) {
      this.props.dispatch(authLogout());
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    this.checkAuth(nextProps);
  }

  checkAuth(props) {
    const isAuthenticated = props
      ? props.isAuthenticated
      : this.props.isAuthenticated;
    if (isAuthenticated) {
      this.props.dispatch(push("/app/dashboard"));
    }
  }

  checkPasswordMatch(errors,credentials){
    if(credentials.password_confirmation.length>0 && credentials.password!==credentials.password_confirmation){
      errors.add({
        field: 'password_confirmation',
        msg: 'The passwords do not match',
      });
    }
    return errors
  }

  customizeErrors(errors){
    if(errors.firstRule('password')=='regex'){
      errors.remove('password')
      errors.add({
        field: 'password',
        msg: 'The password field must contain atleast an uppercase and a digit',
      });
    } else if(errors.firstRule('password_confirmation')=='regex'){
      errors.remove('password_confirmation')
      errors.add({
        field: 'password_confirmation',
        msg: 'The password_confirmation field must contain atleast an uppercase and a digit',
      });
    }
    return errors
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    const { credentials } = this.state;
    credentials[name] = value;
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.customizeErrors(errors)
      let newErrors = this.customizeErrors(errors)
      if(newErrors.items.length==0){
        newErrors=this.checkPasswordMatch(errors,credentials)
      }
      this.setState({ errors: newErrors, credentials });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { credentials } = this.state;
    this.validator.validateAll(credentials).then(success => {
      const { errors } = this.validator;
      if (success) {
        const newErrors=this.checkPasswordMatch(errors,credentials)
        if(newErrors.items.length>0){
          this.setState({ errors: newErrors, credentials });
          return;
        }
        this.setState({
          isLoading: true
        });
        this.submit();
      } else {
        const newErrors = this.customizeErrors(errors)
        this.setState({ errors: newErrors, credentials });
      }
    });
  }

  submit() {
    const attr = _.split(this.props.location.search, "&");
    const token = this.props.location.search
      ? _.last(_.split(attr[0], "="))
      : null;
    const email = this.props.location.search
      ? _.last(_.split(attr[1], "="))
      : null;
    const key = this.props.location.search
      ? _.last(_.split(attr[2], "="))
      : null;
    const input = {
      token: token,
      email: email,
      password: this.state.credentials.password,
      password_confirmation: this.state.credentials.password_confirmation,
      key: key
    };
    this.props
      .dispatch(authService.resetPassword(input))
      .then(data => {
        this.setState({
          isLoading: false
        });
        this.props.dispatch(push("/"));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState({ responseError });
        this.setState({
          isLoading: false
        });
      });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="page-forgot">
        <div className="main-body">
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              <div className="body-inner">
                <div className="card bg-white">
                  <div className="card-content">
                    <section className="logo text-center">
                      <h1>
                        <a href="https://wakilni.com/">
                          <img
                            src="/assets/images/wakilni-logo-v2.png"
                            height="40"
                            alt={this.state.brand}
                          />
                        </a>
                      </h1>
                      <h3>Reset Password</h3>
                      {this.state.responseError.isError && (
                        <p
                          className="text-center m-b-3"
                          style={{ color: "red" }}
                        >
                          {this.state.responseError.text}
                        </p>
                      )}
                    </section>
                    <form>
                      <fieldset>
                        <div className="form-group">
                          <TextField
                            name="password"
                            value={this.state.credentials.password}
                            errorText={
                              errors.has("password") && errors.first("password")
                            }
                            floatingLabelText="Password"
                            type="password"
                            onChange={this.handleChange}
                            fullWidth
                          />
                        </div>
                        <div className="form-group">
                          <TextField
                            name="password_confirmation"
                            value={this.state.credentials.password_confirmation}
                            errorText={
                              errors.has("password_confirmation") &&
                              errors.first("password_confirmation")
                            }
                            floatingLabelText="Password Confirmation"
                            type="password"
                            onChange={this.handleChange}
                            fullWidth
                          />
                        </div>
                      </fieldset>
                    </form>
                  </div>
                  <div className="card-action no-border text-right">
                    {this.state.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FlatButton
                        variant="text"
                        label="Reset"
                        color="primary"
                        onClick={this.handleSubmit}
                      >Reset</FlatButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </QueueAnim>
        </div>
      </div>
    );
  }
}


export default ResetPassword;
