import moment from "moment";
import SearchOptions from "./SearchOptions";

const dateFormatter = data => moment(data).format("ddd D/MM/YYYY");
const localDateFormatter = data =>
  moment
    .utc(data, "YYYY-MM-DD HH:mm:ss")
    .local()
    .format("ddd D/MM/YYYY");
const timeFormatter = data => moment(moment(data, "kk:mm:ss")).format("h:mm a");
const datetimeFormatter = data =>
  moment(moment(data, "YYYY-MM-DD kk:mm:ss")).format("h:mm a");
const localDatetimeFormatter = data =>
  moment
    .utc(data, "YYYY-MM-DD HH:mm:ss")
    .local()
    .format("h:mm a");
const timesheetdateFormatter = data =>
  moment(data).format("MMMM Do YYYY, h:mm:ss a");
const deepClone = o => JSON.parse(JSON.stringify(o));
const isNumeric = value => /^-{0,1}\d+$/.test(value);
const rootAppURL = "http://localhost:3009/";
const absoluteUrl = path => `${rootAppURL}${path}`;

export {
  dateFormatter,
  localDateFormatter,
  localDatetimeFormatter,
  timeFormatter,
  datetimeFormatter,
  timesheetdateFormatter,
  SearchOptions,
  deepClone,
  isNumeric,
  absoluteUrl
};
