import React from "react";
import APPCONFIG from "constants/Config";
import TextField from "components/EnhancedTextField";
import QueueAnim from "rc-queue-anim";
import ReeValidate from "ree-validate";
import CircularProgress from "@mui/material/CircularProgress";
import FlatButton from "@mui/material/Button";
import * as authService from "services/authService";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { push } from 'connected-react-router';

class ForgotPassowrd extends React.Component {
  constructor() {
    super();

    this.validator = new ReeValidate({
      email: "required|email"
    });

    this.state = {
      brand: APPCONFIG.brand,
      credentials: { email: "" },
      responseError: {
        isError: false,
        code: "",
        text: ""
      },
      isLoading: false,
      errors: this.validator.errors
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.checkAuth();
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    this.checkAuth(nextProps);
  }

  checkAuth(props) {
    const isAuthenticated = props
      ? props.isAuthenticated
      : this.props.isAuthenticated;
    if (isAuthenticated) {
      this.props.dispatch(push("/app/orders"));
    }
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    const { credentials } = this.state;
    credentials[name] = value;
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.setState({ errors: errors, credentials });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { credentials } = this.state;

    this.validator.validateAll(credentials).then(success => {
      if (success) {
        this.setState({
          isLoading: true
        });
        this.submit();
      } else {
        const { errors } = this.validator;
        this.setState({ errors: errors, credentials });
      }
    });
  }

  submit() {
    this.props
      .dispatch(authService.forgetPassword(this.state.credentials))
      .then(data => {
        this.setState({
          isLoading: false
        });
        this.props.dispatch(push(
          `/confirm-email?email=${this.state.credentials.email}`
        ));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState({ responseError });
        this.setState({
          isLoading: false
        });
      });
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="page-forgot">
        <div className="main-body">
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              <div className="body-inner">
                <div className="card bg-white">
                  <div className="card-content">
                    <section className="logo text-center">
                      <h1>
                        <a href="https://wakilni.com/">
                          <img
                            src="/assets/images/wakilni-logo-v2.png"
                            height="40"
                            alt={this.state.brand}
                          />
                        </a>
                      </h1>
                      <h4>I Forgot My Password</h4>
                      {this.state.responseError.isError && (
                        <p
                          className="text-center m-b-3"
                          style={{ color: "red" }}
                        >
                          {this.state.responseError.text}
                        </p>
                      )}
                    </section>
                    <form>
                      <fieldset>
                        <div className="form-group">
                          <TextField
                            name="email"
                            value={this.state.credentials.email}
                            floatingLabelText="Email"
                            errorText={
                              errors.has("email") && errors.first("email")
                            }
                            onChange={this.handleChange}
                            fullWidth
                          />
                          <div className="additional-info text-center text-small">
                            Enter your email address that you used to register.
                            We'll send you an email with your username and a
                            link to reset your password.
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                  <div className="card-action no-border text-right">
                    {this.state.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FlatButton
                        variant="text"
                        label="Reset"
                        color="primary"
                        onClick={this.handleSubmit}
                      >Reset</FlatButton>
                    )}
                  </div>
                </div>
                <div className="additional-info">
                  <span>
                    Return to <Link to={`/login`}>Login</Link>
                  </span>
                </div>
              </div>
            </div>
          </QueueAnim>
        </div>
      </div>
    );
  }
}


export default ForgotPassowrd;
