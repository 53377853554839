export const styles = theme => ({
  pending_chip: {
    margin: 4
  },
  spaceBar: {
    padding: "3px",
    marginRight: "5px"
  },

  contactBox: {
    border: "1px solid #f75b5b",
    margin: "0 0 15px 0px",
    padding: "8px 5px",
    color: "white",
    backgroundColor: "#ff756ec2",
    borderRadius: "3px"
  },

  contactMsg: {
    fontSize: "13px",
    padding: "0px 13px",
    margin: "0px"
  },

  rateTitleGreat: {
    paddingTop: "5px",
    fontWeight: "bold",
    fontSize: "18px",
    color: "#86C250"
  },
  rateTitleGood: {
    paddingTop: "5px",
    fontWeight: "bold",
    fontSize: "18px",
    color: "#5E8D47"
  },
  rateTitleOk: {
    paddingTop: "5px",
    fontWeight: "bold",
    fontSize: "18px",
    color: "#F3EB26"
  },
  rateTitleBad: {
    paddingTop: "5px",
    fontWeight: "bold",
    fontSize: "18px",
    color: "#C92D2E"
  }
});
