import React from "react";
import APPCONFIG from "constants/Config";
import QueueAnim from "rc-queue-anim";
import CreateForm from "../app/routes/customers-page/create-customer/components/CreateForm";
import FlatButton from "@mui/material/Button";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { authLogout } from "stores/actions/auth";

class SignUpIn extends React.Component {

  constructor() {
    super();
    this.state = {
      brand: APPCONFIG.brand
    };
  }

  componentDidMount() {
    this.props.dispatch(authLogout());
  }

  render() {
    return (
      <div className="page-login">
        <div
          className="main-body"
          style={{ maxWidth: "1000px", paddingTop: "15px" }}
        >
          <QueueAnim type="bottom" className="ui-animate">
            <article className="article">
              <section className="hero">
                <div className="hero-content">
                  <h1 className="hero-title">
                    Welcome To{" "}
                    <a href="https://wakilni.com/">
                      <img
                        src="/assets/images/wakilni-logo-v2.png"
                        height="40"
                        alt={this.state.brand}
                      />
                    </a>
                    !
                  </h1>
                </div>
                <p className="hero-tagline">
                  Get the best delivery service for your clients!
                </p>
              </section>
            </article>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <section className="pricing-table pricing-table-warning">
                      <header>
                        <h2>Sign Up</h2>
                      </header>
                      <p className="pricing-price">
                        If you dont have an account.
                      </p>
                      <div className="pricing-plan-details">
                        <p className="pricing-lead">
                          Thank you for joining us!
                        </p>
                      </div>
                      <footer>
                        <Link
                          to={`sign-up${
                            this.props.location_Search
                          }`}
                          className="btn btn-warning"
                        >
                          Register here
                        </Link>
                      </footer>
                    </section>
                  </div>
                  <div className="col-md-6">
                    <section className="pricing-table pricing-table-warning">
                      <header>
                        <h2>Login</h2>
                      </header>
                      <p className="pricing-price">I am a wakilni member.</p>
                      <div className="pricing-plan-details">
                        <p className="pricing-lead">Welcome back!</p>
                      </div>
                      <footer>
                        <Link
                          to={`login${
                            this.props.location_Search
                          }`}
                          className="btn btn-warning"
                        >
                          Login here
                        </Link>
                      </footer>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 child_div">
                <div className="row col-md-12">
                  <p className="support_sign-up-in">
                    {" "}
                    If you have any question please contact us on{" "}
                    <a href="https://wa.me/96176766115">+961 76 766 115</a>
                  </p>
                </div>
              </div>
            </div>
          </QueueAnim>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    location_Search: state.router.location.search,
  };
};

export default connect(mapStateToProps)(SignUpIn);
