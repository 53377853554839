import * as types from '../ActionTypes/settings';

export function toggleBoxedLayout(isLayoutBoxed) {
  return { type: types.TOGGLE_BOXED_LAYOUT, isLayoutBoxed };
}
export function toggleCollapsedNav(isNavCollapsed) {
  return { type: types.TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}
export function toggleNavBehind(isNavBehind) {
  return { type: types.TOGGLE_NAV_BEHIND, isNavBehind };
}
export function toggleFixedHeader(isFixedHeader) {
  return { type: types.TOGGLE_FIXED_HEADER, isFixedHeader };
}
export function changeSidebarWidth(sidebarWidth) {
  return { type: types.CHANGE_SIDEBAR_WIDTH, sidebarWidth };
}
export function changeColorOption(colorOption) {
  return { type: types.CHANGE_COLOR_OPTION, colorOption };
}
export function changeTheme(themeOption) {
  return { type: types.CHANGE_THEME, theme: themeOption };
}
export function getType(payload) {
  return { type: types.GET_TYPE, payload };
}

export function fetchConfigurationsResolved(payload) {
  return {
    type: types.FETCH_CONFIGURATIONS,
    payload,
  };
}

export function fetchCustomMessagesResolved(payload) {
  return {
    type: types.FETCH_CUSTOM_MESSAGES,
    payload,
  };
}

export function updateCustomMessageResolved(payload) {
  return {
    type: types.EDIT_CUSTOM_MESSAGE_RESOLVED,
    payload,
  };
}

export function linkConfigurationResolved(payload) {
  return {
    type: types.LINK_CONFIGURATION_RESOLVED,
    payload,
  };
}

export function updateConfigurationResolved(payload) {
  return {
    type: types.EDIT_CONFIGURATION_RESOLVED,
    payload,
  };
}

export function fetchAppTokensResolved(payload) {
  return {
    type: types.FETCH_APP_TOKENS,
    payload,
  };
}

export function fetchWebhooksResolved(payload) {
  return {
    type: types.FETCH_WEBHOOKS,
    payload,
  };
}

export function fetchWebhooksEventsResolved(payload) {
  return {
    type: types.FETCH_WEBHOOK_EVENTS,
    payload,
  };
}

export function fetchAppsResolved(payload) {
  return {
    type: types.FETCH_APPS,
    payload,
  };
}

export function createAppResolved(payload) {
  return {
    type: types.CREATE_APP_RESOLVED,
    payload,
  };
}

export function updateAppResolved(payload) {
  return {
    type: types.UPDATE_APP_RESOLVED,
    payload,
  };
}

export function deleteAppResolved(payload) {
  return {
    type: types.DELETE_APP_RESOLVED,
    payload,
  };
}

export function createTokenResolved(payload) {
  return {
    type: types.CREATE_APP_TOKEN,
    payload,
  };
}

export function createWebhookResolved(payload) {
  return {
    type: types.CREATE_WEBHOOK,
    payload,
  };
}

export function deleteWebhookResolved(payload) {
  return {
    type: types.DELETE_WEBHOOK,
    payload,
  };
}

export function deleteTokenResolved(payload) {
  return {
    type: types.DELETE_APP_TOKEN,
    payload,
  };
}

export function updateTokenResolved(payload) {
  return {
    type: types.EDIT_APP_TOKEN,
    payload,
  };
}

export function updateWebhookResolved(payload) {
  return {
    type: types.EDIT_WEBHOOK,
    payload,
  };
}

//Currencies
export function fetchCurrenciesResolved(payload) {
  return {
    type: types.FETCH_CURRENCIES_RESOLVED,
    payload,
  };
}
export function updateCurrencyResolved(payload) {
  return {
    type: types.UPDATE_CURRENCY_RESOLVED,
    payload,
  };
}
export function deleteCurrencyResolved(payload) {
  return {
    type: types.DELETE_CURRENCY_RESOLVED,
    payload,
  };
}
export function createCurrencyResolved(payload) {
  return {
    type: types.CREATE_CURRENCY_RESOLVED,
    payload,
  };
}

//Areas
export function fetchAreasResolved(payload) {
  return {
    type: types.FETCH_SETTINGS_AREAS_RESOLVED,
    payload,
  };
}
export function updateAreaResolved(payload) {
  return {
    type: types.UPDATE_AREA_RESOLVED,
    payload,
  };
}
export function deleteAreaResolved(payload) {
  return {
    type: types.DELETE_AREA_RESOLVED,
    payload,
  };
}
export function createAreaResolved(payload) {
  return {
    type: types.CREATE_AREA_RESOLVED,
    payload,
  };
}

//Stocks
export function fetchStocksResolved(payload) {
  return {
    type: types.FETCH_STOCKS_RESOLVED,
    payload,
  };
}
export function updateStockResolved(payload) {
  return {
    type: types.UPDATE_STOCK_RESOLVED,
    payload,
  };
}
export function deleteStockResolved(payload) {
  return {
    type: types.DELETE_STOCK_RESOLVED,
    payload,
  };
}
export function createStockResolved(payload) {
  return {
    type: types.CREATE_STOCK_RESOLVED,
    payload,
  };
}

//Vehicles
export function fetchVehiclesResolved(payload) {
  return {
    type: types.FETCH_VEHICLES_RESOLVED,
    payload,
  };
}
export function updateVehicleResolved(payload) {
  return {
    type: types.UPDATE_VEHICLE_RESOLVED,
    payload,
  };
}
export function deleteVehicleResolved(payload) {
  return {
    type: types.DELETE_VEHICLE_RESOLVED,
    payload,
  };
}
export function createVehicleResolved(payload) {
  return {
    type: types.CREATE_VEHICLE_RESOLVED,
    payload,
  };
}

//Zones
export function fetchZonesResolved(payload) {
  return {
    type: types.FETCH_ZONES_RESOLVED,
    payload,
  };
}
export function createZoneResolved(payload) {
  return {
    type: types.CREATE_ZONE_RESOLVED,
    payload,
  };
}
export function updateZoneResolved(payload) {
  return {
    type: types.UPDATE_ZONE_RESOLVED,
    payload,
  };
}
export function deleteZoneResolved(payload) {
  return {
    type: types.DELETE_ZONE_RESOLVED,
    payload,
  };
}

//Offices
export function fetchOfficesResolved(payload) {
  return {
    type: types.FETCH_OFFICES_RESOLVED,
    payload,
  };
}
export function updateOfficeResolved(payload) {
  return {
    type: types.UPDATE_OFFICE_RESOLVED,
    payload,
  };
}
export function deleteOfficeResolved(payload) {
  return {
    type: types.DELETE_OFFICE_RESOLVED,
    payload,
  };
}
export function createOfficeResolved(payload) {
  return {
    type: types.CREATE_OFFICE_RESOLVED,
    payload,
  };
}

//Services
export function fetchServicesResolved(payload) {
  return {
    type: types.FETCH_SERVICES_RESOLVED,
    payload,
  };
}

export function createServiceResolved(payload) {
  return {
    type: types.CREATE_SERVICE_RESOLVED,
    payload,
  };
}

export function updateServiceResolved(payload) {
  return {
    type: types.UPDATE_SERVICE_RESOLVED,
    payload,
  };
}

export function deleteServiceResolved(payload) {
  return {
    type: types.DELETE_SERVICE_RESOLVED,
    payload,
  };
}

//Banners
export function fetchBannersResolved(payload) {
  return {
    type: types.FETCH_BANNERS_RESOLVED,
    payload,
  };
}

export function createBannerResolved(payload) {
  return {
    type: types.CREATE_BANNER_RESOLVED,
    payload,
  };
}

export function updateBannerResolved(payload) {
  return {
    type: types.UPDATE_BANNER_RESOLVED,
    payload,
  };
}

export function deleteBannerResolved(payload) {
  return {
    type: types.DELETE_BANNER_RESOLVED,
    payload,
  };
}

//internal banners
export function fetchInternalBannersResolved(payload) {
  return {
    type: types.FETCH_INTERNAL_BANNERS_RESOLVED,
    payload,
  };
}

export function createInternalBannerResolved(payload) {
  return {
    type: types.CREATE_INTERNAL_BANNER_RESOLVED,
    payload,
  };
}

export function updateInternalBannerResolved(payload) {
  return {
    type: types.UPDATE_INTERNAL_BANNER_RESOLVED,
    payload,
  };
}

export function deleteInternalBannerResolved(payload) {
  return {
    type: types.DELETE_INTERNAL_BANNER_RESOLVED,
    payload,
  };
}

//Preferences
export function linkPreferencesResolved(payload) {
  return {
    type: types.LINK_PREFERENCES_RESOLVED,
    payload,
  };
}

export function fetchPreferencesResolved(payload) {
  return {
    type: types.FETCH_PREFERENCES_RESOLVED,
    payload,
  };
}

//internal banners
export function fetchSurveyListResolved(payload) {
  return {
    type: types.FETCH_SURVEY_LIST_RESOLVED,
    payload,
  };
}

export const fetchActiveSurveyResolved = (payload) => {
  return {
    type: types.FETCH_ACTIVE_SURVEY_RESOLVED,
    payload,
  };
};


//Area Routes
export function fetchAreaRoutesResolved(payload) {
  return {
    type: types.FETCH_SETTINGS_AREA_ROUTES_RESOLVED,
    payload,
  };
}
export function updateAreaRouteResolved(payload) {
  return {
    type: types.UPDATE_AREA_ROUTE_RESOLVED,
    payload,
  };
}
export function deleteAreaRouteResolved(payload) {
  return {
    type: types.DELETE_AREA_ROUTE_RESOLVED,
    payload,
  };
}
export function createAreaRouteResolved(payload) {
  return {
    type: types.CREATE_AREA_ROUTE_RESOLVED,
    payload,
  };
}

//District
export function fetchDistrictsResolved(payload) {
  return {
    type: types.FETCH_DISTRICT_RESOLVED,
    payload,
  };
}

export function updateDistrictResolved(payload) {
  return {
    type: types.UPDATE_DISTRICT_RESOLVED,
    payload,
  };
}


export function createDistrictResolved(payload) {
  return {
    type: types.CREATE_DISTRICT_RESOLVED,
    payload,
  };
}

export function deleteDistrictResolved(payload) {
  return {
    type: types.DELETE_DISTRICT_RESOLVED,
    payload,
  };
}


//Governorate
export function fetchGovernoratesResolved(payload) {
  return {
    type: types.FETCH_GOVERNORATE_RESOLVED,
    payload,
  };
}

export function updateGovernorateResolved(payload) {
  return {
    type: types.UPDATE_GOVERNORATE_RESOLVED,
    payload,
  };
}


export function createGovernorateResolved(payload) {
  return {
    type: types.CREATE_GOVERNORATE_RESOLVED,
    payload,
  };
}

export function deleteGovernorateResolved(payload) {
  return {
    type: types.DELETE_GOVERNORATE_RESOLVED,
    payload,
  };
}


export function fetchHolidaysResolved(payload) {
  return {
    type: types.FETCH_HOLIDAYS_RESOLVED,
    payload,
  };
}


export function getHolidayResolved(payload) {
  return {
    type: types.GET_HOLIDAY_RESOLVED,
    payload,
  };
}

export function updateHolidayResolved(payload) {
  return {
    type: types.UPDATE_HOLIDAY_RESOLVED,
    payload,
  };
}

export function deleteHolidayResolved(payload) {
  return {
    type: types.DELETE_HOLIDAY_RESOLVED,
    payload,
  };
}

export function createHolidayResolved(payload) {
  return {
    type: types.CREATE_HOLIDAY_RESOLVED,
    payload,
  };
}