export const styles = theme => ({
  tableHeaderCell: {
    padding: "0px 5px 0px 0px",
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: "0.8rem",
    color: "black",
    "&:last-child": {
      paddingRight: 0,
      width: "50px"
    }
  },
  leftAllign: {
    textAlign: "left"
  },
  table_checkbox: {
    width: "29px"
  },
  checked: {
    color: "#ffc107"
  },
  tableCheckboxCell: {
    padding: "0px 5px 0px 0px",
    whiteSpace: "nowrap"
  }
});
