import React from "react";
import QueueAnim from "rc-queue-anim";
import StatBoxes from "./StatBoxes";
import StatBoxesMonthly from "./StatBoxesMonthly";
import StatBoxesToday from "./StatBoxesToday";
import { List, ListItem } from "@mui/material";
import { connect } from "react-redux";
import Notifications from "./Notifications";
import PropTypes from "prop-types";
import * as authService from "services/authService";
import { isCustomer } from "utils/helpers";

class Dashboard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props
      .dispatch(authService.fetchDashboardData())
      .then(data => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState({
          isLoading: false
        });
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState({
          isLoading: false,
          responseError
        });
      });
  }

  render() {
    const { open, errorMsg, dashboard, user } = this.props;
    return (
      <div className="container-fluid no-breadcrumbs page-dashboard">
        <QueueAnim type="bottom" className="ui-animate">
          <Notifications key="4" data={dashboard} />
          {dashboard &&
            isCustomer(user) && (
              <div key="1">
                <StatBoxesToday data={dashboard} />
              </div>
            )}
          {dashboard && (
            <div key="2">
              <StatBoxesMonthly data={dashboard} />
            </div>
          )}
          {dashboard && (
            <div key="3">
              <StatBoxes data={dashboard} />
            </div>
          )}
        </QueueAnim>
      </div>
    );
  }
}
Dashboard.propTypes = {
  dashboard: PropTypes.any,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    dashboard: state.auth.dashboard,
    user: state.auth.user
  };
};
export default connect(mapStateToProps)(Dashboard);
