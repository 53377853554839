export const FETCH_CUSTOMERS_RESOLVED = "FETCH_CUSTOMERS_RESOLVED";
export const FETCH_CUSTOMER_RESOLVED = "FETCH_CUSTOMER_RESOLVED";
export const UPDATE_CUSTOMER_RESOLVED = "UPDATE_CUSTOMER_RESOLVED";
export const CREATE_CONTACT_RESOLVED = "CREATE_CONTACT_RESOLVED";
export const EDIT_CONTACT_RESOLVED = "EDIT_CONTACT_RESOLVED";
export const DELETE_CONTACT_RESOLVED = "DELETE_CONTACT_RESOLVED";
export const FETCH_RECIPIENTS_RESOLVED = "FETCH_RECIPIENTS_RESOLVED";
export const EDIT_RECIPIENT_RESOLVED = "EDIT_RECIPIENT_RESOLVED";
export const FETCH_PRICES_RESOLVED = "FETCH_PRICES_RESOLVED";
export const EDIT_PRICE_RESOLVED = "EDIT_PRICE_RESOLVED";
export const DELETE_PRICE_RESOLVED = "DELETE_PRICE_RESOLVED";
export const NEW_PRICE_RESOLVED = "NEW_PRICE_RESOLVED";
export const FETCH_PIGGYBANKS_RESOLVED = "FETCH_PIGGYBANKS_RESOLVED";
export const UPDATE_PIGGYBANK_RESOLVED = "UPDATE_PIGGYBANK_RESOLVED";
export const UPDATE_PIGGYBANK_List = "UPDATE_PIGGYBANK_List";
export const FETCH_PIGGYBANK_TASKS_RESOLVED = "FETCH_PIGGYBANK_TASKS_RESOLVED";
export const CHOSEN_CUSTOMER = "CHOSEN_CUSTOMER";
export const FETCH_CONTACTS_RESOLVED = "FETCH_CONTACTS_RESOLVED";
export const FETCH_CUSTOMERS_NAMES_LIST_RESOLVED =
  "FETCH_CUSTOMERS_NAMES_LIST_RESOLVED";
export const UPDATE_TASK_COLLECTIONS_RESOLVED =
  "UPDATE_TASK_COLLECTIONS_RESOLVED";
export const FETCH_ACTIVE_CUSTOMERS_RESOLVED =
  "FETCH_ACTIVE_CUSTOMERS_RESOLVED";
export const UPDATE_CUSTOMER_NAME_RESOLVED = "UPDATE_CUSTOMER_NAME_RESOLVED";
export const FETCH_AUTOCOMPLETE_CUSTOMERS_RESOLVED =
  "FETCH_AUTOCOMPLETE_CUSTOMERS_RESOLVED";
export const UPDATE_PIGGYBANKS_RESOLVED = "UPDATE_PIGGYBANKS_RESOLVED";
export const FETCH_CUSTOMER_CURRENCIES = "FETCH_CUSTOMER_CURRENCIES";
export const CREATE_CUSTOMER_CURRENCY_RESOLVED =
  "CREATE_CUSTOMER_CURRENCY_RESOLVED";
export const UPDATE_CUSTOMER_CURRENCY_RESOLVED =
  "UPDATE_CUSTOMER_CURRENCY_RESOLVED";
export const DELETE_CUSTOMER_CURRENCY_RESOLVED =
  "DELETE_CUSTOMER_CURRENCY_RESOLVED";
export const FETCH_SHOP_NOTES_RESOLVED =
  "FETCH_SHOP_NOTES_RESOLVED";
export const CREATE_SHOP_NOTE_RESOLVED =
  "CREATE_SHOP_NOTE_RESOLVED";
export const DELETE_SHOP_NOTE_RESOLVED =
  "DELETE_SHOP_NOTE_RESOLVED";
