import React from "react";
import IconMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton/IconButton";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import * as authService from "services/authService";
import { push } from 'connected-react-router';
import { ListItemText } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';

const HeaderIconButtonStyle = {
  width: "60px",
  height: "60px"
};

const listItemStyle = {
  paddingLeft: "40px" // 36 + 16, algin with sub list
};

class NavLeftList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
    };
    
    this.readNotifications = this.readNotifications.bind(this);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeRoute = (event, value) => {
    this.props.dispatch(push("/app/notifications"));
    this.handleClose();
  };

  readNotifications() {
    this.props
      .dispatch(authService.readNotifications())
      .then(response => {})
      .catch(({ error, statusCode }) => {});
  }

  render() {
    var notifications_count = this.props.notifications.filter(
      elem => !elem.read_at
    ).length;

    const { anchorEl } = this.state;

    return (
      <ul className="list-unstyled list-inline">
        <li className="list-inline-item">
        <IconButton
          style={HeaderIconButtonStyle}
          onClick={this.handleClick}
          className="md-button header-btn"
        >
          <Badge 
            badgeContent={notifications_count}
            color="primary"
            sx={{
              marginRight: "10px",
            }}
          >
            <NotificationsIcon
              sx={{
                fontSize: "24px",
                fontWeight: "400",
                color: "black"
              }}
            />
          </Badge>
        </IconButton>
        <IconMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          MenuListProps={{
            sx: {
              minWidth: "350px",
            }
          }}
          onClick={this.readNotifications}
        >
          {this.props.notifications.length > 0
            ? this.props.notifications.slice(0, 7).map(item => (
                <MenuItem
                  key={item.id}
                  className={
                    item.read_at
                      ? "header-icon-dropdown-item"
                      : "header-icon-dropdown-item menuNotRead"
                  }
                >
                  <ListItemText 
                  primary={_.truncate(item.data, {
                    length: 49
                  })}
                  secondary={
                    <span className="text-muted">
                      {moment(item.created_at).fromNow()}
                    </span>
                  }
                  />
                  
                </MenuItem>
              ))
            : null}
          <Divider />
          <MenuItem onClick={this.handleChangeRoute} >See All</MenuItem>
        </IconMenu>
        </li>
      </ul>
    );
  }
}
const mapStateToProps = state => {
  return {
    notifications: state.auth.notifications
  };
};

export default connect(mapStateToProps)(NavLeftList);
