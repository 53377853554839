import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";

class EnhancedCircularProgress extends React.Component {
  render() {
    const { paddingTop } = this.props;
    return (
      // <CircularProgress
      //   style={{
      //     display: "grid",
      //     margin: "0 auto",
      //     paddingTop: paddingTop ? paddingTop : "200px"
      //   }}
      // />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          paddingTop: paddingTop ? paddingTop : "200px"
        }}
      >
      <CircularProgress />
    </div>
    );
  }
}

EnhancedCircularProgress.propTypes = {
  paddingTop: PropTypes.string
};

export default EnhancedCircularProgress;
