import { yellow, green, grey } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

export default {
  components: {
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffc107", // pickerHeaderColor
          padding: '20px',
        }
      },
    },
    MuiDatePickerToolbar: {
      styleOverrides: {
        title: {
          fontSize: '36px',
          color: 'rgb(51, 60, 68)',
          fontWeight: 500,   // Set font weight to bold (700)
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        clock: {
          color: alpha("#212121", 0.07), // clockCircleColor
        },
      },
    },
    //since the MuiMenuItem is not using the seconday color for Mui-selected
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          background: 'none',
          "&:hover": {
            backgroundColor: grey[300],
          },
          "&.Mui-selected": {
            color: green[400],
            background: 'none',
          },
          "&.Mui-selected:hover": {
            backgroundColor: grey[300],
          },
          "&.Mui-selected:focus": {
            color: green[400],
            background: 'none',
          },
         /*  "&.Mui-focusVisible": {
            background: 'none', // No background when focused
          },
          "&:focus": {
            background: 'none', // No background when focused
          }, */
          "&:first-of-type": {
            "&.Mui-focusVisible": {
              background: 'none', // Prevent background on focus for the first item
            },
            "&.Mui-selected": {
              background: 'none', // Ensure no background when first item is selected
            },
            "&:hover": {
              backgroundColor: grey[300], // Hover color
            },
          },

        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '14px',
          lineHeight: '24px',
          height: '72px',
          minWidth: 120,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          '&:before': {
            borderBottom: "1px solid rgb(224, 224, 224)", // Customize the underline color before focus
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: "1px solid rgb(224, 224, 224)", // Customize the underline color on hover
          },
          '&.Mui-disabled:before': {
            borderBottom: "2px dotted rgb(224, 224, 224)", // Dashed underline when disabled
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            cursor: 'not-allowed', // Change cursor to "not allowed" for disabled input
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          color: 'rgba(0, 0, 0, 0.3)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '14px',
          '&:focus': {
            background: 'none',
          },
        },
        icon: {
          color: 'rgb(224, 224, 224)', // Change the color of the dropdown indicator icon
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '24px',  // Set the height for the AppBar
          minHeight: '24px',
          justifyContent: "center",
          '&.BigAppBar': {
            height: '48px',
            minHeight: '48px',
            justifyContent: 'center',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '56px',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "16px", // Set the font size for all FormControlLabel labels
          '&.Mui-disabled': {
            cursor: 'not-allowed', // Also change cursor for the label when disabled
          },
        },
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed', // Change cursor to "not-allowed" for disabled FormControlLabel
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: 25, // Custom icon size for the checkbox
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          right: '1px',
          color: 'white',
          fontSize: '12px',
          width: '22px',
          height: '22px',
          borderRadius: '50%',
          fontWeight: '600',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&.MuiTypography-root': {
            fontSize: '22px',
            fontWeight: '400',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&:hover': {
            backgroundColor: 'transparent', // Apply globally
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTab-root': {
            fontSize: '14px', // Font size for tab labels
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          transition: 'background-color 0.3s, color 0.3s',
          '&:hover': {
            backgroundColor: yellow[300],
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '12px',
          display: "flex",
          flexWrap: "wrap",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: '#C2C1C1',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: {
          display: 'none',
        },
        popupIndicator: {
          display: 'none',
        },
        option: {
          "&.MuiAutocomplete-option:hover": {
            backgroundColor: grey[300],
          },
          "&.MuiAutocomplete-option[aria-selected='true']": {
            color: green[400],
            background: 'none !important',
          },
        },
        noOptions: {
          display: 'none',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "red !important",
          },
        },
      },
    },
  },
};
