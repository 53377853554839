export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const FETCH_NOTIFICATIONS_RESOLVED = "FETCH_NOTIFICATIONS_RESOLVED";
export const SERVER_ERROR = "SERVER_ERROR";
export const SERVER_ERROR_RESET = "SERVER_ERROR_RESET";
export const SERVER_SUCCESS = "SERVER_SUCCESS";
export const SERVER_SUCCESS_RESET = "SERVER_SUCCESS_RESET";
export const READ_NOTIFICATIONS_RESOLVED = "READ_NOTIFICATIONS_RESOLVED";
export const UPDATE_USER_RESOLVED = "UPDATE_USER_RESOLVED";
export const UPDATE_CUSTOMER_INFO_RESOLVED = "UPDATE_CUSTOMER_INFO_RESOLVED";
export const UPDATE_CUSTOMER_THIRD_PARTY_RESOLVED='UPDATE_CUSTOMER_THIRD_PARTY_RESOLVED';
export const UPDATE_USER_CURRENCIES_RESOLVED = 'UPDATE_USER_CURRENCIES_RESOLVED';
export const DELETE_USER_CURRENCY_RESOLVED = 'DELETE_USER_CURRENCY_RESOLVED';
export const CREATE_USER_CURRENCY_RESOLVED = 'CREATE_USER_CURRENCY_RESOLVED';
export const FETCH_DASHBOARD_DATA_RESOLVED = "FETCH_DASHBOARD_DATA_RESOLVED";
export const UPDATE_NOTIFICATIONS_RESOLVED = "UPDATE_NOTIFICATIONS_RESOLVED";
export const SET_TOKEN_RESOLVED = "SET_TOKEN_RESOLVED";
export const TURN_NOTIFICATION_SOUND_OFF = "TURN_NOTIFICATION_SOUND_OFF";
export const ME = "ME";
export const RECAPTCHA = "RECAPTCHA";
export const FETCH_PAGE_NOTIFICATIONS_RESOLVED =
  "FETCH_PAGE_NOTIFICATIONS_RESOLVED";
export const ADD_PAGE_NOTIFICATIONS_RESOLVED =
  "ADD_PAGE_NOTIFICATIONS_RESOLVED";
export const RE_CLEAR_NOTIFICATIONS = "RE_CLEAR_NOTIFICATIONS";
export const FETCH_SHOP_DATA_RESOLVED = "FETCH_SHOP_DATA_RESOLVED";
export const MAKE_DEFAULT_LOCATION_RESOLVED = "MAKE_DEFAULT_LOCATION_RESOLVED";
