import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import classnames from "classnames";
import SelectField from "@mui/material/Select";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";

class EnhancedSelectField extends React.Component {
  render() {
    const {
      floatingLabelText,
      classes,
      value,
      onChange,
      fullWidth,
      children,
      errorText,
      autoWidth,
      menuStyle,
      iconStyle,
      disabled,
      multiple,
      underlineDisabledStyle,
      underlineShow,
      renderValue,
      fieldWidth
    } = this.props;

    const selectValue = multiple ? (Array.isArray(value) ? value : []) : value === 0 ? 0 : value || "";

    return (
      <FormControl
        error={Boolean(errorText)}
        disabled={disabled}
        variant="standard"
        fullWidth={fullWidth}
        sx={{
          minWidth: fullWidth ? '100%' : '200px',
          "& .MuiInput-underline:before": underlineShow && underlineShow === false ? { display: "none" } : {},
          "& .MuiInput-underline:after": underlineShow && underlineShow === false ? { borderBottom: "none" } : {},
          "& .MuiInputBase-root.Mui-disabled": underlineDisabledStyle,
        }}
        >
        {floatingLabelText && (
          <InputLabel>{floatingLabelText}</InputLabel>
        )}
        <SelectField
          fullWidth={fullWidth}
          autoWidth={autoWidth}
          value={selectValue}
          multiple={multiple}
          onChange={onChange}
          label={floatingLabelText}
          MenuProps={{
            PaperProps: {
              sx: menuStyle,
            },
          }}
          renderValue={renderValue}
          sx={{
            width: fieldWidth,
            "& .MuiSelect-icon": { ...iconStyle }
          }}
        >
          {children}
        </SelectField>
        {errorText && <FormHelperText sx={{ color: "red" }}>{errorText}</FormHelperText>}
      </FormControl> 
    );
  }
}

EnhancedSelectField.propTypes = {
  floatingLabelText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.any,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  children: PropTypes.array,
  errorText: PropTypes.any,
  autoWidth: PropTypes.bool,
  menuStyle: PropTypes.any,
  iconStyle: PropTypes.any,
  disabled: PropTypes.bool
};

export default withStyles(styles)(EnhancedSelectField);
