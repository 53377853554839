import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import classnames from "classnames";
import RaisedButton from "@mui/material/Button";
import ButtonCircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import * as authActions from "stores/actions/auth";
import { connect } from "react-redux";

class EnhancedSnackBar extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  handleRequestClose() {
    this.props.dispatch(
      this.props.successMsg
        ? authActions.serverSuccessReset()
        : authActions.serverErrorReset()
    );
  }

  render() {
    const { successMsg, errorMsg, open } = this.props;
    return (
      <span>
        {successMsg || (errorMsg && typeof errorMsg === "string") ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            onClose={this.handleRequestClose}
            className={successMsg ? "snackbar_success" : "snackbar_error"}
            message={<span>{successMsg ? successMsg : errorMsg}</span>}
            autoHideDuration={3000}
          />
        ) : null}
      </span>
    );
  }
}

EnhancedSnackBar.propTypes = {
  successMsg: PropTypes.string,
  errorMsg: PropTypes.any,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    successMsg: state.auth.successMsg,
    errorMsg: state.auth.errorMsg,
    open: state.auth.open
  };
};

export default withStyles(styles)(connect(mapStateToProps)(EnhancedSnackBar));
