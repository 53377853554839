import React from "react";
import { connect } from "react-redux";
import { fetchDashboardBlocksData } from "services/authService";
import { withStyles } from "@mui/styles";
import { styles } from "../styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@mui/material";
import CircularProgress from "components/EnhancedCircularProgress";
import EmptyComponent from "components/EmptyComponent";

class TopPeaksBlock extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
      customers_list: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chosen_account_manager !== this.props.chosen_account_manager
    ) {
      this.loadData();
    }
  }

  sendDataToParent = isLoading => {
    this.props.parentCallback(isLoading);
  };

  setLoading = isLoading => {
    this.setState({ ...this.state, isLoading: isLoading });
  };

  loadData = () => {
    this.setLoading(true);

    let customized_search_input_here = [];
    if (this.props.chosen_account_manager) {
      customized_search_input_here.push({
        key: "customer_account_manager_id",
        element: this.props.chosen_account_manager
      });
    }

    this.props
      .dispatch(
        fetchDashboardBlocksData("top_peaks", customized_search_input_here)
      )
      .then(return_data => {
        this.setState({
          isLoading: false,
          customers_list: return_data.top_peaks
        });

        this.sendDataToParent(this.state.isLoading);
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
        this.sendDataToParent(this.state.isLoading);
      });
  };

  render() {
    let { customers_list, isLoading } = this.state;
    let { classes } = this.props;
    let table_head_data = ["Customer", "Average"];

    let TableHeadComponent = (
      <TableHead>
        <TableRow>
          {table_head_data.map((item, index) => (
            <TableCell key={index} className={classes.fontBold}>
              {item}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );

    let TableBodyGrowingCustomersComponent = (
      <TableBody>
        {customers_list
          .sort((a, b) => Number(b.average) - Number(a.average))
          .slice(0, 5)
          .map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.customer}</TableCell>
              <TableCell>{item.average}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    );

    let TableBodyShrinkingCustomersComponent = (
      <TableBody>
        {customers_list
          .sort((a, b) => Number(a.average) - Number(b.average))
          .slice(0, 5)
          .map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.customer}</TableCell>
              <TableCell>{item.average}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    );

    return (
      <div className="box box-default">
        <div className={classes.block_header}>Top Peaks</div>
        <div className="box-body">
          <div className="row">
            <div className="col-md-6">
              <h5 style={{ paddingLeft: "30%" }}>Growing Clients</h5>
              {isLoading ? (
                <CircularProgress />
              ) : customers_list.length ? (
                <Table className={classes.table} aria-label="simple table">
                  {TableHeadComponent}
                  {TableBodyGrowingCustomersComponent}
                </Table>
              ) : (
                <EmptyComponent title="No Clients Available" />
              )}
            </div>
            <div className="col-md-6">
              <h5 style={{ paddingLeft: "30%" }}>Shrinking Clients</h5>
              {isLoading ? (
                <CircularProgress />
              ) : customers_list.length ? (
                <Table className={classes.table} aria-label="simple table">
                  {TableHeadComponent}
                  {TableBodyShrinkingCustomersComponent}
                </Table>
              ) : (
                <EmptyComponent title="No Clients Available" />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(connect()(TopPeaksBlock));
