export const dashboard_orders_page_path = "/app/dashboard/orders/view";
export const dashboard_customers_page_path = "/app/dashboard/customers/view";

export const PENDING_BARCODES_PICKUP_EXISTING_PAGE_PATH =
  "/app/barcodes/pending/pickup/existing";
export const PENDING_BARCODES_PICKUP_REMOVED_PAGE_PATH =
  "/app/barcodes/pending/pickup/removed";
export const PENDING_BARCODES_RETURN_PAGE_PATH = "/app/barcodes/pending/return";
export const PENDING_BARCODES_RETURN_ATTACHED_PAGE_PATH =
  "/app/barcodes/pending/return/attached";

export const RETURN_ORDERS_ACTIVE_PAGE_PATH = "/app/orders/return/active";
export const RETURN_ORDERS_HISTORY_PAGE_PATH = "/app/orders/return/history";

export const EXPRESS_ORDERS_ACTIVE_PAGE_PATH = "/app/orders/express/active";
export const EXPRESS_ORDERS_HISTORY_PAGE_PATH = "/app/orders/express/history";

export const EMAIL_SUBSCRIPTION_PAGE_PATH = "/app/email_subscription";
