import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import classnames from "classnames";
import TextField from "@mui/material/TextField";

class EnhancedTextField extends React.Component {
  _onKeyPress = event => {
    if (event.key === 'Enter') {
      // enter key pressed
      event.preventDefault();
      if (this.props.onKeyPress) {
        this.props.onKeyPress();
      }
    }
  };

  render() {
    const {
      onKeyPress,
      name,
      floatingLabelText,
      rows,
      rowsMax,
      onChange,
      fullWidth,
      value,
      type,
      errorText,
      hintText,
      multiLine,
      disabled,
      maxLength,
      underlineShow,
      underlineDisabledStyle,
      className,
      fieldWidth
    } = this.props;
    return (
      <TextField
        variant="standard"
        name={name}
        label={floatingLabelText}
        rows={rows}
        value={value === 0 ? 0 : value || ""}
        maxRows={rowsMax}
        onChange={onChange}
        fullWidth={fullWidth}
        type={type}
        helperText={errorText}
        placeholder={hintText}
        multiline={multiLine}
        disabled={disabled}
        inputProps={{ maxLength: maxLength }}
        onKeyDown ={this._onKeyPress} //onKeyPress
        error={!!errorText}
        sx={{
          "& .MuiInput-underline:before": underlineShow && underlineShow === false ? { display: "none" } : { },
          "& .MuiInputBase-root.Mui-disabled": underlineDisabledStyle,
        }}
        InputProps={{
          sx: {
            width: fieldWidth,
          },
        }}
        className={className}
      />
    );
  }
}

EnhancedTextField.propTypes = {
  onKeyPress: PropTypes.func,
  pressKey: PropTypes.bool,
  floatingLabelText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.any,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  errorText: PropTypes.any,
  hintText: PropTypes.any,
  multiLine: PropTypes.any,
  disabled: PropTypes.bool,
  maxlength:PropTypes.number,
};

export default withStyles(styles)(EnhancedTextField);
