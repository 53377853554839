import {
  FETCH_TRANSACTIONS_RESOLVED,
  FETCH_TASKS_FOR_SUBMISSION,
  UPDATE_TASK_COLLECTION_RESOLEVED,
  FETCH_PIGGY_BANK_ORDERS_RESOLEVED,
  UPDATE_TASK_COLLECTION_IN_PIGGYBANK_RESOLEVED,
  UPDATE_PIGGY_BANK_ORDERS_RESOLVED
} from "../ActionTypes/accountant";

const initialState = {
  transactions: [],
  tasksForSubmission: [],
  piggybankOrders: []
};

const accountantReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PIGGY_BANK_ORDERS_RESOLVED: {
      let piggybankOrders = JSON.parse(JSON.stringify(state.piggybankOrders));
      let index = _.findIndex(piggybankOrders, function(o) {
        return o.id == action.payload.id;
      });
      if (index >= 0) {
        piggybankOrders[index] = action.payload;
      }
      return Object.assign({}, state, {
        piggybankOrders: piggybankOrders
      });
    }
    case FETCH_PIGGY_BANK_ORDERS_RESOLEVED:
      return Object.assign({}, state, {
        piggybankOrders: action.payload
      });
    case FETCH_TRANSACTIONS_RESOLVED:
      return Object.assign({}, state, {
        transactions: action.payload
      });
    case UPDATE_TASK_COLLECTION_IN_PIGGYBANK_RESOLEVED: {
      let piggybankOrders = JSON.parse(JSON.stringify(state.piggybankOrders));
      let index = _.findIndex(piggybankOrders, function(o) {
        return o.id == action.payload.order.id;
      });
      if (index >= 0) {
        let task_index = _.findIndex(piggybankOrders[index].tasks, function(
          item
        ) {
          return item.id == action.payload.id;
        });
        if (task_index >= 0) {
          piggybankOrders[index].tasks[task_index] = action.payload;
        }
      }
      return Object.assign({}, state, {
        piggybankOrders: piggybankOrders
      });
    }
    case UPDATE_TASK_COLLECTION_RESOLEVED: {
      let tasksForSubmission = JSON.parse(
        JSON.stringify(state.tasksForSubmission)
      );
      let index = _.findIndex(tasksForSubmission, function(o) {
        return o.id == action.payload.id;
      });
      if (index >= 0) {
        tasksForSubmission[index] = action.payload;
      }
      return Object.assign({}, state, {
        tasksForSubmission: tasksForSubmission
      });
    }
    case FETCH_TASKS_FOR_SUBMISSION:
      return Object.assign({}, state, {
        tasksForSubmission: action.payload
      });
    default:
      return state;
  }
};

export default accountantReducer;
