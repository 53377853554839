import {
  FETCH_ZONES_RESOLVED,
  FETCH_VEHICLES_RESOLVED,
  FETCH_AREAS_RESOLVED,
  FETCH_LIST_RESOLVED,
  FETCH_RECIPIENTS_RESOLVED,
  CREATE_TYPE_RESOLVED,
  UPDATE_TYPE_RESOLVED,
  FETCH_AUTOCOMPLETE_AREAS_RESOLVED,
  FETCH_AUTOCOMPLETE_RECIPIENTS_RESOLVED,
  FETCH_OPERATORS_NAMES_RESOLVED,
  CLEAR_AUTOCOMPLETE_AREAS_RESOLVED,
  CLEAR_AUTOCOMPLETE_RECIPIENTS_RESOLVED,
  FETCH_TOP_AREAS_BY_CUSTOMER_RESOLVED
} from "../ActionTypes/data";

import {
  UPDATE_AREA_RESOLVED,
  CREATE_AREA_RESOLVED
} from "../ActionTypes/settings";

import { AUTH_LOGOUT } from "../ActionTypes/auth";

const initialState = {
  zoneList: [],
  vehicleList: [],
  areaList: [],
  typeList: [],
  recipientList: [],
  autocompleteAreaList: [],
  autocompleteRecipientList: [],
  operatorsNames: [],
  isTopAreas: false,

};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OPERATORS_NAMES_RESOLVED:
      return Object.assign({}, state, {
        operatorsNames: action.payload
      });
    case AUTH_LOGOUT:
      return Object.assign({}, state, {
        typeList: []
      });
    case FETCH_ZONES_RESOLVED:
      return Object.assign({}, state, {
        zoneList: action.payload
      });
    case FETCH_VEHICLES_RESOLVED:
      return Object.assign({}, state, {
        vehicleList: action.payload.data
      });
    case FETCH_AREAS_RESOLVED:
      return Object.assign({}, state, {
        areaList: action.payload.data,
        isTopAreas: false,

        
      });
    case FETCH_RECIPIENTS_RESOLVED:
      return Object.assign({}, state, {
        recipientList: action.payload
      });

    case FETCH_LIST_RESOLVED:
      return Object.assign({}, state, {
        typeList: action.payload.data
      });
    case FETCH_AUTOCOMPLETE_AREAS_RESOLVED:
      return Object.assign({}, state, {
        autocompleteAreaList: action.payload.data,
        isTopAreas: false,

      });
    case FETCH_TOP_AREAS_BY_CUSTOMER_RESOLVED:
      return Object.assign({}, state, {
        autocompleteAreaList: action.payload.data,
        isTopAreas: true
      });
    case CLEAR_AUTOCOMPLETE_AREAS_RESOLVED:
      return Object.assign({}, state, {
        autocompleteAreaList: [],
        isTopAreas: true,

      });
    case FETCH_AUTOCOMPLETE_RECIPIENTS_RESOLVED:
      return Object.assign({}, state, {
        autocompleteRecipientList: action.payload.data
      });
    case CLEAR_AUTOCOMPLETE_RECIPIENTS_RESOLVED:
      return Object.assign({}, state, {
        autocompleteRecipientList: []
      });
    case UPDATE_TYPE_RESOLVED: {
      let types = JSON.parse(JSON.stringify(state.typeList));
      let type = action.objectType;
      types[type].forEach((item, index) => {
        if (item.id == action.payload.id) {
          types[type][index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        typeList: types
      });
    }

    case CREATE_TYPE_RESOLVED: {
      let types = JSON.parse(JSON.stringify(state.typeList));
      let type = action.objectType;
      types[type].push(action.payload);
      return Object.assign({}, state, {
        typeList: types
      });
    }

    case UPDATE_AREA_RESOLVED: {
      let areas = JSON.parse(JSON.stringify(state.areaList));
      areas.forEach((item, index) => {
        if (item.id == action.payload.id) {
          areas[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        areaList: areas
      });
    }
    case CREATE_AREA_RESOLVED: {
      let areas = JSON.parse(JSON.stringify(state.areaList));
      areas.push(action.payload);
      return Object.assign({}, state, {
        areaList: areas
      });
    }

    default:
      return state;
  }
};

export default dataReducer;
