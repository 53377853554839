import * as authActions from "../stores/actions/auth";
import Http from "../utils/Http";
import { me } from "stores/models/Me";
import APPCONFIG from "constants/Config";
import { serializeInput } from "utils/helpers";

export function login(credentials) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("login", credentials)
        .then(res => {
          dispatch(authActions.authLogin(res.data));
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function logout() {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("logout")
        .then(res => {
          dispatch(authActions.authLogout());
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function getShopifyShop(code, shop) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("shopify/shop?code=" + code + "&shop=" + shop)
        .then(res => {
          dispatch(authActions.fetchShopDataResolved(res.data.data.shop));
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function forgetPassword(credentials) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("password/email", credentials)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function resetPassword(credentials) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("password/reset", credentials)
        .then(res => {
          dispatch(authActions.authLogin(res.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchNotifications(pageNumber = null) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("notifications?page=" + pageNumber)
        .then(res => {
          if (pageNumber) {
            dispatch(authActions.AddPageNotificationsResolved(res.data));
          } else {
            dispatch(authActions.fetchNotificationsResolved(res.data.data));
            dispatch(authActions.fetchPageNotificationsResolved(res.data));
          }

          return resolve(res.data);
        })
        .catch(err => {
          // dispatch(authActions.serverError('Notification fetch error'))
          return reject(err);
        });
    });
}

export function getMe() {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("me")
        .then(res => {
          dispatch(authActions.getMe(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function readNotifications() {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("notifications")
        .then(res => {
          dispatch(authActions.readNotificationsResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function updateUser(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("me", info.input)
        .then(res => {
          dispatch(authActions.updateUserResolved(me(res.data.data)));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchDashboardData() {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("dashboard")
        .then(res => {
          dispatch(authActions.fetchDashboardDatasResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          // dispatch(authActions.serverError(err.error))
          return reject(err);
        });
    });
}

export function fetchDashboardBlocksData(block, input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(`dashboard/blocks?block=${block}&${serializeInput(input)}`)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function makeDefaultLocation(id, action) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put(`locations/${id}`, action)
        .then(res => {
          dispatch(authActions.makeDefaultLocation(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
