import Login from "./Login";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  open: state.auth.open,
  successMsg: state.auth.successMsg,
  token: state.auth.token,
  recaptcha: state.auth.recaptcha,
  shop: state.auth.shop
});

export default connect(mapStateToProps)(Login);
