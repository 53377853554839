import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import classnames from "classnames";
import AutoComplete from "@mui/material/Autocomplete";
import _ from "lodash";
import * as customerService from "services/customerService";
import { connect } from "react-redux";
import { isEqual } from "utils/helpers";
import { TextField } from "@mui/material";

class EnhancedCustomerAutoComplete extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      isClosing: false,
      initiate: props.update ? !props.update : true,
      data: {
        customerId: props.value ? props.value : null,
        customerText: props.searchText ? props.searchText : ""
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.initiate &&
      nextProps.value &&
      nextProps.searchText &&
      nextProps.update
    ) {
      this.setState({
        initiate: true,
        data: {
          customerId: nextProps.value,
          customerText: nextProps.searchText
        }
      });
    }
  }

  onClose = () => {
    if (this.state.isLoading) {
      this.setState({ isClosing: true });
    } else {
      this.setState({ isClosing: false });
      if (!this.state.data.customerId) {
        const { data } = this.state;
        data["customerText"] = "";
        this.setState({ data });
      }
    }
  };

  // when the user inputs or outputs keywords into the autocomplete
  handleOnUpdateInput = (
    searchText,
    dataSource,
    from_api = false
  ) => {
    var chosenRequest = dataSource.filter(
      elem => _.toLower(elem.full_name) == _.toLower(searchText.trimRight())
    );
    const { data } = this.state;
    data["customerText"] = searchText;
    const { updateCustomerObject, disableUpdateCustomerObjectOnChange } = this.props;
    if (chosenRequest && chosenRequest.length > 0) {
      data["customerId"] = chosenRequest[0].id;
      this.setState({ data });

      // Conditionally update customer object in parent component based on props
      if (!disableUpdateCustomerObjectOnChange) {
        updateCustomerObject(chosenRequest[0]);
      }
    } else {
      data["customerId"] = null;
      this.setState({ data });
      updateCustomerObject(null);
      if (!from_api && searchText.length >= 3) {
          this.getUpdatedCustomerList();

      }

      /* if (this.state.isClosing) {
        this.onClose();
      } */
    }
  };

  // when the user choose on click one of the customers
  // chosenRequest is customerObject
  handleAutoCompleteChange = (chosenRequest) => {
      const { data } = this.state;
      data["customerId"] = chosenRequest ? chosenRequest.id : null;
      data["customerText"] = chosenRequest ? chosenRequest.full_name : '';
      this.setState({ data });
      this.props.updateCustomerObject(chosenRequest);
  };

  getUpdatedCustomerList = _.debounce(() => {
    this.setState({ isLoading: true });
    var search_input = (this.props.is_active || this.props.is_active === 0)
    ? [
        { key: "is_active", element: this.props.is_active },
        { key: "name", element: this.state.data.customerText },
      ]
    : [{ key: "name", element: this.state.data.customerText }];
    this.props
      .dispatch(customerService.fetchAutoCompleteCustomers(search_input))
      .then(response => {
        this.setState({ isLoading: false });
        this.handleOnUpdateInput(
          this.state.data.customerText,
          response.data,
          true
        );
      })
      .catch(({ error, statusCode }) => {
        this.setState({ isLoading: false });
      });
  }, 500);

  render() {
    const {
      autocompleteCustomerList,
      floatingLabelText,
      classes,
      errorText,
      fullWidth,
      value,
      searchText,
      is_active,
      freeSolo
    } = this.props;
    const customerConfig = {
      text: "full_name",
      value: "id"
    };
    return (
      <AutoComplete
        freeSolo={true}
        aria-label={floatingLabelText}
        openOnFocus
        fullWidth={fullWidth}
        value={autocompleteCustomerList.find((option) => option.id === this.state.data.customerId) || null}
        //onClose={this.onClose}
        inputValue={this.state.data.customerText || ""}
        options={autocompleteCustomerList}
        getOptionLabel={(option) => option.full_name || ""}
        getOptionKey={(option) => option.id || ""}
        onChange={(event, newValue) => this.handleAutoCompleteChange(newValue)}
        onInputChange={(event, newInputValue) => this.handleOnUpdateInput(newInputValue, autocompleteCustomerList)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label={floatingLabelText}
            error={!!errorText}
            helperText={errorText}
            variant="standard"
            InputProps={{ ...params.InputProps }}
            style={{ maxHeight: "200px" }}
          />
        )}
      />
    );
  }
}

EnhancedCustomerAutoComplete.propTypes = {
  floatingLabelText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  is_active: PropTypes.any,
  errorText: PropTypes.any,
  value: PropTypes.any,
  searchText: PropTypes.any,
  autocompleteCustomerList: PropTypes.array.isRequired,
  update: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    autocompleteCustomerList: state.customers.autocompleteCustomerList
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(EnhancedCustomerAutoComplete)
);
