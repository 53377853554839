import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import RaisedButton from "@mui/material/Button";
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseError: {
        isError: false,
      },
      banner_modal: true,
      step: 0
    };
  }

  handleNext = () => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }));
  };

  handleClose = () => {
    let { banner_modal } = this.state;
    banner_modal = false;
    this.setState({
      ...this.state,
      banner_modal
    });
    localStorage.setItem("is_banner_viewed", "yes");
  };
 
  render() {
    const { activeBanner } = this.props;
    const { step } = this.state;
    const actions = (
      <div>
        {step < activeBanner.length - 1 ? (
          <RaisedButton variant="contained" label="Next" color="primary" onClick={this.handleNext} >Next</RaisedButton>
        ) : (
          <div>
            <RaisedButton variant="contained" label="Close" color="primary" onClick={this.handleClose} >Close</RaisedButton>
          </div>
        )}
      </div>
    );

    return (
      <Dialog
        className="text-center font-weight-bold"
        open={this.state.banner_modal}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: { xs: 'auto', lg: '700px' },
          },
        }}
      >
        <DialogTitle>{activeBanner[step].subject}</DialogTitle>
        <DialogContent  
          sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}
        >
          <DialogContentText>{activeBanner[step].message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {!activeBanner ? <CircularProgress /> : actions}
        </DialogActions>
      </Dialog>
    );
  }

}

Banner.propTypes = {
  activeBanner: PropTypes.array.isRequired
};

export default Banner;
