import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'jquery-slimscroll/jquery.slimscroll.min';
import { connect } from 'react-redux';
import { getRole, isCustomer, hasHrPrivilege,isFeatureEnabled } from 'utils/helpers';
import {
  PENDING_BARCODES_PICKUP_EXISTING_PAGE_PATH,
  PENDING_BARCODES_PICKUP_REMOVED_PAGE_PATH,
  PENDING_BARCODES_RETURN_PAGE_PATH,
  PENDING_BARCODES_RETURN_ATTACHED_PAGE_PATH,
} from 'constants/Paths';

class SidebarContent extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    const nav = this.nav;
    const $nav = $(nav);

    // scroll
    $nav.slimscroll({
      height: '100%',
    });

    // Append icon to submenu
    // Append to child `div`
    $nav
      .find('.prepend-icon')
      .children('div')
      .prepend('<i class="material-icons">keyboard_arrow_right</i>');

    // AccordionNav
    const slideTime = 250;
    const $lists = $nav.find('ul').parent('li');
    $lists.append('<i class="material-icons icon-has-ul">arrow_drop_down</i>');
    const $As = $lists.children('a');

    // Disable A link that has ul
    $As.on('click', (event) => event.preventDefault());

    // Accordion nav
    $nav.on('click', (e) => {
      const target = e.target;
      const $parentLi = $(target).closest('li'); // closest, insead of parent, so it still works when click on i icons
      if (!$parentLi.length) return; // return if doesn't click on li
      const $subUl = $parentLi.children('ul');

      // let depth = $subUl.parents().length; // but some li has no sub ul, so...
      const depth = $parentLi.parents().length + 1;

      // filter out all elements (except target) at current depth or greater
      const allAtDepth = $nav.find('ul').filter(function () {
        if ($(this).parents().length >= depth && this !== $subUl.get(0)) {
          return true;
        }
        return false;
      });
      allAtDepth.slideUp(slideTime).closest('li').removeClass('open');

      // Toggle target
      if ($parentLi.has('ul').length) {
        $parentLi.toggleClass('open');
      }
      $subUl.stop().slideToggle(slideTime);
    });

    // HighlightActiveItems
    const $links = $nav.find('a');
    const currentLocation = history.location;
    function highlightActive(pathname) {
      const path = `${pathname}`;

      $links.each((i, link) => {
        const $link = $(link);
        const $li = $link.parent('li');
        const href = $link.attr('href');

        if ($li.hasClass('active')) {
          $li.removeClass('active');
        }
        if (path.indexOf(href) === 0) {
          $li.addClass('active');
        }
      });
    }
    highlightActive(currentLocation.pathname);
    history.listen((location) => {
      highlightActive(location.pathname);
    });
  }

  render() {
    const user_role = getRole(this.props.user);
    const staff = ['admin', 'operator', 'accountant'];
    const admin = ['admin'];
    const accountant_and_admin = ['accountant', 'admin'];
    const customer = ['customer'];
    const customer_and_staff = ['customer', 'admin', 'operator', 'accountant'];
    const admin_and_operator = ['admin', 'operator'];
    const tracker = ['tracker'];
    const staff_and_tracker = ['admin', 'operator', 'accountant', 'tracker'];
    const external_viewer_role = ['external_viewer'];
    const admin_and_accountant_and_customer = ['accountant', 'admin', 'customer'];

    return (
      <ul
        className="nav"
        ref={(c) => {
          this.nav = c;
        }}
      >
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          customer.includes(user_role) && (
            <li>
              <Link to={`/app/dashboard`}>
                <i className="nav-icon material-icons">dashboard</i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          (!tracker.includes(user_role) && !external_viewer_role.includes(user_role)) &&  (
            <li>
              <Link to={`/app/orders`}>
                <i className="nav-icon material-icons">layers</i>
                <span className="nav-text">Orders</span>
              </Link>
              <ul>
                {staff.includes(user_role) && (
                  <li>
                    <Link className="prepend-icon" to={`/app/orders/pending`}>
                      <span>Pending</span>
                    </Link>
                  </li> 
                )}
                <li>
                  <Link className="prepend-icon" to={`/app/orders`}>
                    <span>Active</span>
                  </Link>
                </li>
                <li>
                  <Link className="prepend-icon" to={`/app/orders/history`}>
                    <span>History</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          ( staff.includes(user_role) || external_viewer_role.includes(user_role) ) && (
            <li>
              <Link to={`/app/orders/express`}>
                <i className="nav-icon material-icons">star</i>
                <span className="nav-text">Express Orders</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/orders/express/active`}
                  >
                    <span>Active</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/orders/express/history`}
                  >
                    <span>History</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to={`/app/orders/critical`}>
                <i className="nav-icon material-icons">priority_high</i>
                <span className="nav-text">Critical Orders</span>
              </Link>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to={`/app/orders/pickup`}>
                <i className="nav-icon material-icons">local_shipping</i>
                <span className="nav-text">Pickup </span>
              </Link>
              <ul>
                <li>
                  <Link className="prepend-icon" to={`/app/orders/pickup`}>
                    <span>Orders</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/orders/pickup/tasks`}
                  >
                    <span>Tasks</span>
                  </Link>
                </li>
              </ul>
              <span className="nav-text"></span>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to={`/app/orders/return`}>
                <i className="nav-icon material-icons">swap_horiz</i>
                <span className="nav-text">Return Orders</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/orders/return/active`}
                  >
                    <span>Active</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/orders/return/history`}
                  >
                    <span>History</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to={`/app/barcodes/pending`}>
                <i className="nav-icon material-icons">crop_free</i>
                <span className="nav-text">Pending Barcodes</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className="prepend-icon"
                    to={PENDING_BARCODES_PICKUP_EXISTING_PAGE_PATH}
                  >
                    <span>Pickup Existing</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={PENDING_BARCODES_PICKUP_REMOVED_PAGE_PATH}
                  >
                    <span>Pickup Removed</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={PENDING_BARCODES_RETURN_PAGE_PATH}
                  >
                    <span>Return</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={PENDING_BARCODES_RETURN_ATTACHED_PAGE_PATH}
                  >
                    <span>Return Attached</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          admin_and_operator.includes(user_role) && (
            <li>
              <Link to={`/app/barcodes/master`}>
                <i className="nav-icon material-icons">settings_overscan</i>
                <span className="nav-text">Master Barcodes</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/barcodes/master/inactive`}
                  >
                    <span>Inactive</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/barcodes/master/active`}
                  >
                    <span>Active</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/barcodes/master/history`}
                  >
                    <span>History</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to={`/app/clients`}>
                <i className="nav-icon material-icons">people</i>
                <span className="nav-text">Clients</span>
              </Link>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to={`/app/drivers`}>
                <i className="nav-icon material-icons">directions_car</i>
                <span className="nav-text">Drivers</span>
              </Link>
            </li>
          )}
        {isFeatureEnabled("AreaRoutes") &&
          process.env.REACT_APP_IS_SHOPIFY !== "true" &&
          staff.includes(user_role) && (
            <li>
              <Link to={`/app/routes`}>
                <i className="nav-icon material-icons">swap_calls</i>
                <span className="nav-text">Routes</span>
              </Link>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          customer_and_staff.includes(user_role) && (
            <li>
              <Link to={`/app/locations`}>
                <i className="nav-icon material-icons">place</i>
                <span className="nav-text">
                  {isCustomer(this.props.user) ? 'My Locations' : 'Locations'}
                </span>
              </Link>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          isCustomer(this.props.user) && (
            <li>
              <Link to={`/app/recipients`}>
                <i className="nav-icon material-icons">people</i>
                <span className="nav-text">Recipients</span>
              </Link>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          isCustomer(this.props.user) && (
            <li>
              <Link to={`/app/team`}>
                <i className="nav-icon material-icons">face</i>
                <span className="nav-text">Team Members</span>
              </Link>
            </li>
          )}
      
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          accountant_and_admin.includes(user_role) && (
            <li>
              <Link to={`/app/cashboxes`}>
                <i className="nav-icon material-icons">attach_money</i>
                <span className="nav-text">Cashboxes</span>
              </Link>
              <ul>
              <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/cashboxes`}
                  >
                    <span>{isCustomer(this.props.user)? "My ": "Client "} Cashboxes</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/cashboxes/orders`}
                  >
                    <span>Orders</span>
                  </Link>
                </li>
            
                
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff_and_tracker.includes(user_role) && (
            <li>
              <Link to={`/app/map`}>
                <i className="nav-icon material-icons">map</i>
                <span className="nav-text">Map</span>
              </Link>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to="javascript:;">
                <i className="nav-icon material-icons">device_hub</i>
                <span className="nav-text">HR</span>
              </Link>
              <ul>
                {(admin.includes(user_role) ||
                  hasHrPrivilege(this.props.user)) && (
                  <div>
                    <li>
                      <Link
                        className="prepend-icon"
                        to={{ pathname: `/app/users`, hash: '#hr-tab' }}
                      >
                        <span>Users</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="prepend-icon"
                        to={{ pathname: `/app/timesheets`, hash: '#hr-tab' }}
                      >
                        <span>Timesheets</span>
                      </Link>
                    </li> */}
                  </div>
                )}
                <li>
                  <Link className="prepend-icon" to={`/links`}>
                    <span>Requests</span>
                  </Link>
                  <ul>
                    {/* <li>
                      <Link
                        className="prepend-icon"
                        to={`/app/requests/recruitment`}
                      >
                        <span>Recruitment</span>
                      </Link>
                      <ul>
                        <li>
                          <Link
                            className="prepend-icon"
                            to={{
                              pathname: `/app/requests/recruitment/pending`,
                            }}
                          >
                            <span>Pending</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="prepend-icon"
                            to={{
                              pathname: `/app/requests/recruitment/rejected`,
                            }}
                          >
                            <span>Rejected</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="prepend-icon"
                            to={{
                              pathname: `/app/requests/recruitment/approved`,
                            }}
                          >
                            <span>Approved</span>
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link className="prepend-icon" to={`/app/requests/leave`}>
                        <span>Leave</span>
                      </Link>
                      <ul>
                        <li>
                          <Link
                            className="prepend-icon"
                            to={{ pathname: `/app/requests/leave/pending` }}
                          >
                            <span>Pending</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="prepend-icon"
                            to={{ pathname: `/app/requests/leave/rejected` }}
                          >
                            <span>Rejected</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="prepend-icon"
                            to={{ pathname: `/app/requests/leave/approved` }}
                          >
                            <span>Approved</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                   {/*  {hasHrPrivilege(this.props.user) && (
                      <li>
                        <Link
                          className="prepend-icon"
                          to={`/app/requests/exit`}
                        >
                          <span>Exit</span>
                        </Link>
                      </li>
                    )} */}
                  </ul>
                </li>
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to="javascript:;">
                <i className="nav-icon material-icons">assessment</i>
                <span className="nav-text">Accounting</span>
              </Link>
              <ul>
                {/* <li>
                  {accountant_and_admin.includes(user_role) && (
                    <Link className="prepend-icon" to={`/app/payments`}>
                      <span>Orders Payments</span>
                    </Link>
                  )}
                  <ul>
                    <li>
                      <Link
                        className="prepend-icon"
                        to={`/app/accounting/orders`}
                      >
                        <span>Current</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="prepend-icon"
                        to={`/app/accounting/orders/history`}
                      >
                        <span>History</span>
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link
                    className="prepend-icon"
                    to={`/app/accounting/submissions`}
                  >
                    <span>Submissions</span>
                  </Link>
                </li>
                {/* {accountant_and_admin.includes(user_role) && (
                  <li>
                    <Link
                      className="prepend-icon"
                      to={`/app/accounting/transactions`}
                    >
                      <span>Transactions</span>
                    </Link>
                  </li>
                )} */}
                {/* {accountant_and_admin.includes(user_role) && (
                  <li>
                    <Link
                      className="prepend-icon"
                      to={`/app/accounting/piggybanks`}
                    >
                      <span>Piggy Banks</span>
                    </Link>
                    <ul>
                      <li>
                        <Link
                          className="prepend-icon"
                          to={`/app/accounting/piggybanks/active`}
                        >
                          <span>Active</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="prepend-icon"
                          to={`/app/accounting/piggybanks/history`}
                        >
                          <span>History</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                )} */}
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          staff.includes(user_role) && (
            <li>
              <Link to="javascript:;">
                <i className="nav-icon material-icons">pie_chart_outlined</i>
                <span className="nav-text">Reports</span>
              </Link>
              <ul>
                <li>
                  <Link className="prepend-icon" to={`/app/reports/orders`}>
                    <span>Order Reports</span>
                  </Link>
                </li>
                <li>
                  <Link className="prepend-icon" to={`/app/reports/tasks`}>
                    <span>Task Reports</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/app/queue_requests`}>
                    <span className="nav-text">Queued Exports</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          customer.includes(user_role) && (
            <li>
              <Link to={`/app/reports/orders`}>
                <i className="nav-icon material-icons">pie_chart_outlined</i>
                <span className="nav-text">Order Reports</span>
              </Link>
            </li>
          )}
        {/* {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          customer.includes(user_role) && (
            <li>
              <Link to="javascript:;">
                <i className="nav-icon material-icons">attach_money</i>
                <span className="nav-text">Piggy Banks</span>
              </Link>
              <ul>
                <li>
                  <Link className="prepend-icon" to={`/app/piggybanks/active`}>
                    <span>Active</span>
                  </Link>
                </li>
                <li>
                  <Link className="prepend-icon" to={`/app/piggybanks/history`}>
                    <span>History</span>
                  </Link>
                </li>
              </ul>
            </li>
          )} */}

        {customer.includes(user_role) &&
        this.props.user.customer &&
        this.props.user.customer.third_party_accounts.length > 0 ? (
          process.env.REACT_APP_IS_SHOPIFY === 'true' ? (
            this.props.user.customer.third_party_accounts.map((a) => (
              <li key={a.id}>
                <Link to={`/app/third_party/${a.app_name}/${a.id}`}>
                  <i className="nav-icon material-icons">device_hub</i>
                  <span className="nav-text">UnShipped</span>
                </Link>
              </li>
            ))
          ) : (
            <li>
              <Link to="javascript:;">
                <i className="nav-icon material-icons">device_hub</i>
                <span className="nav-text">Third-Party</span>
              </Link>

              <ul>
                {this.props.user.customer.third_party_accounts.map(
                  (account) => {
                    return (
                      <li key={account.id}>
                        <Link
                          className="prepend-icon"
                          to={`/app/third_party/${account.app_name}/${account.id}`}
                        >
                          <span>
                            {account.shop} ({account.app_name})
                          </span>
                        </Link>
                        <ul>
                          <li>
                            <Link
                              className="prepend-icon"
                              to={`/app/third_party/${account.app_name}/${account.id}`}
                            >
                              <span>UnShipped</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="prepend-icon"
                              to={`/app/third_party/${account.app_name}/${account.id}/shipped`}
                            >
                              <span>Shipped</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    );
                  },
                )}
              </ul>
            </li>
          )
        ) : null}

        {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
          customer_and_staff.includes(user_role) && (
            <li>
              <Link to={`/app/reviews`}>
                <i className="nav-icon material-icons">rate_review</i>
                <span className="nav-text">Reviews</span>
              </Link>
            </li>
          )}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(SidebarContent));
