import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import NavLeftList from './NavLeftList';
import NavRightList from './NavRightList';
import PropTypes from 'prop-types';
import { isCustomer } from 'utils/helpers';

class Header extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      responseError: {
        isError: false,
      },
    };
  }

  componentDidMount() {
    const sidebarToggler = this.sidebarBtn;
    const $sidebarToggler = $(sidebarToggler);
    const $body = $('#body');

    $sidebarToggler.on('click', (e) => {
      // _sidebar.scss, _page-container.scss
      $body.toggleClass('sidebar-mobile-open');
    });
  }

  render() {
    const { colorOption, user } = this.props;

    return (
      <section className="app-header">
        <div
          className={classnames('app-header-inner', {
            'bg-color-light':
              ['11', '12', '13', '14', '15', '16', '21'].indexOf(colorOption) >=
              0,
            'bg-color-dark': colorOption === '31',
            'bg-color-primary': ['22', '32'].indexOf(colorOption) >= 0,
            'bg-color-success': ['23', '33'].indexOf(colorOption) >= 0,
            'bg-color-info': ['24', '34'].indexOf(colorOption) >= 0,
            'bg-color-warning': ['25', '35'].indexOf(colorOption) >= 0,
            'bg-color-danger': ['26', '36'].indexOf(colorOption) >= 0,
          })}
        >
          <div className="d-lg-none d-xl-none float-left">
            <a
              className="md-button header-icon toggle-sidebar-btn"
              ref={(c) => {
                this.sidebarBtn = c;
              }}
            >
              <i className="material-icons">menu</i>
            </a>
          </div>

          <div className="brand d-none d-lg-inline-block d-xl-inline-block">
            <h2>
              <Link
                to={
                  process.env.REACT_APP_IS_SHOPIFY === 'true'
                    ? '/app/settings'
                    : isCustomer(user)
                    ? '/'
                    : '/app/orders/pending'
                }
              >
                <img
                  src="/assets/images/wakilni-logo-v2.png"
                  height="22"
                  alt="{APPCONFIG.brand}"
                />
              </Link>
            </h2>
          </div>

          <div className="top-nav-left d-none d-lg-inline-block d-xl-inline-block">
            <NavLeftList />
          </div>

          <div className="top-nav-right">
            <NavRightList />
          </div>
        </div>
      </section>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  colorOption: state.settings.colorOption,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Header);
