export const styles = theme => ({
  tableCell: {
    // verticalAlign: '-webkit-baseline-middle',
    // padding: '5px 5px 0px 0px',
    padding: "0px 5px 0px 0px",
    whiteSpace: "nowrap",
    textAlign: "center",
    "&:last-child": {
      paddingRight: 0,
      width: "50px",
      textAlign: "right !important"
    }
  },
  button_icon: {
    width: "28px !important",
    padding: "12px 0px !important"
  },
  tableHeaderCell: {
    padding: "0px 5px 0px 0px",
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: "0.8rem",
    color: "black",
    "&:last-child": {
      paddingRight: 0,
      width: "50px",
      textAlign: "right"
    }
  },
  assign_tableHeaderCell: {
    padding: "0px 5px 0px 0px",
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: "0.8rem",
    color: "black"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  table: {
    minWidth: 800
  },
  assign_table: {
    minWidth: 600
  },
  request_button: {
    cursor: "pointer",
    "&:hover": {
      color: "red !important"
    }
  },
  raised_buttons: {
    margin: "5px !important",
  },
  UploadLogoButton: {
    marginTop: "40px",
    //backgroundColor: "rgb(193, 193, 193) !important",
    //color: "white",
    cursor: "pointer"
  },
  UploadLogoInput: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    opacity: 0
  },
  tableCheckboxCell: {
    padding: "0px 5px 0px 0px",
    whiteSpace: "nowrap"
  },
  table_checkbox: {
    width: "29px"
  },
  tableIconCell: {
    padding: "0px 5px 0px 0px",
    whiteSpace: "nowrap",
    textAlign: "center",
    width: "100px"
  },
  checked: {
    color: "#ffc107"
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    flexBasis: 200
  },
  leftAllign: {
    textAlign: "left"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table_cell_break: {
    textOverflow: "ellipsis !important",
    width: "150px !important",
    cursor: "pointer !important",
    whiteSpace: "pre-line"
  }
});
