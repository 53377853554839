import * as locationsActions from "../stores/actions/locations";
import Http from "../utils/Http";
import { location } from "stores/models/Location";
import * as authActions from "../stores/actions/auth";
import { serializeSearchInput, serializeInput } from "utils/helpers";

export function fetchLocations(
  input = [],
  with_pagination = false,
  pageNumber = null,
  other_input = []
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `locations${
        input.length > 0 ? serializeSearchInput(input) : ""
      }${
        other_input.length > 0 ? (input.length > 0 ? "&" : "?") : ""
      }${serializeInput(other_input)}`;
      Http.get(
        with_pagination
          ? url +
            (input.length > 0 || other_input.length > 0 ? "&" : "?") +
            "with_pagination=true&page=" +
            pageNumber
          : url
      )
        .then(res => {
          var locations = [];
          res.data.data.forEach(element => {
            locations.push(location(element));
          });
          dispatch(locationsActions.fetchLocationsResolved(locations));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchLocationsNames(input = []) {
  const isShipping = process.env.REACT_APP_IS_SHOPIFY === 'true';
  const urlBase = 'locations/names';
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url =
        input.length > 0
          ? `${isShipping ? 'shipping/' : ''}${urlBase}?${serializeInput(
              input,
              false,
            )}`
          : `${isShipping ? 'shipping/' : ''}${urlBase}`;
      Http.get(url)
        .then((res) => {
          dispatch(locationsActions.fetchLocationsNamesResolved(res.data.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchLocation(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("locations/" + id)
        .then(res => {
          dispatch(
            locationsActions.fetchLocationResolved(location(res.data.data))
          );
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateLocation(info, from_modal = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("locations/" + info.id, info.input)
        .then(res => {
          if (!from_modal) {
            dispatch(
              locationsActions.updateLocationResolved(location(res.data.data))
            );
          }
          dispatch(authActions.serverSuccess("Location updated successfully"));
          return resolve(res.data.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteLocationLog(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.delete("locations/" + info.location_id + "/location_logs/" + info.id)
        .then(res => {
          dispatch(locationsActions.deleteLocationLogResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function deleteLocation(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.delete("locations/" + info.id)
        .then(res => {
          dispatch(locationsActions.deleteLocationResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}


export function locationCheck(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("locations/check/" + info.id)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchLocationsPersonables() {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("locations/personables")
        .then(res => {
          dispatch(
            locationsActions.fetchLocationsPersonablesResolved(res.data.data)
          );
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
