import { FETCH_ACTIVE_BANNER_RESOLVED } from "../ActionTypes/banners";

const initialState = {
  activeBanner: []
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_BANNER_RESOLVED:
      return Object.assign({}, state, {
        activeBanner: action.payload.data.length ? action.payload.data : {}
      });

    default:
      return state;
  }
};

export default bannerReducer;
