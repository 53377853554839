import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import Header from 'components/Header';
import Sidenav from 'components/Sidenav';
import EnhancedSnackBar from 'components/EnhancedSnackBar';
import Footer from 'components/Footer';
import { connect } from 'react-redux';
import Dashboard from '../routes/dashboard/';
import { Authorization } from './Authorization';
import { getRole } from 'utils/helpers';
import EnhancedPushNotification from 'components/EnhancedPushNotification/EnhancedPushNotification';
import MainComponentCalls from 'components/MainComponentCalls';
import PropTypes from 'prop-types';
import Banner from 'components/Banner';
import InternalBanner from 'components/InternalBanner';
import SurveysModel from '../routes/settings/components/Surveys/SurveysModel';
import { isCustomer } from 'utils/helpers';
import _ from 'lodash';
import { isAccountant, isOperator } from '../../../utils/helpers';
import * as internalBannerService from "services/internalBannerService";

const Customer = Authorization(['customer']);
const Accountant = Authorization(['accountant', 'admin']);
const Admin = Authorization(['admin']);
const CustomerAndOperator = Authorization([
  'operator',
  'admin',
  'customer',
  'accountant',
  'external_viewer'
]);
const AdminAndOperator = Authorization(['operator', 'admin']);
const Staff = Authorization(['operator', 'admin', 'accountant']);
const StaffAndExternalViewer = Authorization(['operator', 'admin', 'accountant', 'external_viewer']);
const User = Authorization([
  'operator',
  'admin',
  'customer',
  'driver',
  'accountant',
  'external_viewer'
]);
const AdminAndOperatorAndCustomer = Authorization([
  'operator',
  'admin',
  'customer',
]);
const AdminAndAccountantAndCustomer = Authorization([
  'accountant',
  'admin',
  'customer',
]);
const AdminAndAccountant = Authorization([
  'accountant',
  'admin',
]);
const StaffAndTracker = Authorization([
  'operator',
  'admin',
  'accountant',
  'tracker',
]);
const OperatorAndAccountant = Authorization(['operator', 'accountant']);

function LoadingComponent() {
  return <div />;
  // return <ReactLoading type='SpinningBubbles' color='#ffc107' height={667} width={375} />;
}

let AsyncOrder = loadable({
  loader: () => import('../routes/orders-page/active'),
  loading: LoadingComponent,
});

let AsyncSearchOrder = loadable({
  loader: () => import('../routes/orders-page/search'),
  loading: LoadingComponent,
});

let AsyncViewOrders = loadable({
  loader: () => import('../routes/orders-page/view'),
  loading: LoadingComponent,
});

let AsyncViewCustomers = loadable({
  loader: () => import('../routes/customers-page/view'),
  loading: LoadingComponent,
});

let AsyncCriticalOrder = loadable({
  loader: () => import('../routes/orders-page/critical'),
  loading: LoadingComponent,
});

let AsyncPickupOrder = loadable({
  loader: () => import('../routes/orders-page/pickup'),
  loading: LoadingComponent,
});

let AsyncPickupTasksOrder = loadable({
  loader: () => import('../routes/orders-page/pickup-tasks'),
  loading: LoadingComponent,
});

let AsyncExpressOrder = loadable({
  loader: () => import('../routes/orders-page/express'),
  loading: LoadingComponent,
});

let AsyncReturnOrder = loadable({
  loader: () => import('../routes/orders-page/return'),
  loading: LoadingComponent,
});

let AsyncCustomersOrder = loadable({
  loader: () => import('../routes/orders-page/customers-active'),
  loading: LoadingComponent,
});

let AsyncAccountantOrder = loadable({
  loader: () => import('../routes/orders-page/accountant-orders'),
  loading: LoadingComponent,
});

let AsyncPendingOrders = loadable({
  loader: () => import('../routes/orders-page/pending'),
  loading: LoadingComponent,
});

let AsyncAccountantOrderHistory = loadable({
  loader: () => import('../routes/orders-page/accountant-orders-history'),
  loading: LoadingComponent,
});

let AsyncCashboxDetails = loadable({
  loader: () => import('../routes/cashboxes-page/components/CashboxDetails'),
  loading: LoadingComponent,
});

let AsyncHistory = loadable({
  loader: () => import('../routes/orders-page/history'),
  loading: LoadingComponent,
});

let AsyncOrderDetails = loadable({
  loader: () => import('../routes/orders-page/order-details'),
  loading: LoadingComponent,
});

let AsyncTaskDetails = loadable({
  loader: () => import('../routes/orders-page/task-details'),
  loading: LoadingComponent,
});

let AsyncCreateOrder = loadable({
  loader: () => import('../routes/orders-page/create-order'),
  loading: LoadingComponent,
});

let AsyncBarcode = loadable({
  loader: () => import('../routes/barcodes-page'),
  loading: LoadingComponent,
});

let AsyncMasterBarcode = loadable({
  loader: () => import('../routes/master-barcodes-page'),
  loading: LoadingComponent,
});

let AsyncMasterBarcodeOrders = loadable({
  loader: () => import('../routes/master-barcodes-page/OrdersRelatedView'),
  loading: LoadingComponent,
});

let AsyncDropoffOrders = loadable({
  loader: () => import('../routes/drivers-page/driver-details/components/RfidDropoff/OrderRelatedView'),
  loading: LoadingComponent,
});

let AsyncCustomer = loadable({
  loader: () => import('../routes/customers-page/customers'),
  loading: LoadingComponent,
});

let AsyncRecipient = loadable({
  loader: () => import('../routes/customers-page/recipients'),
  loading: LoadingComponent,
});

let AsyncCustomerDetails = loadable({
  loader: () => import('../routes/customers-page/customer-details'),
  loading: LoadingComponent,
});

let AsyncCreateCustomer = loadable({
  loader: () => import('../routes/customers-page/create-customer'),
  loading: LoadingComponent,
});

let AsyncCreateCustomersOrder = loadable({
  loader: () => import('../routes/customers-page/create-order'),
  loading: LoadingComponent,
});

let AsyncCreateCustomersBulkOrders = loadable({
  loader: () => import('../routes/customers-page/bulk-orders'),
  loading: LoadingComponent,
});

let AsyncDriver = loadable({
  loader: () => import('../routes/drivers-page/drivers'),
  loading: LoadingComponent,
});

let AsyncDriverDetails = loadable({
  loader: () => import('../routes/drivers-page/driver-details'),
  loading: LoadingComponent,
});

let AsyncDriverSubmissions = loadable({
  loader: () => import('../routes/drivers-page/submissions'),
  loading: LoadingComponent,
});

let AsyncSubmissions = loadable({
  loader: () => import('../routes/accountant/submissions'),
  loading: LoadingComponent,
});

let AsyncTransactions = loadable({
  loader: () => import('../routes/accountant/transactions'),
  loading: LoadingComponent,
});

// let AsyncPiggyBanksActive = loadable({
//   loader: () => import('../routes/accountant/PiggyBanks'),
//   loading: LoadingComponent,
// });

// let AsyncPiggyBanksHistory = loadable({
//   loader: () => import('../routes/accountant/PiggyBanksHistory'),
//   loading: LoadingComponent,
// });

let AsyncCreateDriver = loadable({
  loader: () => import('../routes/drivers-page/create-driver'),
  loading: LoadingComponent,
});

let AsyncLocation = loadable({
  loader: () => import('../routes/locations-page/locations'),
  loading: LoadingComponent,
});

let AsyncPrices = loadable({
  loader: () => import('../routes/customers-page/prices'),
  loading: LoadingComponent,
});

let AsyncCurrencies = loadable({
  loader: () => import('../routes/customers-page/currencies'),
  loading: LoadingComponent,
});

let AsyncCreateLocation = loadable({
  loader: () => import('../routes/locations-page/create-location'),
  loading: LoadingComponent,
});

let AsyncLocationDetails = loadable({
  loader: () => import('../routes/locations-page/location-details'),
  loading: LoadingComponent,
});

let AsyncCreateRecipient = loadable({
  loader: () => import('../routes/customers-page/create-recipient'),
  loading: LoadingComponent,
});

let AsyncMap = loadable({
  loader: () => import('../routes/map/'),
  loading: LoadingComponent,
});

let AsyncPackage = loadable({
  loader: () => import('../routes/packages/'),
  loading: LoadingComponent,
});

let AsyncLog = loadable({
  loader: () => import('../routes/logs/'),
  loading: LoadingComponent,
});

let AsyncOrdersReports = loadable({
  loader: () => import('../routes/reports/orders-reports'),
  loading: LoadingComponent,
});
let AsyncImportOrders = loadable({
  loader: () => import('../routes/reports/orders-reports/import-orders'),
  loading: LoadingComponent,
});

//Order Import History
let AsyncOrderImportsHistory = loadable({
  loader: () => import('../routes/reports/orders-reports/import-history'),
  loading: LoadingComponent,
});

let AsyncImportTasks = loadable({
  loader: () => import('../routes/reports/tasks-reports/import-tasks'),
  loading: LoadingComponent,
});

//Task Import History
let AsyncTaskImportsHistory = loadable({
  loader: () => import('../routes/reports/tasks-reports/import-history'),
  loading: LoadingComponent,
});
let AsyncTasksReports = loadable({
  loader: () => import('../routes/reports/tasks-reports'),
  loading: LoadingComponent,
});


let AsyncSettings = loadable({
  loader: () => import('../routes/settings'),
  loading: LoadingComponent,
});

let AsyncLeaveRequest = loadable({
  loader: () => import('../routes/hr/leave-requests'),
  loading: LoadingComponent,
});

let AsyncTimeSheet = loadable({
  loader: () => import('../routes/hr/timesheets'),
  loading: LoadingComponent,
});

let AsyncUser = loadable({
  loader: () => import('../routes/hr/users'),
  loading: LoadingComponent,
});

let AsyncRecruitment = loadable({
  loader: () => import('../routes/hr/requests/recruitment'),
  loading: LoadingComponent,
});

let AsyncUpsertRecruitment = loadable({
  loader: () =>
    import('../routes/hr/requests/recruitment/components/upsert-recruitment'),
  loading: LoadingComponent,
});

let AsyncLeave = loadable({
  loader: () => import('../routes/hr/requests/leave'),
  loading: LoadingComponent,
});

let AsyncUpsertLeave = loadable({
  loader: () => import('../routes/hr/requests/leave/components/upsert-leave'),
  loading: LoadingComponent,
});

let AsyncViewLeave = loadable({
  loader: () => import('../routes/hr/requests/leave/components/LeaveInfo'),
  loading: LoadingComponent,
});

let AsyncExit = loadable({
  loader: () => import('../routes/hr/requests/exit'),
  loading: LoadingComponent,
});

let AsyncUpsertExit = loadable({
  loader: () => import('../routes/hr/requests/exit/components/upsert-exit'),
  loading: LoadingComponent,
});

let AsyncTeamMembers = loadable({
  loader: () => import('../routes/team'),
  loading: LoadingComponent,
});

let AsyncNotification = loadable({
  loader: () => import('../routes/notifications'),
  loading: LoadingComponent,
});

let AsyncProfileDetails = loadable({
  loader: () => import('../routes/profile'),
  loading: LoadingComponent,
});

let AsyncEmailSubscription = loadable({
  loader: () => import('../routes/email-subscription'),
  loading: LoadingComponent,
});

let AsyncShopify = loadable({
  loader: () => import('../routes/third-party/shopify'),
  loading: LoadingComponent,
});

let AsyncWoocommerce = loadable({
  loader: () => import('../routes/third-party/woocommerce'),
  loading: LoadingComponent,
});

let AsyncFeel22 = loadable({
  loader: () => import('../routes/third-party/feel22'),
  loading: LoadingComponent,
});

let AsyncQueueRequests = loadable({
  loader: () => import('../routes/queue-requests-page'),
  loading: LoadingComponent,
});

let AsyncReviews = loadable({
  loader: () => import('../routes/reviews'),
  loading: LoadingComponent,
});

let AsyncFeedback = loadable({
  loader: () => import('../routes/feedback'),
  loading: LoadingComponent,
});

let AsyncImportRecipients = loadable({
  loader: () => import('../routes/customers-page/recipients-import'),
  loading: LoadingComponent,
});

let AsyncAreaRoutes = loadable({
  loader: () => import('../routes/area-route-page'),
  loading: LoadingComponent,
});

let AsyncCashboxes = loadable({
  loader: () => import('../routes/cashboxes-page'),
  loading: LoadingComponent,
});
let AsyncCashboxOrders = loadable({
  loader: () => import('../routes/orders-page/cashbox-orders'),
  loading: LoadingComponent,
});
class MainApp extends React.Component {


  loadActiveInternalBanner = () => {
      let search_input = [{ key: "is_active", element: 1 }];
      this.props
        .dispatch(internalBannerService.fetchActiveInternalBanner(search_input))
        .then(response => {
        })
        .catch(({ error, statusCode }) => {
          this.setState({
            isLoading: false,
            responseError: {
              isError: true
            }
          });
        });
    };

    componentDidMount() {
          if (isOperator(this.props.user) || isAccountant(this.props.user) ) {
            this.loadActiveInternalBanner();
        }
      }

  render() {
    const { match, activeBanner , activeInternalBanner , user } = this.props;
    const his_role = getRole(user);

    const routes = [
      <Route
        key={`${match.url}/dashboard`}
        exact
        strict
        path={`${match.url}/dashboard`}
        component={Customer(Dashboard)}
      />,

      <Route
        key={`${match.url}/orders/pending`}
        exact
        strict
        path={`${match.url}/orders/pending`}
        component={Staff(AsyncPendingOrders)}
      />,
      <Route
        key={`${match.url}/orders/search`}
        exact
        strict
        path={`${match.url}/orders/search`}
        component={CustomerAndOperator(AsyncSearchOrder)}
      />,
      <Route
        key={`${match.url}/dashboard/orders/view`}
        exact
        strict
        path={`${match.url}/dashboard/orders/view`}
        component={AdminAndOperatorAndCustomer(AsyncViewOrders)}
      />,
      <Route
        key={`${match.url}/dashboard/clients/view`}
        exact
        strict
        path={`${match.url}/dashboard/clients/view`}
        component={AdminAndOperator(AsyncViewCustomers)}
      />,
      <Route
        key={`${match.url}/orders/critical`}
        exact
        strict
        path={`${match.url}/orders/critical`}
        component={Staff(AsyncCriticalOrder)}
      />,
      <Route
        key={`${match.url}/orders/pickup`}
        exact
        strict
        path={`${match.url}/orders/pickup`}
        component={Staff(AsyncPickupOrder)}
      />,
      <Route
        key={`${match.url}/orders/pickup/tasks`}
        exact
        strict
        path={`${match.url}/orders/pickup/tasks`}
        component={Staff(AsyncPickupTasksOrder)}
      />,
      <Route
        key={`${match.url}/orders/return`}
        exact
        strict
        path={[
          `${match.url}/orders/return`,
          `${match.url}/orders/return/active`,
          `${match.url}/orders/return/history`,
        ]}
        component={Staff(AsyncReturnOrder)}
      />,
      <Route
        key={`${match.url}/orders/express`}
        exact
        strict
        path={[
          `${match.url}/orders/express`,
          `${match.url}/orders/express/active`,
          `${match.url}/orders/express/history`,
        ]}
        component={StaffAndExternalViewer(AsyncExpressOrder)}
      />,
      <Route
        key={`${match.url}/orders`}
        exact
        strict
        path={`${match.url}/orders`}
        component={User(
          his_role == 'customer' ? AsyncCustomersOrder : AsyncOrder,
        )}
      />,
      <Route
        key={`${match.url}/orders/history`}
        exact
        strict
        path={`${match.url}/orders/history`}
        component={AsyncHistory}
      />,
      <Route
        key={`${match.url}/orders/new`}
        exact
        strict
        path={`${match.url}/orders/new`}
        component={CustomerAndOperator(
          his_role == 'customer' ? AsyncCreateCustomersOrder : AsyncCreateOrder,
        )}
      />,
      <Route
        key={`${match.url}/orders/bulk`}
        exact
        strict
        path={`${match.url}/orders/bulk`}
        component={Customer(AsyncCreateCustomersBulkOrders)}
      />,
      <Route
        key={`${match.url}/orders/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/orders/:id(\\d+)`}
        types={{ id: Number }}
        component={CustomerAndOperator(AsyncOrderDetails)}
      />,
      <Route
        key={`${match.url}/orders/:order_id(\\d+)/tasks/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/orders/:order_id(\\d+)/tasks/:id(\\d+)`}
        types={{ order_id: Number, id: Number }}
        component={CustomerAndOperator(AsyncTaskDetails)}
      />,
      <Route
        key={`${match.url}/barcodes/pending`}
        exact
        strict
        path={[
          `${match.url}/barcodes/pending/pickup/existing`,
          `${match.url}/barcodes/pending/pickup/removed`,
          `${match.url}/barcodes/pending/return`,
          `${match.url}/barcodes/pending/return/attached`,
        ]}
        component={Staff(AsyncBarcode)}
      />,
      <Route
        key={`${match.url}/barcodes/master`}
        exact
        strict
        path={[
          `${match.url}/barcodes/master`,
          `${match.url}/barcodes/master/inactive`,
          `${match.url}/barcodes/master/active`,
          `${match.url}/barcodes/master/history`,
        ]}
        component={AdminAndOperator(AsyncMasterBarcode)}
      />,
      <Route
        key={`${match.url}/queue_requests`}
        exact
        strict
        path={`${match.url}/queue_requests`}
        component={Staff(AsyncQueueRequests)}
      />,
      <Route
        key={`${match.url}/barcode/master/:id/orders/incomplete`}
        exact
        strict
        path={`${match.url}/barcode/master/:id/orders/incomplete`}
        component={AdminAndOperator(AsyncMasterBarcodeOrders)}
      />,
      <Route
        key={`${match.url}/barcode/master/:id/orders/complete`}
        exact
        strict
        path={`${match.url}/barcode/master/:id/orders/complete`}
        component={AdminAndOperator(AsyncMasterBarcodeOrders)}
      />,
      <Route
      key={`${match.url}/drivers/orders/:id/found`}
      exact
      strict
      path={`${match.url}/drivers/orders/:id/found`}
      component={AdminAndOperator(AsyncDropoffOrders)}
      />,
      <Route
      key={`${match.url}/drivers/orders/:id/missing`}
      exact
      strict
      path={`${match.url}/drivers/orders/:id/missing`}
      component={AdminAndOperator(AsyncDropoffOrders)}
      />,
      <Route
      key={`${match.url}/drivers/orders/:id/extra`}
      exact
      strict
      path={`${match.url}/drivers/orders/:id/extra`}
      component={AdminAndOperator(AsyncDropoffOrders)}
      />,
      <Route
        key={`${match.url}/clients`}
        exact
        strict
        path={`${match.url}/clients`}
        component={Staff(AsyncCustomer)}
      />,
      <Route
        key={`${match.url}/recipients`}
        exact
        strict
        path={`${match.url}/recipients`}
        component={Customer(AsyncRecipient)}
      />,
      <Route
        key={`${match.url}/clients/new`}
        exact
        strict
        path={`${match.url}/clients/new`}
        component={Staff(AsyncCreateCustomer)}
      />,
      <Route
        key={`${match.url}/clients/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/clients/:id(\\d+)`}
        types={{ id: Number }}
        component={Staff(AsyncCustomerDetails)}
      />,
      <Route
        key={`${match.url}/clients/:id(\\d+)/locations/new`}
        exact
        strict
        path={`${match.url}/clients/:id(\\d+)/locations/new`}
        types={{ id: Number }}
        component={Staff(AsyncCreateLocation)}
      />,
      <Route
        key={`${match.url}/locations/new`}
        exact
        strict
        path={`${match.url}/locations/new`}
        component={Customer(AsyncCreateLocation)}
      />,
      <Route
        key={`${match.url}/clients/:id(\\d+)/orders/new`}
        exact
        strict
        path={`${match.url}/clients/:id(\\d+)/orders/new`}
        types={{ id: Number }}
        component={Staff(AsyncCreateCustomersOrder)}
      />,
      <Route
        key={`${match.url}/clients/:id(\\d+)/orders/bulk`}
        exact
        strict
        path={`${match.url}/clients/:id(\\d+)/orders/bulk`}
        types={{ id: Number }}
        component={Staff(AsyncCreateCustomersBulkOrders)}
      />,
      <Route
        key={`${match.url}/clients/:id(\\d+)/prices`}
        exact
        strict
        path={`${match.url}/clients/:id(\\d+)/prices`}
        types={{ id: Number }}
        component={Staff(AsyncPrices)}
      />,
      <Route
        key={`${match.url}/clients/:id(\\d+)/currencies`}
        exact
        strict
        path={`${match.url}/clients/:id(\\d+)/currencies`}
        types={{ id: Number }}
        component={Staff(AsyncCurrencies)}
      />,
      <Route
        key={`${match.url}/drivers`}
        exact
        strict
        path={`${match.url}/drivers`}
        component={Staff(AsyncDriver)}
      />,
      <Route
        key={`${match.url}/drivers/new`}
        exact
        strict
        path={`${match.url}/drivers/new`}
        component={Staff(AsyncCreateDriver)}
      />,
      <Route
        key={`${match.url}/cashboxes`}
        exact
        strict
        path={`${match.url}/cashboxes`}
        component={AdminAndAccountant(AsyncCashboxes)}
      />,
      <Route
        key={`${match.url}/routes`}
        exact
        strict
        path={`${match.url}/routes`}
        component={Staff(AsyncAreaRoutes)}
      />,
      <Route
        key={`${match.url}/drivers/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/drivers/:id(\\d+)`}
        types={{ id: Number }}
        component={Staff(AsyncDriverDetails)}
      />,
      <Route
        key={`${match.url}/drivers/:id(\\d+)/submissions`}
        exact
        strict
        path={`${match.url}/drivers/:id(\\d+)/submissions`}
        types={{ id: Number }}
        component={Staff(AsyncDriverSubmissions)}
      />,
      <Route
        key={`${match.url}/locations`}
        exact
        strict
        path={`${match.url}/locations`}
        component={CustomerAndOperator(AsyncLocation)}
      />,
      <Route
        key={`${match.url}/clients/:id(\\d+)/recipients/new`}
        exact
        strict
        path={`${match.url}/clients/:id(\\d+)/recipients/new`}
        types={{ id: Number }}
        component={Staff(AsyncCreateRecipient)}
      />,
      <Route
        key={`${match.url}/recipients/new`}
        exact
        strict
        path={`${match.url}/recipients/new`}
        component={Customer(AsyncCreateRecipient)}
      />,
      <Route
        key={`${match.url}/locations/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/locations/:id(\\d+)`}
        types={{ id: Number }}
        component={CustomerAndOperator(AsyncLocationDetails)}
      />,
      <Route
        key={`${match.url}/map`}
        strict
        exact
        path={`${match.url}/map`}
        component={StaffAndTracker(AsyncMap)}
      />,
      <Route
        key={`${match.url}/packages`}
        exact
        strict
        path={`${match.url}/packages`}
        component={Staff(AsyncPackage)}
      />,
      <Route
        key={`${match.url}/logs`}
        exact
        strict
        path={`${match.url}/logs`}
        component={Staff(AsyncLog)}
      />,
      <Route
        key={`${match.url}/reports/orders`}
        exact
        strict
        path={`${match.url}/reports/orders`}
        component={CustomerAndOperator(AsyncOrdersReports)}
      />,
      <Route
        key={`${match.url}/reports/orders/import`}
        exact
        strict
        path={`${match.url}/reports/orders/import`}
        component={Accountant(AsyncImportOrders)}
      />,
      <Route
        key={`${match.url}/reports/orders/import-history`}
        exact
        strict
        path={`${match.url}/reports/orders/import-history`}
        component={Accountant(AsyncOrderImportsHistory)}
      />,
      <Route
        key={`${match.url}/reports/tasks`}
        exact
        strict
        path={`${match.url}/reports/tasks`}
        component={Staff(AsyncTasksReports)}
      />,
      <Route
        key={`${match.url}/reports/tasks/import`}
        exact
        strict
        path={`${match.url}/reports/tasks/import`}
        component={Accountant(AsyncImportTasks)}
      />,
      <Route
        key={`${match.url}/reports/tasks/import-history`}
        exact
        strict
        path={`${match.url}/reports/tasks/import-history`}
        component={Accountant(AsyncTaskImportsHistory)}
      />,
      <Route
        key={`${match.url}/timesheets`}
        exact
        strict
        path={`${match.url}/timesheets`}
        component={Admin(AsyncTimeSheet)}
      />,
      <Route
        key={`${match.url}/requests`}
        exact
        strict
        path={`${match.url}/requests`}
        component={Admin(AsyncLeaveRequest)}
      />,
      <Route
        key={`${match.url}/users`}
        exact
        strict
        path={`${match.url}/users`}
        component={Admin(AsyncUser)}
      />,
      <Route
        key={`${match.url}/team`}
        exact
        strict
        path={`${match.url}/team`}
        component={Customer(AsyncTeamMembers)}
      />,
      <Route
        key={`${match.url}/accounting/orders`}
        exact
        strict
        path={`${match.url}/accounting/orders`}
        component={Accountant(AsyncAccountantOrder)}
      />,
      <Route
        key={`${match.url}/accounting/orders/history`}
        exact
        strict
        path={`${match.url}/accounting/orders/history`}
        component={Accountant(AsyncAccountantOrderHistory)}
      />,
      <Route
        key={`${match.url}/cashbox/:id/orders`}
        exact
        strict
        path={`${match.url}/cashbox/:id/orders`}
        component={AdminAndAccountant(AsyncCashboxDetails)}
      />,
      <Route
        key={`${match.url}/cashboxes/orders`}
        exact
        strict
        path={`${match.url}/cashboxes/orders`}
        component={AdminAndAccountant(AsyncCashboxOrders)}
      />,
      <Route
        key={`${match.url}/accounting/submissions`}
        exact
        strict
        path={`${match.url}/accounting/submissions`}
        component={Staff(AsyncSubmissions)}
      />,
      <Route
        key={`${match.url}/accounting/transactions`}
        exact
        strict
        path={`${match.url}/accounting/transactions`}
        component={Accountant(AsyncTransactions)}
      />,
      <Route
        key={`${match.url}/transactions`}
        exact
        strict
        path={`${match.url}/transactions`}
        component={Accountant(AsyncTransactions)}
      />,
      // <Route
      //   exact
      //   strict
      //   path={`${match.url}/accounting/piggybanks/active`}
      //   component={Accountant(AsyncPiggyBanksActive)}
      // />,
      // <Route
      //   exact
      //   strict
      //   path={`${match.url}/accounting/piggybanks/history`}
      //   component={Accountant(AsyncPiggyBanksHistory)}
      // />,
      // <Route
      //   exact
      //   strict
      //   path={`${match.url}/piggybanks/active`}
      //   component={Customer(AsyncPiggyBanksActive)}
      // />,
      // <Route
      //   exact
      //   strict
      //   path={`${match.url}/piggybanks/history`}
      //   component={Customer(AsyncPiggyBanksHistory)}
      // />,
      <Route
        key={`${match.url}/third_party/woocommerce/:id(\\d+)/shipped`}
        exact
        strict
        path={`${match.url}/third_party/woocommerce/:id(\\d+)/shipped`}
        types={{ id: Number }}
        component={Customer(AsyncWoocommerce)}
      />,

      <Route
      key={`${match.url}/third_party/marketplace/:id(\\d+)/shipped`}
      exact
      strict
      path={`${match.url}/third_party/marketplace/:id(\\d+)/shipped`}
      types={{ id: Number }}
      component={Customer(AsyncWoocommerce)}
      />,
      <Route
        key={`${match.url}/email_subscription`}
        exact
        strict
        path={`${match.url}/email_subscription`}
        component={Customer(AsyncEmailSubscription)}
      />,
      <Route
        key={`${match.url}/third_party/woocommerce/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/third_party/woocommerce/:id(\\d+)`}
        types={{ id: Number }}
        component={Customer(AsyncWoocommerce)}
      />,

      <Route
        key={`${match.url}/third_party/marketplace/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/third_party/marketplace/:id(\\d+)`}
        types={{ id: Number }}
        component={Customer(AsyncWoocommerce)}
      />,
      <Route
        key={`${match.url}/third_party/feel22/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/third_party/feel22/:id(\\d+)`}
        types={{ id: Number }}
        component={Customer(AsyncFeel22)}
      />,
      <Route
        key={`${match.url}/reviews`}
        exact
        strict
        path={`${match.url}/reviews`}
        component={CustomerAndOperator(AsyncReviews)}
      />,
      <Route
        key={`${match.url}/feedback`}
        exact
        strict
        path={`${match.url}/feedback`}
        component={Customer(AsyncFeedback)}
      />,
      <Route
        key={`${match.url}/recipients/import`}
        exact
        strict
        path={`${match.url}/recipients/import`}
        component={Customer(AsyncImportRecipients)}
      />,

      <Route
        key={`${match.url}/requests/recruitment/pending&rejected&approved`}
        exact
        strict
        path={[
          `${match.url}/requests/recruitment/pending`,
          `${match.url}/requests/recruitment/rejected`,
          `${match.url}/requests/recruitment/approved`,
        ]}
        component={Staff(AsyncRecruitment)}
      />,
      <Route
        key={`${match.url}/requests/recruitment/create&update`}
        exact
        strict
        path={[
          `${match.url}/requests/recruitment/create`,
          `${match.url}/requests/recruitment/update`,
        ]}
        component={Staff(AsyncUpsertRecruitment)}
      />,

      <Route
        key={`${match.url}/requests/leave/pending&rejected&approved`}
        exact
        strict
        path={[
          `${match.url}/requests/leave/pending`,
          `${match.url}/requests/leave/rejected`,
          `${match.url}/requests/leave/approved`,
        ]}
        component={Staff(AsyncLeave)}
      />,
      <Route
        key={`${match.url}/requests/leave/create&update`}
        exact
        strict
        path={[
          `${match.url}/requests/leave/create`,
          `${match.url}/requests/leave/update`,
        ]}
        component={Staff(AsyncUpsertLeave)}
      />,
      <Route
        key={`${match.url}/requests/leave/:id(\\d+)`}
        exact
        strict
        path={`${match.url}/requests/leave/:id(\\d+)`}
        component={OperatorAndAccountant(AsyncViewLeave)}
      />,

      <Route
        key={`${match.url}/requests/exit`}
        exact
        strict
        path={`${match.url}/requests/exit`}
        component={Staff(AsyncExit)}
      />,
      <Route
        key={`${match.url}/requests/create&update`}
        exact
        strict
        path={[
          `${match.url}/requests/exit/create`,
          `${match.url}/requests/exit/update`,
        ]}
        component={Staff(AsyncUpsertExit)}
      />,
    ];

    return (
      <div className="main-app-container">
        <MainComponentCalls />
        {isCustomer(user) && <SurveysModel />}

        <Sidenav />
       {
          !_.isEmpty(activeInternalBanner) &&
          (isOperator(this.props.user) || isAccountant(this.props.user))&&
          localStorage.getItem('is_internal_banner_viewed') != 'yes' ? (
            <InternalBanner activeInternalBanner={activeInternalBanner} />
          ) : null
        }

        <section id="page-container" className="app-page-container">
          <Header />
          {!_.isEmpty(activeBanner) &&
          isCustomer(user) &&
          localStorage.getItem('is_banner_viewed') != 'yes' ? (
            <Banner activeBanner={activeBanner} />
          ) : null}
          <div className="app-content-wrapper">
            <div className="app-content">
              <div className="full-height">
                <Switch>
                  {process.env.REACT_APP_IS_SHOPIFY !== 'true' &&
                    routes.map((r) => r)}

                  <Route
                    exact
                    strict
                    path={`${match.url}/notifications`}
                    component={User(AsyncNotification)}
                  />
                  <Route
                    exact
                    strict
                    path={`${match.url}/profile`}
                    component={User(AsyncProfileDetails)}
                  />
                  <Route
                    exact
                    strict
                    path={`${match.url}/settings`}
                    component={CustomerAndOperator(AsyncSettings)}
                  />
                  <Route
                    exact
                    strict
                    path={`${match.url}/third_party/shopify/:id(\\d+)/newest`}
                    types={{ id: Number }}
                    component={Customer(AsyncShopify)}
                  />
                  <Route
                    exact
                    strict
                    path={`${match.url}/third_party/shopify/:id(\\d+)`}
                    types={{ id: Number }}
                    component={Customer(AsyncShopify)}
                  />
                  <Route
                    exact
                    strict
                    path={`${match.url}/third_party/shopify/:id(\\d+)/shipped`}
                    types={{ id: Number }}
                    component={Customer(AsyncShopify)}
                  />

                  <Redirect
                    to={_.isEmpty(user) || user == {} ? '/login' : '/404'}
                  />
                </Switch>
              </div>
            </div>
            <EnhancedSnackBar />
            <EnhancedPushNotification />
            <Footer />
          </div>
        </section>
      </div>
    );
  }
}

MainApp.propTypes = {
  activeBanner: PropTypes.array.isRequired,
  activeInternalBanner: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    activeBanner: state.banners.activeBanner,
    activeInternalBanner: state.internalBanners.activeInternalBanner,
  };
};

export default connect(mapStateToProps)(MainApp);
