/**
 * Create a new me object
 * @param {int} id Id
 * @param {string} id_string Id int to string
 * @param {string} full_name Full name
 */
export function me(props) {
  return {
    full_name: props.contact.first_name + " " + props.contact.last_name,
    id: props.id,
    is_active: props.is_active,
    email: props.email,
    phone_number: props.phone_number,
    start_date: props.start_date,
    language_type: props.language_type,
    currencies: props.currencies,
    contact: props.contact
      ? {
          id: props.contact.id,
          first_name: props.contact.first_name,
          last_name: props.contact.last_name,
          phone_number: props.contact.phone_number,
          date_of_birth: props.contact.date_of_birth,
          gender: props.contact.gender,
          customer_id: props.contact.customer_id,
          is_active: props.contact.is_active
        }
      : null,
    roles: props.roles ? props.roles : [],
    time_sheet_status: props.time_sheet_status,
    office: props.office
      ? {
          id: props.office.id,
          name: props.office.name
        }
      : null,
    customer: props.customer
      ? {
          id: props.customer.id,
          golden_rule: props.customer.golden_rule,
          shop_note: props.customer.shop_note,
          quality_check: props.customer.quality_check,
          note: props.customer.note,
          is_active: props.customer.is_active,
          deactivate_reason: props.customer.deactivate_reason,
          type: props.customer.type,
          type_id: props.customer.type_id,
          payment_method: props.customer.payment_method,
          users: props.customer.users,
          subscription_type: props.customer.subscription,
          name: props.customer.name,
          shop_name: props.customer.shop_name,
          image_id: props.customer.image_id,
          image: props.customer.image,
          shop_phone_number: props.customer.shop_phone_number,
          phone_number: props.customer.phone_number,
          mof: props.customer.mof,
          vat: props.customer.vat,
          accounting_ref: props.customer.accounting_ref,
          email_notifications: props.customer.email_notifications,
          show_items_in_comments: props.customer.show_items_in_comments,
          rate_update_email_notifications:
            props.customer.rate_update_email_notifications,
          sms_notifications: props.customer.sms_notifications,
          default_address: props.customer.default_address,
          third_party_accounts: props.customer.third_party_accounts,
          third_party: props.customer.third_party,
          allow_push_notifications: props.allow_push_notifications,
          waybill_customizations: props.waybill_customizations
        }
      : null,
    driver: props.driver
      ? {
          id: props.driver.id,
          nationality: props.driver.nationality,
          color: props.driver.color,
          has_gps: props.driver.has_gps,
          has_internet: props.driver.has_internet,
          status: props.driver.status,
          status_code: props.driver.status_code,
          type: props.driver.type,
          now_driving: props.driver.nowDriving,
          is_active: props.driver.is_active
        }
      : null,
    devices: props.devices,
    hr_privilege: props.hr_privilege,
    supervisor_privilege: props.supervisor_privilege
  };
}
