import {
  FETCH_BARCODES_RESOLVED,
  UPDATE_BARCODE_RESOLVED,
  DELETE_BARCODE_RESOLVED
} from "../ActionTypes/barcodes";
import _ from "lodash";

const initialState = {
  barcodeList: []
};

const barcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BARCODE_RESOLVED: {
      let barcodeList = JSON.parse(JSON.stringify(state.barcodeList));
      _.remove(barcodeList, o => o.id == action.payload.id);
      return Object.assign({}, state, {
        barcodeList
      });
    }
    case FETCH_BARCODES_RESOLVED:
      return Object.assign({}, state, {
        barcodeList: action.payload
      });
    case DELETE_BARCODE_RESOLVED: {
      let barcodeList = JSON.parse(JSON.stringify(state.barcodeList));
      _.remove(barcodeList, o => o.id == action.payload.id);
      return Object.assign({}, state, {
        barcodeList
      });
    }
    default:
      return state;
  }
};

export default barcodeReducer;
