import * as cashboxesActions from "../stores/actions/cashbox";
import Http from "../utils/Http";
import { cashbox } from "stores/models/Cashbox";
import { cashbox_extra_fee } from "stores/models/CashboxExtraFee";
import { serializeSearchInput, serializeInput } from "utils/helpers";

import * as authActions from "../stores/actions/auth";

export function fetchCashboxes(pageNumber = 1, input = [], other_input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `cashboxes${input.length > 0 ? serializeSearchInput(input) : ""}${
        other_input.length > 0 ? (input.length > 0 ? "&" : "?") : ""
      }${serializeInput(other_input)}`;
      Http.get(
        url +
          (pageNumber
            ? (input.length > 0 || other_input.length > 0 ? "&" : "?") +
              "page=" +
              pageNumber
            : ""),
      )
        .then((res) => {
          let cashboxes = [];
          res.data.data.forEach((element) => {
            cashboxes.push(cashbox(element));
          });
          dispatch(cashboxesActions.fetchCashboxesResolved(cashboxes));
          return resolve(res.data);
        })
        .catch((error) => {
          dispatch(cashboxesActions.fetchCashboxesFailed(error));
          dispatch(authActions.serverError(error.error));
          return reject(error);
        });
    });
}
export function fetchCashboxDetails(cashboxId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `cashbox-settings/${cashboxId}`;
      Http.get(url)
        .then((res) => {
          const data = res.data;
          if (data.cashbox) {
            dispatch(
              cashboxesActions.fetchCashboxDetailsResolved(data.cashbox),
            );
          } else {
            dispatch(cashboxesActions.fetchCashboxDetailsResolved({}));
          }
          return resolve(data);
        })
        .catch((error) => {
          dispatch(cashboxesActions.fetchCashboxDetailsFailed(error));
          dispatch(authActions.serverError(error.error));
          return reject(error);
        });
    });
}


export function exportCashboxes(input = []) {
  const urlBase = `cashboxes/export`;
  let url = urlBase;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(url, input)
        .then((res) => {
          dispatch(authActions.serverSuccess(res.data.message));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function exportMultipleCashboxDetailsPdf(cashboxIds, fileName) {
  const urlBase = "cashboxes/pdf-multiple-export";
  let url = urlBase;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(url, { cashboxIds }, { responseType: 'blob' })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a'); 
          link.href = url;
          link.setAttribute('download', 'multiple_' + fileName + '.pdf');
          document.body.appendChild(link);
          link.click();
          dispatch(authActions.serverSuccess('PDF downloaded successfully.'));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError('Failed to generate PDF'));
          return reject(err);
        });
    });
}
export function exportCashboxDetailsPdf(cashboxId, fileName) {
  const urlBase = "cashboxes/pdf-export/" + cashboxId;
  let url = urlBase;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(url, {}, { responseType: 'blob' })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'cashbox_report_' + fileName + '.pdf');
          document.body.appendChild(link);
          link.click();
          dispatch(authActions.serverSuccess('PDF downloaded successfully.'));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError('Failed to generate PDF'));
          return reject(err);
        });
    });
}
export function exportCashboxDetailsExcel(cashboxId, fileName) {
  const urlBase = "cashboxes/export/excel/" + cashboxId;
  let url = urlBase;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(url, {}, { responseType: 'blob' })
        .then((res) => {
        
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError('Failed to generate Excel'));
          return reject(err);
        });
    });
}
export function fetchCashbox(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("cashboxes/" + id)
        .then(res => {
          dispatch(cashboxesActions.fetchCashboxResolved(cashbox(res.data.data)));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}


export function updateCashbox(input, remove = false) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put("cashboxes/" + input.id, input.data)
        .then((res) => {
          dispatch(
            cashboxesActions.updateCashboxResolved(
              cashbox(res.data.data),
              remove,
            ),
          );
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          if (err.errors) {
            const validationErrors = err.errors;
            const errorMessages = Object.values(validationErrors)
              .flat()
              .join("; ");
            err.error = errorMessages;
            dispatch(authActions.serverError(errorMessages));
          } else {
            dispatch(authActions.serverError(err.error));
          }
          return reject(err);
        });
    });
}

export function updateCashboxSettlement(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put("cashbox-details/" + input.cashbox_id, input)
        .then((res) => {
          dispatch(cashboxesActions.updateSettlementResolved(cashbox(res.data.data)));

          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          if (err.errors) {
            const validationErrors = err.errors;
            const errorMessages = Object.values(validationErrors)
              .flat()
              .join("; ");
            err.error = errorMessages;
            dispatch(authActions.serverError(errorMessages));
          } else {
            dispatch(authActions.serverError(err.error));
          }
          return reject(err);
        });
    });
}
export function addExtraFees(input, remove = false) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("cashboxes/" + input.id + "/add-extra-fees", input.data)
        .then((res) => {
          let cashbox_extra_fees = [];
          res.data.data.forEach((element) => {
            cashbox_extra_fees.push(cashbox_extra_fee(element));
          });

          dispatch(
            cashboxesActions.addCashboxExtraFeesResolved(cashbox_extra_fees),
          );
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        }).catch((err) => {
          if (err.errors) {
            const validationErrors = err.errors;
            const errorMessages = Object.values(validationErrors)
              .flat()
              .map((error) => {
                const messageParts = error.split(".");

                const message = messageParts[messageParts.length - 2];
                return message;
              })
              .join("; ");

            err.error = errorMessages;
            dispatch(authActions.serverError(errorMessages));
          } else {
            dispatch(authActions.serverError(err.error));
          }
          return reject(err);
        });
    });
}

export function fetchCashboxExtraFeesHistory(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("cashboxes/" + id + "/extra-fees-history")
        .then(res => {
          dispatch(cashboxesActions.fetchCashboxExtraFeesHistory(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchCashboxHistory(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("cashboxes/" + id + "/cashbox-history")
        .then(res => {
          dispatch(cashboxesActions.fetchCashboxHistory(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));

          return reject(err);
        });
    });
}

export function cancelCashbox(input, remove = false) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put("cashboxes/" + input.id + "/cancel")
        .then((res) => {
          dispatch(
            cashboxesActions.updateCashboxResolved(
              cashbox(res.data.data),
              remove,
            ),
          );
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta.message);
        })
        .catch((err) => {
          if (err.errors) {
            const validationErrors = err.errors;
            const errorMessages = Object.values(validationErrors)
              .flat()
              .join("; ");
            err.error = errorMessages;
            dispatch(authActions.serverError(errorMessages));
          } else {
            dispatch(authActions.serverError(err.error));
          }
          return reject(err);
        });
    });
}

export function fetchCashboxExtraFee(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("cashboxes/extra-fees/" + id)
        .then(res => {
          dispatch(cashboxesActions.fetchCashboxExtraFeeResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));

          return reject(err);
        });
    });
}

export function updateCashboxExtraFee(input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("cashboxes/extra-fees/" + input.id, input)
        .then(res => {
          dispatch(cashboxesActions.updateCashboxExtraFeeResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function createCashboxOrders(input){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("cashboxes/create-multiple-orders", input)
        .then((res) => {
         let cashboxes = [];
         res.data.cashboxes.forEach((element) => {
           cashboxes.push(cashbox(element));
         });

         dispatch(cashboxesActions.createMultipleOrdersResolved(cashboxes));
         dispatch(authActions.serverSuccess(res.data.meta.message));
         return resolve(res.data.meta.message);
        }).catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    }); 
}
export function cancelCashboxes(input){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("cashboxes/cancel", input)
        .then((res) => {
         let cashboxes = [];
         res.data.cashboxes.forEach((element) => {
           cashboxes.push(cashbox(element));
         });

         dispatch(cashboxesActions.cancelCashboxesResolved(cashboxes));
         dispatch(authActions.serverSuccess(res.data.meta.message));
         return resolve(res.data.meta.message);
        }).catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    }); 
}
export function addCashbox(input){
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("cashboxes/add-manual", input)
        .then((res) => {
         dispatch(cashboxesActions.AddCashboxResolved(res.data.data));
         dispatch(authActions.serverSuccess(res.data.meta.message));
         return resolve(res.data.meta.message);
        }).catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    }); 
}