/* import React from "react";
import PropTypes from "prop-types";
import * as authActions from "stores/actions/auth";
import * as orderService from "services/orderService";
import * as ordersActions from "stores/actions/orders";
import Sound from "react-sound";
import { connect } from "react-redux";

class EnhancedPushNotification extends React.Component {
  componentWillUpdate(nextProps) {
    if (nextProps.isNewNotification == true) {
      this.timer = setTimeout(() => {
        this.props.dispatch(authActions.turnNotificationSoundOff());
      }, 4000);
    }
  }

  render() {
    const { isNewNotification } = this.props;
    return (
      <Sound
        url="assets/notification.mp3"
        playStatus={
          isNewNotification ? Sound.status.PLAYING : Sound.status.STOPPED
        }
        volume={100}
      />
    );
  }
}

EnhancedPushNotification.propTypes = {
  isNewNotification: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isNewNotification: state.auth.isNewNotification
  };
};

export default connect(mapStateToProps)(EnhancedPushNotification);
 */
import React from "react";
import PropTypes from "prop-types";
import * as authActions from "stores/actions/auth";
import { connect } from "react-redux";
import Howler from 'react-howler';
import notification from "../../assets/notification.mp3";

class EnhancedPushNotification extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.isNewNotification && !prevProps.isNewNotification) {
      this.timer = setTimeout(() => {
        this.props.dispatch(authActions.turnNotificationSoundOff());
      }, 4000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { isNewNotification } = this.props;
    return (
      <Howler
        src={notification}
        playing={isNewNotification}
        volume={1}
      />
    );
  }
}

EnhancedPushNotification.propTypes = {
  isNewNotification: PropTypes.bool
};

const mapStateToProps = state => ({
  isNewNotification: state.auth.isNewNotification
});

export default connect(mapStateToProps)(EnhancedPushNotification);
