/**
 * Create a new task object
 * @param {int} id Id
 * @param {object} location Location
 * @param {int} order_id Order Id
 * @param {object} receiverable Receiver data
 * @param {int} sequence Sequence
 * @param {int} status ID of zone
 * @param {int} typeId ID of type
 */
export function task(props) {
  return {
    id: props.id,
    location: props.location,
    full_location: props.full_location,
    settled: props.settled,
    fail_reason: props.fail_reason,
    order_id: props.order ? props.order.id : props.order_id,
    order: props.order,
    receiverable: props.receiver,
    receiver: props.receiver,
    require_signature: props.require_signature,
    require_picture: props.require_picture,
    signature: props.signature,
    picture: props.picture,
    deliver_package_json: props.deliver_package_json,
    receive_package_json: props.receive_package_json,
    sequence: props.sequence,
    overall_sequence: props.overall_sequence,
    preferred_date: props.preferred_date,
    preferred_from_time: props.preferred_from_time,
    preferred_to_time: props.preferred_to_time,
    status: props.status,
    status_code: props.status_code,
    driver: props.driver,
    customer: props.customer,
    customer_id: props.customer ? props.customer.id : props.customer_id,
    customer_type: props.customer_type,
    type: props.type,
    receive_money: props.receive_money,
    relative_receive_money: props.relative_receive_money,
    relativeCurrency: props.relativeCurrency,
    deliver_money: props.deliver_money,
    receive_price: props.receive_price,
    deliver_amount: props.deliver_amount,
    receive_amount: props.receive_amount,
    show_receive_money: props.show_receive_money,
    type_id: props.type_id,
    amount_currency: props.amount_currency,
    amountCurrency: props.amountCurrency,
    priceCurrency: props.priceCurrency,
    receive_package: props.receive_package,
    deliver_package: props.deliver_package,
    note: props.note,
    collection_note: props.collection_note,
    collections: props.collections,
    collection_date: props.collection_date,
    alternative_collection_currencies: props.alternative_collection_currencies,
    is_rejected: props.is_rejected? props.is_rejected: false,
    alternative_currencies: props.alternative_currencies
  };
}
