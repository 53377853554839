import React from 'react';
import APPCONFIG from 'constants/Config';
import FlatButton from '@mui/material/Button';
import TextField from 'components/EnhancedTextField';
import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';
import ReeValidate from 'ree-validate';
import * as settingService from '../../services/settingService';
import CircularProgress from '@mui/material/CircularProgress';
import EnhancedSnackBar from 'components/EnhancedSnackBar';
import { HotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';
import { SHOPIFY_APP_ID } from 'constants/Data';
import { push } from 'connected-react-router';

class ShopifyLogin extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.validator = new ReeValidate({
      shop: 'required|regex:.myshopify.com$',
    });

    this.state = {
      brand: APPCONFIG.brand,
      redirectToShopify: false,
      data: {
        shop: '',
        app_id: SHOPIFY_APP_ID,
        customer_id: props.user.customer.id,
        access_token: '',
        code: '',
        country_code: null,
        extra: null,
        auto_restock: null,
        auto_refund: null,

        order_auto_cancelation: true,
      },
      responseError: {
        isError: false,
        code: '',
        text: '',
      },
      isLoading: false,
      errors: this.validator.errors,
    };
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const { data } = this.state;
    data[name] = value;

    this.setState({
      ...this.state,
      data,
    });
  };

  setLoading = (isLoading) => {
    this.setState({
      ...this.state,
      isLoading: isLoading,
    });
  };

  keyMap = {
    SUBMIT: 'enter',
  };

  handlers = {
    SUBMIT: (event) => {
      this.handleSubmit();
    },
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const { data } = this.state;
    this.validator.validateAll(data).then((success) => {
      if (success) {
        this.submit(data);
      } else {
        const { errors } = this.validator;
        this.setState({ errors: errors, data });
      }
    });
  };

  submit = (data) => {
    this.setLoading(true);
    let input = {
      ...data,
    };

    this.props
      .dispatch(settingService.createToken(input))
      .then((data) => {
        const responseError = {
          isError: false,
          code: '',
          text: '',
        };
        this.setState({ responseError });
        this.setLoading(false);
        this.redirectToShopify(data);
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error,
        };
        this.setState({ responseError });
        this.setLoading(false);
      });
  };

  checkAuth = () => {
    if (!this.props.isAuthenticated) {
      this.props.dispatch(push('/login'));
    }
  };

  componentDidMount() {
    this.checkAuth();
  }

  redirectToShopify = (data) => {
    this.setState({ ...this.state, redirectToShopify: true });
    window.location.href = data.redirect;
  };

  render() {
    const { errors, redirectToShopify } = this.state;

    return (
      <div className="page-login">
        <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <div className="main-body">
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              <div className="body-inner">
                <div className="card bg-white">
                  <div className="card-content">
                    <section className="logo">
                      <h3 className="text-center">Shopify Login</h3>
                      {this.state.responseError.isError && (
                        <p
                          className="text-center m-b-3"
                          style={{ color: 'red' }}
                        >
                          {this.state.responseError.text}
                        </p>
                      )}
                      {redirectToShopify ? (
                        <div className="text-center">
                          <CircularProgress />
                        </div>
                      ) : null}
                    </section>
                    <form className="form-horizontal">
                      <fieldset>
                        <div className="form-group">
                          <TextField
                            name="shop"
                            value={this.state.data.shop}
                            floatingLabelText="Shop URL (ex: xxx.myshopify.com)"
                            errorText={
                              errors.has('shop') && errors.first('shop')
                            }
                            onChange={this.handleChange}
                            fullWidth
                          />
                        </div>
                      </fieldset>
                    </form>
                  </div>
                  <div className="card-action no-border text-right">
                    {this.state.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <FlatButton
                        variant="text"
                        label="Login"
                        color="primary"
                        onClick={this.handleSubmit}
                      >Login</FlatButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </QueueAnim>
        </div>
        </HotKeys>
        <EnhancedSnackBar />
      </div>
    );
  }
}

ShopifyLogin.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(ShopifyLogin);
