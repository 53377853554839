import React from "react";
import marketplaceIcon from "../../assets/images/marketplaceicon.jpg";

class RedirectToApp extends React.Component {
  componentDidMount() {
    const userAgent = navigator.userAgent.toLowerCase();
    const playStoreLink =
      "https://play.google.com/store/apps/details?id=com.wakilni.customer";
    const appStoreLink =
      "https://apps.apple.com/app/wakilni-customer/id1613026444";
    const appLink = `wakilni://marketplace/item/${this.props.match.params.id}`;

    const isInstagram = userAgent.includes("instagram");
    const isFacebook = userAgent.includes("fbav");
    const isTwitter = userAgent.includes("twitter");
    const isSnapchat = userAgent.includes("snapchat");
    const isTikTok = userAgent.includes("tiktok");

    const isAndroid = /android/.test(userAgent);
    const isIOS = /iphone|ipad|ipod/.test(userAgent);

    if (isInstagram || isFacebook || isTwitter || isSnapchat || isTikTok) {
      this.showFallbackMessage(
        isAndroid,
        isIOS,
        playStoreLink,
        appStoreLink,
        isFacebook,
      );
    } else if (isAndroid) {
      window.location.href = `intent://portal.wakilni.com/marketplace/item/${
        this.props.match.params.id
      }#Intent;scheme=https;package=com.wakilni.customer;S.browser_fallback_url=${encodeURIComponent(
        playStoreLink,
      )};end`;
    } else if (isIOS) {
      window.location.assign(appLink);
      setTimeout(() => {
        if (document.visibilityState !== "hidden") {
          window.location.assign(appStoreLink);
        } else {
          console.log("App opened successfully");
        }
      }, 3000);
    }
  }

  showFallbackMessage(
    isAndroid,
    isIOS,
    playStoreLink,
    appStoreLink,
    isFacebook,
  ) {
    const messageDiv = document.createElement("div");
    messageDiv.style.cssText =
      "position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; background-color: white; z-index: 9999;";
    let innerHTMLContent = `
    <div style="width: 100%; padding-top: 150px;  box-sizing: border-box; display: flex;  justify-content: flex-start; align-items: flex-start; flex-direction: column; padding-left: 30px;">
      <img src="${marketplaceIcon}" alt="Marketplace Logo" style="width: 70%; height: 70%;  margin-bottom: 20px;  padding: 0;">
      <h3 style="font-size: 20px; margin: 0; padding: 0; text-align: left; color: #152238;">Welcome To Wakilni Marketplace!</h3>
    </div>
    `;

    if (isIOS) {
      innerHTMLContent += `
        <div style="padding: 30px; flex-grow: 1; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <p style="font-size: 20px; font-weight: bold; color: #152238;">Please follow these steps to open the product link:</p>
          <ol style="list-style: none; padding: 0;">
            <li style="margin: 10px 0; display: flex; align-items: center;">
              <div style="background: #152238; color: white; width: 40px; height: 40px; line-height: 40px; border-radius: 50%; text-align: center; font-size: 20px; margin-right: 10px;">01</div>
              <span style="font-size: 14px; color: #152238;">${
                isFacebook
                  ? "Tap share icon in bottom right or 3 dots in top right"
                  : "Tap the three dots menu in the top-right corner"
              }</span>
            </li>
            <li style="margin: 10px 0; display: flex; align-items: center;">
              <div style="background: #152238; color: white; width: 40px; height: 40px; line-height: 40px; border-radius: 50%; text-align: center; font-size: 20px; margin-right: 10px;">02</div>
              <span style="font-size: 14px; color: #152238;">${
                isFacebook
                  ? 'Select "Open in Safari"'
                  : 'Select "Open in External Browser"'
              }</span>
            </li>
          </ol>
          <br>
          <div style="text-align: center; margin-top: 40px;">
            <p style="font-size: 16px; font-weight: bold; color: #152238; margin-top: 20px;">Don’t have the app installed?</p>
            <button onclick="window.location.href='${appStoreLink}';" style="font-size: 20px; background-color: #152238; color: white; border: none; border-radius: 20px; padding: 15px 30px; cursor: pointer;">Open in App Store</button>
          </div>
        </div>
      `;
    }

    if (isAndroid) {
      innerHTMLContent += `
        <div style="padding: 30px; flex-grow: 1; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <p style="font-size: 20px; font-weight: bold; color: #152238;">Please follow these steps to open the link:</p>
          <ol style="list-style: none; padding: 0;">
            <li style="margin: 10px 0; display: flex; align-items: center;">
              <div style="background: #152238; color: white; width: 40px; height: 40px; line-height: 40px; border-radius: 50%; text-align: center; font-size: 20px; margin-right: 10px;">01</div>
              <span style="font-size: 14px; color: #152238;">${
                isFacebook
                  ? "Tap share icon in bottom right or 3 dots in top right"
                  : "Tap the three dots menu in the top-right corner"
              }</span>
            </li>
            <li style="margin: 10px 0; display: flex; align-items: center;">
              <div style="background: #152238; color: white; width: 40px; height: 40px; line-height: 40px; border-radius: 50%; text-align: center; font-size: 20px; margin-right: 10px;">02</div>
              <span style="font-size: 14px; color: #152238;">${
                isFacebook
                  ? 'Select "Open in Chrome"'
                  : 'Select "Open in External Browser"'
              }</span>
            </li>
          </ol>
          <br>
          <div style="text-align: center; margin-top: 40px;">
            <p style="font-size: 16px; font-weight: bold; color: #152238; margin-top: 20px;">Don’t have the app installed?</p>
            <button onclick="window.location.href='${playStoreLink}';" style="font-size: 20px; background-color: #152238; color: white; border: none; border-radius: 20px; padding: 15px 30px; cursor: pointer;">Open in Play Store</button>
          </div>
        </div>
      `;
    }

    messageDiv.innerHTML = innerHTMLContent;
    document.body.appendChild(messageDiv);
  }

  render() {
    return <div>Please wait, redirecting...</div>;
  }
}

export default RedirectToApp;
