import {
  FETCH_CUSTOMERS_RESOLVED,
  FETCH_CUSTOMER_RESOLVED,
  UPDATE_CUSTOMER_RESOLVED,
  CREATE_CONTACT_RESOLVED,
  EDIT_CONTACT_RESOLVED,
  DELETE_CONTACT_RESOLVED,
  FETCH_RECIPIENTS_RESOLVED,
  EDIT_RECIPIENT_RESOLVED,
  FETCH_PRICES_RESOLVED,
  EDIT_PRICE_RESOLVED,
  DELETE_PRICE_RESOLVED,
  NEW_PRICE_RESOLVED,
  FETCH_PIGGYBANKS_RESOLVED,
  FETCH_PIGGYBANK_TASKS_RESOLVED,
  UPDATE_PIGGYBANK_RESOLVED,
  UPDATE_PIGGYBANK_List,
  CHOSEN_CUSTOMER,
  FETCH_CONTACTS_RESOLVED,
  FETCH_CUSTOMERS_NAMES_LIST_RESOLVED,
  UPDATE_TASK_COLLECTIONS_RESOLVED,
  FETCH_ACTIVE_CUSTOMERS_RESOLVED,
  UPDATE_CUSTOMER_NAME_RESOLVED,
  FETCH_AUTOCOMPLETE_CUSTOMERS_RESOLVED,
  FETCH_SHOP_NOTES_RESOLVED,
  CREATE_SHOP_NOTE_RESOLVED,
  DELETE_SHOP_NOTE_RESOLVED,
  UPDATE_PIGGYBANKS_RESOLVED,
  FETCH_CUSTOMER_CURRENCIES,
  CREATE_CUSTOMER_CURRENCY_RESOLVED,
  UPDATE_CUSTOMER_CURRENCY_RESOLVED,
  DELETE_CUSTOMER_CURRENCY_RESOLVED
} from "../ActionTypes/customers";
import _ from "lodash";

const initialState = {
  customerList: [],
  customer: {},
  recipientList: [],
  priceList: [],
  piggyBankList: [],
  piggyBankTasks: [],
  contactList: [],
  ChosenCustomer: null,
  customersNames: [],
  activeCustomers: [],
  autocompleteCustomerList: [],
  currencies: [],
  shopNotesHistory: []
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    //CURRENCIES
    case FETCH_CUSTOMER_CURRENCIES:
      return Object.assign({}, state, {
        currencies: action.payload
      });

    case CREATE_CUSTOMER_CURRENCY_RESOLVED: {
      let currencies = JSON.parse(JSON.stringify(state.currencies));
      currencies.push(action.payload);
      return Object.assign({}, state, {
        currencies: currencies
      });
    }

    case UPDATE_CUSTOMER_CURRENCY_RESOLVED: {
      let currencies = JSON.parse(JSON.stringify(state.currencies));
      currencies.forEach((item, index) => {
        if (item.id == action.payload.id) {
          currencies[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        currencies: currencies
      });
    }
    case DELETE_CUSTOMER_CURRENCY_RESOLVED: {
      let currencies = JSON.parse(JSON.stringify(state.currencies));
      currencies.forEach((item, index) => {
        if (item.id == action.payload.data.currency.id) {
          currencies.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        currencies: currencies
      });
    }
    case FETCH_ACTIVE_CUSTOMERS_RESOLVED:
      return Object.assign({}, state, {
        activeCustomers: action.payload
      });
    case FETCH_CUSTOMERS_NAMES_LIST_RESOLVED:
      return Object.assign({}, state, {
        customersNames: action.payload
      });
    case FETCH_CONTACTS_RESOLVED:
      return Object.assign({}, state, {
        contactList: action.payload
      });
    case CHOSEN_CUSTOMER:
      return Object.assign({}, state, {
        ChosenCustomer: action.payload
      });
    case UPDATE_PIGGYBANK_RESOLVED: {
      let piggyBanks = JSON.parse(JSON.stringify(state.piggyBankList));
      piggyBanks.forEach((item, index) => {
        if (item.id == action.payload.id) {
          piggyBanks[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        piggyBankList: piggyBanks
      });
    }
    case UPDATE_PIGGYBANK_List: {
      let piggyBanks = JSON.parse(JSON.stringify(state.piggyBankList));
      _.remove(piggyBanks, function(p) {
        return p.id == action.piggyBank_id;
      });
      piggyBanks.unshift(action.payload);
      return Object.assign({}, state, {
        piggyBankList: piggyBanks
      });
    }
    case UPDATE_PIGGYBANKS_RESOLVED: {
      let piggyBanks = JSON.parse(JSON.stringify(state.piggyBankList));
      action.payload.map(item => {
        let index = _.findIndex(piggyBanks, function(o) {
          return o.id == item.id;
        });
        if (index >= 0) {
          piggyBanks[index] = item;
        } else {
          piggyBanks.push(item);
        }
      });
      return Object.assign({}, state, {
        piggyBankList: piggyBanks
      });
    }
    case FETCH_PIGGYBANKS_RESOLVED:
      return Object.assign({}, state, {
        piggyBankList: action.payload
      });
    case FETCH_PIGGYBANK_TASKS_RESOLVED:
      return Object.assign({}, state, {
        piggyBankTasks: action.payload
      });
    case NEW_PRICE_RESOLVED: {
      let prices = JSON.parse(JSON.stringify(state.priceList));
      prices.push(action.payload);
      return Object.assign({}, state, {
        priceList: prices
      });
    }
    case DELETE_PRICE_RESOLVED: {
      let prices = JSON.parse(JSON.stringify(state.priceList));
      let new_prices = _.remove(prices, function(o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        priceList: prices
      });
    }
    case EDIT_PRICE_RESOLVED: {
      let prices = JSON.parse(JSON.stringify(state.priceList));
      prices.forEach((item, index) => {
        if (item.id == action.payload.id) {
          prices[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        priceList: prices
      });
    }
    case FETCH_PRICES_RESOLVED:
      return Object.assign({}, state, {
        priceList: action.payload
      });
    case FETCH_CUSTOMERS_RESOLVED:
      return Object.assign({}, state, {
        customerList: action.payload
      });
    case UPDATE_CUSTOMER_RESOLVED: {
      let customers = JSON.parse(JSON.stringify(state.customerList));
      let customer = JSON.parse(JSON.stringify(state.customer));
      if (customers.length > 0) {
        customers.forEach((item, index) => {
          if (item.id == action.payload.id) {
            customers[index] = action.payload;
          }
        });
      }

      if (customer) {
        customer = action.payload;
      }

      return Object.assign({}, state, {
        customerList: customers,
        customer
      });
    }
    case DELETE_CONTACT_RESOLVED: {
      let customer = JSON.parse(JSON.stringify(state.customer));
      let users = _.remove(customer.users, function(o) {
        return o.id == action.payload.id;
      });

      return Object.assign({}, state, {
        customer
      });
    }
    case CREATE_CONTACT_RESOLVED: {
      let customer = JSON.parse(JSON.stringify(state.customer));
      customer.users.push(action.payload);
      return Object.assign({}, state, {
        customer
      });
    }
    case EDIT_CONTACT_RESOLVED: {
      let customer = JSON.parse(JSON.stringify(state.customer));
      customer.users.forEach((item, index) => {
        if (item.id == action.payload.id) {
          customer.users[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        customer
      });
    }
    case EDIT_RECIPIENT_RESOLVED: {
      let recipients = JSON.parse(JSON.stringify(state.recipientList));
      recipients.forEach((item, index) => {
        if (item.id == action.payload.id) {
          recipients[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        recipientList: recipients
      });
    }
    case FETCH_CUSTOMER_RESOLVED:
      return Object.assign({}, state, {
        customer: action.payload
      });
    case FETCH_RECIPIENTS_RESOLVED:
      return Object.assign({}, state, {
        recipientList: action.payload
      });
    case FETCH_AUTOCOMPLETE_CUSTOMERS_RESOLVED:
      return Object.assign({}, state, {
        autocompleteCustomerList: action.payload.data
      });

    case FETCH_SHOP_NOTES_RESOLVED:
        return Object.assign({}, state, {
          shopNotesHistory: action.payload
      });

    case CREATE_SHOP_NOTE_RESOLVED: {
      let customer = JSON.parse(JSON.stringify(state.customer));
      customer.shop_note = action.payload.shop_note
        return Object.assign({}, state, {
          customer
        });
    }

    case DELETE_SHOP_NOTE_RESOLVED: {
      let customer = JSON.parse(JSON.stringify(state.customer));
      customer.shop_note = null
        return Object.assign({}, state, {
          customer
        });
    }

    case UPDATE_TASK_COLLECTIONS_RESOLVED: {
      let piggyBankTasks = JSON.parse(JSON.stringify(state.piggyBankTasks));
      let task_index = _.findIndex(piggyBankTasks, function(o) {
        return o.id == action.task_id;
      });
      if (task_index >= 0) {
        piggyBankTasks[task_index].collections = action.payload.collections;
      }

      return Object.assign({}, state, {
        piggyBankTasks
      });

    // var piggyBankList = JSON.parse(JSON.stringify(state.piggyBankList));
    // var piggy_id = _.findIndex(piggyBankList, function(o) { return o.id == action.piggybank_id; })
    // if(piggy_id >= 0) {
    //   var task_index = _.findIndex(piggyBankList[piggy_id].tasks, function(o) { return o.id == action.payload.id; })
    //   if(task_index >= 0) {
    //     piggyBankList[piggy_id].tasks[task_index].collections = action.payload.collections
    //   }
    // }
    // return Object.assign({}, state, {
    //   piggyBankList
    // });
    }

    case UPDATE_CUSTOMER_NAME_RESOLVED: {
      let customer = JSON.parse(JSON.stringify(state.customer));
      customer["name"] = action.payload.name;
      return Object.assign({}, state, {
        customer
      });
    }

    default:
      return state;
  }
};

export default customerReducer;
