import * as types from '../ActionTypes/third-party';

export function fetchShopifyOrders(payload) {
  return { type: types.FETCH_SHOPIFY_ORDERS, payload };
}

export function fetchWoocommerceOrders(payload) {
  return { type: types.FETCH_WOOCOMMERCE_ORDERS, payload };
}

export function createBulk(payload, thirdparty_type) {
  if (thirdparty_type == 'Shopify') {
    return { type: types.CREATE_SHOPIFY_BULK, payload };
  } else {
    return { type: types.CREATE_FEEL22_BULK, payload };
  }
}

export function fetchFeel22Orders(payload) {
  return { type: types.FETCH_FEEL22_ORDERS, payload };
}
