import React from 'react';
import AutoComplete from 'components/EnhancedAutoComplete';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchLocationsNames } from 'services/locationService';
import DatePicker from 'components/EnhancedDatePicker';
import TimePicker from 'components/EnhancedTimePicker';
import { NOTES, OTHER_NOTES } from 'constants/Data';
import TextField from 'components/EnhancedTextField';
import MenuItem from '@mui/material/MenuItem';
import SelectField from 'components/EnhancedSelectField';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

const mapStateToProps = (state) => {
  return {
    typeList: state.data.typeList,
    locationList: state.locations.locationsNamesList,
  };
};

export const SelectDriver = (props) => {
  const { name, value_id, value, list, handleChange } = props;

  let handleAutoCompleteChange = (chosenRequest) => {
    if (chosenRequest && chosenRequest.id) {
      handleChange(name, chosenRequest.full_name);
      handleChange(name + '_id', chosenRequest.id);
    }
  };

  let handleOnUpdateInput = (searchText, dataSource) => {
    var chosenRequest = dataSource.filter(
      (elem) => _.toLower(elem.full_name) == _.toLower(searchText),
    );
    handleChange(name, searchText);
    handleChange(
      name + '_id',
      chosenRequest.length > 0 ? chosenRequest[0].id : null,
    );
  };

  const driverConfig = {
    text: 'full_name',
    value: 'id',
  };

  return (
    <AutoComplete
      name={name}
      floatingLabelText="Assign Driver"
      searchText={value}
      value={value_id}
      onNewRequest={(event, newValue) =>
        handleAutoCompleteChange(newValue)
      }
      onUpdateInput={(event, newInputValue) =>
        handleOnUpdateInput(newInputValue, list)
      }
      dataSource={list}
      dataSourceConfig={driverConfig}
      openOnFocus
      fullWidth={true}
      getOptionLabel={(option) => option.full_name || ""}
      getOptionKey={(option) => option.id || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export const DatePickerComponent = (props) => {
  const { name, value, label, handleChange, minDate } = props;

  let handleDateChange = (newValue) => {
    handleChange(name, newValue);
  };

  return (
    <DatePicker
      floatingLabelText={label}
      value={value}
      onChange={handleDateChange}
      fullWidth={true}
      minDate={minDate}
    />
  );
};

export const TimePickerComponent = (props) => {
  const { name, value, label, handleChange } = props;

  let handleDateChange = (newValue) => {
    handleChange(name, newValue);
  };

  return (
    <TimePicker
      floatingLabelText={label}
      name={name}
      value={value}
      onChange={handleDateChange}
      autoOk={true}
      fullWidth={true}
    />
  );
};

export const SelectNote = (props) => {
  const {
    name,
    name_id,
    parent_value,
    value_id,
    label,
    label_id,
    handleChange,
    errors,
  } = props;

  let handleSelectChange = (value) => {
    handleChange(name_id, value);
    handleChange(
      name,
      value == OTHER_NOTES ? '' : NOTES.find((elem) => elem.id == value).label,
    );
  };

  let handleTextChange = (event) => {
    const value = event.target.value;
    handleChange(name, value);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <SelectField
          floatingLabelText={label_id}
          value={value_id}
          onChange={(event) =>
            handleSelectChange(event.target.value)
          }
          fullWidth={true}
        >
          {NOTES.map((item) => (
            <MenuItem key={item.id} value={item.id} >{item.label}</MenuItem>
          ))}
        </SelectField>
      </div>
      {value_id === OTHER_NOTES && (
        <div className="col-md-12">
          <TextField
            name={name}
            value={parent_value}
            floatingLabelText={label}
            errorText={errors.has(name) && errors.first(name)}
            onChange={(event) => handleTextChange(event)}
            fullWidth={true}
          />
        </div>
      )}
    </div>
  );
};

export const TextFieldComponent = (props) => {
  const { name, value, label, handleChange, errors, disabled } = props;

  let handleTextChange = (event) => {
    const input_value = event.target.value;
    handleChange(name, input_value);
  };

  return (
    <TextField
      name={name}
      floatingLabelText={label}
      value={value}
      errorText={errors.has('waybill') && errors.first('waybill')}
      onChange={(event) => handleTextChange(event)}
      disabled={disabled}
      fullWidth={true}
    />
  );
};

export const SelectFieldComponent = connect(mapStateToProps)((props) => {
  let {
    value,
    handleChange,
    name,
    input_label,
    typeList,
    typeList_name,
    text_attribute,
    disabled,
    alternativeCollectionCurrencies,
  } = props;

  const handleChangeValue = (event) => {
    handleChange(name, event.target.value);
  };

  return (
    <SelectField
      floatingLabelText={input_label}
      value={value}
      onChange={handleChangeValue}
      fullWidth={true}
      disabled={disabled}
    >
      {typeList &&
        typeList[typeList_name] &&
        typeList[typeList_name].map((itm) => (
          <MenuItem
            key={itm.id}
            value={itm.id}
            disabled={
              alternativeCollectionCurrencies
                ? !!alternativeCollectionCurrencies.find(
                    (c) => c.currency_id == itm.id,
                  )
                : false
            }
          >{itm[text_attribute]}</MenuItem>
        ))}
    </SelectField>
  );
});

export const CheckBoxComponent = (props) => {
  const { name, value, label, handleChange } = props;

  let handleCheck = (event, isInputChecked) => {
    handleChange(name, isInputChecked);
  };

  return (
    <FormControlLabel
    control={
      <Checkbox
        checked={value}
        onChange={(event, checked) => handleCheck(event, checked)}
      />
    }
    label={label}
  />
  );
};

export const PackagesComponent = (props) => {
  const { name, value, label, handleChange, errors } = props;

  let handleChangeInput = (input_name, input_value, key) => {
    const packages = value;
    packages[key][input_name] = input_value;
    handleChange(name, packages);
  };

  let addNewPackage = () => {
    const packages = value;
    var package_data = {
      new: true,
      quantity: '1',
      type_id: 58,
      trip_number: 1,
    };

    packages.push(package_data);
    handleChange(name, packages);
  };

  let removePackage = (index, key) => {
    const packages = value;

    packages.splice(key, 1);
    handleChange(name, packages);
  };

  return (
    <div className="row">
      <div className="col-md-2">
        <Tooltip
          id="tooltip-fab"
          title="Add Package"
          className="action_tooltip"
        >
          <IconButton onClick={addNewPackage}>
            {' '}
            <i className="material-icons yellow-icon">add_circle</i>{' '}
          </IconButton>
        </Tooltip>
        <p>
          <b> {label} </b>
        </p>
      </div>
      <div className="col-md-10">
        <div className="row">
          {value.map(
            (item, key) =>
              item.trip_number == 1 && (
                <div className="col-md-12" key={key}>
                  <div className="row">
                    <div className="col-md-5">
                      <SelectFieldComponent
                        name={'type_id'}
                        value={item.type_id}
                        input_label={'Package Type *'}
                        handleChange={(input_name, input_value) =>
                          handleChangeInput(input_name, input_value, key)
                        }
                        errors={errors}
                        typeList_name={'packageTypes'}
                        text_attribute={'label'}
                      />
                    </div>
                    <div className="col-md-5">
                      <TextField
                        name={'quantity'}
                        value={item.quantity}
                        floatingLabelText="Quantity *"
                        onChange={( event ) =>
                          handleChangeInput(event.target.name , event.target.value , key)
                        }
                        errorText={errors.has('quantity_quantity') && errors.first('quantity_quantity')}
                        fullWidth={true}
                      /> 
                    </div>
                    {value.length > 1 && (
                      <div className="col-md-2 remove-package-btn">
                        <Tooltip
                          id="tooltip-fab"
                          title="Delete Package"
                          className="action_tooltip"
                        >
                          <IconButton onClick={() => removePackage(key)}>
                            {' '}
                            <i className="material-icons red-icon">
                              remove_circle
                            </i>{' '}
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </div>
  );
};

class SelectReceiverSender extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {

    this.loadLocations();
  }

  loadLocations = (searchText = null) => {
    this.setState((state) => ({ isLoading: true }));
    var input = [{ key: 'customer_id', element: this.props.customer_id }];
    if (searchText) {
      input.push({ key: 'name', element: searchText });
    } else {
      input.push({ key: 'just_customer', element: true });
    }

    this.props
      .dispatch(fetchLocationsNames(input))
      .then((data) => {
        this.setState((state) => ({ isLoading: false }));
      })
      .catch(({ error, statusCode }) => {
        this.setState((state) => ({ isLoading: false }));
      });
  };

  handleAutoCompleteChange = (chosenRequest) => {
    this.props.handleChange(this.props.name_id, chosenRequest ? chosenRequest.id : "");
  };

  handleOnUpdateInput = (searchText, dataSource) => {
    if (searchText.length >= 3 && this.props.customer_id) {
      this.loadLocations(searchText);
    }
    var chosenRequest = dataSource.filter(
      (elem) => _.toLower(elem[this.props.attribute]) == _.toLower(searchText),
    );

    this.props.handleChange(this.props.name, searchText);
    this.props.handleChange(
      this.props.name_id,
      chosenRequest && chosenRequest.length > 0 ? chosenRequest[0].id : null,
    );
  };

  render() {
    const config = {
      text: 'full_location',
      value: 'id',
    };

    const {
      name,
      name_id,
      value_id,
      value,
      attribute,
      label,
      locationList,
      handleChange,
      customer_id,
      errors,
      smallWidth,
    } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="row">
        <div className= {smallWidth ? "col-md-4 mobile-width" : "col-md-12 mobile-width"} style={{ paddingRight: '0px' }}>
          <AutoComplete
            freeSolo={true}
            name={name_id}
            floatingLabelText={label}
            onNewRequest={(event, newValue) =>
              this.handleAutoCompleteChange(newValue)
            }
            onUpdateInput={(event, newInputValue) =>
              this.handleOnUpdateInput(newInputValue, locationList)
            }
            dataSource={locationList}
            dataSourceConfig={config}
            searchText={value}
            value={value_id}
            errorText={errors.has(name_id) && errors.first(name_id)}
            fullWidth={true}
            style={{ width: '100%' }}
            getOptionLabel={(option) => option.full_location || ""}
            getOptionKey={(option) => option.id || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {isLoading && <CircularProgress />}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SelectReceiverSender);
