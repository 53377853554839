export const FETCH_CASHBOXES_RESOLVED = 'FETCH_CASHBOXES_RESOLVED';
export const FETCH_CASHBOXES_FAILED = 'FETCH_CASHBOXES_FAILED';
export const FETCH_CASHBOX_RESOLVED = "FETCH_CASHBOX_RESOLVED";
export const UPDATE_CASHBOX_RESOLVED = "UPDATE_CASHBOX_RESOLVED";
export const UPDATE_CASHBOX_SETTLEMENT_RESOLVED = "UPDATE_CASHBOX_SETTLEMENT_RESOLVED";
export const ADD_CASHBOX_EXTRA_FEES_RESOLVED = "ADD_CASHBOX_EXTRA_FEES_RESOLVED";
export const UPDATE_CASHBOX_EXTRA_FEE_RESOLVED = "UPDATE_CASHBOX_EXTRA_FEE_RESOLVED";
export const FETCH_CASHBOX_EXTRA_FEE_RESOLVED = "FETCH_CASHBOX_EXTRA_FEE_RESOLVED";
export const FETCH_CASHBOX_EXTRA_FEES_HISTORY_RESOLVED = "FETCH_CASHBOX_EXTRA_FEES_HISTORY_RESOLVED";
export const FETCH_CASHBOX_HISTORY_RESOLVED = "FETCH_CASHBOX_HISTORY_RESOLVED";
export const CREATE_MULTIPLE_CASHBOX_ORDERS_RESOLVED = "CREATE_MULTIPLE_CASHBOX_ORDERS_RESOLVED";
export const CANCEL_CASHBOXES_RESOLVED = "CANCEL_CASHBOXES_RESOLVED";
export const ADD_CASHBOX_RESOLVED = "ADD_CASHBOX_RESOLVED";
export const FETCH_CASHBOX_DETAILS_RESOLVED = "ADD_CASHBOX_RESOLVED";
export const FETCH_CASHBOX_DETAILS_FAILED = "ADD_CASHBOX_RESOLVED";


