import { FETCH_AUTOCOMPLETE_USERS_RESOLVED } from "../ActionTypes/users";

const initialState = {
  autocompleteUserList: []
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTOCOMPLETE_USERS_RESOLVED:
      return Object.assign({}, state, {
        autocompleteUserList: action.payload.data
      });
    default:
      return state;
  }
};

export default userReducer;
