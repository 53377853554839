import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import settings from "./settings";
import orderReducer from "./orderReducer";
import driverReducer from "./driverReducer";
import modalReducer from "./modalReducer";
import authReducer from "./authReducer";
import barcodeReducer from "./barcodeReducer";
import customerReducer from "./customerReducer";
import locationReducer from "./locationReducer";
import dataReducer from "./dataReducer";
import taskReducer from "./taskReducer";
import storeReducer from "./storeReducer";
import hrReducer from "./hrReducer";
import thirdPartyReducer from "./thirdPartyReducer";
import reviewReducer from "./reviewReducer";
import accountantReducer from "./accountantReducer";
import masterBarcodeReducer from "./masterBarcodeReducer";
import userReducer from "./userReducer";
import bannerReducer from "./bannerReducer";
import countryReducer from "./countryReducer";
import internalBannerReducer from "./internalBannerReducer";
import queueRequestReducer from "./queueRequestReducer";
import areaRouteReducer from "./areaRouteReducer";
import cashboxReducer from "./cashboxReducer";

const reducers = (history) => combineReducers({
  router: connectRouter(history),
  orders: orderReducer,
  hr: hrReducer,
  drivers: driverReducer,
  modals: modalReducer,
  auth: authReducer,
  barcodes: barcodeReducer,
  masterBarcodes: masterBarcodeReducer,
  queueRequests: queueRequestReducer,
  customers: customerReducer,
  stores: storeReducer,
  locations: locationReducer,
  data: dataReducer,
  tasks: taskReducer,
  thirdParty: thirdPartyReducer,
  settings,
  reviews: reviewReducer,
  routes: areaRouteReducer,
  accountant: accountantReducer,
  users: userReducer,
  banners: bannerReducer,
  countries: countryReducer,
  internalBanners: internalBannerReducer,
  cashboxes: cashboxReducer,
});

export default reducers;