import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRole } from "utils/helpers";

class Statboxes extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true
    };
  }

  render() {
    const { data, user } = this.props;
    const user_role = getRole(user);
    const customer = ["customer"];
    const operator = ["admin", "operator", "accountant"];
    const customer_and_operator = [
      "customer",
      "admin",
      "operator",
      "accountant"
    ];
    const customer_and_accountant = ["customer", "admin", "accountant"];
    return (
      <article className="article">
        <h2 className="article-title">Overall Stats</h2>
        <div className="row">
          {customer_and_operator.includes(user_role) &&
          (data.pending_orders || data.pending_orders == 0) ? (
            <div className="col-xl-3 col-sm-6">
              <div className="box box-default">
                <div className="box-top">
                  <span>{data.pending_orders}</span>
                </div>
                <div className="box-info">
                  <span>Pending Orders</span>
                </div>
                <div className="box-bottom">
                  <i className="material-icons color-info">reorder</i>
                </div>
              </div>
            </div>
          ) : null}
          {/* {customer_and_accountant.includes(user_role) &&
          (data.piggybank_amount || data.piggybank_amount == 0) ? (
            <div className="col-xl-3 col-sm-6">
              <div className="box box-default">
                <div className="box-top">
                  <span>
                    {data.piggybank_amount.toFixed(2)}
                    <span className="size-h5">$</span>
                  </span>
                </div>
                <div className="box-info">
                  <span>
                    {customer.includes(user_role) ? "My " : null}
                    Piggy Bank
                  </span>
                </div>
                <div className="box-bottom">
                  <i className="material-icons color-warning">attach_money</i>
                </div>
              </div>
            </div>
          ) : null} */}
          {customer_and_accountant.includes(user_role) &&
          data.unpaid_delivery_fees ? (
            <div className="col-xl-3 col-sm-6">
              <div className="box box-default">
                <div className="box-top">
                  <span>
                    {data.unpaid_delivery_fees}
                    <span className="size-h5">$</span>
                  </span>
                </div>
                <div className="box-info">
                  <span>Unpaid Delivery Fees</span>
                </div>
                <div className="box-bottom">
                  <i className="material-icons color-danger">payment</i>
                </div>
              </div>
            </div>
          ) : null}
          {operator.includes(user_role) && data.all_customers_registered ? (
            <div className="col-xl-3 col-sm-6">
              <div className="box box-default">
                <div className="box-top">
                  <span>{data.all_customers_registered}</span>
                </div>
                <div className="box-info">
                  <span>All Registered Customers</span>
                </div>
                <div className="box-bottom">
                  <i className="material-icons color-success">face</i>
                </div>
              </div>
            </div>
          ) : null}
          {customer.includes(user_role) &&
          (data.on_the_way_orders_overall ||
            data.on_the_way_orders_overall == 0) ? (
            <div className="col-xl-3 col-sm-6">
              <div className="box box-default">
                <div className="box-top">
                  <span>{data.on_the_way_orders_overall}</span>
                </div>
                <div className="box-info">
                  <span>Orders On the Way</span>
                </div>
                <div className="box-bottom">
                  <i className="material-icons color-danger">train</i>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </article>
    );
  }
}

Statboxes.propTypes = {
  data: PropTypes.any,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};
export default connect(mapStateToProps)(Statboxes);
