import * as customersActions from "../stores/actions/customers";
import Http from "../utils/Http";
import { customer } from "stores/models/Customer";
import { order } from "stores/models/Order";
import * as ordersActions from "../stores/actions/orders";
import * as authActions from "../stores/actions/auth";
import { location } from "stores/models/Location";
import * as locationsActions from "../stores/actions/locations";
import * as hrActions from "../stores/actions/hr";
import { user } from "stores/models/User";
import { serializeSearchInput, serializeInput } from "utils/helpers";

export function fetchCustomers(
  pageNumber = null,
  input = [],
  other_input = []
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `customers${
        input.length > 0 ? serializeSearchInput(input) : ""
      }${
        other_input.length > 0 ? (input.length > 0 ? "&" : "?") : ""
      }${serializeInput(other_input)}`;
      Http.get(
        url +
          (pageNumber
            ? (input.length > 0 || other_input.length > 0 ? "&" : "?") +
              "page=" +
              pageNumber
            : "")
      )
        .then(res => {
          var customers = [];
          res.data.data.forEach(element => {
            customers.push(customer(element));
          });
          dispatch(customersActions.fetchCustomersResolved(customers));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchActiveCustomers(status = "active") {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(`customers/has_orders_status?status=${status}`)
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchPiggyBanks(info, pageNumber = null, input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url =
        input.length > 0
          ? `customers/${info.customer_id}/piggy_banks${serializeSearchInput(
              input,
              false
            )}`
          : "customers/" + info.customer_id + "/piggy_banks";
      Http.get(
        pageNumber
          ? url + (input.length == 0 ? "?" : "&") + "page=" + pageNumber
          : url
      )
        .then(res => {
          dispatch(customersActions.fetchPiggyBanksResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchAllPiggyBanks(
  input = [],
  pageNumber = null,
  get_total_amount = false
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url =
        input.length > 0
          ? `piggy_banks${serializeSearchInput(input, false)}`
          : "piggy_banks";

      Http.get(
        pageNumber
          ? get_total_amount
            ? url +
              (input.length == 0 ? "?" : "&") +
              "page=" +
              pageNumber +
              "&get_total_amount=true"
            : url + (input.length == 0 ? "?" : "&") + "page=" + pageNumber
          : get_total_amount
            ? url + (input.length == 0 ? "?" : "&") + "get_total_amount=true"
            : url
      )
        .then(res => {
          dispatch(customersActions.fetchPiggyBanksResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchPiggyBankTasks(
  piggybank_id,
  with_pagination = false,
  pageNumber = null
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = "piggy_banks/" + piggybank_id + "/tasks";
      Http.get(
        with_pagination ? url + "?with_pagination=true&page=" + pageNumber : url
      )
        .then(res => {
          dispatch(customersActions.fetchPiggyBankTasksResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updatePiggyBank(input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post(
        "customers/" + input.customer_id + "/piggy_banks/" + input.id,
        input.data
      )
        .then(res => {
          dispatch(customersActions.updatePiggyBankResolved(res.data.data));
          dispatch(
            authActions.serverSuccess("Piggy Bank Updated successfully")
          );
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateAllPrices(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("customers/" + info.customer_id + "/customerPrices", info.input)
        .then(res => {
          dispatch(customersActions.fetchCustomerPricesResolved(res.data.data));
          dispatch(authActions.serverSuccess("Prices Updated successfully"));
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function updateGlobalPriceBasedOnClientType(input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put(`customerPrices/customers_type/${input["customer_type"]}`, input)
        .then(res => {
          dispatch(customersActions.fetchCustomerPricesResolved(res.data.data));
          dispatch(authActions.serverSuccess("Prices Updated successfully"));
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function incrementOrDecrementAllPrices(input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("customers/customerPrices", input)
        .then(res => {
          dispatch(customersActions.fetchCustomerPricesResolved(res.data.data));
          dispatch(authActions.serverSuccess("Prices Updated successfully"));
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function fetchCustomer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("customers/" + id)
        .then(res => {
          var customer_data = customer(res.data.data);
          dispatch(customersActions.fetchCustomerResolved(customer_data));
          return resolve(customer_data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}

export function generatePrices(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("customerPrices", info)
        .then(res => {
          dispatch(customersActions.fetchCustomerPricesResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchCustomerPrices(id, search_input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `customers/${id}/customerPrices`;
      if (search_input.length > 0) {
        url = `customers/${id}/customerPrices${serializeSearchInput(
          search_input,
          false
        )}`;
      }
      Http.get(url)
        .then(res => {
          dispatch(customersActions.fetchCustomerPricesResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err));
          return reject(err);
        });
    });
}

export function create(info, isAuthenticated = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("customers", info.input)
        .then(res => {
          if (isAuthenticated) {
            dispatch(authActions.authLogin(res.data));
          } else {
            dispatch(authActions.serverSuccess(res.data.meta.message));
          }
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function update(info, from_profile = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("customers/" + info.id, info.input)
        .then(res => {
          if (from_profile) {
            dispatch(
              authActions.updateCustomerInfoResolved(customer(res.data.data))
            );
          } else {
            dispatch(
              customersActions.updateCustomerResolved(customer(res.data.data))
            );
          }
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function duplicateRecipient(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post(
        "customers/" + info.customer_id + "/duplicate_recipients",
        info.input
      )
        .then(res => {
          dispatch(authActions.serverSuccess("Recipient created successfully"));
          return resolve(location(res.data.data.locations[0]));
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchShopNotes(customer_id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get("shop_note/" + customer_id)
        .then(res => {
          dispatch(customersActions.fetchShopNotesResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createShopNote(customer_id ,input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("shop_note/" + customer_id, input)
        .then(res => {
          dispatch(customersActions.createShopNoteResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteShopNote(customer_id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.delete("shop_note/" + customer_id)
        .then(res => {
          dispatch(customersActions.deleteShopNoteResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createRecipient(info, modal = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("customers/" + info.customer_id + "/recipients", info.input)
        .then(res => {
          if (modal) {
            dispatch(
              locationsActions.createLocationResolved(
                location(res.data.data.locations[0])
              )
            );
          } else {
            dispatch(
              authActions.serverSuccess("Recipient created successfully")
            );
          }
          return resolve(location(res.data.data.locations[0]));
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createRecipients(input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("/recipients/import", input)
        .then(res => {
          return resolve(res.data.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createContact(info, from_setting = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("customers/" + info.customer_id + "/contacts", info.input)
        .then(res => {
          if (from_setting) {
            dispatch(hrActions.createStaff(user(res.data.data)));
          } else {
            dispatch(
              customersActions.createContactResolved(user(res.data.data))
            );
          }
          dispatch(authActions.serverSuccess("Contact created successfully"));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editContact(info, is_customer) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put(
        "customers/" + info.customer_id + "/contacts/" + info.id,
        info.input
      )
        .then(res => {
          if (is_customer) {
            dispatch(hrActions.editStaff(user(res.data.data)));
          } else {
            dispatch(customersActions.editContactResolved(res.data.data));
            if (res.data.meta.customer) {
              dispatch(
                customersActions.updateCustomerNameResolved(
                  res.data.meta.customer
                )
              );
            }
          }
          dispatch(authActions.serverSuccess("Contact updated successfully"));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editPrice(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("customerPrices/" + info.id, info.input)
        .then(res => {
          dispatch(customersActions.editPriceResolved(res.data.data));
          dispatch(authActions.serverSuccess("Price updated successfully"));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deletePrice(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.delete("customerPrices/" + info.id)
        .then(res => {
          dispatch(customersActions.deletePriceResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function newPrice(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("customers/" + info.customer_id + "/customerPrices", info.input)
        .then(res => {
          dispatch(customersActions.newPriceResolved(res.data.data));
          dispatch(authActions.serverSuccess("Price added successfully"));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editRecipient(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put(
        "customers/" + info.customer_id + "/recipients/" + info.id,
        info.input
      )
        .then(res => {
          dispatch(customersActions.editRecipeintResolved(res.data.data));
          dispatch(authActions.serverSuccess("Recipient updated successfully"));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteContact(info, is_customer) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.delete("customers/" + info.customer_id + "/contacts/" + info.id)
        .then(res => {
          if (is_customer) {
            dispatch(hrActions.deleteUserResolved(user(res.data.data)));
          } else {
            dispatch(
              customersActions.deleteContactResolved(user(res.data.data))
            );
            if (res.data.meta.customer) {
              dispatch(
                customersActions.updateCustomerNameResolved(
                  res.data.meta.customer
                )
              );
            }
          }
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createLocation(info, modal = false) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("locations", info.input)
        .then(res => {
          if (modal) {
            dispatch(
              locationsActions.createLocationResolved(location(res.data.data))
            );
          } else {
            dispatch(
              authActions.serverSuccess("Location created successfully")
            );
          }
          return resolve(location(res.data.data));
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchRecipients(
  info,
  search_input = [],
  custom_input = [],
  pageNumber = null
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `customers/${info.customer_id}/recipients${
        search_input.length > 0 ? serializeSearchInput(search_input) : ""
      }${
        custom_input.length > 0 ? (search_input.length > 0 ? "&" : "?") : ""
      }${serializeInput(custom_input)}`;

      Http.get(
        url +
          (pageNumber
            ? (search_input.length > 0 || custom_input.length > 0 ? "&" : "?") +
              "page=" +
              pageNumber
            : "")
      )
        .then(res => {
          dispatch(customersActions.fetchRecipientsResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function RequestPickupNotification(input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("notifications", input)
        .then(res => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchContacts(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(
        input.length > 0
          ? `users/names?${serializeInput(input)}`
          : "users/names"
      )
        .then(res => {
          dispatch(customersActions.fetchContactsResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function showPiggyBank(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(`customers/${info.customer_id}/piggy_banks/${info.id}`)
        .then(res => {
          dispatch(customersActions.updatePiggyBankResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchCustomersNamesList(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(
        input.length > 0
          ? `customers/names${serializeSearchInput(input, false)}`
          : "customers/names"
      )
        .then(res => {
          dispatch(customersActions.fetchCustomersNamesResolved(res.data.data));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchAutoCompleteCustomers(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(
        input.length > 0
          ? `customers/names?${serializeInput(input)}`
          : "customers/names"
      )
        .then(res => {
          dispatch(customersActions.fetchAutoCompleteCustomers(res.data));
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function fetchCustomerCurrencies(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.get(`currencies?customer_id=${id}`)
        .then(res => {
          dispatch(customersActions.fetchCustomerCurrenciesResolved(res.data.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function resendRegistrationEmail(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.post("users/" + info.id + "/resend_email", info.input)
        .then(res => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function exportCustomers(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let url = `export/customers${
        input.length > 0 ? "?" + serializeInput(input) : ""
      }`;
      Http.get(url)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
}

export function upgradeToMarketplaceSeller(info) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put(`customer/${info.id}/marketplace/upgrade_to_seller`)
        .then(res => {
          let customer_data = customer(res.data.data);
          dispatch(customersActions.fetchCustomerResolved(customer_data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateClientsAccountManager(input) {
  return dispatch =>
    new Promise((resolve, reject) => {
      Http.put("customers/account_manager", input)
        .then(res => {
          dispatch(
            authActions.serverSuccess(
              "Clients' Account Manager Updated successfully"
            )
          );
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
}
