import * as ActionTypes from "../ActionTypes/auth";
import Http from "../../utils/Http";
import { me } from "../models/Me";
import _ from "lodash";
import moment from "moment";
import * as authService from "../../services/authService";
import { utoa, atou } from "../../utils/helpers";

const initialState = {
  isAuthenticated: true,
  notifications: [],
  user: {},
  dashboard: {},
  errorMsg: "",
  successMsg: "",
  open: false,
  token: "",
  isNewNotification: false,
  recaptcha: null,
  pageNotifications: [],
  totalNotificationsCount: 0,
  shop: {}
};

const auth = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.FETCH_SHOP_DATA_RESOLVED:
      return Object.assign({}, state, {
        shop: payload
      });
    case ActionTypes.AUTH_LOGIN:
      return authLogin(state, payload);
    case ActionTypes.ME:
      return getMe(state, payload);
    case ActionTypes.AUTH_CHECK:
      return checkAuth(state);
    case ActionTypes.AUTH_LOGOUT:
      return logout(state);
    case ActionTypes.FETCH_NOTIFICATIONS_RESOLVED:
      return Object.assign({}, state, {
        notifications: payload
      });
    case ActionTypes.UPDATE_NOTIFICATIONS_RESOLVED: {
      let notificationsPage = JSON.parse(
        JSON.stringify(state.pageNotifications)
      );
      let notificationList = JSON.parse(JSON.stringify(state.notifications));
      notificationList.unshift(payload);
      notificationsPage.unshift(payload);
      return Object.assign({}, state, {
        notifications: notificationList,
        pageNotifications: notificationsPage,
        totalNotificationsCount: state.totalNotificationsCount + 1,
        isNewNotification: true
      });
    }
    case ActionTypes.TURN_NOTIFICATION_SOUND_OFF:
      return Object.assign({}, state, {
        isNewNotification: false
      });
    case ActionTypes.SERVER_ERROR:
      return Object.assign({}, state, {
        errorMsg: payload,
        open: true
      });
    case ActionTypes.SERVER_ERROR_RESET:
      return Object.assign({}, state, {
        errorMsg: "",
        open: false
      });
    case ActionTypes.SERVER_SUCCESS:
      return Object.assign({}, state, {
        successMsg: payload,
        open: true
      });
    case ActionTypes.SERVER_SUCCESS_RESET:
      return Object.assign({}, state, {
        successMsg: "",
        open: false
      });
    case ActionTypes.READ_NOTIFICATIONS_RESOLVED: {
      let notifications = JSON.parse(JSON.stringify(state.notifications));
      notifications.forEach((item, index) => {
        let noti = payload.filter(elem => elem.id == item.id);
        if (noti.length > 0) {
          notifications[index] = noti[0];
        }
      });
      return Object.assign({}, state, {
        notifications
      });
    } 

    //user
    case ActionTypes.UPDATE_USER_RESOLVED:
      localStorage.setItem("auth", utoa(JSON.stringify(payload)));
      return Object.assign({}, state, {
        user: payload
      });

    case ActionTypes.SET_TOKEN_RESOLVED:
      return Object.assign({}, state, {
        token: payload
      });
    case ActionTypes.UPDATE_CUSTOMER_INFO_RESOLVED: {
      let user = JSON.parse(JSON.stringify(state.user));
      user.customer.golden_rule = payload.golden_rule;
      user.customer.shop_note = payload.shop_note ;
      user.customer.shop_name = payload.shop_name;
      user.customer.image = payload.image;
      user.customer.shop_phone_number = payload.shop_phone_number;
      user.customer.email_notifications = payload.email_notifications;
      user.customer.rate_update_email_notifications =
        payload.rate_update_email_notifications;
      user.customer.show_items_in_comments = payload.show_items_in_comments;
      user.customer.sms_notifications = payload.sms_notifications;
      user.customer.mof = payload.mof == "N/A" ? null : payload.mof;
      user.customer.vat = payload.vat == "N/A" ? null : payload.vat;
      user.customer.accounting_ref =
        payload.accounting_ref == "N/A" ? null : payload.accounting_ref;
      user.customer.payment_method = payload.payment_method;
      user.customer.type_id = payload.type.id;
      user.customer.waybill_customizations = payload.waybill_customizations,
      localStorage.setItem("auth", utoa(JSON.stringify(user)));
      return Object.assign({}, state, {
        user
      });
    }  
    case ActionTypes.UPDATE_CUSTOMER_THIRD_PARTY_RESOLVED: {
      let user = JSON.parse(JSON.stringify(state.user));
      const index = user.customer.third_party_accounts.findIndex(account => account.id === payload.id);
      if (index !== -1) {
        user.customer.third_party_accounts[index].alternative_currency = payload.alternative_currency;
        localStorage.setItem("auth", utoa(JSON.stringify(user)));
      }
      return Object.assign({}, state, {
        user
    });
    }
    case ActionTypes.UPDATE_USER_CURRENCIES_RESOLVED: {
      let user = JSON.parse(JSON.stringify(state.user));
      const currencyIndex = user.currencies.findIndex((c) => c.currency.id === parseInt(payload.currency.id))
      if (currencyIndex !== -1) {
        user.currencies[currencyIndex].exchange_rate = parseInt(payload.currency.exchange_rate);
      }
      return Object.assign({}, state, {
        user
      });
    }
      
    case ActionTypes.DELETE_USER_CURRENCY_RESOLVED: {
      let user = JSON.parse(JSON.stringify(state.user));
      user.currencies.forEach((item, index) => {
        if (item.currency.id === payload.data.id) {
          user.currencies.splice(index, 1);
        }
      });
      return Object.assign({}, state, {
        user
      });
    }

    case ActionTypes.CREATE_USER_CURRENCY_RESOLVED: {
      let user = JSON.parse(JSON.stringify(state.user));
      user.currencies.push(payload);
      return Object.assign({}, state, {
        user
      });
    }

    case ActionTypes.FETCH_DASHBOARD_DATA_RESOLVED:
      return Object.assign({}, state, {
        dashboard: payload
      });
    case ActionTypes.RECAPTCHA:
      return Object.assign({}, state, {
        recaptcha: payload
      });
    case ActionTypes.FETCH_PAGE_NOTIFICATIONS_RESOLVED:
      return Object.assign({}, state, {
        pageNotifications: payload.data,
        totalNotificationsCount: payload.meta.pagination.total
      });
    case ActionTypes.ADD_PAGE_NOTIFICATIONS_RESOLVED: {
      const addedNotificationsPage = payload.data;
      let notificationsPage = JSON.parse(
        JSON.stringify(state.pageNotifications)
      );
      return Object.assign({}, state, {
        pageNotifications: notificationsPage.concat(addedNotificationsPage),
        totalNotificationsCount: payload.meta.pagination.total
      });
    }
    case ActionTypes.RE_CLEAR_NOTIFICATIONS: {
      let notificationsPage = JSON.parse(JSON.stringify(state.notifications));
      return Object.assign({}, state, {
        pageNotifications: notificationsPage
      });
    }

    case ActionTypes.MAKE_DEFAULT_LOCATION_RESOLVED: {
      let user = JSON.parse(JSON.stringify(state.user));
      user.customer.default_address.id = payload.id;
      return Object.assign({}, state, {
        user
      });
    }
    default:
      return state;
  }
};

const authLogin = (state, payload) => {
  try {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("auth");
    delete Http.defaults.headers.common["Authorization"];
  } catch (e) {
      // do nothing
    }

  const jwtToken = payload.access_token;
  const user_info = me(payload.info);
  localStorage.setItem("jwt_token", jwtToken);
  localStorage.setItem("auth", utoa(JSON.stringify(user_info)));

  Http.defaults.headers.common["Authorization"] = `Bearer ${jwtToken}`;
  state = Object.assign({}, state, {
    isAuthenticated: true,
    user: user_info
  });
  return state;
};

const getMe = (state, payload) => {
  const user_info = me(payload);
  localStorage.setItem("auth", utoa(JSON.stringify(user_info)));

  state = Object.assign({}, state, {
    user: user_info
  });
  return state;
};

const checkAuth = state => {
  state = Object.assign({}, state, {
    isAuthenticated: !!localStorage.getItem("jwt_token"),
    user: !!localStorage.getItem("auth")
      ? JSON.parse(atou(localStorage.getItem("auth")))
      : {}
  });
  if (state.isAuthenticated) {
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("jwt_token")}`;
  }
  return state;
};

const logout = state => {
  localStorage.removeItem("jwt_token");
  localStorage.removeItem("auth");
  localStorage.removeItem("country");
  delete Http.defaults.headers.common["Authorization"];
  state = Object.assign({}, state, {
    isAuthenticated: false,
    notifications: [],
    user: {},
    errorMsg: "",
    open: false,
    recaptcha: null
  });
  return state;
};

export default auth;
