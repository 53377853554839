import React from "react";
import { connect } from "react-redux";
import { fetchDashboardBlocksData } from "services/authService";
import { getFilterData, isOperator, isAdmin } from "utils/helpers";
import { ORDER_HISTORY } from "constants/Statuses";
import FilterComponent from "./FilterComponent";
import { withStyles } from "@mui/styles";
import { styles } from "../styles";
import { fetchOrders } from "services/orderService";
import PropTypes from "prop-types";
import { ORDER_STATUS_SUCCESS } from "constants/Statuses";
import { dashboard_orders_page_path } from "constants/Paths";
import EmptyComponent from "components/EmptyComponent";
import ReactEcharts from "components/ReactECharts";
import CHARTCONFIG from "constants/ChartConfig";
import { isCustomer } from "utils/helpers";
import { push } from 'connected-react-router';

const SuccessOrderDwellTimeChart = props => {
  let { isLoading, onEvents, average, dwell_data, user, for_wakilni } = props;
  let added_title_value =
    isOperator(user) || isAdmin(user)
      ? for_wakilni
        ? "Wakilni\n\n"
        : "Account Manager\n\n"
      : "";

  return (
    <ReactEcharts
      style={{ height: "375px" }}
      option={{
        title: {
          text:
            added_title_value +
            "Avg: " +
            parseFloat(average).toFixed(2) +
            " Days",
          left: "left",
          top: 20,
          textStyle: {
            color: "#ccc"
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          x: "center",
          y: "bottom",
          data: [
            "Same Day",
            "Next Day",
            "2 Days",
            "3 Days",
            "4 Days",
            "5 Days",
            ">5 Days"
          ],
          textStyle: {
            color: CHARTCONFIG.color.text
          }
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: { show: true, title: "save" }
          }
        },
        calculable: true,
        series: [
          {
            name: "Radius model",
            type: "pie",
            radius: [20, 110],
            center: ["120%", 200],
            roseType: "radius",
            width: "40%", // for funnel
            max: 40, // for funnel
            itemStyle: {
              normal: {
                label: {
                  show: true
                },
                labelLine: {
                  show: true
                }
              },
              emphasis: {
                label: {
                  show: true
                },
                labelLine: {
                  show: true
                }
              }
            },
            data: dwell_data
          }
        ]
      }}
      showLoading={isLoading}
      onEvents={onEvents}
    />
  );
};

class SuccessOrderDwellTimeBlock extends React.Component {

  constructor(props, context) {
    super(props, context);
    var date = new Date();
    date.setDate(date.getDate() - 7);

    this.state = {
      isLoading: false,
      dwell_data: [],
      average: 0,
      dwell_data_wakilni: [],
      average_wakilni: 0,
      ...getFilterData({
        receiverZone: null,
        fromCompletedDate: date,
        toCompletedDate: new Date()
      })
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chosen_account_manager !== this.props.chosen_account_manager
    ) {
      this.loadData();
    }
  }

  sendDataToParent = isLoading => {
    this.props.parentCallback(isLoading);
  };

  setLoading = isLoading => {
    this.setState({ ...this.state, isLoading: isLoading });
  };

  loadData = () => {
    this.setLoading(true);

    let customized_search_input_here = this.state.customized_search_input.concat(
      this.state.search_input
    );
    if (this.props.chosen_account_manager) {
      customized_search_input_here.push({
        key: "customer_account_manager_id",
        element: this.props.chosen_account_manager
      });
    }

    this.props
      .dispatch(
        fetchDashboardBlocksData(
          "completed_order_dwell_time",
          customized_search_input_here
        )
      )
      .then(return_data => {
        this.setState({
          isLoading: false,
          dwell_data: return_data.dwell_data,
          average: return_data.average,
          dwell_data_wakilni: return_data.dwell_data_wakilni,
          average_wakilni: return_data.average_wakilni
        });

        if (!isCustomer(this.props.user)) {
          this.sendDataToParent(this.state.isLoading);
        }
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);

        if (!isCustomer(this.props.user)) {
          this.sendDataToParent(this.state.isLoading);
        }
      });
  };

  handleOrderFilter = (data, search_input, customized_search_input) => {
    this.setState(
      {
        data,
        customized_search_input,
        search_input
      },
      () => this.loadData()
    );
  };

  viewOrdersList = (event, chart) => {
    const dwell_time = event.name;

    let search_input_here = this.state.search_input;
    search_input_here.push(
      { key: "completed_on", element: "not null" },
      { key: "confirmed_at", element: "not null" },
      { key: "status", element: ORDER_STATUS_SUCCESS }
    );
    if (this.props.chosen_account_manager) {
      let chart_title = chart._model.option.title[0].text.substring(
        0,
        chart._model.option.title[0].text.indexOf("\n")
      );
      chart_title === "Account Manager" &&
        search_input_here.push({
          key: "customer_account_manager_id",
          element: this.props.chosen_account_manager
        });
    }

    let customized_search_input_here = this.state.customized_search_input;
    customized_search_input_here.push({ key: "days", element: dwell_time });

    this.props
      .dispatch(fetchOrders(search_input_here, customized_search_input_here))
      .then(data => {
        this.setLoading(true);
        this.props.dispatch(push({
          pathname: dashboard_orders_page_path,
          state: {
            search_input: search_input_here,
            customized_search_input: customized_search_input_here,
            meta: data.meta
          }
        }));
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
      });
  };

  render() {
    let {
      isLoading,
      data,
      components,
      dwell_data,
      average,
      dwell_data_wakilni,
      average_wakilni
    } = this.state;
    let { classes, user, chosen_account_manager } = this.props;
    let onEvents = {
      click: this.viewOrdersList
    };
    let has_dwell_data = dwell_data.some(element => element["value"]);
    let has_dwell_data_wakilni = dwell_data_wakilni.some(
      element => element["value"]
    );

    let CustomerChart = (
      <SuccessOrderDwellTimeChart
        isLoading={isLoading}
        onEvents={onEvents}
        average={average}
        dwell_data={dwell_data}
        user={user}
      />
    );

    let AccountManagerAndWakilniChart = (
      <div className="row">
        {chosen_account_manager !== 0 &&
          chosen_account_manager !== null && (
            <div className="col-md-6">
              {!has_dwell_data ? (
                <EmptyComponent title="No Orders Available" />
              ) : (
                <SuccessOrderDwellTimeChart
                  isLoading={isLoading}
                  onEvents={onEvents}
                  average={average}
                  dwell_data={dwell_data}
                  user={user}
                />
              )}
            </div>
          )}
        <div className={chosen_account_manager ? "col-md-6" : "col-md-12"}>
          {!has_dwell_data_wakilni ? (
            <EmptyComponent title="No Orders Available" />
          ) : (
            <SuccessOrderDwellTimeChart
              isLoading={isLoading}
              onEvents={onEvents}
              average={average_wakilni}
              dwell_data={dwell_data_wakilni}
              for_wakilni={true}
              user={user}
            />
          )}
        </div>
      </div>
    );

    return (
      <div className="box box-default">
        <div className={classes.block_header}>
          Successful Orders: Dwell Time
        </div>
        <div className="box-body">
          <FilterComponent
            isLoading={isLoading}
            handleOrderFilter={this.handleOrderFilter}
            data={data}
            components={components}
            statusesOptions={ORDER_HISTORY}
          />
          {isOperator(user) || isAdmin(user)
            ? AccountManagerAndWakilniChart
            : CustomerChart}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(SuccessOrderDwellTimeBlock)
);
