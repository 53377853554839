import { deepClone } from "./index";
import _ from "underscore";
import Transformer from "./Transformer";

class SearchOptions {
  constructor(model) {
    this.resetModel = deepClone(model);
    this.initialize(model);
  }

  initialize(model) {
    this.model = deepClone(model);
    this.orderBy = "id";
    this.sortedBy = "asc";
    this.pageNumber = 1;
    this.sizePerPage = 10;
  }

  reset() {
    this.initialize(this.resetModel);
  }

  updateSearchModel(newModel) {
    this.model = Object.assign(
      {},
      {
        ...this.model,
        ...newModel
      }
    );
  }

  serializeToRequest(requireTransformRequest = true) {
    let searchFields = requireTransformRequest
      ? Transformer.send(
          _.omit(
            this.model,
            item => !_.isBoolean(item) && !_.isNumber(item) && _.isNull(item)
          )
        )
      : _.omit(
          this.model,
          item => !_.isBoolean(item) && !_.isNumber(item) && _.isNull(item)
        );

    // options must be like this :
    // http://elections/voters?search=name:Ayham;email:ayham@pixel38.com&orderBy=id
    // reference : https://github.com/andersao/l5-repository
    return _.map(
      {
        page: this.pageNumber,
        limit: this.sizePerPage,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        search: _.map(searchFields, (value, key) => `${key}:${value}`).join(";")
      },
      (value, key) => `${key}=${value}`
    ).join("&");
  }
}

export default SearchOptions;
