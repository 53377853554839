export const FETCH_TIMESHEETS_RESOLVED = "FETCH_TIMESHEETS_RESOLVED";
export const FETCH_MESSAGES_RESOLVED = "FETCH_MESSAGES_RESOLVED";
export const UPDATE_TIMESHEET_RESOLVED = "UPDATE_TIMESHEET_RESOLVED";
export const UPDATE_MESSAGE_RESOLVED = "UPDATE_MESSAGE_RESOLVED";
export const FETCH_USERS_RESOLVED = "FETCH_USERS_RESOLVED";
export const CREATE_STAFF = "CREATE_STAFF";
export const EDIT_STAFF = "EDIT_STAFF";
export const DELETE_USER = "DELETE_USER";

export const CREATE_RECRUITMENT_REQUEST = "CREATE_RECRUITMENT_REQUEST";
export const FETCH_RECRUITMENT_REQUESTS_RESOLVED =
  "FETCH_RECRUITMENT_REQUESTS_RESOLVED";
export const DELETE_RECRUITMENT_REQUEST = "DELETE_RECRUITMENT_REQUEST";
export const APPROVE_OR_REJECT_RECRUITMENT_REQUEST =
  "APPROVE_OR_REJECT_RECRUITMENT_REQUEST";

export const CREATE_LEAVE_REQUEST = "CREATE_LEAVE_REQUEST";
export const FETCH_LEAVE_REQUESTS_RESOLVED = "FETCH_LEAVE_REQUESTS_RESOLVED";
export const FETCH_LEAVE_REQUEST_RESOLVED = "FETCH_LEAVE_REQUEST_RESOLVED";
export const DELETE_LEAVE_REQUEST = "DELETE_LEAVE_REQUEST";
export const APPROVE_OR_REJECT_LEAVE_REQUEST =
  "APPROVE_OR_REJECT_LEAVE_REQUEST";

export const CREATE_EXIT_REQUEST = "CREATE_EXIT_REQUEST";
export const FETCH_EXIT_REQUESTS_RESOLVED = "FETCH_EXIT_REQUESTS_RESOLVED";
export const DELETE_EXIT_REQUEST = "DELETE_EXIT_REQUEST";
export const APPROVE_OR_REJECT_EXIT_REQUEST = "APPROVE_OR_REJECT_EXIT_REQUEST";
