export const styles = theme => ({
  filter_button: {
    margin: theme.spacing(1),
    backgroundColor: "#ffc107",
    color: "#333C44",
    "&:hover": {
      zIndex: 1,
      backgroundColor: "#ffc107"
    }
  },
  filter_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  block_header: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "600",
    paddingTop: "25px"
  },
  fontBold: {
    fontWeight: "bolder"
  }
});
