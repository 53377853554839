import React from "react";
import { connect } from "react-redux";
import { fetchDashboardBlocksData } from "services/authService";
import { fetchOrders } from "services/orderService";
import { getFilterData } from "utils/helpers";
import { ORDER_HISTORY } from "constants/Statuses";
import FilterComponent from "./FilterComponent";
import { withStyles } from "@mui/styles";
import { styles } from "../styles";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { isCustomer, isAdmin } from "utils/helpers";
import { dashboard_orders_page_path } from "constants/Paths";
import ReactEcharts from "echarts-for-react";
import CHARTCONFIG from "constants/ChartConfig";
import "echarts/theme/macarons";
import { push } from 'connected-react-router';

const ClosedOrdersChart = props => {
  let {
    zones_names,
    sum_filtered,
    specific_filter,
    isLoading,
    onEvents,
    sum_history,
    history,
    for_all_wakilni
  } = props;
  return (
    <div className="row">
      <div className="col-md-6">
        <ReactEcharts
          style={{ height: "300px", width: "100%" }}
          option={{
            title: {
              text: for_all_wakilni
                ? "Selected Filter - Wakilni Average"
                : "Selected Filter",
              x: "center"
            },
            tooltip: {
              trigger: "axis"
            },
            toolbox: {
              show: false
            },
            calculable: true,
            grid: {
              bottom: 100
            },
            xAxis: [
              {
                type: "category",
                data: ["Total", ...zones_names],
                axisLabel: {
                  interval: 0,
                  rotate: 90,
                  fontSize: 10,
                  color: CHARTCONFIG.color.text
                },
                axisTick: {
                  show: false
                },
                axisPointer: {
                  show: true,
                  type: "shadow"
                }
              }
            ],
            yAxis: [
              {
                max: sum_filtered,
                axisLabel: {
                  textStyle: {
                    color: CHARTCONFIG.color.text
                  }
                },
                splitLine: {
                  lineStyle: {
                    color: CHARTCONFIG.color.splitLine
                  }
                },
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: CHARTCONFIG.color.splitArea
                  }
                }
              }
            ],
            series: [
              {
                name: "Closed orders",
                type: "bar",
                data: [sum_filtered, ...specific_filter],
                itemStyle: {
                  normal: {
                    color: CHARTCONFIG.color.info
                  }
                },
                lineStyle: {
                  normal: {
                    color: CHARTCONFIG.color.info
                  }
                },
                areaStyle: {
                  normal: {
                    color: CHARTCONFIG.color.info
                  }
                },
                symbol: "diamond"
              }
            ]
          }}
          showLoading={isLoading}
          onEvents={onEvents}
        />
      </div>
      <div className="col-md-6">
        <ReactEcharts
          style={{ height: "300px", width: "100%" }}
          option={{
            title: {
              text: for_all_wakilni
                ? "Current Month - Wakilni Average"
                : "Current Month",
              x: "center"
            },
            tooltip: {
              trigger: "axis"
            },
            toolbox: {
              show: false
            },
            grid: {
              bottom: 100
            },
            calculable: true,
            xAxis: [
              {
                type: "category",
                data: ["Total", ...zones_names],
                axisLabel: {
                  interval: 0,
                  rotate: 90,
                  fontSize: 10,
                  color: CHARTCONFIG.color.text
                },
                axisTick: {
                  show: false
                },
                axisPointer: {
                  show: true,
                  type: "shadow"
                }
              }
            ],
            yAxis: [
              {
                max: sum_history,
                axisLabel: {
                  textStyle: {
                    color: CHARTCONFIG.color.text
                  }
                },
                splitLine: {
                  lineStyle: {
                    color: CHARTCONFIG.color.splitLine
                  }
                },
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: CHARTCONFIG.color.splitArea
                  }
                }
              }
            ],
            series: [
              {
                name: "Closed orders",
                type: "bar",
                data: [sum_history, ...history],
                itemStyle: {
                  normal: {
                    color: CHARTCONFIG.color.info
                  }
                },
                lineStyle: {
                  normal: {
                    color: CHARTCONFIG.color.info
                  }
                },
                areaStyle: {
                  normal: {
                    color: CHARTCONFIG.color.info
                  }
                },
                symbol: "diamond"
              }
            ]
          }}
          showLoading={isLoading}
          onEvents={onEvents}
        />
      </div>
    </div>
  );
};

class ClosedOrdersBlock extends React.Component {

  constructor(props, context) {
    super(props, context);
    var date = new Date();
    date.setDate(date.getDate() - 7);

    this.state = {
      isLoading: false,
      history: [],
      history_wakilni: [],
      specific_filter: [],
      specific_filter_wakilni: [],
      zones_names: [],

      ...getFilterData({
        orderStatus_one: null,
        fromCompletedDate: date,
        toCompletedDate: new Date(),
        paymentCurrencyType: null
      })
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chosen_account_manager !== this.props.chosen_account_manager
    ) {
      this.loadData();
    }
  }

  sendDataToParent = isLoading => {
    this.props.parentCallback(isLoading);
  };

  setLoading = isLoading => {
    this.setState({ ...this.state, isLoading: isLoading });
  };

  loadData = () => {
    this.setLoading(true);

    let customized_search_input_here = this.state.customized_search_input.concat(
      this.state.search_input
    );
    if (this.props.chosen_account_manager) {
      customized_search_input_here.push({
        key: "customer_account_manager_id",
        element: this.props.chosen_account_manager
      });
    }

    this.props
      .dispatch(
        fetchDashboardBlocksData("closed_orders", customized_search_input_here)
      )
      .then(return_data => {
        this.setState({
          isLoading: false,
          history: return_data.history,
          history_wakilni: return_data.history_wakilni,
          zones_names: return_data.zones_names,
          specific_filter: return_data.specific_filter,
          specific_filter_wakilni: return_data.specific_filter_wakilni
        });
        if (!isCustomer(this.props.user)) {
          this.sendDataToParent(this.state.isLoading);
        }
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
        if (!isCustomer(this.props.user)) {
          this.sendDataToParent(this.state.isLoading);
        }
      });
  };

  handleOrderFilter = (data, search_input, customized_search_input) => {
    this.setState(
      {
        data,
        customized_search_input,
        search_input,
        isLoading: true
      },
      () => this.loadData()
    );
  };

  viewOrdersList = (event, chart) => {
    const chart_title = chart._model.option.title[0].text;
    const receiver_zone_id = event.dataIndex;

    let is_chart_current_month =
      chart_title === "Current Month" ||
      chart_title === "Current Month (Wakilni)";
    let is_chart_specified_orders =
      chart_title === "Selected Filter" || chart_title === "Current Month";

    let search_input_here = this.state.search_input;
    let customized_search_input_here = this.state.customized_search_input;

    search_input_here.push({ key: "completed_on", element: "not null" });
    if (is_chart_specified_orders && this.props.chosen_account_manager) {
      search_input_here.push({
        key: "customer_account_manager_id",
        element: this.props.chosen_account_manager
      });
    }

    if (receiver_zone_id) {
      search_input_here.push({
        key: "receiver_zone_id",
        element: receiver_zone_id
      });
    }

    if (is_chart_current_month) {
      let from_completed_on_index = _.findIndex(
        customized_search_input_here,
        function(o) {
          return o.key == "from_completed_on";
        }
      );
      if (from_completed_on_index !== -1) {
        customized_search_input_here[from_completed_on_index] = {
          key: "from_completed_on",
          element: moment()
            .startOf("month")
            .format("YYYY-MM-DD hh:mm")
        };
      } else {
        customized_search_input_here.push({
          key: "from_completed_on",
          element: moment()
            .startOf("month")
            .format("YYYY-MM-DD hh:mm")
        });
      }

      let to_completed_on_index = _.findIndex(
        customized_search_input_here,
        function(o) {
          return o.key == "to_completed_on";
        }
      );
      if (to_completed_on_index !== -1) {
        customized_search_input_here[to_completed_on_index] = {
          key: "to_completed_on",
          element: moment()
            .endOf("month")
            .format("YYYY-MM-DD hh:mm")
        };
      } else {
        customized_search_input_here.push({
          key: "to_completed_on",
          element: moment()
            .endOf("month")
            .format("YYYY-MM-DD hh:mm")
        });
      }
    }

    this.props
      .dispatch(fetchOrders(search_input_here, customized_search_input_here))
      .then(data => {
        this.setLoading(true);
        this.props.dispatch(push({
          pathname: dashboard_orders_page_path,
          state: {
            search_input: search_input_here,
            customized_search_input: customized_search_input_here,
            meta: data.meta
          }
        }));
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
      });
  };

  render() {
    let {
      isLoading,
      data,
      components,
      zones_names,
      history,
      specific_filter,
      history_wakilni,
      specific_filter_wakilni
    } = this.state;
    let { classes, user, chosen_account_manager } = this.props;

    let sum_history = _.sum(history);
    let sum_filtered = _.sum(specific_filter);
    let sum_history_wakilni = _.sum(history_wakilni).toFixed(2);
    let sum_filtered_wakilni = _.sum(specific_filter_wakilni).toFixed(2);

    let onEvents = {
      click: this.viewOrdersList
    };

    return (
      <div className="box box-default">
        <div className={classes.block_header}>Closed Orders</div>
        <div className="box-body">
          <FilterComponent
            isLoading={isLoading}
            handleOrderFilter={this.handleOrderFilter}
            data={data}
            components={components}
            statusesOptions={ORDER_HISTORY}
            closed_orders_filter={true}
          />
          {!(isAdmin(user) && !chosen_account_manager) && (
            <ClosedOrdersChart
              zones_names={zones_names}
              isLoading={isLoading}
              onEvents={onEvents}
              sum_filtered={sum_filtered}
              specific_filter={specific_filter}
              sum_history={sum_history}
              history={history}
            />
          )}
          {!isCustomer(user) && (
            <ClosedOrdersChart
              zones_names={zones_names}
              isLoading={isLoading}
              onEvents={onEvents}
              sum_filtered={sum_filtered_wakilni}
              specific_filter={specific_filter_wakilni}
              sum_history={sum_history_wakilni}
              history={history_wakilni}
              for_all_wakilni={true}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default withStyles(styles)(connect(mapStateToProps)(ClosedOrdersBlock));
