import * as dataActions from "../stores/actions/data";
import * as authActions from "../stores/actions/auth";
import Http from "../utils/Http";
import { serializeSearchInput, serializeInput } from "utils/helpers";

export function fetchTypeList(api = false) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        api
          ? 'APItypes'
          : (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
              'lists',
      )

        .then((res) => {
          dispatch(dataActions.fetchListResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function createType(info, type) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('types', info.input)
        .then((res) => {
          dispatch(dataActions.createTypeResolved(res.data.data, type));
          dispatch(authActions.serverSuccess(res.data.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateType(info, type) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('types/' + info.id, info.input)
        .then((res) => {
          dispatch(dataActions.updateTypeResolved(res.data.data, type));
          dispatch(authActions.serverSuccess(res.data.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchZones() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('zones')
        .then((res) => {
          dispatch(dataActions.fetchZonesResolved(res.data.data));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function fetchAreas() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        `${
          process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : ''
        }areas`,
      )
        .then((res) => {
          dispatch(dataActions.fetchAreasResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function fetchAutoCompleteAreas(input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        `${process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : ''}` +
          (input.length > 0
            ? `areas/names?${serializeInput(input)}`
            : 'areas/names'),
      )
        .then((res) => {
          dispatch(dataActions.fetchAutocompleteAreas(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function fetchTopFiveAreas(input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        `${process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : ''}` +
          (input.length > 0
            ? `top-areas?${serializeInput(input)}`
            : 'top-areas'),
      )
        .then((res) => {
          dispatch(dataActions.fetchTopAreasByCustomer(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
export function fetchAutoCompleteRecipients(
  info,
  input = [],
  is_search_by_name = false,
  customer_details_filter = false,
) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = `customers/${
        info.customer_id
      }/recipients/names?${serializeInput(input)}`;
      Http.get(
        url +
          (input.length == 0 ? '?' : '&') +
          (customer_details_filter
            ? 'groupBy=phone_number'
            : 'other_recipients=true&groupBy=phone_number'),
      )
        .then((res) => {
          dispatch(dataActions.fetchAutocompleteRecipients(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function fetchVehicles() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('vehicles')
        .then((res) => {
          dispatch(dataActions.fetchVehiclesResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function fetchRecipients() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('recipients')
        .then((res) => {
          var recipients = [];
          res.data.data.forEach((element) => {
            recipients.push(element);
          });
          dispatch(dataActions.fetchRecipientsResolved(recipients));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function fetchOperators() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        `${
          process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : ''
        }users/operators/names`,
      )
        .then((res) => {
          dispatch(dataActions.fetchOperatorsNames(res.data.data));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchFlag() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = 'featureFlag/list';
      Http.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}