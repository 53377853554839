import * as ActionTypes from "../ActionTypes/auth";

export function authLogin(payload) {
  return {
    type: ActionTypes.AUTH_LOGIN,
    payload
  };
}

export function recaptcha(payload) {
  return {
    type: ActionTypes.RECAPTCHA,
    payload
  };
}

export function authLogout() {
  return {
    type: ActionTypes.AUTH_LOGOUT
  };
}

export function getMe(payload) {
  return {
    type: ActionTypes.ME,
    payload
  };
}

export function authCheck() {
  return {
    type: ActionTypes.AUTH_CHECK
  };
}

export function reClearNotifications() {
  return {
    type: ActionTypes.RE_CLEAR_NOTIFICATIONS
  };
}

export function fetchPageNotificationsResolved(payload) {
  return {
    type: ActionTypes.FETCH_PAGE_NOTIFICATIONS_RESOLVED,
    payload
  };
}

export function AddPageNotificationsResolved(payload) {
  return {
    type: ActionTypes.ADD_PAGE_NOTIFICATIONS_RESOLVED,
    payload
  };
}

export function fetchNotificationsResolved(payload) {
  return {
    type: ActionTypes.FETCH_NOTIFICATIONS_RESOLVED,
    payload
  };
}

export function fetchShopDataResolved(payload) {
  return {
    type: ActionTypes.FETCH_SHOP_DATA_RESOLVED,
    payload
  };
}

export function updateNotificationsResolved(payload) {
  return {
    type: ActionTypes.UPDATE_NOTIFICATIONS_RESOLVED,
    payload
  };
}

export function turnNotificationSoundOff() {
  return {
    type: ActionTypes.TURN_NOTIFICATION_SOUND_OFF
  };
}

export function serverError(payload) {
  return {
    type: ActionTypes.SERVER_ERROR,
    payload
  };
}

export function serverErrorReset() {
  return {
    type: ActionTypes.SERVER_ERROR_RESET
  };
}

export function serverSuccess(payload) {
  return {
    type: ActionTypes.SERVER_SUCCESS,
    payload
  };
}

export function serverSuccessReset() {
  return {
    type: ActionTypes.SERVER_SUCCESS_RESET
  };
}

export function readNotificationsResolved(payload) {
  return {
    type: ActionTypes.READ_NOTIFICATIONS_RESOLVED,
    payload
  };
}

export function updateUserResolved(payload) {
  return {
    type: ActionTypes.UPDATE_USER_RESOLVED,
    payload
  };
}

export function updateCustomerInfoResolved(payload) {
  return {
    type: ActionTypes.UPDATE_CUSTOMER_INFO_RESOLVED,
    payload
  };
}

export function updateCustomerThirdPartyResolved(payload) {
  return {
    type: ActionTypes.UPDATE_CUSTOMER_THIRD_PARTY_RESOLVED,
    payload
  };
}

export function updateUserCurrenciesResolved(payload){
  return {
    type: ActionTypes.UPDATE_USER_CURRENCIES_RESOLVED,
    payload
  };
}

export function deleteUserCurrencyResolved(payload){
  return {
    type: ActionTypes.DELETE_USER_CURRENCY_RESOLVED,
    payload
  };
}

export function createUserCurrencyResolved(payload){
  return {
    type: ActionTypes.CREATE_USER_CURRENCY_RESOLVED,
    payload
  }
}
export function fetchDashboardDatasResolved(payload) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_DATA_RESOLVED,
    payload
  };
}

export function saveTokenToServer(payload) {
  return {
    type: ActionTypes.SET_TOKEN_RESOLVED,
    payload
  };
}

export function makeDefaultLocation(payload) {
  return {
    type: ActionTypes.MAKE_DEFAULT_LOCATION_RESOLVED,
    payload
  };
}
