import React from "react";
import * as types from "constants/Types";
import TextField from "components/EnhancedTextField";
import SelectField from "components/EnhancedSelectField";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "components/EnhancedDatePicker";
import CustomerAutoComplete from "components/EnhancedCustomerAutoComplete";
import { connect } from "react-redux";
import AutoComplete from "components/EnhancedAutoComplete";

const mapStateToProps = state => {
  return {
    operatorsNames: state.data.operatorsNames,
    typeList: state.data.typeList
  };
};

export const FilterByText = props => {
  let { value, handleChange, name, label } = props;

  let handleChangeValue = (event) => {
    handleChange(name, event.target.value);
  };

  return (
    <TextField
      name={name}
      value={value}
      floatingLabelText={label}
      onChange={handleChangeValue}
      fullWidth={true}
    />
  );
};

export const FilterBySelectField = (props) => {
  let { value, handleChange, name, input_label, list, is_multi } = props;

  let handleChangeValue = (event) => {
    handleChange(name, event.target.value);
  };

  // Append the "All" item to the beginning of the list
  const allItemList = [{ id: 0, type: "All", label: "All", name: "All" }, ...list];
  return (
    <SelectField
      floatingLabelText={input_label}
      value={value}
      multiple={is_multi ? is_multi : false}
      onChange={handleChangeValue}
      fullWidth={true}
    >
      {allItemList.map((itm) => (
            <MenuItem key={itm.id} value={itm.id}>{itm.label ? itm.label : itm.name}</MenuItem>
      ))}
    </SelectField>
  );
};

export const FilterByTypeListField = connect(mapStateToProps)(props => {
  let {
    value,
    handleChange,
    name,
    input_label,
    typeList,
    typeList_name,
    text_attribute
  } = props;

  let handleChangeValue = (event) => {
    handleChange(name, event.target.value);
  };

  return (
    <SelectField
      floatingLabelText={input_label}
      value={value}
      onChange={handleChangeValue}
      fullWidth={true}
    >
      <MenuItem value={0}>All</MenuItem>
      {typeList &&
        typeList[typeList_name] &&
        typeList[typeList_name].map(itm => (
          <MenuItem
            key={itm.id}
            value={itm.id}
          >{itm[text_attribute]}</MenuItem>
        ))}
    </SelectField>
  );
});

export const FilterByDate = props => {
  let { value, handleChange, name, label } = props;

  let handleChangeValue = (newValue) => {
    handleChange(name, newValue);
  };

  return (
    <DatePicker
      floatingLabelText={label}
      value={value}
      onChange={handleChangeValue}
      fullWidth={true}
    />
  );
};

export const FilterByCustomer = props => {
  let { handleChange, name, value, searchText, disableUpdateCustomerObjectOnChange,floatingLabelText, isActiveClient } = props;

  let handleChangeValue = (new_value, isExcludedCustomer=false) => {
    if (new_value) {
      handleChange(name, new_value, isExcludedCustomer);
      handleChange(name + "_id", new_value.id, isExcludedCustomer);
    } else {
      handleChange(name, "", isExcludedCustomer);
      handleChange(name + "_id", null, isExcludedCustomer);
    }
  };

  return (
    <CustomerAutoComplete
      floatingLabelText={floatingLabelText ? floatingLabelText : "Client"}
      updateCustomerObject={handleChangeValue}
      fullWidth={true}
      searchText={searchText}
      value={value}
      is_active={isActiveClient}
      disableUpdateCustomerObjectOnChange={
        disableUpdateCustomerObjectOnChange ? true : false
      }
    />
  );
};

export const FilterByDriver = props => {
  const { name, value_id, value, list, handleChange } = props;

  let handleAutoCompleteChange = (chosenRequest) => {
    handleChange(name, chosenRequest ? chosenRequest.full_name : '');
    handleChange(name + "_id", chosenRequest ? chosenRequest.id : null);
  };

  let handleOnUpdateInput = (searchText, dataSource) => {
    var chosenRequest = dataSource.filter(
      elem => _.toLower(elem.full_name) == _.toLower(searchText)
    );
    handleChange(name, searchText);
    handleChange(
      name + "_id",
      chosenRequest.length > 0 ? chosenRequest[0].id : null
    );
  };

  const driverConfig = {
    text: "full_name",
    value: "id"
  };

  const options = list.concat([
    { id: -1, full_name: "All" },
    { id: -2, full_name: "Unassigned" },
    { id: -3, full_name: "Self-Pickup"},
  ])

  return (
    <AutoComplete
      name={name}
      floatingLabelText="Active Driver"
      searchText={value}
      value={value_id}
      onNewRequest={(event, newValue) =>
        handleAutoCompleteChange(newValue)
      }
      onUpdateInput={(event, newInputValue) =>
        handleOnUpdateInput(newInputValue, options)
      }
      dataSource={options}
      dataSourceConfig={driverConfig}
      openOnFocus
      fullWidth={true}
      getOptionLabel={(option) => option.full_name || ""}
      getOptionKey={(option) => option.id || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

const FilterByAccountManagerOrIndustry = props => {
  const {
    value,
    operatorsNames,
    handleChange,
    filter_by_industry,
    filter_by_account_manager,
    typeList,
    is_filter_supervisor,
    is_filter_zone_supervisor
  } = props;

  let handleAccountValue = (event) => {
    handleChange("account_manager_id", event.target.value);
  };

  let handleZoneSupervisor = (event) => {
    handleChange("zone_supervisor_id", event.target.value);
  };

  let handleSupervisorValue = (event) => {
    handleChange("supervisor_id", event.target.value);
  };

  let handleIndustryValue = (event) => {
    handleChange("industry_id", event.target.value);
  };

  let floatingLabelText = "Account Manager";
  if (is_filter_supervisor) {
    floatingLabelText = "Driver Supervisor";
  }
  if (is_filter_zone_supervisor) {
    floatingLabelText = "Recipient Zone Supervisor";
  }

  return (
    <span>
      {filter_by_account_manager && (
        <SelectField
          floatingLabelText={floatingLabelText}
          value={value}
          onChange={
            is_filter_supervisor
              ? handleSupervisorValue
              : is_filter_zone_supervisor
                ? handleZoneSupervisor
                : handleAccountValue
          }
          fullWidth={true}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={"null"}>Not Specified</MenuItem>
          {operatorsNames.map(item => (
            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
          ))}
        </SelectField>
      )}

      {filter_by_industry && (
        <SelectField
          floatingLabelText="Industry"
          value={value}
          onChange={handleIndustryValue}
          fullWidth={true}
        >
          <MenuItem value={0}>All</MenuItem>
          {typeList &&
            typeList.industryTypes &&
            typeList.industryTypes.map(item => (
              <MenuItem
                key={item.id}
                value={item.id}
              >{item.label}</MenuItem>
            ))}
        </SelectField>
      )}
    </span>
  );
};

export default connect(mapStateToProps)(FilterByAccountManagerOrIndustry);
