import moment from 'moment';
import { dateFormatter, datetimeFormatter } from 'utils';

/**
 * Create a new cashbox object
 */
export function cashbox_extra_fee(props, page = null) {

 

  return {
    page_index: page,
    id: props.id,
    type_id: props.type_id,
    amount: props.amount,
    note:  props.note,
    cashbox_id: props.cashbox_id,
    cashbox_details_id: props.cashbox_details_id
  };
}
