import moment from "moment";
import { dateFormatter, datetimeFormatter } from "utils";

/**
 * Create a new auditedOrderCollection object
 */

export function auditedOrderCollection(props) {
  const created_at = moment
    .utc(props.created_at, "YYYY-MM-DD HH:mm:ss")
    .local();

  return {
    id: props.id,
    order_id: props.order_id,
    task_id: props.task_id,
    price: props.price,
    price_currency_id: props.price_currency_id,
    collection_amount: props.collection_amount,
    collection_currency_id: props.collection_currency_id,
    cashbox_id: props.cashbox_id,
    collection_type_id: props.collection_type_id,
    currency: props.currency,
    currency_id: props.currency_id,
    import_date: dateFormatter(props.import_date),
    is_deleted: false,
    value: props.value,
    cashbox: props.cashbox
  };
}
