import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';
import { deepmerge } from '@mui/utils';

import MainApp from 'routes/app/';
import Page404 from 'routes/404/';
import Page500 from 'routes/500/';
import PageConfirmEmail from 'routes/confirm-email/';
import PageResetPassword from 'routes/reset-password';
import PageForgotPassword from 'routes/forgot-password/';
import PageFullscreen from 'routes/fullscreen/';
import PrivacyPolice from 'routes/privacy-policy';
import PageLockScreen from 'routes/lock-screen/';
import PageLogin from 'routes/login/';
import PageSignUp from 'routes/sign-up/';
import TrackingOrder from 'routes/tracking-order';
import PageSignUpIn from 'routes/sign-up-in/';
import { isCustomer } from 'utils/helpers';
import MaintenancePage from '../routes/maintenance/MaintenancePage';
import PageShopifyLogin from '../routes/shopify-login';

// = styles =
// 3rd
import 'styles/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/app.scss';

import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import grayTheme from './themes/grayTheme';
import muiComponentsTheme from './themes/muiComponentsTheme';
import { EMAIL_SUBSCRIPTION_PAGE_PATH } from 'constants/Paths';
import RedirectToApp from '../routes/RedirectToApp/RedirectToApp';
import RedirectToMarketplace from '../routes/RedirectToMarketplace/RedirectToMarketplace';


class App extends Component {
  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    const {
      match,
      location,
      layoutBoxed,
      navCollapsed,
      navBehind,
      fixedHeader,
      sidebarWidth,
      theme,
      user,
    } = this.props;
    let materialUITheme;
    switch (theme) {
      case 'gray':
        materialUITheme = grayTheme;
        break;
      case 'dark':
        materialUITheme = darkTheme;
        break;
      default:
        materialUITheme = lightTheme;
    }
    const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;
    if (isMaintenanceMode == 'true') {
      return <MaintenancePage />;
    }
    const isRoot = location.pathname === '/' ? true : false;
    if (location.pathname == EMAIL_SUBSCRIPTION_PAGE_PATH) {
      localStorage.setItem('lastPageVisited', EMAIL_SUBSCRIPTION_PAGE_PATH);
    }

    if (isRoot) {
      if (process.env.REACT_APP_IS_SHOPIFY === 'true') {
        let is_authenticated = localStorage.getItem('jwt_token');
        if (!is_authenticated) {
          return <Redirect to={'/login'} />;
        }
      } else {
        let path = '/login';
        if (!_.isEmpty(user)) {
          path = isCustomer(user) ? '/app/orders' : '/app/orders/pending';
        }
        return <Redirect to={path} />;
      }
    }

    return (
      <ThemeProvider theme={createTheme(deepmerge(materialUITheme, muiComponentsTheme))}>
        <div id="app-inner">
          <div className="preloaderbar hide">
            <span className="bar" />
          </div>
          <div
            className={classnames('app-main full-height', {
              'fixed-header': fixedHeader,
              'nav-collapsed': navCollapsed,
              'nav-behind': navBehind,
              'layout-boxed': layoutBoxed,
              'theme-gray': theme === 'gray',
              'theme-dark': theme === 'dark',
              'sidebar-sm': sidebarWidth === 'small',
              'sidebar-lg': sidebarWidth === 'large',
            })}
          >
            <Switch>
              {process.env.REACT_APP_IS_SHOPIFY !== 'true' && (
                <Route
                  exact
                  strict
                  path="/confirm-email"
                  component={PageConfirmEmail}
                />
              )}
              {process.env.REACT_APP_IS_SHOPIFY !== 'true' && (
                <Route
                  exact
                  strict
                  path="/privacy-policy"
                  component={PrivacyPolice}
                />
              )}
              {process.env.REACT_APP_IS_SHOPIFY !== 'true' && (
                <Route
                  exact
                  strict
                  path="/reset-password"
                  component={PageResetPassword}
                />
              )}
              <Route
                exact
                strict
                path="/forgot-password"
                component={PageForgotPassword}
              />
              <Route
                exact
                strict
                path={`/marketplace/item/:id(\\d+)`}
                component={RedirectToApp}
              />
              <Route
                exact
                strict
                path={`/customer-app`}
                component={RedirectToMarketplace}
              />
              {process.env.REACT_APP_IS_SHOPIFY !== 'true' && (
                <Route
                  exact
                  strict
                  path="/sign-up-in"
                  component={PageSignUpIn}
                />
              )}
              {process.env.REACT_APP_IS_SHOPIFY !== 'true' && (
                <Route
                  exact
                  strict
                  path="/orders/:number([a-zA-Z0-9]+)"
                  component={TrackingOrder}
                />
              )}
              {process.env.REACT_APP_IS_SHOPIFY !== 'true' && (
                <Route
                  exact
                  strict
                  path="/orders/:number([a-zA-Z0-9]+)/:code([a-zA-Z0-9.$]+)"
                  component={TrackingOrder}
                />
              )}
              {process.env.REACT_APP_IS_SHOPIFY !== 'true' && (
                <Route
                  exact
                  strict
                  path="/lock-screen"
                  component={PageLockScreen}
                />
              )}
              {process.env.REACT_APP_IS_SHOPIFY !== 'true' && (
                <Route exact strict path="/sign-up" component={PageSignUp} />
              )}

              <Route path={`${match.url}app`} component={MainApp} />
              <Route exact strict path="/404" component={Page404} />
              <Route exact strict path="/500" component={Page500} />
              <Route exact strict path="/shopify" component={PageFullscreen} />
              {process.env.REACT_APP_IS_SHOPIFY === 'true' && (
                <Route
                  exact
                  strict
                  path="/shopify/login"
                  component={PageShopifyLogin}
                />
              )}
              <Route exact strict path="/login" component={PageLogin} />
              <Redirect
                to={_.isEmpty(user) || user == {} ? '/login' : '/404'}
              />
            </Switch>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme,
  user: state.auth.user,
});

export default connect(mapStateToProps)(App);
