import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import classnames from "classnames";
import RaisedButton from "@mui/material/Button";
import ButtonCircularProgress from "@mui/material/CircularProgress";
class EmptyComponent extends React.Component {
  render() {
    const { onClick, title, sub_title, isLoading, label } = this.props;
    return (
      <div className="feature-callout feature-content-center image-cover">
        <div className="feature-callout-cover">
          <div className="container-fluid with-maxwidth">
            <div className="callout-feature-content">
              <h2>{title}</h2>
              <p>{sub_title}</p>
              {onClick ? (
                isLoading ? (
                  <ButtonCircularProgress />
                ) : (
                  <RaisedButton
                    variant="contained"
                    label={label}
                    onClick={onClick}
                    sx={{ 
                      backgroundColor: "#333C44", 
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "primary.light",
                      }, 
                    }}
                  >{label}</RaisedButton>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmptyComponent.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  sub_title: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string
};

export default withStyles(styles)(EmptyComponent);
