import { task } from 'stores/models/Task';
import moment from 'moment';
import { dateFormatter, datetimeFormatter } from 'utils';

/**
 * Create a new order object
 */
export function order(props, page = null) {
  let tasks = [];
  if (props.t && props.t.length > 0) {
    props.t.forEach((element) => {
      tasks.push(task(element));
    });
  }

  let schedule_date = moment(props.s_d).isValid()
    ? moment(props.s_t_t, 'kk:mm:ss').isValid()
      ? moment(props.s_d)
          .second(59)
          .minute(moment(props.s_t_t, 'kk:mm:ss').minute())
          .hour(moment(props.s_t_t, 'kk:mm:ss').hour())
      : moment(props.s_d).second(59).minute(59).hour(23)
    : 'Not Specified';
  let created_date = moment.utc(props.c_d, 'YYYY-MM-DD HH:mm:ss').local();
  return {
    page_index: page,
    id: props.id,
    drivers_location: props.d_l,
    created_date: created_date,
    created_date_formatted: dateFormatter(created_date),
    created_time_formatted: datetimeFormatter(created_date),
    schedule_date: moment(schedule_date).isValid()
      ? moment.utc(schedule_date, 'YYYY-MM-DD HH:mm:ss').local()
      : schedule_date,
    schedule_from_time: props.s_f_t ? props.s_f_t : null,
    schedule_to_time: props.s_t_t ? props.s_t_t : null,
    comments: props.c ? props.c : [],
    has_comments: props.h_c,
    is_critical: props.is_c,
    imp_comment: props.i_c,
    next_task_status: props.n_t_s ? parseInt(props.n_t_s) : null,
    comment_id: props.c_i,
    completed_on: props.c_o,
    parent_id: props.p_i,
    alternative_currencies: props.a_c,
    parent_order: props.p_o,
    order_number: props.o_n,
    worker: props.wk,
    last_worker: props.l_w,
    package_status: props.pk_s,
    package_status_code: props.pk_s_c,
    payment_status: props.p_s,
    payment_status_code: props.p_s_c,
    price: props.p,
    price_currency_id: props.p_c_i,
    rate: props.r,
    remaining_balance: props.r_b,
    status: props.s,
    is_rejected: props.i_r,
    status_code: props.s_c,
    status_updated_at: moment.utc(props.s_u_a, 'YYYY-MM-DD HH:mm:ss').local(),
    confirmed_date:
      props.con_d && moment(props.con_d).isValid()
        ? moment.utc(props.con_d, 'YYYY-MM-DD HH:mm:ss').local()
        : null,
    tasks: tasks,
    pickup_tasks: props.p_t,
    deliver_tasks: props.d_t,
    waybill: props.w,
    allow_receiver_contact: props.a_r_c,
    location: props.oD.s_l,
    receiver_location: props.oD.r_l,
    car_needed: props.c_n,
    try_on: props.t_o,
    exchange: props.e,
    self_pickup: props.s_p_u,
    self_pickup_details: props.s_p_d,
    self_pickup_office: props.s_p_o,
    settled_with_customer: props.s_w_c,
    suggestedArea: props.sA ? {
        id: props.sA.id,
        suggested_area: props.sA.s_a,
        original_area_name: props.sA.o_a_n,
        order_id: props.sA.o_i,
        location_id: props.sA.l_i,
        confirmed_area: props.sA.c_a
      } : null,
    internationalOrderDetails: props.i_o_d ? {
      id: props.i_o_d.id,
      isInsured: props.i_o_d.i_i,
      isDangerous: props.i_o_d.i_d,
      statusDescription: props.i_o_d.s_d,
      contentDescription: props.i_o_d.c_d,
      shippingTypeId: props.i_o_d.s_t_id,
      trackingNumber: props.i_o_d.t_n
    } : null,
    orderDetails: {
      id: props.oD.id,
      cash_collection_type: props.oD.c_c_t,
      cash_collection_type_id: props.oD.c_c_t_id,
      customer_rate: props.oD.c_r,
      collection_amount: props.oD.c_a,
      collection_currency: props.oD.c_c,
      collection_currency_id: props.oD.c_c_id,
      collection_currency_exchange_rate: props.oD.c_c_a_r,
      collect_in_either_currencies: props.oD.c_i_e_c,
      customer: props.oD.c,
      packages: props.oD.p ? props.oD.p : [],
      items: props.oD.i ? props.oD.i : [],
      type_id: props.oD.t_i,
      same_package: props.oD.s_p,
      note: props.oD.n,
      payment_type: props.oD.p_t,
      piggy_bank_id: props.oD.p_b_i,
      preferred_receiver_date: props.oD.p_r_d
        ? props.oD.p_r_d
        : 'Not Specified',
      preferred_receiver_from_time: props.oD.p_r_f_t,
      preferred_receiver_to_time: props.oD.p_r_t_t,
      preferred_sender_date: props.oD.p_s_d ? props.oD.p_s_d : 'Not Specified',
      preferred_sender_from_time: props.oD.p_s_f_t,
      preferred_sender_to_time: props.oD.p_s_t_t,
      receiver_location: props.oD.r_l,
      receiverable: props.oD.r,
      require_signature: props.oD.r_s,
      require_picture: props.oD.r_p,
      sender_location: props.oD.s_l,
      sender_location_full_location:
        props.oD.s_l && props.oD.s_l.area
          ? props.oD.s.name +
            ' - ' +
            props.oD.s_l.type_label +
            ' - ' +
            props.oD.s_l.area.name
          : '',
      receiver_location_full_location:
        props.oD.r_l && props.oD.r_l.area
          ? props.oD.r.name +
            ' - ' +
            props.oD.r_l.type_label +
            ' - ' +
            props.oD.r_l.area.name
          : '',
      senderable: props.oD.s,
      type: props.oD.t,
    },
    picture: props.o_p,
    pending_pickup_barcodes_count: props.p_p_b_c,
    pending_return_barcodes_count: props.p_r_b_c,
    pickup_waybill_count: props.p_w_c,
    source_id: props.s_id,
    bulk_return_linked_orders_count: props.b_r_l_o_c,
    bulk_return_parent_id: props.b_r_p_i,
    bulk_return_packages_count: props.b_r_p_c,
    is_under_processing: props.i_u_p,
    audited_amounts: props.a_a
  };
}
