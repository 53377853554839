import React from "react";
import { connect } from "react-redux";
import { fetchDashboardBlocksData } from "services/authService";
import { withStyles } from "@mui/styles";
import { styles } from "../styles";
import PropTypes from "prop-types";
import moment from "moment";
import { fetchCustomers } from "services/customerService";
import { dashboard_customers_page_path } from "constants/Paths";
import ReactEcharts from "echarts-for-react";
import CHARTCONFIG from "constants/ChartConfig";
import "echarts/theme/macarons";
import _ from "lodash";
import { push } from 'connected-react-router';

const Active_count = "active";
const Assigned_count = "assigned";

const CustomersCountChart = props => {
  let { isLoading, onEvents, values, count_type } = props;
  let for_all_wakilni = values.all_wakilni !== null;

  return (
    <div className="col-md-6">
      <ReactEcharts
        style={{ height: "300px", width: "100%" }}
        option={{
          title: {
            text: count_type === Assigned_count ? "Assigned" : "Active",
            x: "center"
          },
          tooltip: {
            trigger: "axis"
          },
          toolbox: {
            show: false
          },
          calculable: true,
          grid: {
            bottom: 100
          },
          xAxis: [
            {
              type: "category",
              data: [
                for_all_wakilni ? "Wakilni" : "Account Manager",
                "Wakilni Average"
              ],
              axisLabel: {
                textStyle: {
                  color: CHARTCONFIG.color.text
                }
              },
              axisTick: {
                show: false
              },
              axisPointer: {
                show: true,
                type: "shadow"
              }
            }
          ],
          yAxis: [
            {
              max: _.max([
                for_all_wakilni
                  ? values.all_wakilni
                  : values.specific_account_manager,
                values.average_wakilni
              ]),
              axisLabel: {
                textStyle: {
                  color: CHARTCONFIG.color.text
                }
              },
              splitLine: {
                lineStyle: {
                  color: CHARTCONFIG.color.splitLine
                }
              },
              splitArea: {
                show: true,
                areaStyle: {
                  color: CHARTCONFIG.color.splitArea
                }
              }
            }
          ],
          series: [
            {
              name: count_type === Assigned_count ? "Assigned" : "Active",
              type: "bar",
              data: [
                for_all_wakilni
                  ? values.all_wakilni
                  : values.specific_account_manager,
                values.average_wakilni
              ],
              itemStyle: {
                normal: {
                  color: CHARTCONFIG.color.info
                }
              },
              lineStyle: {
                normal: {
                  color: CHARTCONFIG.color.info
                }
              },
              areaStyle: {
                normal: {
                  color: CHARTCONFIG.color.info
                }
              },
              symbol: "diamond"
            }
          ]
        }}
        showLoading={isLoading}
        onEvents={onEvents}
      />
    </div>
  );
};

class CustomersBlock extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
      assigned_customers_and_average_wakilni: [],
      active_customers_and_average_wakilni: []
    };
  }

  sendDataToParent = isLoading => {
    this.props.parentCallback(isLoading);
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chosen_account_manager !== this.props.chosen_account_manager
    ) {
      this.loadData();
    }
  }

  setLoading = isLoading => {
    this.setState({ ...this.state, isLoading: isLoading });
  };

  loadData = () => {
    this.setLoading(true);

    let customized_search_input_here = [];
    if (this.props.chosen_account_manager) {
      customized_search_input_here.push({
        key: "customer_account_manager_id",
        element: this.props.chosen_account_manager
      });
    }

    this.props
      .dispatch(
        fetchDashboardBlocksData("customers", customized_search_input_here)
      )
      .then(return_data => {
        this.setState({
          isLoading: false,
          assigned_customers_and_average_wakilni:
            return_data.count_assigned_customers,
          active_customers_and_average_wakilni:
            return_data.count_active_customers
        });

        this.sendDataToParent(this.state.isLoading);
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
        this.sendDataToParent(this.state.isLoading);
      });
  };

  viewCustomersList = (event, chart) => {
    const chart_title = chart._model.option.title[0].text;

    let search_input_here = [];
    let customized_search_input_here = [];

    search_input_here.push({ key: "is_active", element: 1 });

    event.dataIndex === 0 &&
      search_input_here.push({
        key: "account_manager_id",
        element: this.props.chosen_account_manager
      });

    chart_title === "Active" &&
      customized_search_input_here.push({
        key: "has_orders_this_month",
        element: true
      });

    this.props
      .dispatch(
        fetchCustomers(null, search_input_here, customized_search_input_here)
      )
      .then(data => {
        this.setLoading(true);
        this.props.dispatch(push({
          pathname: dashboard_customers_page_path,
          state: {
            search_input: search_input_here,
            customized_search_input: customized_search_input_here,
            meta: data.meta
          }
        }));
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
      });
  };

  render() {
    let {
      isLoading,
      assigned_customers_and_average_wakilni,
      active_customers_and_average_wakilni
    } = this.state;
    let { classes } = this.props;

    let onEvents = {
      click: this.viewCustomersList
    };

    return (
      <div className="box box-default">
        <div className={classes.block_header}>Clients</div>
        <div className="box-body">
          <div className="row">
            <CustomersCountChart
              isLoading={isLoading}
              onEvents={onEvents}
              values={assigned_customers_and_average_wakilni}
              count_type={Assigned_count}
            />
            <CustomersCountChart
              isLoading={isLoading}
              onEvents={onEvents}
              values={active_customers_and_average_wakilni}
              count_type={Active_count}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default withStyles(styles)(connect(mapStateToProps)(CustomersBlock));
