import * as bannersActions from "stores/actions/banners";
import Http from "utils/Http";
import * as authActions from "stores/actions/auth";
import { serializeSearchInput } from "utils/helpers";

export function fetchActiveBanner(input = []) {
  return dispatch =>
    new Promise((resolve, reject) => {
    let url =
      `${
        process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : ''
      }banners` +
      (input.length > 0 ? `${serializeSearchInput(input, false)}` : '');
      Http.get(url)
        .then(res => {
          dispatch(bannersActions.fetchActiveBannerResolved(res.data));
          return resolve(res.data);
        })
        .catch(err => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
