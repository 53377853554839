import React from "react";
import QueueAnim from "rc-queue-anim";
import _ from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { push } from 'connected-react-router';

class ConfirmEmail extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.checkAuth();
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    this.checkAuth(nextProps);
  }

  checkAuth(props) {
    const isAuthenticated = props
      ? props.isAuthenticated
      : this.props.isAuthenticated;
    if (isAuthenticated) {
      this.props.dispatch(push("/app/dashboard"));
    }
  }

  render() {
    const email = this.props.location.search
      ? _.last(_.split(this.props.location.search, "="))
      : "your email";
    return (
      <div className="page-auth page-confirm-email">
        <div className="main-body">
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              <div className="body-inner">
                <div className="card bg-color-white">
                  <div className="card-content">
                    <div className="logo text-center">
                      <a>Confirm Email</a>
                    </div>

                    <div>
                      <p className="confirm-mail-icon">
                        <span className="material-icons">mail_outline</span>
                      </p>
                      <p className="text-center text-small no-margin">
                        If the email address exists, an email will be sent to <strong>{email}</strong>.
                       <br/> Please check for an email from us and click on the
                        included link to reset your password.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="additional-info">
                  <span>
                    Return to <Link to="/login">Login</Link>
                  </span>
                </div>
              </div>
            </div>
          </QueueAnim>
        </div>
      </div>
    );
  }
}


export default ConfirmEmail;
