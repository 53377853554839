export const TOGGLE_BOXED_LAYOUT = "TOGGLE_BOXED_LAYOUT";
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSED_NAV";
export const TOGGLE_NAV_BEHIND = "TOGGLE_NAV_BEHIND";
export const TOGGLE_FIXED_HEADER = "TOGGLE_FIXED_HEADER";
export const CHANGE_SIDEBAR_WIDTH = "CHANGE_SIDEBAR_WIDTH";
export const CHANGE_COLOR_OPTION = "CHANGE_COLOR_OPTION";
export const CHANGE_THEME = "CHANGE_THEME";
export const GET_TYPE = "GET_TYPE";

export const FETCH_APP_TOKENS = "FETCH_APP_TOKENS";
export const FETCH_WEBHOOKS = "FETCH_WEBHOOKS";
export const FETCH_WEBHOOK_EVENTS = "FETCH_WEBHOOK_EVENTS";
export const CREATE_WEBHOOK = "CREATE_WEBHOOK";
export const DELETE_WEBHOOK = "DELETE_WEBHOOK";
export const EDIT_WEBHOOK = "EDIT_WEBHOOK";
export const FETCH_APPS = "FETCH_APPS";
export const UPDATE_APP_RESOLVED = "UPDATE_APP_RESOLVED";
export const CREATE_APP_RESOLVED = "CREATE_APP_RESOLVED";
export const DELETE_APP_RESOLVED = "DELETE_APP_RESOLVED";
export const CREATE_APP_TOKEN = "CREATE_APP_TOKEN";
export const DELETE_APP_TOKEN = "DELETE_APP_TOKEN";
export const EDIT_APP_TOKEN = "EDIT_APP_TOKEN";

export const FETCH_CONFIGURATIONS = "FETCH_CONFIGURATIONS";
export const LINK_CONFIGURATION_RESOLVED = "LINK_CONFIGURATION_RESOLVED";
export const EDIT_CONFIGURATION_RESOLVED = "EDIT_CONFIGURATION_RESOLVED";
export const FETCH_CUSTOM_MESSAGES = "FETCH_CUSTOM_MESSAGES";
export const EDIT_CUSTOM_MESSAGE_RESOLVED = "EDIT_CUSTOM_MESSAGE_RESOLVED";
//Currencies
export const FETCH_CURRENCIES_RESOLVED = "FETCH_CURRENCIES_RESOLVED";
export const UPDATE_CURRENCY_RESOLVED = "UPDATE_CURRENCY_RESOLVED";
export const DELETE_CURRENCY_RESOLVED = "DELETE_CURRENCY_RESOLVED";
export const CREATE_CURRENCY_RESOLVED = "CREATE_CURRENCY_RESOLVED";
export const LINK_CURRENCY_RESOLVED = "LINK_CURRENCY_RESOLVED";

//Areas
export const FETCH_SETTINGS_AREAS_RESOLVED = "FETCH_SETTINGS_AREAS_RESOLVED";
export const UPDATE_AREA_RESOLVED = "UPDATE_AREA_RESOLVED";
export const DELETE_AREA_RESOLVED = "DELETE_AREA_RESOLVED";
export const CREATE_AREA_RESOLVED = "CREATE_AREA_RESOLVED";

//Stocks
export const FETCH_STOCKS_RESOLVED = "FETCH_STOCKS_RESOLVED";
export const UPDATE_STOCK_RESOLVED = "UPDATE_STOCK_RESOLVED";
export const DELETE_STOCK_RESOLVED = "DELETE_STOCK_RESOLVED";
export const CREATE_STOCK_RESOLVED = "CREATE_STOCK_RESOLVED";

//Vehicles
export const FETCH_VEHICLES_RESOLVED = "FETCH_VEHICLES_RESOLVED";
export const UPDATE_VEHICLE_RESOLVED = "UPDATE_VEHICLE_RESOLVED";
export const DELETE_VEHICLE_RESOLVED = "DELETE_VEHICLE_RESOLVED";
export const CREATE_VEHICLE_RESOLVED = "CREATE_VEHICLE_RESOLVED";

//Zones
export const FETCH_ZONES_RESOLVED = "FETCH_ZONES_RESOLVED";
export const CREATE_ZONE_RESOLVED = "CREATE_ZONE_RESOLVED";
export const UPDATE_ZONE_RESOLVED = "UPDATE_ZONE_RESOLVED";
export const DELETE_ZONE_RESOLVED = "DELETE_ZONE_RESOLVED";

//Offices
export const FETCH_OFFICES_RESOLVED = "FETCH_OFFICES_RESOLVED";
export const UPDATE_OFFICE_RESOLVED = "UPDATE_OFFICE_RESOLVED";
export const DELETE_OFFICE_RESOLVED = "DELETE_OFFICE_RESOLVED";
export const CREATE_OFFICE_RESOLVED = "CREATE_OFFICE_RESOLVED";

//Store Currencies
export const FETCH_STORE_CURRENCIES_RESOLVED =
  "FETCH_STORE_CURRENCIES_RESOLVED";
export const UPDATE_STORE_CURRENCY_RESOLVED = "UPDATE_STORE_CURRENCY_RESOLVED";
export const DELETE_STORE_CURRENCY_RESOLVED = "DELETE_STORE_CURRENCY_RESOLVED";
export const CREATE_STORE_CURRENCY_RESOLVED = "CREATE_STORE_CURRENCY_RESOLVED";

//Services
export const FETCH_SERVICES_RESOLVED = "FETCH_SERVICES_RESOLVED";
export const CREATE_SERVICE_RESOLVED = "CREATE_SERVICE_RESOLVED";
export const UPDATE_SERVICE_RESOLVED = "UPDATE_SERVICE_RESOLVED";
export const DELETE_SERVICE_RESOLVED = "DELETE_SERVICE_RESOLVED";

//Banners
export const FETCH_BANNERS_RESOLVED = "FETCH_BANNERS_RESOLVED";
export const CREATE_BANNER_RESOLVED = "CREATE_BANNER_RESOLVED";
export const UPDATE_BANNER_RESOLVED = "UPDATE_BANNER_RESOLVED";
export const DELETE_BANNER_RESOLVED = "DELETE_BANNER_RESOLVED";

//internal banners
export const FETCH_INTERNAL_BANNERS_RESOLVED = "FETCH_INTERNAL_BANNERS_RESOLVED";
export const CREATE_INTERNAL_BANNER_RESOLVED = "CREATE_INTERNAL_BANNER_RESOLVED";
export const UPDATE_INTERNAL_BANNER_RESOLVED = "UPDATE_INTERNAL_BANNER_RESOLVED";
export const DELETE_INTERNAL_BANNER_RESOLVED = "DELETE_INTERNAL_BANNER_RESOLVED";

//Preferences
export const LINK_PREFERENCES_RESOLVED = "LINK_PREFERENCES_RESOLVED";
export const FETCH_PREFERENCES_RESOLVED = "FETCH_PREFERENCES_RESOLVED";

//Survey Monkey
export const FETCH_SURVEY_LIST_RESOLVED = "FETCH_SURVEY_LIST_RESOLVED";
export const FETCH_ACTIVE_SURVEY_RESOLVED = 'FETCH_ACTIVE_SURVEY_RESOLVED';

//Area Routes
export const FETCH_SETTINGS_AREA_ROUTES_RESOLVED = "FETCH_SETTINGS_AREA_ROUTES_RESOLVED";
export const UPDATE_AREA_ROUTE_RESOLVED = "UPDATE_AREA_ROUTE_RESOLVED";
export const DELETE_AREA_ROUTE_RESOLVED = "DELETE_AREA_ROUTE_RESOLVED";
export const CREATE_AREA_ROUTE_RESOLVED = "CREATE_AREA_ROUTE_RESOLVED";


export const FETCH_DISTRICT_RESOLVED = "FETCH_DISTRICT_RESOLVED";
export const CREATE_DISTRICT_RESOLVED = "CREATE_DISTRICT_RESOLVED";
export const UPDATE_DISTRICT_RESOLVED = "UPDATE_DISTRICT_RESOLVED";
export const DELETE_DISTRICT_RESOLVED = "DELETE_DISTRICT_RESOLVED";

export const FETCH_GOVERNORATE_RESOLVED = "FETCH_GOVERNORATE_RESOLVED";
export const CREATE_GOVERNORATE_RESOLVED = "CREATE_GOVERNORATE_RESOLVED";
export const UPDATE_GOVERNORATE_RESOLVED = "UPDATE_GOVERNORATE_RESOLVED";
export const FETCH_HOLIDAYS_RESOLVED = "FETCH_HOLIDAYS_RESOLVED";
export const GET_HOLIDAY_RESOLVED = "GET_HOLIDAY_RESOLVED";
export const UPDATE_HOLIDAY_RESOLVED = "UPDATE_HOLIDAY_RESOLVED";
export const DELETE_HOLIDAY_RESOLVED = "DELETE_HOLIDAY_RESOLVED";
export const CREATE_HOLIDAY_RESOLVED = "CREATE_HOLIDAY_RESOLVED";
