import * as types from "../ActionTypes/locations";

export function fetchLocationsResolved(payload) {
  return {
    type: types.FETCH_LOCATIONS_RESOLVED,
    payload
  };
}

export function fetchLocationResolved(payload) {
  return {
    type: types.FETCH_LOCATION_RESOLVED,
    payload
  };
}

export function updateLocationResolved(payload) {
  return {
    type: types.UPDATE_LOCATION_RESOLVED,
    payload
  };
}

export function fetchLocationsNamesResolved(payload) {
  return {
    type: types.FETCH_LOCATIONS_NAMES_RESOLVED,
    payload
  };
}

export function deleteLocationLogResolved(payload) {
  return {
    type: types.DELETE_LOCATION_LOG_RESOLVED,
    payload
  };
}

export function deleteLocationResolved(payload) {
  return {
    type: types.DELETE_LOCATION_RESOLVED,
    payload
  };
}

export function createLocationResolved(payload) {
  return {
    type: types.CREATE_LOCATION_RESOLVED,
    payload
  };
}

export function fetchLocationsPersonablesResolved(payload) {
  return {
    type: types.FETCH_LOCATIONS_PERSONABLES_RESOLVED,
    payload
  };
}
