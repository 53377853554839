// import {
//   cyan700,
//   grey600,
//   green600,
//   green400,
//   green200,
//   fullWhite
// } from "material-ui/styles/colors";
// import { fade } from "material-ui/utils/colorManipulator";
// import spacing from "material-ui/styles/spacing";

// // $dark:                  #333C44 !default; // darken Blue 100 > #343E46
// // $theme_dark_text_color:       rgba(255,255,255,.7);

// export default {
//   spacing,
//   fontFamily: "Roboto, sans-serif",
//   borderRadius: 2,
//   palette: {
//     primary1Color: cyan700,
//     primary2Color: cyan700,
//     primary3Color: grey600,
//     accent1Color: green600,
//     accent2Color: green400,
//     accent3Color: green200,
//     textColor: "rgba(255,255,255,.7)",
//     secondaryTextColor: fade(fullWhite, 0.54),
//     alternateTextColor: "#333C44",
//     canvasColor: "#333C44",
//     borderColor: fade(fullWhite, 0.15),
//     disabledColor: fade(fullWhite, 0.3),
//     pickerHeaderColor: fade(fullWhite, 0.12),
//     clockCircleColor: fade(fullWhite, 0.12)
//   }
// };

import { cyan, grey, green } from "@mui/material/colors";
import { alpha } from '@mui/material/styles';

export default {
  spacing: 8,
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  shape: {
    borderRadius: 2,
  },
  palette: {
    primary: {
      main: cyan[700],  // Replaces primary1Color
      light: cyan[700], // primary2Color
      dark: grey[600],  // primary3Color
    },
    secondary: {
      main: green[600],  // Replaces accent1Color
      light: green[400], // Replaces accent2Color
      dark: green[200],  // Replaces accent3Color
    },
    text: {
      primary: "rgba(255,255,255,.7)", // Replaces textColor
      secondary: alpha('#FFFFFF', 0.54), // Replaces secondaryTextColor
    },
    background: {
      default: "#333C44", // Replaces canvasColor
      paper: "#333C44",
    },
    divider: alpha('#FFFFFF', 0.15), // Replaces borderColor
    action: {
      disabled: alpha('#FFFFFF', 0.3), // Replaces disabledColor
    },
    custom: {
      alternateText: "#333C44", // alternateTextColor
    },
  },
  components: {
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#FFFFFF', 0.12), // pickerHeaderColor
          padding: '20px',
          //color: 'rgb(51, 60, 68)',
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        clock: {
          color: alpha('#FFFFFF', 0.12), // clockCircleColor
        },
      },
    },
  },
  shadows: ['none', '0px 1px 3px rgba(0, 0, 0, 0.2)', '0px 1px 5px rgba(0, 0, 0, 0.12)', ...Array(23).fill('none')],
};
