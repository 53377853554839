import React from "react";
import { connect } from "react-redux";
import QueueAnim from "rc-queue-anim";
import ClosedOrders from "./components/ClosedOrdersBlock";
import Customers from "./components/CustomersBlock";
import SuccessOrderDwellTime from "./components/SuccessOrderDwellTimeBlock";
import TopPeaks from "./components/TopPeaksBlock";
import { getFilterData, isAdmin, isCustomer, isOperator } from "utils/helpers";
import FilterComponent from "./components/FilterComponent";
import RecipientSatisfaction from "./components/RecipientSatisfactionBlock";
import OrderStatistics from "./components/OrderStatisticsBlock";

class DetailedDashboard extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ...getFilterData({
        accountManager:
          isAdmin(props.user) || isOperator(props.user) ? props.user.id : null
      }),
      isLoading: false,
      allDataLoaded: []
    };
  }

  getChildData = isLoadingBlockData => {
    let { allDataLoaded } = this.state;

    if (isLoadingBlockData === false) {
      allDataLoaded.push(isLoadingBlockData);
    }

    if (allDataLoaded.length === 4) {
      this.setState({
        ...this.state,
        isLoading: isLoadingBlockData,
        allDataLoaded: []
      });
    }
  };

  handleAccountManagerFilter = data => {
    this.setState(
      {
        ...this.state,
        isLoading:
          this.state.data.account_manager_id !== data.account_manager_id,
        data
      },
      () => this.getChildData()
    );
  };

  render() {
    let { user } = this.props;
    let { isLoading } = this.state;
    let { account_manager_id } = this.state.data;

    let CustomerDashboard = (
      <QueueAnim type="bottom" className="ui-animate">
        <div className="row">
          <div className="col-md-12">
            <ClosedOrders />
          </div>
          <div className="col-md-12">
            <OrderStatistics />
          </div>
          <div className="col-md-6">
            <SuccessOrderDwellTime />
          </div>
          <div className="col-md-6">
            <RecipientSatisfaction />
          </div>
        </div>
      </QueueAnim>
    );

    let AccountManagerDashboard = (
      <QueueAnim type="bottom" className="ui-animate">
        {isAdmin(user) && (
          <div className="box box-default p-md-3">
            <FilterComponent
              isLoading={isLoading}
              handleOrderFilter={this.handleAccountManagerFilter}
              data={this.state.data}
              components={this.state.components}
              globalFilter={true}
            />
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <ClosedOrders
              chosen_account_manager={account_manager_id}
              parentCallback={this.getChildData}
            />
          </div>
          <div className="col-md-12">
            <Customers
              chosen_account_manager={account_manager_id}
              parentCallback={this.getChildData}
            />
          </div>
          <div className="col-md-12">
            <SuccessOrderDwellTime
              chosen_account_manager={account_manager_id}
              parentCallback={this.getChildData}
            />
          </div>
          <div className="col-md-12">
            <TopPeaks
              chosen_account_manager={account_manager_id}
              parentCallback={this.getChildData}
            />
          </div>
        </div>
      </QueueAnim>
    );

    return (
      <div className="container-fluid no-breadcrumbs page-dashboard">
        {isCustomer(user) ? CustomerDashboard : AccountManagerDashboard}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(DetailedDashboard);
