import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import reducers from './stores/reducers';
import App from './containers/App';
import thunk from 'redux-thunk';
import { authCheck } from 'stores/actions/auth';
import { getMe } from 'services/authService';
import HttpsRedirect from 'react-https-redirect';
import * as Sentry from '@sentry/browser';
// import firebase from 'firebase/app';
import ReactGA from 'react-ga';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

Sentry.init({
  dsn: 'https://bd9318fb66a74d50866d1da318c306aa@o187481.ingest.sentry.io/1305182',
});

ReactGA.initialize('G-T8JTHGHSLH');

const history = createBrowserHistory();
const middleware = [thunk, routerMiddleware(history)];

export const store = createStore(
  reducers(history), // Pass history to reducers
  applyMiddleware(...middleware)
);

store.dispatch(authCheck());
if (!!localStorage.getItem('jwt_token') && !store.getState().auth.user.id) {
  store.dispatch(getMe());
}

// Set moment locale globally to start the week on Monday
moment.updateLocale('en', {
  week: {
    dow: 1, // Set Monday as the first day of the week
  },
});

//  Have this in case you stop running your laravel echo server

// window.addEventListener('load', function() {
// // Initialize the Firebase app in the service worker by passing in the
// // messagingSenderId.
// let config = {
//   apiKey: "AIzaSyD-FJCl-5utZmdJW4jpYHWWfSbNefxPDnA",
//   authDomain: "wakilni-3ccfc.firebaseapp.com",
//   databaseURL: "https://wakilni-3ccfc.firebaseio.com",
//   projectId: "wakilni-3ccfc",
//   storageBucket: "wakilni-3ccfc.appspot.com",
//   messagingSenderId: "181452121062"
// }
// firebase.initializeApp(config)

// if ('serviceWorker' in navigator) {

//     navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then(function(registration) {
//       firebase.messaging().useServiceWorker(registration);
//       // console.log('Registration successful, scope is:', registration.scope);
//     }).catch(function(err) {
//       // console.log('Service worker registration failed, error:', err);
//     });

// }

// var chrome   = navigator.userAgent.indexOf('Chrome') > -1;
// var safari   = navigator.userAgent.indexOf("Safari") > -1;
// if ((chrome) && (safari)) safari = false;

// if (!safari) {
//   // Retrieve an instance of Firebase Messaging so that it can handle background
//   // messages.
//   var messaging = firebase.messaging();

//   messaging.onMessage(function(payload) {
//     // console.log('Message received. ', payload);

//     // var type = payload.data.type ? JSON.parse(payload.data.type) : null ;
//     // var object_id = payload.data.object_id ? payload.data.object_id : null;
//     // var object_type = payload.data.object_type ? payload.data.object_type : null;
//     // var created_at = JSON.parse(payload.data.created_at);

//     // var data = { id: payload.data.id, data: payload.data.body, type: type, object_id: object_id, object_type: object_type, created_at: created_at };

//     // store.dispatch(authActions.updateNotificationsResolved(data))
//     // console.log(data)
//     // if(history.location.pathname == '/app/orders')
//     // {
//     //   console.log(data)
//     //   // if(!type && object_type == "App\\Models\\Order"){
//     //   //   store.dispatch(orderService.fetchOrder(object_id))
//     //   //   .then((data) => {
//     //   //     if(payload.data.title == "Order Created"){
//     //   //       store.dispatch(ordersActions.addOrderResolved(data.data))
//     //   //     }
//     //   //   })
//     //   //   .catch(({error, statusCode}) => {
//     //   //     // console.log("error while fetching order")
//     //   //   })
//     //   // }
//     // }
//   });

//   // [END receive_message]
//   function resetUI() {
//     // [START get_token]
//     // Get Instance ID token. Initially this makes a network call, once retrieved
//     // subsequent calls to getToken will return from cache.
//     messaging.getToken()
//     .then(function(currentToken) {
//       if (currentToken) {
//         sendTokenToServer(currentToken);
//       } else {
//         requestPermission()
//         setTokenSentToServer(false);
//       }
//     })
//     .catch(function(err) {
//       console.log('An error occurred while retrieving token. ', err);
//       setTokenSentToServer(false);
//     });
//   }

//   function sendTokenToServer(currentToken) {
//     if (!isTokenSentToServer()) {
//       store.dispatch(saveTokenToServer(currentToken))
//       setTokenSentToServer(true);
//     } else {
//       // console.log('Token already sent to server so won\'t send it again ' +
//           // 'unless it changes');
//     }
//   }

//   function isTokenSentToServer() {
//     return false;
//   }

//   function setTokenSentToServer(sent) {
//     localStorage.setItem('sentToServer', sent ? 1 : 0);
//   }

//   function requestPermission() {
//     messaging.requestPermission()
//     .then(function() {
//       resetUI();
//     })
//     .catch(function(err) {
//       console.log('Unable to get permission to notify.', err);
//     });
//   }

//   function deleteToken() {
//     messaging.getToken()
//     .then(function(currentToken) {
//       messaging.deleteToken(currentToken)
//       .then(function() {
//         // console.log('Token deleted.');
//         setTokenSentToServer(false);
//         // Once token is deleted update UI.
//         resetUI();
//       })
//       .catch(function(err) {
//         // console.log('Unable to delete token. ', err);
//       });
//     })
//     .catch(function(err) {
//       console.log('Error retrieving Instance ID token. ', err);
//     });
//   }

//   resetUI();
// }
// });

render(
  <Provider store={store}>
    <HttpsRedirect>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
            <Redirect to="/404" />
          </Switch>
        </ConnectedRouter>
      </LocalizationProvider>
    </HttpsRedirect>
  </Provider>,
  document.getElementById('app-container'),
);
