import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import classnames from "classnames";
import AutoComplete from "@mui/material/Autocomplete";
import _ from "lodash";
import * as dataService from "services/dataService";
import { connect } from "react-redux";
import { isEqual } from "utils/helpers";
import { TextField } from "@mui/material";

class EnhancedAreaAutoComplete extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      isClosing: false,
      initiate: props.update ? !props.update : true,
      resetArea: props.resetArea ? props.resetArea : false,
      data: {
        area: props.value ? props.value : null,
        areaText: props.searchText ? props.searchText : "",
      },
    };
    this.onClose = this.onClose.bind(this);
    this.handleAutoCompleteChange = this.handleAutoCompleteChange.bind(this);
    this.handleOnUpdateInput = this.handleOnUpdateInput.bind(this);
    this.getUpdatedAreaList = _.debounce(
      this.getUpdatedAreaList.bind(this),
      500,
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetArea && !this.state.resetArea) {
      this.setState({
        resetArea: false,
        data: {
          area: null,
          areaText: "",
        },
      });
      this.props.onReset(false);
    }
    if (
      !this.state.initiate &&
      nextProps.value &&
      nextProps.searchText &&
      nextProps.update
    ) {
      this.setState({
        initiate: true,
        data: {
          area: nextProps.value,
          areaText: nextProps.searchText,
        },
      });
    }
  }

  onClose() {
    if (this.state.isLoading) {
      this.setState({ isClosing: true });
    } else {
      this.setState({ isClosing: false });
      if (!this.state.data.area) {
        const { data } = this.state;
        data["areaText"] = "";
        this.setState({ data });
      }
    }
  }

  handleOnUpdateInput(searchText) {
    const trimmedSearchText = searchText.trimRight();
    const { data } = this.state;

    // Always update the input text display
    data.areaText = searchText;
    this.setState({ data });

    // Clear results if input is empty
    if (trimmedSearchText.length === 0) {
      data.area = null;
      this.props.updateAreaObject(null);
      this.setState({ data });
      return;
    }

    // Trigger backend search when input reaches 3 characters
    if (trimmedSearchText.length >= 3) {
      this.getUpdatedAreaList(trimmedSearchText);
    }
  }

  // when the user choose on click one of the areas
  // chosenRequest is areaObject
  handleAutoCompleteChange = (event,newValue) => {
    const { data } = this.state;
    data["area"] = newValue ? newValue : null;
    data["areaText"] = newValue ? newValue.name : "";
    this.setState({ data });
    this.props.updateAreaObject(newValue, true);
  };
  // Modified debounced API call function
  getUpdatedAreaList = _.debounce((searchText) => {
    this.setState({ isLoading: true });

    const input = [{ key: "name", element: searchText }];

    // Add your existing filters here
    if (this.props.filter_by_country) {
      input.push({ key: "country_id", element: this.props.filter_by_country });
    }

    if (this.props.filter_governorate) {
      input.push({
        key: "governorate_id",
        element: this.props.filter_governorate,
      });
    }

    if (this.props.filter_district) {
      input.push({ key: "district_id", element: this.props.filter_district });
    }

    if (this.props.filter_by_route_id_null) {
      input.push({ key: "route_id", element: null });
    }
    this.props
      .dispatch(dataService.fetchAutoCompleteAreas(input))
      .then((response) => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }, 500);


  render() {
    const {
      autocompleteAreaList,
      floatingLabelText,
      classes,
      errorText,
      fullWidth,
      value,
      searchText,
    } = this.props;
    const areaConfig = {
      text: "name",
      value: "id",
    };

    return (
      <AutoComplete
        aria-label={floatingLabelText}
        value={this.state.data.area}
        inputValue={this.state.data.areaText}
        filterOptions={(options) => options}
        onChange={this.handleAutoCompleteChange}
        onInputChange={(event, newInputValue) => this.handleOnUpdateInput(newInputValue, autocompleteAreaList)}
        openOnFocus
        fullWidth={fullWidth}
        options={autocompleteAreaList}
        getOptionLabel={(option) => option.name || ""}
        getOptionKey={(option) => option.id || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onClose={this.onClose}
        renderInput={(params) => (
          <TextField
            {...params}
            label={floatingLabelText}
            error={!!errorText}
            helperText={errorText}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: null, // This removes the dropdown icon
             }}
            style={{ maxHeight: "200px" }}
          />
        )}
      />
    );
  }
}

EnhancedAreaAutoComplete.propTypes = {
  floatingLabelText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  errorText: PropTypes.any,
  value: PropTypes.any,
  searchText: PropTypes.any,
  autocompleteAreaList: PropTypes.array.isRequired,
  update: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    autocompleteAreaList: state.data.autocompleteAreaList,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(EnhancedAreaAutoComplete)
);
