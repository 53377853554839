/**
 * Create a new location object
 * @param {int} id Id
 * @param {string} full_location Full location
 */
export function location(props) {
  return {
    id: props.id,
    is_active: props.is_active,
    full_location: props.full_location
      ? props.full_location
      : props.personable
        ? props.personable.name +
          " - " +
          props.type.label +
          " - " +
          (props.area ? props.area.name : null)
        : null,
    name: props.personable ? props.personable.name : null,
    phone_number: props.personable ? props.personable.phone_number : null,
    secondary_phone_number: props.personable
      ? props.personable.secondary_phone_number
      : null,
    type: props.type,
    type_label: props.type_label,
    area: props.area ? props.area : null,
    longitude: props.longitude,
    latitude: props.latitude,
    directions: props.directions,
    floor: props.floor,
    postal_code: props.postal_code,
    building: props.building,
    viewer: props.personable ? props.personable.viewer : null,
    locationLogs: props.locationLogs,
    personable: props.personable,
    location: props.location,
    image: props.image,
    image_id: props.image_id,
    voice: props.voice,
    country: props.country
  };
}
