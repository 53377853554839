import {
  FETCH_REVIEWS_RESOLVED,
  UPDATE_REVIEW_RESOLVED,
  CREATE_REVIEW_RESOLVED
} from "../ActionTypes/reviews";

const initialState = {
  reviewList: [],
  review: {}
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REVIEWS_RESOLVED:
      return Object.assign({}, state, {
        reviewList: action.payload
      });
    case UPDATE_REVIEW_RESOLVED: {
      let reviews = JSON.parse(JSON.stringify(state.reviewList));
      reviews.forEach((item, index) => {
        if (item.id == action.payload.id) {
          reviews[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        reviewList: reviews,
        review: action.payload
      });
    }
    case CREATE_REVIEW_RESOLVED: {
      let reviews = JSON.parse(JSON.stringify(state.reviewList));
      reviews.push(action.payload.data);
      return Object.assign({}, state, {
        reviewList: reviews,
        review: action.payload
      });
    }
    default:
      return state;
  }
};

export default reviewReducer;
