import CHARTCONFIG from 'constants/ChartConfig';
import * as status from 'constants/Statuses';
import moment from 'moment';
import { DEFAULT_COUNTRY } from 'constants/Data';

/**
 * Get the color of the day
 */
export function dayColor(day) {
  let color = CHARTCONFIG.days.Monday;
  switch (day) {
    case 'Monday':
      color = CHARTCONFIG.days.Monday;
      break;
    case 'Tuesday':
      color = CHARTCONFIG.days.Tuesday;
      break;
    case 'Wednesday':
      color = CHARTCONFIG.days.Wednesday;
      break;
    case 'Thursday':
      color = CHARTCONFIG.days.Thursday;
      break;
    case 'Friday':
      color = CHARTCONFIG.days.Friday;
      break;
    case 'Saturday':
      color = CHARTCONFIG.days.Saturday;
      break;
    case 'Sunday':
      color = CHARTCONFIG.days.Sunday;
      break;
    default:
      color = CHARTCONFIG.days.Monday;
  }

  return color;
}

/**
 * Group array by customer Id
 */
export function groupByCustomerId(nonGroupedArray) {
  let groupedArray = [];
  nonGroupedArray.forEach(function (item) {
    var element = groupedArray.find((x) => {
      return x.key == item.orderDetails.customer.id;
    });
    if (element) {
      element.value.push(item);
    } else {
      groupedArray.push({
        key: item.orderDetails.customer.id,
        customer: item.orderDetails.customer,
        value: [item],
      });
    }
  });

  return groupedArray;
}

export function groupByFromZone(nonGroupedArray) {
  let groupedArray = [];
  nonGroupedArray.forEach(function (item) {
    var element = groupedArray.find((x) => {
      return x.key == item.from_zone.id;
    });
    if (element) {
      element.value.push(item);
    } else {
      groupedArray.push({
        key: item.from_zone.id,
        object: item.from_zone,
        value: [item],
      });
    }
  });

  return groupedArray;
}

export function groupByFromStaff(nonGroupedArray) {
  let groupedArray = [];
  nonGroupedArray.forEach(function (item) {
    var element = groupedArray.find((x) => {
      return x.key == item.user.id;
    });
    if (element) {
      element.value.unshift(item);
    } else {
      groupedArray.push({
        key: item.user.id,
        object: item.user,
        value: [item],
      });
    }
  });

  return groupedArray;
}

export function serializeInput(input) {
  let stringed_input = '';
  input.map((item) => {
    if (stringed_input != '') {
      stringed_input = stringed_input + '&';
    }
    stringed_input = stringed_input + item.key + '=' + item.element;
  });
  return stringed_input;
}

export function serializeSearchInput(
  input,
  is_export,
  recipient_search_by_name = false,
) {
  let stringed_input = '';
  input.map((item) => {
    if (stringed_input == '') {
      stringed_input = '?search=';
    }
    stringed_input =
      !('element' in item) && item.key != 'is_active'
        ? stringed_input
        : stringed_input + item.key + ':' + item.element + ';';
  });
  if (stringed_input != '')
    stringed_input = stringed_input.slice(0, -1) + '&searchJoin=and';

  if (is_export) {
    stringed_input =
      stringed_input + (stringed_input == '' ? '?' : '&') + 'is_export=true';
  }
  return stringed_input;
}

export function isCustomer(user) {
  return user.roles && user.roles.length > 0 && user.roles[0].name == 'customer'
    ? true
    : false;
}

export function isOperator(user) {
  return user.roles && user.roles.length > 0 && user.roles[0].name == 'operator'
    ? true
    : false;
}

export function isAccountant(user) {
  return user.roles &&
    user.roles.length > 0 &&
    user.roles[0].name == 'accountant'
    ? true
    : false;
}

export function isAdmin(user) {
  return user.roles && user.roles.length > 0 && user.roles[0].name == 'admin'
    ? true
    : false;
}

export function isTracker(user) {
  return user.roles && user.roles.length > 0 && user.roles[0].name == 'tracker'
    ? true
    : false;
}

export function isExternalViewer(user) {
  return user.roles && user.roles.length > 0 && user.roles[0].name == 'external_viewer'
    ? true
    : false;
}

export function hasHrPrivilege(user) {
  return user.hr_privilege;
}

export function hasSupervisorPrivilege(user) {
  return user.supervisor_privilege;
}

export function getRole(user) {
  return user.roles && user.roles.length > 0 ? user.roles[0].name : '';
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
export function getMapBounds(zone) {
  var bounds = new google.maps.LatLngBounds();
  for (var i = 0; i < zone.length; i++) {
    const latLng = new google.maps.LatLng(zone[i].lat, zone[i].lng);
    bounds.extend(latLng);
  }
  return bounds;
}

export function isEqual(value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length;
  var otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
}

export function amountInDollar(input) {
  var total = 0;
  input.map((item) => {
    total += parseFloat(item.amount) / item.exchange_rate;
  });
  return total.toFixed(2);
}

// ucs-2 string to base64 encoded ascii
export function utoa(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}
// base64 encoded ascii to ucs-2 string
export function atou(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function isEmpty(obj) {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export function getLogAttribute(attribute) {
  if (attribute) {
    if (attribute == 'true') {
      return 'Enabled';
    } else if (attribute == 'false') {
      return 'Disabled';
    } else {
      return attribute;
    }
  } else {
    return 'empty';
  }
}

export const getLocalDate = (date = new Date()) => {
  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local();
};

export const getFilterData = (filter_elements) => {
  var data = {};
  var components = {};
  var search_input = [];
  var customized_search_input = [];

  if (!Object.entries) {
    Object.entries = function (obj) {
      var ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    };
  }

  Object.entries(filter_elements).map(([key, value]) => {
    if (key === 'orderId') {
      data = { ...data, order_id: value ? value : '' };
      components = { ...components, orderId: true };
      if (value) {
        search_input.push({ key: 'order_id', element: escape(value) });
      }
    }

    if (key === 'waybill') {
      data = { ...data, waybill: value ? value : '' };
      components = { ...components, waybill: true };
      if (value) {
        search_input.push({ key: 'waybill', element: escape(value) });
      }
    }

    if (key === 'customer') {
      data = {
        ...data,
        customer_id: value ? value.id : null,
        customer: value ? value : '',
      };
      components = { ...components, customer: true };
      if (value) {
        search_input.push({ key: 'customer_id', element: value.id });
        search_input.push({ key: 'customer', element: value });
      }
    }

    if (key === 'driver') {
      data = {
        ...data,
        driver_id: value ? value.id : null,
        driver: value ? value.full_name : '',
      };
      components = { ...components, driver: true };
      if (value) {
        if (value.id === -2) {
          search_input.push({ key: 'task_driver_user_id', element: 'null' });
        } else if (value.id === -1) {
          search_input.push({
            key: 'task_driver_user_id',
            element: 'not null',
          });
        } else {
          search_input.push({ key: 'task_driver_id', element: value.id });
        }
        search_input.push({ key: 'completed_on', element: 'null' });
      }
    }

    if (key === 'sender') {
      data = { ...data, sender: value ? value : '' };
      components = { ...components, sender: true };
      if (value) {
        customized_search_input.push({ key: 'senderable_name', element: escape(value) });
      }
    }

    if (key === 'receiver') {
      data = { ...data, receiver: value ? value : '' };
      components = { ...components, receiver: true };
      if (value) {
        customized_search_input.push({ key: 'receiverable_name', element: escape(value) });
      }
    }

    if (key === 'orderType') {
      data = { ...data, order_type: value ? value : 0 };
      components = { ...components, orderType: true };
      if (value) {
        search_input.push({ key: 'type_type', element: value });
      }
    }

    if (key === 'receiver_area_id') {
      data = { ...data, receiver_area_id: value ? value : 0 };
      components = { ...components, receiver_area_id: true };
      if (value) {
        search_input.push({ key: 'receiver_area_id', element: value== 1 ? 0 :null });
      }
    }

    if (key === "orderSource") {
      data = { ...data, source_id: value ? value : 0 };
      components = { ...components, orderSource: true };
      if (value) {
        search_input.push({ key: "source_id", element: value });
      }
    }

    if (key === "paymentCurrencyType") {
      data = { ...data, payment_currency_type_id: value ? value : 0 };
      components = { ...components, paymentCurrencyType: true };
    }


    if (key === 'orderStatus' || key === 'orderStatus_one') {
      data = {
        ...data,
        order_status: value ? value : key === 'orderStatus' ? [] : 0,
      };
      components = { ...components, orderStatus: true };
      if (value) {
        search_input.push({ key: 'status', element: value });
      }
    }

    if (key === 'receiverZone') {
      data = { ...data, receiver_zone_id: value ? value : 0 };
      components = { ...components, receiverZone: true };
      if (value) {
        search_input.push({ key: 'receiver_zone_id', element: value });
      }
    }

    if (key === 'areaRoute') {
      data = {
        ...data,
        area_route_ids: value ? value : key === 'area_route_ids' ? [] : 0,
      };
      components = { ...components, areaRoute: true };
      if (value) {
        search_input.push({ key: 'areaRoute', element: value });
      }
    }

    if (key === 'fromCreatedDate') {
      data = { ...data, from_created_date: value ? value : null };
      components = { ...components, fromCreatedDate: true };
      if (value) {
        customized_search_input.push({
          key: 'from_created_at',
          element: moment(value)
            // .hour(0)
            // .minute(0)
            // .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'toCreatedDate') {
      data = { ...data, to_created_date: value ? value : null };
      components = { ...components, toCreatedDate: true };
      if (value) {
        customized_search_input.push({
          key: 'to_created_at',
          element: moment(value)
            .hour(23)
            .minute(59)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'fromConfirmedDate') {
      data = { ...data, from_confirmed_date: value ? value : null };
      components = { ...components, fromConfirmedDate: true };
      if (value) {
        customized_search_input.push({
          key: 'from_confirmed_at',
          element: moment(value)
            // .hour(0)
            // .minute(0)
            // .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'toConfirmedDate') {
      data = { ...data, to_confirmed_date: value ? value : null };
      components = { ...components, toConfirmedDate: true };
      if (value) {
        customized_search_input.push({
          key: 'to_confirmed_at',
          element: moment(value)
            .hour(23)
            .minute(59)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'fromCriticalDate') {
      data = { ...data, from_critical_date: value ? value : null };
      components = { ...components, fromCriticalDate: true };
      if (value) {
        customized_search_input.push({
          key: 'from_critical_at',
          element: moment(value)
            .hour(0)
            .minute(0)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'toCriticalDate') {
      data = { ...data, to_critical_date: value ? value : null };
      components = { ...components, toCriticalDate: true };
      if (value) {
        customized_search_input.push({
          key: 'to_critical_at',
          element: moment(value)
            .hour(23)
            .minute(59)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'orderCriticalness') {
      data = { ...data, order_criticalness: value ? value : 0 };
      components = { ...components, orderCriticalness: true };
      if (value) {
        search_input.push({ key: 'is_critical', element: value });
      }
    }

    if (key === 'fromCompletedDate') {
      data = { ...data, from_completed_date: value ? value : null };
      components = { ...components, fromCompletedDate: true };
      if (value) {
        customized_search_input.push({
          key: 'from_completed_on',
          element: moment(value)
            .hour(0)
            .minute(0)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'toCompletedDate') {
      data = { ...data, to_completed_date: value ? value : null };
      components = { ...components, toCompletedDate: true };
      if (value) {
        customized_search_input.push({
          key: 'to_completed_on',
          element: moment(value)
            .hour(23)
            .minute(59)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'receiverPhoneNumber') {
      data = { ...data, receiverable_phone_number: value ? value : '' };
      components = { ...components, receiverPhoneNumber: true };
      if (value) {
        customized_search_input.push({
          key: 'receiverable_phone_number',
          element: value.replace('+', ''),
        });
      }
    }

    if (key === 'senderPhoneNumber') {
      data = { ...data, senderable_phone_number: value ? value : '' };
      components = { ...components, senderPhoneNumber: true };
      if (value) {
        customized_search_input.push({
          key: 'senderable_phone_number',
          element: value.replace('+', ''),
        });
      }
    }

    if (key === 'accountManager') {
      data = { ...data, account_manager_id: value ? value : 0 };
      components = { ...components, accountManager: true };
      if (value) {
        search_input.push({
          key: 'customer_account_manager_id',
          element: value,
        });
      }
    }

    if (key === 'confirmedByUserId') {
      data = { ...data, confirmed_by_user_id: value ? value : null};
      components = { ...components, confirmedByUser: true };
      if (value) {
        customized_search_input.push({
          key: 'confirmed_by_user_id',
          element: value,
        });
      }
    }

    if (key === 'confirmedByUserName') {
      data = { ...data, confirmed_by_user_name: value ? value : null};
      components = { ...components, confirmedByUser: true };
    }

    if (key === 'industry') {
      data = { ...data, industry_id: value ? value : 0 };
      components = { ...components, industry: true };
      if (value) {
        search_input.push({ key: 'customer.industry_id', element: value });
      }
    }

    // Related to barcode filter
    if (key === 'pickupOrderId') {
      data = { ...data, pickup_order_id: value ? value : '' };
      components = { ...components, pickupOrderId: true };
      if (value) {
        search_input.push({ key: 'pickup_order_id', element: escape(value) });
      }
    }

    if (key === 'barcodeName') {
      data = { ...data, barcode_name: value ? value : '' };
      components = { ...components, barcodeName: true };
      if (value) {
        search_input.push({ key: 'barcode_name', element: escape(value) });
      }
    }

    if (key === 'fromScannedAt') {
      data = { ...data, from_scanned_at: value ? value : null };
      components = { ...components, fromScannedAt: true };
      if (value) {
        customized_search_input.push({
          key: 'from_scanned_at',
          element: moment(value)
            .hour(0)
            .minute(0)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'toScannedAt') {
      data = { ...data, to_scanned_at: value ? value : null };
      components = { ...components, toScannedAt: true };
      if (value) {
        customized_search_input.push({
          key: 'to_scanned_at',
          element: moment(value)
            .hour(23)
            .minute(59)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }

    if (key === 'pickupDriverId') {
      data = {
        ...data,
        pickup_driver_id: value ? value.id : null,
        pickup_driver: value ? value.full_name : '',
      };
      components = { ...components, pickupDriverId: true };
      if (value) {
        search_input.push({ key: 'pickup_driver_id', element: value.id });
      }
    }

    // related to master barcode filter
    if (key === 'nickname') {
      data = { ...data, nickname: value ? value : '' };
      components = { ...components, nickname: true };
      if (value) {
        search_input.push({ key: 'nickname', element: escape(value) });
      }
    }

    if (key === 'requestStatus') {
      data = { ...data, request_status: value ? value : '' };
      components = { ...components, requestStatus: true };
      if (value) {
        search_input.push({ key: 'request_status', element: value });
      }
    }

    if (key === 'driverSupervisor') {
      data = { ...data, supervisor_id: value ? value : '' };
      components = { ...components, driverSupervisor: true };
      if (value) {
        search_input.push({
          key: 'latestTasks.driver.supervisor.id',
          element: value,
        });
      }
    }

    if (key === 'receiverZoneSupervisor') {
      data = { ...data, zone_supervisor_id: value ? value : '' };
      components = { ...components, receiverZoneSupervisor: true };
      if (value) {
        search_input.push({
          key: 'receiverZone.supervisor.id',
          element: value,
        });
      }
    }

    if (key === 'senderZoneSupervisor') {
      data = { ...data, zone_supervisor_id: value ? value : '' };
      components = { ...components, senderZoneSupervisor: true };
      if (value) {
        search_input.push({
          key: 'senderZone.supervisor.id',
          element: value,
        });
      }
    }

    if (key === 'returnType') {
      data = { ...data, return_type_id: value ? value : 0 };
      components = { ...components, returnType: true };
      if (value) {
        customized_search_input.push({
          key: 'return_type_id',
          element: value,
        });
      }
    }

    if (key === 'bulkParentId') {
      data = { ...data, bulk_return_parent_id: value ? value : '' };
      components = { ...components, bulkParentId: true };
      if (value) {
        customized_search_input.push({
          key: 'bulk_return_parent_id',
          element: value,
        });
      }
    }

    if (key === 'originalWithReturns') {
      data = { ...data, original_order_id: value ? value : '' };
      components = { ...components, originalWithReturns: true };
      if (value) {
        customized_search_input.push({
          key: 'original_order_id',
          element: value,
        });
      }
    }

    if (key === 'completedRejected') {
      data = { ...data, completed_rejected: value ? value : null};
      components = { ...components, completedRejected: true };

      if (value) {
        customized_search_input.push({
          key: 'completed_rejected',
          element: value,
        });
      }
    }
    if (key === 'cashboxId') {
      data = { ...data, id: value ? value : '' };
      components = { ...components, cashboxId: true };
      if (value) {
        search_input.push({ key: 'id', element: escape(value) });
      }
    }
    if (key === 'OrderCashboxId') {
      data = { ...data, cashbox_id: value ? value : '' };
      components = { ...components, OrderCashboxId: true };
      if (value) {
        search_input.push({ key: 'cashbox_id', element: escape(value) });
      }
    }
    if (key === 'currencyId') {
      data = { ...data, currency_id: value ? value : 1 };
      components = { ...components, currencyId: true };
      if (value) {
        search_input.push({ key: 'currency_id', element: escape(value) });
      }
    }
    if (key === 'cashboxStatus') {
      data = {
        ...data,
        cashbox_status: value ? value : key === 'cashboxStatus' ? [] : 0,
      };
      components = { ...components, cashboxStatus: true };
      if (value) {
        search_input.push({ key: 'status', element: value });
      }
    }

    if (key === 'scheduleDate') {
      data = { ...data, scheduled_date: value ? value : null };
      components = { ...components, scheduleDate: true };
      if (value) {
        customized_search_input.push({
          key: 'scheduled_date',
          element: moment(value)
            .hour(0)
            .minute(0)
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }
    if (key === 'fromDate') {
      data = { ...data, from_date: value ? value : null };
      components = { ...components, fromDate: true };
      if (value) {
        customized_search_input.push({
          key: 'from_date',
          element: moment(value)
            .hour(0)
            .minute(0)
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }
    if (key === 'toDate') {
      data = { ...data, to_date: value ? value : null };
      components = { ...components, toDate: true };
      if (value) {
        customized_search_input.push({
          key: 'to_date',
          element: moment(value)
            .hour(0)
            .minute(0)
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }
    }
    if (key === 'phoneNumber') {
      data = { ...data, phone_number: value ? value : '' };
      components = { ...components, phoneNumber: true };
      if (value) {
        customized_search_input.push({
          key: 'phone_number',
          element: value.replace('+', ''),
        });
      }
    }

    if (key === 'officialInvoice') {
      data = {
        ...data,
        official_invoice: value ? value : 0,
      };
      components = { ...components, officialInvoice: true };
      if (value) {
        search_input.push({ key: 'official_invoice', element: value });
      }
    }
    if (key === 'wakilniBranch') {
      data = {
        ...data,
        office_id: value ? value : [],
      };
      components = { ...components, wakilniBranch: true };
      if (value) {
        search_input.push({ key: 'office_id', element: value });
      }
    }

    if (key === 'recipientBranch') {
      data = {
        ...data,
        recipient_office_ids: value ? value : [],
      };
      components = { ...components, recipientBranch: true };
      if (value) {
        customized_search_input.push({
          key: 'recipient_office_ids',
          element: value,
        });
      }
    }

    if (key === 'senderBranch') {
      data = {
        ...data,
        sender_office_ids: value ? value : [],
      };
      components = { ...components, senderBranch: true };
      if (value) {
        customized_search_input.push({
          key: 'sender_office_ids',
          element: value,
        });
      }
    }
    if (key === 'expectedAmountOp') {
      data = {
        ...data,
        exp_amount_operation: value ? value : '=',
      };
      components = { ...components, expectedAmountOp: true };
      if (value) {
        search_input.push({ key: 'exp_amount_operation', element: value });
      }
    }


    if (key === 'expectedAmountNum') {
      data = { ...data, expected_amount: value ? value : '' };
      components = { ...components, expectedAmountNum: true };
      if (value) {
        search_input.push({ key: 'expected_amount', element: escape(value) });
      }
    }

    if (key === 'orderShipmentType') {
      data = { ...data, order_shipment_type: value ? value : null};
      components = { ...components, orderShipmentType: true };

      if (value) {
        customized_search_input.push({
          key: 'order_shipment_type',
          element: value,
        });
      }
    }
  });

  return {
    data: data,
    components: components,
    search_input: search_input,
    customized_search_input: customized_search_input,
  };
};

export const convertFromUtcToLocalDate = (str) => new Date(`${str} UTC`);
/**
 *
 * @param {number} currency_id
 * @param {Array<{code: string, symbol_left: string, symbol_right: string, id: number}>} currencies
 * @returns {{code: string, symbol_left: string, symbol_right: string, id: number}}
 */
export const getCurrencyObj = (currency_id, currencies) =>
  currencies.find((c) => c.id === currency_id);

export function isFeatureEnabled(featureName) {
  // Parse the stored flags from local storage
  const storedFlags = JSON.parse(localStorage.getItem('storedFlag')) || [];

  // Check if there is a flag with the provided featureName that is not disabled
  return storedFlags.some(
    (flag) => flag.key === featureName && flag.status !== 'disable',
  );
}

export const capitalizeWord = (word)=>{
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const checkIfNational = (value) => {
  let national = value == DEFAULT_COUNTRY ? true : false;
  return national;
}