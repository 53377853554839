import React from "react";
import QueueAnim from "rc-queue-anim";
import { authLogout } from "stores/actions/auth";
import { connect } from "react-redux";

class Fullscreen extends React.Component {
  constructor(props, context) {
    super(props, context);
    if (props.isAuthenticated) {
      props.dispatch(authLogout());
    }
  }

  render() {
    return (
      <section className="container-fluid">
        <QueueAnim type="bottom" className="ui-animate">
          <div key="1">
            <div className="article-title">Blank</div>
          </div>
        </QueueAnim>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Fullscreen);
