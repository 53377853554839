import {
  FETCH_CASHBOX_RESOLVED,
    FETCH_CASHBOXES_RESOLVED,
    UPDATE_CASHBOX_RESOLVED,
    ADD_CASHBOX_EXTRA_FEES_RESOLVED,
    UPDATE_CASHBOX_EXTRA_FEE_RESOLVED,
    FETCH_CASHBOX_EXTRA_FEE_RESOLVED,
    FETCH_CASHBOX_EXTRA_FEES_HISTORY_RESOLVED,
    FETCH_CASHBOX_HISTORY_RESOLVED,
    UPDATE_CASHBOX_SETTLEMENT_RESOLVED,
    CREATE_MULTIPLE_CASHBOX_ORDERS_RESOLVED,
    CANCEL_CASHBOXES_RESOLVED
  } from "../ActionTypes/cashbox";
  
  const initialState = {
    cashboxes: [],
    cashbox: {},
    cashbox_extra_fees: [],
    cashbox_extra_fee: {},
    extra_fees_history:[],
    cashbox_history:[]

  };
  
  const cashboxReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CASHBOXES_RESOLVED:
        return Object.assign({}, state, {
          cashboxes: action.payload,
        });
      case FETCH_CASHBOX_RESOLVED:
        return Object.assign({}, state, {
          cashbox: action.payload,
          cashboxes: state.cashboxes.map((cashbox) =>
            cashbox.id === action.payload.id ? action.payload : cashbox,
          ),
        });
      case UPDATE_CASHBOX_RESOLVED:
        return Object.assign({}, state, {
          cashbox: action.payload,
        });
      case FETCH_CASHBOX_EXTRA_FEE_RESOLVED:
        return Object.assign({}, state, {
          cashbox_extra_fee: action.payload,
        });
      case ADD_CASHBOX_EXTRA_FEES_RESOLVED:
        return Object.assign({}, state, {
          cashbox_extra_fees: action.payload,
        });
      case UPDATE_CASHBOX_EXTRA_FEE_RESOLVED:
        return Object.assign({}, state, {
          cashbox_extra_fee: action.payload,
        });
      case FETCH_CASHBOX_EXTRA_FEES_HISTORY_RESOLVED:
        return Object.assign({}, state, {
          extra_fees_history: action.payload,
        });
      case FETCH_CASHBOX_HISTORY_RESOLVED:
        return Object.assign({}, state, {
          cashbox_history: action.payload,
        });
      case CREATE_MULTIPLE_CASHBOX_ORDERS_RESOLVED: {
        const cashboxes = action.payload;
        return {
          ...state,
          cashboxes: updateCashboxesInList(state.cashboxes, cashboxes, false),
        };
      }
      case CANCEL_CASHBOXES_RESOLVED: {
        const cancelled_cashboxes = action.payload;
        return {
          ...state,
          cashboxes: updateCashboxesInList(state.cashboxes, cancelled_cashboxes, false),
        };
      }
      case UPDATE_CASHBOX_SETTLEMENT_RESOLVED:
        return Object.assign({}, state, {
          cashbox: action.payload,
        });
      default:
        return state;
    }
  };
  
  function updateCashboxesInList(list, cashboxesToUpdate, remove = false) {
    return cashboxesToUpdate.reduce((updatedList, cashbox) => {
      const index = updatedList.findIndex(c => c.id === cashbox.id);
      if (index >= 0) {
        if (remove) {
          return updatedList.filter(c => c.id !== cashbox.id);
        } else {
          updatedList[index] = cashbox;
        }
      } else if (!remove) {
        updatedList.push(cashbox);
      }
      return updatedList;
    }, [...list]);
  }
  export default cashboxReducer;
  