import React from 'react';
import { connect } from 'react-redux';
import { round } from 'utils/helpers';

export const AlternativeCurrencies = ({
  alternative_currencies,
  typeList,
  breakLine,
}) => {
  return (
    <span>
      {breakLine ? <br /> : ' '} or {breakLine ? <br /> : ' '}
      {alternative_currencies.length &&
        alternative_currencies
          .map((alternative_currency, i) => {
            if (typeList.currencies && typeList.deleted_currencies) {
              const currency = typeList.currencies
                .concat(typeList.deleted_currencies)
                .find((item) => item.id === alternative_currency.currency_id);

              if (currency) {
                return (
                  <div
                    key={alternative_currency.id}
                    style={{
                      display: breakLine ? 'block' : 'inline',
                    }}
                  >
                    {currency.symbol_left +
                      round(
                        alternative_currency.relative_collection,
                        currency.rounding,
                      ) +
                      currency.symbol_right}
                  </div>
                );
              }
              console.error('ERROR FINDING CURRENCY');
              return <div key="empty"></div>;
            }
          })
          .reduce((prev, curr) => [prev, ' or ', curr])}
    </span>
  );
};

const AmountWithCurrency = (props) => {
  const { amount, currency_id, typeList, alternative_currencies } = props;

  const chosen_currency =
    currency_id && typeList.currencies
      ? typeList.currencies.find((elem) => elem.id === currency_id)
      : null;

  return amount && chosen_currency ? (
    <span>
      {chosen_currency.symbol_left + amount + chosen_currency.symbol_right}
      {alternative_currencies && alternative_currencies.length > 0 && (
        <AlternativeCurrencies
          alternative_currencies={alternative_currencies}
          typeList={typeList}
          breakLine={props.breakLine}
        />
      )}
    </span>
  ) : (
    <span>{'$0.00'}</span>
  );
};

const mapStateToProps = (state) => {
  return {
    typeList: state.data.typeList,
  };
};

export default connect(mapStateToProps)(AmountWithCurrency);
