import axios from "axios";
import { store } from "../client";
import { authLogout } from "../stores/actions/auth";
import { countries } from "../constants/Data";
import APPCONFIG from "constants/Config";

const version = "v1";

axios.defaults.baseURL = APPCONFIG.api_url;

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers['Access-Control-Allow-Credentials'] = 'true';


axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("jwt_token");

    if (`Bearer ${token}` !== config.headers.Authorization) {
      //delete config.headers.common["Authorization"];
      delete config.headers.Authorization;
    }

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if(process.env.REACT_APP_IS_SHOPIFY === 'true') {
      const country =localStorage.getItem('country');
      if(country && countries.includes( country )){
        config.baseURL = APPCONFIG.api_url_alt[localStorage.getItem("country")];
      } else {
        store.dispatch(authLogout());
        return Promise.reject(new Error('Country is not set'));
      }
    }
    
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    const data = {
      errors: {},
      error: null,
      statusCode: -100
    };
    if (!error.response) {
      // Network errors mostly
      data.error = error.message ? error.message : "Unknown network error !";
      data.statusCode = -2;
    } else {
      data.statusCode = error.response.status;
      // there is an error defined from requested server
      if (error.response.data.meta) {
        data.error = error.response.meta.message;
      } else if (error.response.data && error.response.data.message) {
        data.error = error.response.data.message;
      } else if (error.response.message) {
        data.error = error.response.message;
      } else if (error.response.statusText) {
        data.error = error.response.statusText;
      } else {
        data.error = "Unknown error !";
      }
    }
    data.errors =
      error.response && error.response.data && error.response.data.errors
        ? error.response.data.errors
        : null;

    if (data.statusCode === 401 || data.statusCode === 400) {
      store.dispatch(authLogout());
    }

    return Promise.reject(data);
  }
);
export default axios;
