import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import RaisedButton from "@mui/material/Button";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import * as modalsActions from "stores/actions/modals";
import { submit_google_review_url } from "../../constants/URLs";
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

class AddReview extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(openReviews) {
    this.props.dispatch(modalsActions.addReview(false, {}));
    if (openReviews){
      window.open(submit_google_review_url);
    }
  }

  render() {
    const { open, classes } = this.props;
    const actions = [
      <Button
      key="1"
      className={classes.raised_buttons}
      onClick={()=>this.handleClose(false)}
      style={{
        color: '#ffc107',
      }}
      >
        Not Now
      </Button>,
      <RaisedButton
      key="2"
      variant="contained"
      label="Go To Google Reviews"
      color="primary"
      onClick={()=>this.handleClose(true)}
      className={classes.raised_buttons}
      >Go To Google Reviews</RaisedButton>
    ];

    return (
      <div>
        <Dialog 
          open={open}
          sx={{
            '& .MuiDialog-paper': {
              minWidth: { xs: 'auto', lg: '700px' },
            },
          }}
        >
          <DialogTitle>Thank you</DialogTitle>
          <DialogContent
            sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}
          >
            <DialogContentText>
              Your feedback makes us happy! Please take a moment to share it on Google as well.
            </DialogContentText>
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddReview.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    open: state.modals.add_review,
    object: state.modals.object
  };
};

export default withStyles(styles)(connect(mapStateToProps)(AddReview));
