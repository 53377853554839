import React from "react";
import ReactEcharts from "components/ReactECharts";
import CHARTCONFIG from "constants/ChartConfig";
import PropTypes from "prop-types";

class Chart extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pie: {
        option: {
          title: {
            text: "Average Delivery Dwell Time",
            x: "center"
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            x: "center",
            y: "bottom",
            data: [
              "Same Day",
              "Next Day",
              "2 Days",
              "3 Days",
              "4 Days",
              "5 Days",
              ">5 Days"
            ],
            textStyle: {
              color: CHARTCONFIG.color.text
            }
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: { show: true, title: "save" }
            }
          },
          calculable: true,
          series: [
            {
              name: "Radius model",
              type: "pie",
              radius: [20, 110],
              center: ["50%", 200],
              roseType: "radius",
              width: "40%", // for funnel
              max: 40, // for funnel
              itemStyle: {
                normal: {
                  label: {
                    show: false
                  },
                  labelLine: {
                    show: false
                  }
                },
                emphasis: {
                  label: {
                    show: true
                  },
                  labelLine: {
                    show: true
                  }
                }
              },
              data: props.data
            }
          ]
        }
      }
    };
  }
  render() {
    return (
      <ReactEcharts
        style={{ height: "400px", width: "100%" }}
        option={this.state.pie.option}
        showLoading={false}
      />
    );
  }
}

Chart.propTypes = {
  data: PropTypes.any
};

export default Chart;
