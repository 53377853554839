import React from "react";
import ReactEcharts from "echarts-for-react";
import "echarts/theme/macarons";
import CHARTCONFIG from "constants/ChartConfig";
import { connect } from "react-redux";
import { fetchDashboardBlocksData } from "services/authService";
import { getFilterData } from "utils/helpers";
import FilterComponent from "./FilterComponent";
import { withStyles } from "@mui/styles";
import { styles } from "../styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { fetchOrders } from "services/orderService";
import { ORDER_STATUS_SUCCESS } from "constants/Statuses";
import { dashboard_orders_page_path } from "constants/Paths";
import { push } from 'connected-react-router';

class RecipientSatisfactionBlock extends React.Component {

  constructor(props, context) {
    super(props, context);
    var date = new Date();
    date.setDate(date.getDate() - 7);

    this.state = {
      isLoading: false,
      recipient_satisfaction: [],

      ...getFilterData({
        receiverZone: null,
        fromCompletedDate: date,
        toCompletedDate: new Date()
      })
    };
  }

  componentDidMount() {
    this.loadData();
  }

  setLoading = isLoading => {
    this.setState({ ...this.state, isLoading: isLoading });
  };

  loadData = () => {
    this.setLoading(true);

    let customized_search_input_here = this.state.customized_search_input.concat(
      this.state.search_input
    );

    this.props
      .dispatch(
        fetchDashboardBlocksData(
          "recipient_satisfaction",
          customized_search_input_here
        )
      )
      .then(return_data => {
        this.setState({
          isLoading: false,
          recipient_satisfaction: return_data.recipient_satisfaction
        });
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
      });
  };

  handleOrderFilter = (data, search_input, customized_search_input) => {
    this.setState(
      {
        data,
        customized_search_input,
        search_input
      },
      () => this.loadData()
    );
  };

  viewOrdersList = event => {
    const feedback = event.name;

    let customized_search_input_here = this.state.customized_search_input;

    let search_input_here = this.state.search_input;
    search_input_here.push(
      { key: "status", element: [ORDER_STATUS_SUCCESS] },
      {
        key: "review.rate",
        element:
          feedback === "Great"
            ? 1
            : feedback === "Good"
              ? 2
              : feedback === "Ok"
                ? 3
                : 4
      }
    );

    this.props
      .dispatch(fetchOrders(search_input_here, customized_search_input_here))
      .then(data => {
        this.setLoading(true);
        this.props.dispatch(push({
          pathname: dashboard_orders_page_path,
          state: {
            search_input: search_input_here,
            customized_search_input: customized_search_input_here,
            meta: data.meta
          }
        }));
      })
      .catch(({ error, statusCode }) => {
        this.setLoading(false);
      });
  };

  render() {
    let { isLoading, data, components, recipient_satisfaction } = this.state;
    let { classes } = this.props;
    var total = _.sum(recipient_satisfaction);
    var avg_rating = 0;
    if (recipient_satisfaction.length === 4) {
      var total_score =
        recipient_satisfaction[0] * 3 +
        recipient_satisfaction[1] * 2 +
        recipient_satisfaction[2] * 1;
      avg_rating = (total_score / total / 3) * 100;
    }
    let onEvents = {
      click: this.viewOrdersList
    };

    return (
      <div className="box box-default">
        <div className={classes.block_header}>Recipient satisfaction</div>
        <div className="box-body">
          <FilterComponent
            isLoading={isLoading}
            handleOrderFilter={this.handleOrderFilter}
            data={data}
            components={components}
          />
          <ReactEcharts
            style={{ height: "375px", width: "100%" }}
            option={{
              title: {
                text: "Total: " + total + " Rated orders",
                subtext: `Average Rating: ${(avg_rating &&
                  avg_rating.toFixed(1) + "%") ||
                  "N/A"}`,
                left: "left",
                textStyle: {
                  color: "#ccc",
                  fontWeight: "normal",
                  fontSize: 15
                },
                subtextStyle: {
                  color: "#ccc",
                  fontWeight: "normal",
                  fontSize: 15
                }
              },
              tooltip: {
                trigger: "axis"
              },
              toolbox: {
                show: false
              },
              calculable: true,
              xAxis: [
                {
                  type: "category",
                  data: ["Great", "Good", "Ok", "Bad"],
                  axisLabel: {
                    textStyle: {
                      color: CHARTCONFIG.color.text
                    }
                  },
                  splitLine: {
                    lineStyle: {
                      color: CHARTCONFIG.color.splitLine
                    }
                  }
                }
              ],
              yAxis: [
                {
                  max: _.max(recipient_satisfaction),
                  axisLabel: {
                    textStyle: {
                      color: CHARTCONFIG.color.text
                    }
                  },
                  splitLine: {
                    lineStyle: {
                      color: CHARTCONFIG.color.splitLine
                    }
                  },
                  splitArea: {
                    show: true,
                    areaStyle: {
                      color: CHARTCONFIG.color.splitArea
                    }
                  }
                }
              ],
              series: [
                {
                  name: "Recipient satisfaction",
                  type: "bar",
                  data: recipient_satisfaction,
                  itemStyle: {
                    normal: {
                      color: CHARTCONFIG.color.info
                    }
                  },
                  lineStyle: {
                    normal: {
                      color: CHARTCONFIG.color.info
                    }
                  },
                  areaStyle: {
                    normal: {
                      color: CHARTCONFIG.color.info
                    }
                  },
                  symbol: "diamond"
                }
              ]
            }}
            showLoading={isLoading}
            onEvents={onEvents}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(connect()(RecipientSatisfactionBlock));
