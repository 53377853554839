import {
  FETCH_MASTER_BARCODES_RESOLVED,
  DELETE_MASTER_BARCODE_RESOLVED
} from "../ActionTypes/master-barcodes";
import _ from "lodash";

const initialState = {
  masterBarcodeList: []
};

const masterBarcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MASTER_BARCODES_RESOLVED:
      return Object.assign({}, state, {
        masterBarcodeList: action.payload
      });
    case DELETE_MASTER_BARCODE_RESOLVED: {
      let masterBarcodeList = JSON.parse(
        JSON.stringify(state.masterBarcodeList)
      );
      _.remove(masterBarcodeList, o => o.id == action.payload.id);

      return Object.assign({}, state, {
        masterBarcodeList
      });
    }
    default:
      return state;
  }
};

export default masterBarcodeReducer;
