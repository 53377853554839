/**
 * Task status returned from server
 */
export const TaskStatus = {
  TASK_STATUS_SECURED: { key: 1, label: "Secured" },
  TASK_STATUS_UNASSIGNED: { key: 2, label: "Unassigned" },
  TASK_STATUS_PENDING: { key: 3, label: "Pending" },
  TASK_STATUS_STARTED: { key: 4, label: "Started" },
  TASK_STATUS_COMPLETE: { key: 5, label: "Completed" },
  TASK_STATUS_FAILED: { key: 6, label: "Failed" },
  TASK_STATUS_CANCELED: { key: 7, label: "Canceled" },
  TASK_STATUS_RE_SCHEDULED: { key: 8, label: "Re scheduled" }
};

export const TASK_STATUS_SECURED = 1;
export const TASK_STATUS_UNASSIGNED = 2;
export const TASK_STATUS_PENDING = 3;
export const TASK_STATUS_STARTED = 4;
export const TASK_STATUS_COMPLETE = 5;
export const TASK_STATUS_FAILED = 6;
export const TASK_STATUS_CANCELED = 7;
export const TASK_STATUS_RE_SCHEDULED = 8;

export const TASKS = [
  { id: 2, type: "TASK_STATUS_UNASSIGNED", label: "Unassigned" },
  { id: 3, type: "TASK_STATUS_PENDING", label: "Pending" },
  { id: 4, type: "TASK_STATUS_STARTED", label: "Started" },
  { id: 5, type: "TASK_STATUS_COMPLETE", label: "Completed" },
  { id: 6, type: "TASK_STATUS_FAILED", label: "Failed" },
  { id: 7, type: "TASK_STATUS_CANCELED", label: "Canceled" },
  { id: 8, type: "TASK_STATUS_RE_SCHEDULED", label: "Re scheduled" }
];

export const STATUS_CHECK_IN_CONFIRMED = 1;
export const STATUS_CHECK_OUT_CONFIRMED = 2;
export const STATUS_CHECK_IN_NOT_CONFIRMED = 3;
export const STATUS_CHECK_OUT_NOT_CONFIRMED = 4;

export const MESSAGE_STATUS_PENDING = 1;
export const MESSAGE_STATUS_ACCEPTED = 2;
export const MESSAGE_STATUS_REJECTED = 3;

export const STATUS_NEED_SUBMISSION = 0;
export const STATUS_NOT_CONFIRMED = 1;
export const STATUS_CONFIRMED = 2;
export const STATUS_CONFIRMED_WITH_EDIT = 3;
export const STATUS_REJECTED = 4;

export const ORDER_STATUS_PENDING = 1;
export const ORDER_STATUS_CONFIRMED = 2;
export const ORDER_STATUS_DECLINED = 6;
export const ORDER_STATUS_CANCELED = 7;
export const ORDER_STATUS_PROCESSING = 3;
export const ORDER_STATUS_SUCCESS = 4;
export const ORDER_STATUS_FAILED = 5;
export const ORDER_STATUS_CLOSED_FAILED = 8;
export const ORDER_STATUS_POSTPONED = 9;
export const ORDER_STATUS_PENDING_CANCELLATION = 10;

export const ORDER = [
  { id: 1, type: "ORDER_STATUS_PENDING", label: "Pending" },
  { id: 2, type: "ORDER_STATUS_CONFIRMED", label: "Confirmed" },
  { id: 6, type: "ORDER_STATUS_DECLINED", label: "Declined" },
  { id: 7, type: "ORDER_STATUS_CANCELED", label: "Canceled" },
  { id: 3, type: "ORDER_STATUS_PROCESSING", label: "Processing" },
  { id: 4, type: "ORDER_STATUS_SUCCESS", label: "Success" },
  { id: 5, type: "ORDER_STATUS_FAILED", label: "Failed" },
  { id: 8, type: "ORDER_STATUS_CLOSED_FAILED", label: "Closed Failed" },
  { id: 9, type: "ORDER_STATUS_POSTPONED", label: "Postponed" },
  { id: 10, type: "ORDER_STATUS_PENDING_CANCELLATION", label: "Pending Cancellation" }
];

export const ORDER_HISTORY = [
  { id: 6, type: "ORDER_STATUS_DECLINED", label: "Declined" },
  { id: 7, type: "ORDER_STATUS_CANCELED", label: "Canceled" },
  { id: 4, type: "ORDER_STATUS_SUCCESS", label: "Success" },
  { id: 8, type: "ORDER_STATUS_CLOSED_FAILED", label: "Closed Failed" }
];

export const ORDER_PENDING_ACTIVE = [
  { id: 1, type: "ORDER_STATUS_PENDING", label: "Pending" },
  { id: 2, type: "ORDER_STATUS_CONFIRMED", label: "Confirmed" },
  { id: 3, type: "ORDER_STATUS_PROCESSING", label: "Processing" },
  { id: 5, type: "ORDER_STATUS_FAILED", label: "Failed" },
  { id: 9, type: "ORDER_STATUS_POSTPONED", label: "Postponed" },
  { id: 10, type: "ORDER_STATUS_PENDING_CANCELLATION", label: "Pending Cancellation" }
];

export const ORDER_ACTIVE = [
  { id: 1, type: "ORDER_STATUS_PENDING", label: "Pending" },
  { id: 2, type: "ORDER_STATUS_CONFIRMED", label: "Confirmed" },
  { id: 3, type: "ORDER_STATUS_PROCESSING", label: "Processing" },
  { id: 5, type: "ORDER_STATUS_FAILED", label: "Failed" },
  { id: 9, type: "ORDER_STATUS_POSTPONED", label: "Postponed" },
  { id: 10, type: "ORDER_STATUS_PENDING_CANCELLATION", label: "Pending Cancellation" }
];

export const ORDER_PAYMENT_NOT_PAID = 0,
  ORDER_PAYMENT_PAID = 1,
  ORDER_PAYMENT_NOT_FULL = 2,
  ORDER_PAYMENT_NOT_APPLICABLE = 3;

export const ACCOUNTANT_ORDER_HISTORY_PAYMENT_STATUS = [
  { id: 1, type: "ORDER_PAYMENT_PAID", label: "Paid" },
  { id: 3, type: "ORDER_PAYMENT_NOT_APPLICABLE", label: "Not Applicable" }
];

export const DRIVER_STATUS_IN_THE_OFFICE = 1;
export const DRIVER_STATUS_FREE = 2;
export const DRIVER_STATUS_ON_DUTY = 3;
export const DRIVER_STATUS_ON_THE_WAY = 4;
export const DRIVER_STATUS_AWAY = 5;

export const DRIVER = [
  { id: 1, type: "DRIVER_STATUS_IN_THE_OFFICE", label: "In The Warehouse" },
  { id: 2, type: "DRIVER_STATUS_FREE", label: "Free" },
  { id: 3, type: "DRIVER_STATUS_ON_DUTY", label: "On Duty" },
  { id: 4, type: "DRIVER_STATUS_ON_THE_WAY", label: "On The Way To Office" },
  { id: 5, type: "DRIVER_STATUS_AWAY", label: "Away" }
];

export const PIGGY_BANK_STATUS_COLLECTING = 1,
  PIGGY_BANK_STATUS_REQUESTED = 2,
  PIGGY_BANK_STATUS_DELIVERED = 3;

export const PIGGY_BANK_STATUS = [
  { id: 1, type: "PIGGY_BANK_STATUS_COLLECTING", label: "Collecting" },
  { id: 2, type: "PIGGY_BANK_STATUS_REQUESTED", label: "Requested" },
  { id: 3, type: "PIGGY_BANK_STATUS_DELIVERED", label: "Delivered" }
];

export const PACKAGE_STATUS_WITH_CUSTOMER = 1;
export const PACKAGE_STATUS_WITH_DRIVER = 2;
export const PACKAGE_STATUS_IN_THE_OFFICE = 3;
export const PACKAGE_STATUS_WITH_RECIPIENT = 4;
export const PACKAGE_STATUS_DAMAGED = 5;

export const BARCODE_STATUS_PENDING = 0;
export const BARCODE_STATUS_SCANNED = 1;

export const DRIVER_SUBMISSION_STATUS_NOT_CONFIRMED = 1;
export const DRIVER_SUBMISSION_STATUS_CONFIRMED = 2;
export const DRIVER_SUBMISSION_STATUS_CONFIRMED_WITH_EDIT = 3;
export const DRIVER_SUBMISSION_STATUS_REJECTED = 4;

export const DRIVER_SUBMISSION_STATUS = [
  { id: 1, type: "STATUS_NOT_CONFIRMED", label: "Not Confirmed" },
  { id: 2, type: "STATUS_CONFIRMED", label: "Confirmed" },
  { id: 3, type: "STATUS_CONFIRMED_WITH_EDIT", label: "Confirmed with Edit" },
  { id: 4, type: "STATUS_REJECTED", label: "Rejected" }
];

export const ACTIVE_DRIVER_SUBMISSION_STATUS = [
  { id: 1, type: "STATUS_NOT_CONFIRMED", label: "Not Confirmed" }
];

export const HISTORY_DRIVER_SUBMISSION_STATUS = [
  { id: 2, type: "STATUS_CONFIRMED", label: "Confirmed" },
  { id: 3, type: "STATUS_CONFIRMED_WITH_EDIT", label: "Confirmed with Edit" },
  { id: 4, type: "STATUS_REJECTED", label: "Rejected" }
];

export const REQUEST_STATUSES = [
  { id: 1, label: "Pending" },
  { id: 2, label: "Rejected" },
  { id: 3, label: "Approved" }
];

export const REQUEST_PENDING_STATUS = 1;
export const REQUEST_REJECTED_STATUS = 2;
export const REQUEST_APPROVED_STATUS = 3;

export const QUEUE_REQUEST_STATUSES = [
  { id: 0, label: "Pending" },
  { id: 1, label: "Queued" },
  { id: 2, label: "Processing" },
  { id: 3, label: "Completed" },
  { id: 4, label: "Failed" }
];

export const QUEUE_REQUEST_PENDING_STATUS = 0;
export const QUEUE_REQUEST_COMPLETED_STATUS = 3;
export const QUEUE_REQUEST_FAILED_STATUS = 4;


export const CASHBOX_STATUS_IN_PROGRESS = 1;
export const CASHBOX_STATUS_READY_TO_DELIVER = 2;
export const CASHBOX_STATUS_PAID = 3;
export const CASHBOX_STATUS_CANCELLED = 4;

export const CASHBOX = [
  { id: 1, type: "CASHBOX_STATUS_IN_PROGRESS", label: "In Progress" },
  { id: 2, type: "CASHBOX_STATUS_READY_TO_DELIVER", label: "Ready To Deliver" },
  { id: 3, type: "CASHBOX_STATUS_PAID", label: "Paid" },
  { id: 4, type: "CASHBOX_STATUS_CANCELLED", label: "Cancelled" },
 
];