export const FETCH_ZONES_RESOLVED = "FETCH_ZONES_RESOLVED";
export const FETCH_VEHICLES_RESOLVED = "FETCH_VEHICLES_RESOLVED";
export const FETCH_AREAS_RESOLVED = "FETCH_AREAS_RESOLVED";
export const FETCH_AUTOCOMPLETE_AREAS_RESOLVED =
  "FETCH_AUTOCOMPLETE_AREAS_RESOLVED";
export const CLEAR_AUTOCOMPLETE_AREAS_RESOLVED =
  "CLEAR_AUTOCOMPLETE_AREAS_RESOLVED";
export const FETCH_AUTOCOMPLETE_RECIPIENTS_RESOLVED =
  "FETCH_AUTOCOMPLETE_RECIPIENTS_RESOLVED";
export const CLEAR_AUTOCOMPLETE_RECIPIENTS_RESOLVED =
  "CLEAR_AUTOCOMPLETE_RECIPIENTS_RESOLVED";
export const FETCH_LIST_RESOLVED = "FETCH_LIST_RESOLVED";
export const FETCH_RECIPIENTS_RESOLVED = "FETCH_RECIPIENTS_RESOLVED";
export const CREATE_TYPE_RESOLVED = "CREATE_TYPE_RESOLVED";
export const UPDATE_TYPE_RESOLVED = "UPDATE_TYPE_RESOLVED";
export const FETCH_OPERATORS_NAMES_RESOLVED = "FETCH_OPERATORS_NAMES_RESOLVED";
export const FETCH_TOP_AREAS_BY_CUSTOMER_RESOLVED = 'FETCH_TOP_AREAS_BY_CUSTOMER_RESOLVED';
