/**
 * Create a new user object
 * @param {int} id Id
 * @param {string} id_string Id int to string
 * @param {string} full_name Full name
 */
export function user(props) {
  return {
    full_name: props.name,
    id: props.id,
    is_active: props.is_active,
    email: props.email,
    reference_id: props.reference_id,
    phone_number: props.phone_number,
    customer_id: props.customer_id,
    start_date: props.start_date,
    contact: props.contact
      ? {
          id: props.contact.id,
          first_name: props.contact.first_name,
          last_name: props.contact.last_name,
          phone_number: props.contact.phone_number,
          date_of_birth: props.contact.date_of_birth,
          gender: props.contact.gender
        }
      : null,
    roles: props.roles ? props.roles : [],
    time_sheet_status: props.time_sheet_status,
    office: props.office
      ? {
          id: props.office.id,
          name: props.office.name
        }
      : null,
    hr_privilege: props.hr_privilege,
    supervisor_privilege: props.supervisor_privilege
  };
}
