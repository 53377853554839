import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "../styles";
import Button from "@mui/material/Button";
import FilterByAccountManagerOrIndustry, {
  FilterBySelectField,
  FilterByDate,
  FilterByTypeListField
} from "routes/app/routes/orders-page/OrderFilters";
import moment from "moment";
import { ORDER_PENDING_ACTIVE, ORDER_STATUS_SUCCESS } from "constants/Statuses";
import { ORDER_PAYMENT_TYPES_CURRENCY } from "constants/Types";
import CircularProgress from "@mui/material/CircularProgress";

class FilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.data
    };
  }

  handleChange = (name, value) => {
    const { state } = this;
    state[name] = value;
    this.setState({ ...state });
  };

  filter = () => {
    let components = this.props.components;

    let search_input = [];

    let customized_search_input = [];
    if (components.fromCompletedDate && this.state.from_completed_date) {
      customized_search_input.push({
        key: "from_completed_on",
        element: moment(this.state.from_completed_date)
          .hour(0)
          .minute(0)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      });
    }
    if (components.toCompletedDate && this.state.to_completed_date) {
      customized_search_input.push({
        key: "to_completed_on",
        element: moment(this.state.to_completed_date)
          .hour(23)
          .minute(59)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      });
    }
    if (components.fromCreatedDate && this.state.from_created_date) {
      customized_search_input.push({
        key: "from_created_at",
        element: moment(this.state.from_created_date)
          .hour(0)
          .minute(0)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      });
    }
    if (components.toCreatedDate && this.state.to_created_date) {
      customized_search_input.push({
        key: "to_created_at",
        element: moment(this.state.to_created_date)
          .hour(23)
          .minute(59)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      });
    }
    if (
      components.orderStatus &&
      this.state.order_status &&
      this.state.order_status !== 0
    ) {
      search_input.push({ key: "status", element: this.state.order_status });
    }
    if (
      components.paymentCurrencyType &&
      this.state.payment_currency_type_id !== 0
    ) {
      var payment_currency_type = ORDER_PAYMENT_TYPES_CURRENCY.find(
        o => o.id === this.state.payment_currency_type_id
      );

      if (payment_currency_type) {
        if (payment_currency_type.currency_id) {
          customized_search_input.push({
            key: "collections.currency_id",
            element: payment_currency_type.currency_id
          });
        }
        if (payment_currency_type.payment_type_id) {
          customized_search_input.push({
            key: "collections.type_id",
            element: payment_currency_type.payment_type_id
          });
        } else {
          customized_search_input.push({
            key: "collections_null",
            element: true
          });
        }
      }
      search_input.push({
        key: "payment_currency_type_id",
        element: this.state.payment_currency_type_id
      });
    }
    if (components.receiverZone && this.state.receiver_zone_id !== 0) {
      search_input.push({
        key: "receiver_zone_id",
        element: this.state.receiver_zone_id
      });
    }
    if (components.accountManager && this.state.account_manager_id !== 0) {
      search_input.push({
        key: "customer_account_manager_id",
        element: this.state.account_manager_id
      });
    }

    this.props.handleOrderFilter(
      this.state,
      search_input,
      customized_search_input
    );
  };

  render() {
    const {
      classes,
      isLoading,
      components,
      statusesOptions,
      globalFilter,
      closed_orders_filter
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            {components.fromCreatedDate && (
              <div className="col-md-4">
                <FilterByDate
                  value={this.state.from_created_date}
                  handleChange={this.handleChange}
                  name={"from_created_date"}
                  label={"From Created Date"}
                />
              </div>
            )}
            {components.toCreatedDate && (
              <div className="col-md-4">
                <FilterByDate
                  value={this.state.to_created_date}
                  handleChange={this.handleChange}
                  name={"to_created_date"}
                  label={"To Created Date"}
                />
              </div>
            )}
            {components.fromCompletedDate && (
              <div className={!closed_orders_filter ? "col-md-4" : "col-md-3"}>
                <FilterByDate
                  value={this.state.from_completed_date}
                  handleChange={this.handleChange}
                  name={"from_completed_date"}
                  label={"From Completed Date"}
                />
              </div>
            )}
            {components.toCompletedDate && (
              <div className={!closed_orders_filter ? "col-md-4" : "col-md-3"}>
                <FilterByDate
                  value={this.state.to_completed_date}
                  handleChange={this.handleChange}
                  name={"to_completed_date"}
                  label={"To Completed Date"}
                />
              </div>
            )}
            {components.orderStatus && (
              <div className={!closed_orders_filter ? "col-md-4" : "col-md-3"}>
                <FilterBySelectField
                  value={this.state.order_status}
                  handleChange={this.handleChange}
                  name={"order_status"}
                  input_label={"Order Status"}
                  list={
                    statusesOptions ? statusesOptions : ORDER_PENDING_ACTIVE
                  }
                />
              </div>
            )}
            {components.paymentCurrencyType && (
              <div className={!closed_orders_filter ? "col-md-4" : "col-md-3"}>
                <FilterBySelectField
                  value={this.state.payment_currency_type_id}
                  handleChange={(name, new_value) => {
                    this.handleChange(name, new_value);
                    this.handleChange("order_status", ORDER_STATUS_SUCCESS);
                  }}
                  name={"payment_currency_type_id"}
                  input_label={"Collected Amount Type"}
                  list={ORDER_PAYMENT_TYPES_CURRENCY}
                />
              </div>
            )}
            {components.receiverZone && (
              <div className="col-md-4">
                <FilterByTypeListField
                  value={this.state.receiver_zone_id}
                  handleChange={this.handleChange}
                  name={"receiver_zone_id"}
                  input_label={"Receiver's Zone"}
                  typeList_name={"zones"}
                  text_attribute={"description"}
                />
              </div>
            )}
            {components.accountManager && (
              <div className="col-md-3">
                <FilterByAccountManagerOrIndustry
                  filter_by_account_manager={true}
                  value={this.state.account_manager_id}
                  handleChange={this.handleChange}
                />
              </div>
            )}
            {globalFilter && (
              <div className="col-md-9">
                <div
                  className="float-md-right"
                  style={{
                    top: "50%",
                    position: "relative",
                    transform: "translateY(-50%)"
                  }}
                >
                  {!isLoading ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.filter_button}
                      onClick={this.filter}
                    >
                      Update Dashboard
                    </Button>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {!globalFilter && (
          <div className="col-md-12">
            <div className={classes.filter_div}>
              {!isLoading ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.filter_button}
                  onClick={this.filter}
                >
                  Filter
                </Button>
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

FilterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  isCustomer: PropTypes.bool
};

export default withStyles(styles)(FilterComponent);
