import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRole } from "utils/helpers";
import moment from "moment";

class StatBoxesToday extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true
    };
  }

  render() {
    const { data, user } = this.props;
    const user_role = getRole(user);
    const customer = ["customer"];
    const operator = ["admin", "operator", "accountant"];
    const customer_and_operator = [
      "customer",
      "admin",
      "operator",
      "accountant"
    ];
    return (
      <article className="article">
        <h2 className="article-title">Today Statistics</h2>
        <div className="row">
          {customer.includes(user_role) &&
            (data.pending_orders_today || data.pending_orders_today == 0) && (
              <div className="col-xl-3 col-sm-6">
                <div className="box box-default">
                  <div className="box-top">
                    <span>{data.pending_orders_today}</span>
                  </div>
                  <div className="box-info">
                    <span>Pending Orders</span>
                  </div>
                  <div className="box-bottom">
                    <i className="material-icons color-info">reorder</i>
                  </div>
                </div>
              </div>
            )}
          {customer.includes(user_role) &&
            (data.orders_placed_today || data.orders_placed_today == 0) && (
              <div className="col-xl-3 col-sm-6">
                <div className="box box-default">
                  <div className="box-top">
                    <span>{data.orders_placed_today}</span>
                  </div>
                  <div className="box-info">
                    <span>Placed Orders</span>
                  </div>
                  <div className="box-bottom">
                    <i className="material-icons color-warning">
                      add_shopping_cart
                    </i>
                  </div>
                </div>
              </div>
            )}
          {customer.includes(user_role) &&
            (data.delivered_orders_today ||
              data.delivered_orders_today == 0) && (
              <div className="col-xl-3 col-sm-6">
                <div className="box box-default">
                  <div className="box-top">
                    <span>{data.delivered_orders_today}</span>
                  </div>
                  <div className="box-info">
                    <span>Delivered Orders</span>
                  </div>
                  <div className="box-bottom">
                    <i
                      className="material-icons color-success"
                      style={{ paddingLeft: "40%" }}
                    >
                      check_circle_outline
                    </i>
                  </div>
                </div>
              </div>
            )}
          {customer.includes(user_role) &&
            (data.on_the_way_orders_today ||
              data.on_the_way_orders_today == 0) && (
              <div className="col-xl-3 col-sm-6">
                <div className="box box-default">
                  <div className="box-top">
                    <span>{data.on_the_way_orders_today}</span>
                  </div>
                  <div className="box-info">
                    <span>Orders On the Way</span>
                  </div>
                  <div className="box-bottom">
                    <i className="material-icons color-danger">train</i>
                  </div>
                </div>
              </div>
            )}
        </div>
      </article>
    );
  }
}

StatBoxesToday.propTypes = {
  data: PropTypes.any,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};
export default connect(mapStateToProps)(StatBoxesToday);
