import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { styles } from "./styles";
import AutoComplete from "@mui/material/Autocomplete";
import _ from "lodash";
import { TextField } from "@mui/material";

class EnhancedAutoComplete extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    if (this.props.notStrict) {
      //do nothing
    } else if (!this.props.value) {
      this.props.onUpdateInput("", this.props.dataSource, {}, this.props.name);
    }
  }

  render() {
    const {
      floatingLabelText,
      style,
      errorText,
      dataSourceConfig,
      onNewRequest,
      fullWidth,
      dataSource,
      searchText,
      onUpdateInput,
      disabled,
      textFieldStyle,
      isOptionEqualToValue,
      getOptionLabel,
      getOptionKey,
      freeSolo,
      multiline
    } = this.props;
    return (
      <AutoComplete
        freeSolo={true}
        aria-label={floatingLabelText}
        onChange={onNewRequest}
        onInputChange={onUpdateInput}
        openOnFocus
        fullWidth={fullWidth}
        options={dataSource}
        //onClose={this.onClose}
        value={dataSource?.find((option) => option.id === this.props.value) || null}
        inputValue={searchText || ""}
        getOptionLabel={getOptionLabel}
        getOptionKey={getOptionKey}
        isOptionEqualToValue={isOptionEqualToValue}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={floatingLabelText}
            error={!!errorText}
            helperText={errorText}
            variant="standard"
            InputProps={{ ...params.InputProps, sx: { ...textFieldStyle } }}
            style={{ maxHeight: "200px" }}
            multiline={multiline}
          />
        )}
        style={style}
      />
    );
  }
}

EnhancedAutoComplete.propTypes = {
  floatingLabelText: PropTypes.string.isRequired,
  name: PropTypes.string,
  style: PropTypes.any,
  value: PropTypes.any,
  classes: PropTypes.object.isRequired,
  dataSourceConfig: PropTypes.object,
  onNewRequest: PropTypes.func,
  dataSource: PropTypes.array,
  fullWidth: PropTypes.bool,
  searchText: PropTypes.any,
  notStrict: PropTypes.bool,
  onUpdateInput: PropTypes.func,
  errorText: PropTypes.any
};

export default withStyles(styles)(EnhancedAutoComplete);
