import moment from 'moment';
import { dateFormatter, datetimeFormatter } from 'utils';

/**
 * Create a new order import object
 */
export function orderImport(props, page = null) {
  let import_date = moment(props.import_date).isValid() ? moment.utc(props.import_date, 'YYYY-MM-DD HH:mm:ss').local(): null;
  let expiration_date = moment(props.expiration_date).isValid() ? moment.utc(props.expiration_date, 'YYYY-MM-DD HH:mm:ss').local(): null;
  return {
    id: props.import_id,
    file_name: props.file_name,
    import_date:  import_date ? dateFormatter(import_date) + ' at ' + datetimeFormatter(import_date): 'N/A' ,
    expiration_date: expiration_date ? dateFormatter(expiration_date) + ' at ' + datetimeFormatter(expiration_date): 'N/A' ,
    is_reverted : props.is_reverted,
    user: props.user,
  };
}
