import React, { Component, createRef } from "react";
import { isAdmin, isAccountant, isOperator } from "utils/helpers";
import SelectField from "components/EnhancedSelectField";
import MenuItem from "@mui/material/MenuItem";
import TextField from "components/EnhancedTextField";
import PhoneNumberInput from "components/PhoneNumberInput";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { styles } from "../../../create-customer/styles";
import ReeValidate from "ree-validate";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Checkbox, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import { DEFAULT_COUNTRY } from "constants/Data";
import * as settingService from "services/settingService";
import SelectReceiverSender from "components/FormComponents";
import * as cashboxService from "services/cashboxService";
import moment from "moment";
import DateField from "@mui/material/TextField";


class CashboxSetup extends Component {
  constructor(props) {
    super(props);
    this.dateInputRef = createRef();
    this.validator = new ReeValidate({});

    this.state = {
      errors: this.validator.errors,
      cashboxSettingId: null,
      cashboxFrequency: null,
      notes: "",
      contactName: "",
      locationId: "",
      locationName: "",
      receiver_location: "",
      auditingDays: 3,
      officeId: 1,
      cashboxPhoneNumber: "",
      cashboxEmail: "",
      selectedWeekDays: null,
      offices: [],
      currencies: [],
      selectedDate: null,
      officialInvoice: false,
      createUponClientRequest: false,
      balances: [],
      deductFromNextCashbox: false,
      paySeparately: false,
      isEditMode: false,
      cashboxDetails: [],
      balancesToRemove: [],
      members: [],
      selectedDates: [],
      showDatePicker: false,
    };
  }

  componentDidMount() {
    this.loadOffices();
    this.loadCurrencies();
    if (this.props.isEditMode) {
      this.fetchCashboxDetails(this.props.customerId);
    }
  }
  loadOffices() {
    this.setState((state) => ({ isLoading: true }));
    this.props
      .dispatch(settingService.fetchOffices())
      .then((response) => {
        this.setState({ offices: response.data || [] });
        const responseError = {
          isError: false,
          code: "",
          text: "",
        };
        this.setState((state) => ({ isLoading: false, responseError }));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error,
        };
        this.setState((state) => ({ isLoading: false, responseError }));
      });
  }

  loadCurrencies = () => {
    this.setState((state) => ({ isLoading: true }));

    this.props
      .dispatch(settingService.fetchCurrencies())
      .then((data) => {
        this.setState({ currencies: data.data || [] });
        const responseError = {
          isError: false,
          code: "",
          text: "",
        };
        this.setState((state) => ({ isLoading: false, responseError }));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error,
        };
        this.setState((state) => ({ isLoading: false, responseError }));
      });
  };
  fetchCashboxDetails = (cashboxId) => {
    this.props
      .dispatch(cashboxService.fetchCashboxDetails(cashboxId))
      .then((data) => {
        const { cashbox } = data;
        if (cashbox) {
          this.setState({
            cashboxSettingId: cashbox.id,
            cashboxFrequency: cashbox.frequency,
            notes: cashbox.notes,
            contactName: cashbox.contact_name,
            locationId: cashbox.location_id,
            auditingDays: cashbox.auditing_days,
            officeId: cashbox.office_id,
            cashboxPhoneNumber: cashbox.phone_number,
            cashboxEmail: cashbox.email,
            selectedWeekDays: JSON.parse(cashbox.cashbox_weekdays),
            selectedDate: data.cashboxMonthlyDate
              ? new Date(data.cashboxMonthlyDate)
              : null,
            // selectedDates: JSON.parse(data.cashboxSelectedDates),
            selectedDates: data.cashboxSelectedDates ? data.cashboxSelectedDates : JSON.parse("[]"),
            officialInvoice: cashbox.official_invoice,
            createUponClientRequest: cashbox.create_upon_client_request,
            deductFromNextCashbox: cashbox.deducted_payment,
            paySeparately: cashbox.separate_payment,
            isEditMode: true,
            receiver_location: data.location_name,
            balances: data.balances,
            members: data.memberNames,
          });
        } else {
          this.setState({
            members: data.memberNames,
          });
        }
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error,
        };
        this.setState({
          responseError,
        });
      });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleCurrencyChange = (value, balanceIndex) =>  {
    const balances = [...this.state.balances];
    if (balances.length > balanceIndex) {
      if (value !== null && value !== undefined) {
        balances[balanceIndex].currency_id = value;
        this.setState({ balances });
      }
    }
  };

  handleCheck = (event, isInputChecked, name) => {
    if (name === "officialInvoice") {
      this.setState({
        [name]: isInputChecked,
        deductFromNextCashbox: false,
        paySeparately: true,
      });
    } else {
      if (name === "deductFromNextCashbox" || name === "paySeparately") {
        this.setState({ deductFromNextCashbox: !this.state.deductFromNextCashbox,
          paySeparately: !this.state.paySeparately,
         });
      } else {
        this.setState({ [name]: isInputChecked });
      }
    }
  };

  handleAddValidationErrorChange = (name, value) => {
    if (value) {
      this.state.errors.add({
        field: name,
        msg: value,
      });
    } else {
      this.state.errors.remove(name);
    }
  };
  handleSelectChange = (value, name) => {
    let maxSelection = 0;
    if (name === "cashboxFrequency") {
      this.setState({ selectedWeekDays: [] });
    } else if (name === "selectedWeekDays") {
      if (this.state.cashboxFrequency === 1) maxSelection = 0;
      if (
        this.state.cashboxFrequency === 2 ||
        this.state.cashboxFrequency === 5
      )
        maxSelection = 1;
      if (this.state.cashboxFrequency === 3) maxSelection = 2;
      if (this.state.cashboxFrequency === 4) maxSelection = 3;
      if (value.length > maxSelection) {
        value = value.slice(0, maxSelection);
      }
    }
    this.setState({ [name]: value });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleBalanceInputChange = (event, index) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const balances = [...this.state.balances];
    balances[index][name] = value;

    this.setState({ balances });
  };

  handleAddBalance = () => {
    const { balances, currencies } = this.state;

    if (balances.length < currencies.length) {
      const availableCurrencies = currencies.filter(
        (currency) =>
          !balances.some((balance) => balance.currency_id === currency.id),
      );
      if (availableCurrencies.length > 0) {
        this.setState((prevState) => ({
          balances: [...prevState.balances, { currency_id: "", value: "" }],
        }));
      }
    }
  };
  openDatePicker = () => {
    this.setState({ showDatePicker: true });
  };

  closeDatePicker = () => {
    this.setState({ showDatePicker: false });
  };

  handleRemoveBalance = (balanceIndex) => {
    this.setState((prevState) => {
      const balanceToRemove = prevState.balances[balanceIndex];
      return {
        balances: prevState.balances.filter(
          (_, index) => index !== balanceIndex,
        ),
        balancesToRemove: [...prevState.balancesToRemove, balanceToRemove],
      };
    });
  };

  handleOfficeChange = (event) => {
    this.setState({
      officeId: event.target.value,
    });
  };
  handleContactChange = (event) => {
    this.setState({
      contactName: event.target.value,
    });
  };
  handleDateChange = (date) => {
    this.setState((prevState) => {
      let newSelectedDates = [...prevState.selectedDates];
      if (newSelectedDates.length < 3) {
        newSelectedDates.push(moment(date).format("YYYY-MM-DD"));
      } else {
        newSelectedDates.shift();
        newSelectedDates.push(moment(date).format("YYYY-MM-DD"));
      }
      newSelectedDates.sort();
      return {
        selectedDates: newSelectedDates,
        selectedDate: newSelectedDates[0],
        showDatePicker: false,
      };
    });
  };
  toggleDatePicker = () => {
    this.setState((prevState) => ({
      showDatePicker: !prevState.showDatePicker,
    }));
  };
  handleRemoveDate = (indexToRemove) => {
    this.setState((prevState) => {
      const newSelectedDates = prevState.selectedDates.filter(
        (_, index) => index !== indexToRemove,
      );
      return {
        selectedDates: newSelectedDates,
        selectedDate: newSelectedDates[0] || null,
      };
    });
  };
  getFrequencyText = () => {
    if (
      this.state.cashboxFrequency != 6 &&
      this.state.cashboxFrequency != 7 &&
      this.state.cashboxFrequency != 8
    ) {
      return "Monthly";
    } else if (
      this.state.selectedDates.length == 0 &&
      (this.state.cashboxFrequency == 6 ||
        this.state.cashboxFrequency == 7 ||
        this.state.cashboxFrequency == 8)
    ) {
      return "Monthly";
    } else if (this.state.selectedDates.length == 1) {
      return "Once per month";
    } else if (this.state.selectedDates.length == 2) {
      return "Twice per month";
    } else if (this.state.selectedDates.length == 3) {
      return "Three times per month";
    }
  };
  getFrequencyId = () => {
    if (this.state.selectedDates.length == 0) {
      return 6;
    } else if (this.state.selectedDates.length == 1) {
      return 6;
    } else if (this.state.selectedDates.length == 2) {
      return 7;
    } else if (this.state.selectedDates.length == 3) {
      return 8;
    }
  };
  render() {
    const { errors } = this.state;
    const { user, classes, isEditMode } = this.props;
    const renderFrequencyField =
      this.state.cashboxFrequency === 6 ? (
        <div className="col-md-8">
          <div style={{ position: "relative" }}>
            <div
              style={{
                padding: "8px",
                minHeight: "48px",
                cursor: "pointer",
                marginTop: "28px",
              }}
              onClick={this.toggleDatePicker}
            >
              <div
                style={{
                  color: "#666",
                  position: "absolute",
                  top: "-20px",
                  fontSize: "12px",
                }}
              >
                Select Dates (Max 3)
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                {this.state.selectedDates.map((date, index) => (
                  <div
                    key={index}
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      flexShrink: 0,
                    }}
                  >
                    <span>{date}</span>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleRemoveDate(index);
                      }}
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#666",
                      }}
                    >
                      ×
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <IconButton
              sx={{
                position: "absolute",
                right: "250px",
                top: "-32px",
                cursor: "pointer",
                color: "#ffc107",
                "&:Disabled": { color: "grey !important" },
              }}
              onClick={this.openDatePicker}
              disabled={this.state.selectedDates.length >= 3}
            >
              <i className="material-icons">add_circle</i>
            </IconButton>
            <MobileDatePicker
              open={this.state.showDatePicker}
              onClose={this.closeDatePicker}
              ref={this.dateInputRef}
              onChange={this.handleDateChange}
              renderInput={(params) => <DateField {...params} sx={{ display: "none" }} />}
            />
          </div>
          {this.state.showDatePicker && (
            <div
              style={{
                position: "absolute",
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                borderRadius: "4px",
              }}
            ></div>
          )}
        </div>
      ) : (
        <div className="col-md-4">
          <SelectField
            floatingLabelText="Scheduled Days*"
            multiple={true}
            value={this.state.selectedWeekDays}
            onChange={(event) =>
              this.handleSelectChange(event.target.value, "selectedWeekDays")
            }
            fullWidth={true}
            disabled={
              !this.state.cashboxFrequency || this.state.cashboxFrequency === 1
            }
          >
            <MenuItem value={1} >Monday</MenuItem>
            <MenuItem value={2} >Tuesday</MenuItem>
            <MenuItem value={3} >Wednesday</MenuItem>
            <MenuItem value={4} >Thursday</MenuItem>
            {DEFAULT_COUNTRY !== 101 && (
              <MenuItem value={5} >Friday</MenuItem>
            )}
            <MenuItem value={6} >Saturday</MenuItem>
            {DEFAULT_COUNTRY === 101 && (
              <MenuItem value={7} >Sunday</MenuItem>
            )}
          </SelectField>
        </div>
      );
    if (this.props.onCreate) {
      const { offices, currencies, ...stateWithoutOfficesAndCurrencies } =
        this.state;
      this.props.onCreate(stateWithoutOfficesAndCurrencies);
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <h4 style={{ fontWeight: "bold" }}>Cashbox Settings</h4>
        </div>
        <div className="col-md-4">
          <SelectField
            floatingLabelText="Cashbox Frequency*"
            value={this.state.cashboxFrequency}
            onChange={(event) =>
              this.handleSelectChange(event.target.value, "cashboxFrequency")
            }
            fullWidth={true}
            style={{ marginRight: "10px" }}
          >
            <MenuItem value={6} >{this.getFrequencyText()}</MenuItem>
            <MenuItem value={5} >Once every two weeks</MenuItem>
            <MenuItem value={2} >Once a week</MenuItem>
            {(isAdmin(user) || isAccountant(user) || isOperator(user)) && (
              <MenuItem value={3} >Twice a week</MenuItem>
            )}
            {(isAdmin(user) || isAccountant(user) || isOperator(user)) && (
              <MenuItem value={4} >Three times a week</MenuItem>
            )}
            {(isAdmin(user) || isAccountant(user) || isOperator(user)) && (
              <MenuItem value={1} >Daily</MenuItem>
            )}
          </SelectField>
        </div>

        {renderFrequencyField}
        {isEditMode && (
          <React.Fragment>
            <div className="col-md-4">
              <SelectField
                name="contactName"
                fullWidth={true}
                floatingLabelText="Contact Name *"
                value={this.state.contactName}
                onChange={this.handleContactChange}
              >
                {this.state.members && this.state.members.map((member, index) => (
                  <MenuItem key={index} value={member}>{member}</MenuItem>
                ))}
              </SelectField>
            </div>
            <div className="col-md-4">
              <SelectReceiverSender
                name={"receiver_location"}
                name_id={"locationId"}
                value_id={this.state.locationId}
                value={this.state.receiver_location}
                attribute={"full_location"}
                label={"Location"}
                handleChange={this.handleChange}
                errors={errors}
                customer_id={this.props.customerId}
              />
            </div>
            <div className="col-md-4">
              <SelectField
                name="officeId"
                floatingLabelText="Wakilni Branch *"
                value={this.state.officeId}
                onChange={this.handleOfficeChange}
                fullWidth={true}
              >
                {this.state.offices &&
                  this.state.offices.map((office) => (
                    <MenuItem
                      key={office.id}
                      value={office.id}
                    >{office.office_name}</MenuItem>
                  ))}
              </SelectField>
            </div>
            <div className="col-md-4">
              <PhoneNumberInput
                name="cashboxPhoneNumber"
                label_text="Phone Number *"
                value={this.state.cashboxPhoneNumber}
                handleChange={this.handleChange}
                handleAddValidationErrorChange={
                  this.handleAddValidationErrorChange
                }
              />
            </div>
            <div className="col-md-4">
              <TextField
                name="cashboxEmail"
                floatingLabelText="Email *"
                fullWidth={true}
                value={this.state.cashboxEmail}
                onChange={this.handleInputChange}
              />
            </div>
          </React.Fragment>
        )}

        <div className="col-md-4">
          <TextField
            name="notes"
            floatingLabelText="Notes"
            fullWidth={true}
            value={this.state.notes}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="col-md-4">
          <TextField
            name="auditingDays"
            floatingLabelText="Auditing Days *"
            fullWidth={true}
            type="number"
            value={this.state.auditingDays}
            onChange={this.handleInputChange}
          />
        </div>
        <div
          className="col-md-6"
          style={{ marginTop: isEditMode ? "6%" : "3%" }}
        >
        <div style={{ display: "flex", flexDirection: "column", marginTop: "-20px" }}>
        <FormControlLabel
            control={
              <Checkbox
                checked={this.state.createUponClientRequest}
                onChange={(event, checked) =>
                  this.handleCheck(event, checked, "createUponClientRequest")
                }
              />
            }
            componentsProps={{
              typography: { fontWeight: "bold", fontSize: "14px !important" },
            }}
            label="Create Upon Client Request"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.officialInvoice}
                onChange={(event, checked) =>
                  this.handleCheck(event, checked, "officialInvoice")
                }
              />
            }
            sx={{ marginTop: "-10px" }}
            componentsProps={{
              typography: { fontWeight: "bold", fontSize: "14px !important" },
            }}
            label="Monthly Official Invoice"
          />
          </div>
          {this.state.officialInvoice ? (
            <div style={{ marginLeft: isEditMode ? "11%" : "4%" }}>
              <p
                style={{
                  marginLeft: "1%",
                  fontSize: "smaller",
                  fontStyle: "italic",
                  color: "grey",
                }}
              >
                Make sure that the MOF Field is filled
              </p>
              <RadioGroup
                name="deductOrPay"
                defaultValue={
                  this.state.deductFromNextCashbox ? "deduct" : "pay"
                }
                value={this.state.deductFromNextCashbox ? "deduct" : "pay"}
                onChange={(event, newValue) => {
                  if (newValue === "deduct") {
                    this.handleCheck(event, true, "deductFromNextCashbox");
                  } else {
                    this.handleCheck(event, true, "paySeparately");
                  }
                }}
              >
                <FormControlLabel
                  label="Deducted from the next Cashbox"
                  value="deduct"
                  control={<Radio />}
                  componentsProps={{
                    typography: { fontSize: "14px !important" },
                  }}
                />
                <FormControlLabel label="Paid Separately" value="pay" control={<Radio />} sx={{ marginTop: "-10px" }} componentsProps={{typography: { fontSize: "14px !important" }}}/>
              </RadioGroup>
            </div>
          ) : null}
        </div>
        {(this.state.cashboxSettingId &&
          (isAdmin(user) || isAccountant(user))) ||
        (!this.state.cashboxSettingId &&
          (isAdmin(this.props.user) ||
            isAccountant(this.props.user) ||
            isOperator(this.props.user))) ? (
          <div className="col-md-12">
            <Button
              variant="contained"
              onClick={this.handleAddBalance}
              className={classes.raised_buttons}
              style={{
                backgroundColor: "rgb(256,196,4)",
                width: "170px",
                height: "35px",
                marginTop: "5%",
              }}
            >
              Add Balance
              <Tooltip
                id="tooltip-fab"
                title="Add Balance"
                className="action_tooltip"
              >
                <IconButton>
                  <i className="material-icons">add_circle</i>
                </IconButton>
              </Tooltip>
            </Button>
            {this.state.balances.map((balance, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <SelectField
                  floatingLabelText="Currency"
                  value={balance.currency_id}
                  onChange={(event) => this.handleCurrencyChange(event.target.value, index)}
                  style={{ marginRight: "10px" }}
                  fullWidth={false}
                >
                  {this.state.currencies.map((currency) => (
                    <MenuItem
                      key={currency.id}
                      value={currency.id}
                      disabled={
                        this.state.balances.length &&
                        this.state.balances.some(
                          (balance) => balance.currency_id === currency.id,
                        )
                      }
                    >{currency.code}</MenuItem>
                  ))}
                </SelectField>
                <TextField
                  name="value"
                  type="number"
                  value={balance.value}
                  onChange={(event) =>
                    this.handleBalanceInputChange(event, index)
                  }
                  style={{ marginLeft: "10px" }}
                  floatingLabelText="Value"
                />
                <Tooltip
                  id="tooltip-fab"
                  title="Delete Balance"
                  className="action_tooltip"
                >
                  <IconButton onClick={() => this.handleRemoveBalance(index)}>
                    <i className="material-icons red-icon">remove_circle</i>
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
CashboxSetup.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    officeList: state.settings.officeList,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(CashboxSetup));
