import {
  FETCH_SHOPIFY_ORDERS,
  CREATE_SHOPIFY_BULK,
  FETCH_FEEL22_ORDERS,
  CREATE_FEEL22_BULK,
  FETCH_WOOCOMMERCE_ORDERS,
} from '../ActionTypes/third-party';
import _ from 'underscore';

const initialState = {
  shopify: {
    orderList: [],
    reinstallPrompt: '',
    paginationLinks: [],
  },
  feel22: {
    orderList: [],
  },
  woocommerce: {
    orderList: [],
  },
};

const thirdPartyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WOOCOMMERCE_ORDERS:
      return Object.assign({}, state, {
        woocommerce: {
          ...state.woocommerce,
          orderList: action.payload,
        },
      });
    case FETCH_SHOPIFY_ORDERS:
      return Object.assign({}, state, {
        shopify: {
          ...state.shopify,
          orderList: action.payload.orders,
          paginationLinks: action.payload.paginationLinks,
          reinstallPrompt: action.payload.reinstallPrompt,
        },
      });
    case FETCH_FEEL22_ORDERS:
      return Object.assign({}, state, {
        feel22: {
          ...state.feel22,
          orderList: action.payload,
        },
      });
    case CREATE_SHOPIFY_BULK: {
      let orderList = JSON.parse(JSON.stringify(state.shopify.orderList));
      action.payload.map((item) => {
        if (item.orderDetails.app_ref_id) {
          let index = _.findIndex(orderList, function (o) {
            return o.id == item.orderDetails.app_ref_id;
          });
          if (index || index == 0) {
            orderList[index].fulfillment_status = 'fulfilled';
            orderList[index].tracking_status = item.status_code;
            orderList[index].related_order = item.id;
          }
        }
      });
      return Object.assign({}, state, {
        shopify: {
          ...state.shopify,
          orderList: orderList,
        },
      });
    }
    case CREATE_FEEL22_BULK: {
      let orderList = JSON.parse(JSON.stringify(state.feel22.orderList));
      action.payload.map((item) => {
        if (item.orderDetails.app_ref_id) {
          let index = _.findIndex(orderList, function (o) {
            return o.id == item.orderDetails.app_ref_id;
          });
          if (index || index == 0) {
            orderList[index].tracking_status = item.status_code;
            orderList[index].related_order = item.id;
          }
        }
      });
      return Object.assign({}, state, {
        feel22: {
          ...state.feel22,
          orderList: orderList,
        },
      });
    }
    default:
      return state;
  }
};

export default thirdPartyReducer;
