import {
  COMPLETE_MODAL,
  RESCHEDULE_MODAL,
  CREATE_STAFF_MODAL,
  EDIT_STAFF_MODAL,
  CLOSE_FAIL_MODAL,
  POSTPONE_MODAL,
  CONFRIM_MODAL,
  DECLINE_MODAL,
  FAIL_MODAL,
  CANCEL_TASK_MODAL,
  EDIT_TASK_MODAL,
  REQUEST_SIGN_MODAL,
  ASSIGN_DRIVER_MODAL,
  ENHANCED_ASSIGN_DRIVER_MODAL,
  CANCEL_ORDER_MODAL,
  PENDING_CANCELLATION_ORDER_MODAL,
  CANCEL_MULTIPLE_ORDERS_MODAL,
  UNASSIGN_DRIVER_MODAL,
  EDIT_CURRENCY_MODAL,
  DEFAULT_CURRENCY_MODAL,
  PREFERRED_ALTERNATIVE_CURRENCY_MODAL,
  DELETE_CURRENCY_MODAL,
  CREATE_CURRENCY_MODAL,
  LINK_CURRENCY_MODAL,
  EDIT_ORDER_MODAL,
  PAID_ORDER_MODAL,
  PAY_CUSTOMER_ORDERS_MODAL,
  NEW_CONTACT_MODAL,
  EDIT_CONTACT_MODAL,
  DELETE_CONTACT_MODAL,
  EDIT_RECIPIENT_MODAL,
  DELETE_LOCATION_LOG_MODAL,
  DELETE_LOCATION_MODAL,
  CHECK_IN_MODAL,
  CHECK_OUT_MODAL,
  PASSWORD_RESET_MODAL,
  EMAIL_RESEND_MODAL,
  CONFIRM_TIME_SHEET,
  NEW_LOCATION,
  NEW_RECEIVER,
  CONFIRM_SUBMISSION,
  DECLINE_SUBMISSION,
  EDIT_PRICE,
  DELETE_PRICE,
  NEW_PRICE,
  EDIT_LOCATION,
  EDIT_PHONE_NBR,
  CREATE_BULK_ORDER_MODAL,
  IMPORT_ORDERS_MODAL,
  IMPORT_TASKS_MODAL,
  DETAILS_TASKS,
  EDIT_PIGGY_BANK_MODAL,
  ADD_PIGGY_BANK_NOTE_MODAL,
  ACTIVATE_DEACTIVATE_DRIVER_MODAL,
  ACTIVATE_DEACTIVATE_CUSTOMER_MODAL,
  ACTIVATE_DEACTIVATE_LOCATION_MODAL,
  ACTIVATE_DEACTIVATE_USER_MODAL,
  EDIT_GOLDEN_RULE,
  EDIT_SHOP_NOTE,
  VIEW_SHOP_NOTE,
  VIEW_EXTRA_TAGS,
  EDIT_INHOUSE_NOTE,
  CREATE_APP_MODAL,
  EDIT_APP_MODAL,
  DELETE_APP_MODAL,
  CREATE_TOKEN_MODAL,
  DELETE_TOKEN_MODAL,
  EDIT_TOKEN_MODAL,
  CREATE_WEBHOOK_MODAL,
  DELETE_WEBHOOK_MODAL,
  EDIT_WEBHOOK_MODAL,
  EDIT_CONFIGURATION,
  EDIT_AREA_MODAL,
  DELETE_AREA_MODAL,
  CREATE_AREA_MODAL,
  SHOW_DISTRICT_MODAL,
  CREATE_DISTRICT_MODAL,
  EDIT_DISTRICT_MODAL,
  DELETE_DISTRICT_MODAL,
  SHOW_GOVERNORATE_MODAL,
  CREATE_GOVERNORATE_MODAL,
  EDIT_GOVERNORATE_MODAL,
  DELETE_GOVERNORATE_MODAL,
  ENHANCED_EDIT_ORDER_MODAL,
  EDIT_ACCOUNTANT_ORDER_MODAL,
  EDIT_STOCK_MODAL,
  DELETE_STOCK_MODAL,
  CREATE_STOCK_MODAL,
  CREATE_EXISTING_RECIPIENT_LOCATION_MODAL,
  EDIT_VEHICLE_MODAL,
  DELETE_VEHICLE_MODAL,
  CREATE_VEHICLE_MODAL,
  VIEW_ZONE_MODAL,
  EDIT_ZONE_MODAL,
  DELETE_ZONE_MODAL,
  CREATE_ZONE_MODAL,
  EDIT_OFFICE_MODAL,
  DELETE_OFFICE_MODAL,
  CREATE_OFFICE_MODAL,
  SET_DEFUALT_WAYBILL_ADDRESS_MODAL,
  EDIT_TYPE_MODAL,
  CREATE_TYPE_MODAL,
  REOPEN_ORDER,
  SHIP_ORDER,
  REVIEW_FEEDBACK,
  LINK_CONFIGURATION,
  EDIT_CUSTOM_MESSAGE,
  SUBMISSIONS_DETAILS_TASKS,
  ENHANCED_COMMENTS_MODAL,
  EDIT_CUSTROMER_MODAL,
  CUSTOMIZE_WAYBILL_MODAL,
  EDIT_CUSTROMER_ACCOUNT_MANAGER_MODAL,
  EDIT_MULTIPLE_CUSTROMERS_ACCOUNT_MANAGER_MODAL,
  MULTIPLE_DECLINE_ORDERS,
  ORDER_REPORT_FIELDS,
  CLOSE_PIGGY_BANK_MODAL,
  PIGGY_BANK_ORDERS_MODAL,
  MAKE_DEFAULT_LOCATION_MODAL,
  IS_CRITICAL_MODAL,
  EDIT_CUSTROMER_INDUSTRY_MODAL,
  IMPORTANT_COMMENT_MODAL,
  BARCODE_CONFIRMATION_MODAL,
  ADD_REVIEW_MODAL,
  // Order
  SET_PACKAGE_WITH_DRIVER_MODAL,
  DISPLAY_ORDER_PICTURE_MODAL,
  // Barcode
  GENERATE_AND_PRINT_BARCODES,
  CREATE_DELIVERY_ORDER_FOR_BARCODE_MODAL,
  DELETE_BARCODE_MODAL,
  ATTACH_BARCODE_TO_EXISTING_ORDER,
  CREATE_RETURN_DELIVERY_ORDER_FOR_BARCODE_MODAL,
  // Master barcode
  GENERATE_AND_PRINT_MASTER_BARCODES,
  DELETE_MASTER_BARCODE_MODAL,
  MOVE_ORDERS_FROM_MASTER_BARCODE,

  //Service
  CREATE_SERVICE_MODAL,
  EDIT_SERVICE_MODAL,
  DELETE_SERVICE_MODAL,

  // Requests
  DELETE_REQUEST_MODAL,
  APPROVE_OR_REJECT_REQUEST_MODAL,

  //Banners
  CREATE_BANNER_MODAL,
  EDIT_BANNER_MODAL,
  DELETE_BANNER_MODAL,
  NOTIFICATION_BANNER_MODAL,

  //Marketplace
  UPGRADE_TO_MARKETPLACE_SELLER_MODAL,

  //Internal Banners
  CREATE_INTERNAL_BANNER_MODAL,
  EDIT_INTERNAL_BANNER_MODAL,
  DELETE_INTERNAL_BANNER_MODAL,
  CONFIRM_ORDER_MODAL,
  EXPRESS_WARNING_MODAL,

  //Area Route
  EDIT_AREA_ROUTE_MODAL,
  DELETE_AREA_ROUTE_MODAL,
  CREATE_AREA_ROUTE_MODAL,

  //Assign Area To Driver
  ASSIGN_AREA_TO_DRIVER,
  EDIT_ROUTE_AREA,

  //bulk return modal
  CREATE_BULK_RETURN_MODAL,
  CREATE_CASHBOX_ORDER_MODAL,
  ADD_CASHBOX_EXTRA_FEES_MODAL,
  UPDATE_CASHBOX_EXTRA_FEE_MODAL,

  //Add Excluded Client
  ADD_EXCLUDED_CLIENT,

  CREATE_CASHBOX_SETTLEMENT,
  CANCEL_CASHBOX_MODAL,
  CANCEL_MULTIPLE_CASHBOXES_MODAL,
  CREATE_MULTIPLE_CASHBOX_ORDERS_MODAL,
  ADD_CASHBOX_MODAL,
  EDIT_AUDITED_ORDER_COLLECTIONS_MODAL,
  SAVE_AUDITED_ORDER_COLLECTIONS_MODAL,
  CANCEL_AUDITED_ORDER_COLLECTIONS_MODAL,
  EDIT_ORDER_DELIVERY_PRICE_MODAL,
  //Add Area Confirmation
  AREA_CONFIRMATION_MODAL,
  
  //Holidays
  DELETE_HOLIDAY_MODAL,
  CREATE_HOLIDAY_MODAL,
  EDIT_HOLIDAY_MODAL,

  //international orders
  CREATE_EDIT_INTERNATIONAL_PACKAGE_ITEM_MODAL,
} from '../ActionTypes/modals';
import _ from 'underscore';


const initialState = {
  object: {},
  array: [],
  name: '',
  cashbox :{},
  cashbox_extra_fee_id:'',
  piggy_bank_orders: false,
  close_piggy_bank: false,
  enhanced_comments: false,
  edit_location: false,
  edit_phone_nbr: false,
  from_receiver: false,
  details_tasks: false,
  submissions_details_tasks: false,
  is_export: false,
  confirm: false,
  add_review: false,
  edit_price: false,
  new_price: false,
  delete_price: false,
  confirm_time_sheet: false,
  confirm_submission: false,
  pwd_reset: false,
  email_resend: false,
  check_in: false,
  check_out: false,
  decline: false,
  close_fail: false,
  postpone: false,
  fail: false,
  cancel_task: false,
  edit_task: false,
  complete: false,
  assign_driver: false,
  enhanced_assign_driver: false,
  unassign_driver: false,
  reschedule: false,
  request_sign: false,
  create_staff: false,
  edit_staff: false,
  edit_order: false,
  edit_accountant_order: false,
  enhanced_edit_order: false,
  edit_recipient: false,
  paid_order: false,
  pay_customer_orders: false,
  new_contact: false,
  edit_contact: false,
  edit_customer: false,
  customize_waybill: false,
  delete_contact: false,
  delete_location_log: false,
  delete_location: false,
  cancel_order: false,
  cancel_multiple_orders: false,
  pending_cancel_order: false,
  confirm_order_modal: false,
  new_location: false,
  edit_currency: false,
  set_default_currency: false,
  set_preferred_alternative_currency: false,
  delete_currency: false,
  create_currency: false,
  link_currency: false,
  new_receiver: false,
  decline_submission: false,
  create_bulk_order: false,
  import_orders: false,
  edit_piggy_bank: false,
  piggybank_note: false,
  deduct_prices: false,
  activate_deactivate_driver: false,
  activate_deactivate_customer: false,
  activate_deactivate_location: false,
  activate_deactivate_user: false,
  edit_golden_rule: false,
  view_shop_note: false,
  edit_shop_note: false,
  view_extra_tags: false,
  create_app: false,
  edit_app: false,
  delete_app: false,
  create_token: false,
  delete_token: false,
  edit_token: false,
  edit_inhouse_note: false,
  edit_area: false,
  delete_area: false,
  create_area: false,
  link_configuration: false,
  edit_stock: false,
  delete_stock: false,
  create_stock: false,
  edit_configuration: false,
  edit_custom_message: false,
  edit_vehicle: false,
  delete_vehicle: false,
  create_vehicle: false,
  reopen_order: false,
  view_zone: false,
  edit_zone: false,
  delete_zone: false,
  create_zone: false,
  multiple_decline: false,
  edit_office: false,
  delete_office: false,
  create_office: false,
  set_default_waybill_address: false,
  edit_customer_account_manager: false,
  edit_multiple_customers_account_manager: false,
  edit_type: false,
  create_type: false,
  remove_data_on_done: false,
  ship_order: false,
  show_feedback: false,
  order_report_fields_open: false,
  confirm_order: false,
  create_location_open: false,
  customer_id: null,
  from_order_details: false,
  edit_bulk_return: false,
  is_critical: false,
  make_default_location: false,
  edit_customer_industry: false,
  open_imp_comment: false,
  open_barcode_confirmation: false,
  // order
  open_set_package_with_driver: false,
  open_order_picture_dialog: false,

  // barcode
  generate_and_print_barcodes: false,
  create_delivery_order_for_barcodes: false,
  create_return_delivery_order_for_barcodes: false,
  delete_barcode_dialog: false,
  attach_barcode_to_existing_order: false,
  chosen_barcode: {},
  // master barcode
  generate_and_print_master_barcodes: false,
  delete_master_barcode_dialog: false,
  move_orders_from_master_barcode:false,

  //service
  create_service: false,
  edit_service: false,
  delete_service: false,

  //requests
  delete_request: false,
  approve_or_reject_request: false,

  //leave requests
  delete_leave_request: false,

  //banners
  create_banner: false,
  edit_banner: false,
  delete_banner: false,
  notification_banner:false,

  //marketplace
  upgrade_to_marketplace_seller: false,

  //internal banners
  create_internal_banner: false,
  edit_internal_banner: false,
  delete_internal_banner: false,

  express_warning: false,

  //bulk return modal
  bulk_return_modal: false,
  create_cashbox_order: false,

  //Cashbox
  cashbox_settlement_modal: false,
  add_cashbox_extra_fees:false,
  cancel_cashbox:false,
  update_cashbox_extra_fee:false,
  cancel_multiple_cashboxes: false,
  create_multiple_cashbox_orders:false,
  import_tasks: false,
  cashboxes : [],
  edit_audited_order_collections: false,
  edit_order_delivery_price: false,
  cancel_audited_order_collections: false,
  save_audited_order_collections: false,
  auditedOrderCollections: [], 
  newAuditedOrderCollections: [], 
  auditedCollectionType: 'N/A', 
  order_id : 0,

  //Area confirmation
  is_area_confirmation_required:false,

  edit_holiday: false,
  delete_holiday: false,
  create_holiday: false,
  holiday: {},

  //international orders
  create_edit_item: false,

};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPORTANT_COMMENT_MODAL:
      return Object.assign({}, state, {
        open_imp_comment: action.payload.open_imp_comment,
        object: action.payload.object,
      });
    case GENERATE_AND_PRINT_BARCODES:
      return Object.assign({}, state, {
        generate_and_print_barcodes: action.payload.open,
        object: action.payload.object,
      });
    case ATTACH_BARCODE_TO_EXISTING_ORDER:
      return Object.assign({}, state, {
        attach_barcode_to_existing_order: action.payload.open,
        objectBarcode: action.payload.objectBarcode,
        from_path: action.payload.from_path,
      });
    case CREATE_DELIVERY_ORDER_FOR_BARCODE_MODAL:
      return Object.assign({}, state, {
        create_delivery_order_for_barcodes: action.payload.open,
        chosen_barcode: action.payload.object,
      });
    case CREATE_RETURN_DELIVERY_ORDER_FOR_BARCODE_MODAL:
      return Object.assign({}, state, {
        create_return_delivery_order_for_barcodes: action.payload.open,
        chosen_barcode: action.payload.object,
      });
    case BARCODE_CONFIRMATION_MODAL:
      return Object.assign({}, state, {
        open_barcode_confirmation: action.payload.open_barcode_confirmation,
        object: action.payload.object,
      });
    case DELETE_BARCODE_MODAL:
      return Object.assign({}, state, {
        delete_barcode_dialog: action.payload.open,
        object: action.payload.object,
      });
    case GENERATE_AND_PRINT_MASTER_BARCODES:
      return Object.assign({}, state, {
        generate_and_print_master_barcodes: action.payload.open,
        object: action.payload.object,
      });
    case DELETE_MASTER_BARCODE_MODAL:
      return Object.assign({}, state, {
        delete_master_barcode_dialog: action.payload.open,
        object: action.payload.object,
      });
    case MOVE_ORDERS_FROM_MASTER_BARCODE:
      return Object.assign({}, state, {
        move_orders_from_master_barcode: action.payload.open,
        object: action.payload.object,
      });
    case SET_PACKAGE_WITH_DRIVER_MODAL:
      return Object.assign({}, state, {
        open_set_package_with_driver:
          action.payload.open_set_package_with_driver,
        object: action.payload.object,
      });
    case IS_CRITICAL_MODAL:
      return Object.assign({}, state, {
        is_critical: action.payload.is_critical,
        object: action.payload.object,
      });
    case CREATE_APP_MODAL:
      return Object.assign({}, state, {
        create_app: action.payload,
      });
    case EDIT_APP_MODAL:
      return Object.assign({}, state, {
        edit_app: action.payload.edit_app,
        object: action.payload.object,
      });
    case DELETE_APP_MODAL:
      return Object.assign({}, state, {
        delete_app: action.payload.delete_app,
        object: action.payload.object,
      });
    case PIGGY_BANK_ORDERS_MODAL:
      return Object.assign({}, state, {
        piggy_bank_orders: action.payload.piggy_bank_orders,
        object: action.payload.object,
      });
    case CLOSE_PIGGY_BANK_MODAL:
      return Object.assign({}, state, {
        close_piggy_bank: action.payload.close_piggy_bank,
        object: action.payload.object,
      });
    case EDIT_ACCOUNTANT_ORDER_MODAL:
      return Object.assign({}, state, {
        edit_accountant_order: action.payload.edit_order,
        object: action.payload.object,
      });
    case CREATE_EXISTING_RECIPIENT_LOCATION_MODAL:
      return Object.assign({}, state, {
        create_location_open: action.payload.create_location_open,
        customer_id: action.payload.customer_id,
        recipient: action.payload.recipient
      });
    case ORDER_REPORT_FIELDS:
      return Object.assign({}, state, {
        order_report_fields_open: action.payload.order_report_fields_open,
        order_report_fields: action.payload.order_report_fields,
      });
    case MULTIPLE_DECLINE_ORDERS:
      return Object.assign({}, state, {
        multiple_decline: action.payload.multiple_decline,
        array: action.payload.array,
      });
    case EDIT_CUSTROMER_MODAL:
      return Object.assign({}, state, {
        edit_customer: action.payload.edit_customer,
        object: action.payload.object,
      });

    case CUSTOMIZE_WAYBILL_MODAL:
      return Object.assign({}, state, {
        customize_waybill: action.payload.customize_waybill,
        object: action.payload.object,
        user: action.payload.user,
      });
    case EDIT_CUSTROMER_ACCOUNT_MANAGER_MODAL:
      return Object.assign({}, state, {
        edit_customer_account_manager: action.payload.edit_customer,
        object: action.payload.object,
      });
    case EDIT_MULTIPLE_CUSTROMERS_ACCOUNT_MANAGER_MODAL:
      return Object.assign({}, state, {
        edit_multiple_customers_account_manager: action.payload.open,
      });
    case EDIT_CUSTROMER_INDUSTRY_MODAL:
      return Object.assign({}, state, {
        edit_customer_industry: action.payload.edit_customer,
        object: action.payload.object,
      });
    case ENHANCED_EDIT_ORDER_MODAL:
      return Object.assign({}, state, {
        enhanced_edit_order: action.payload.edit_order,
        object: action.payload.object,
        from_order_details: action.payload.from_order_details,
        edit_bulk_return: action.payload.edit_bulk_return,
      });
    case SUBMISSIONS_DETAILS_TASKS:
      return Object.assign({}, state, {
        submissions_details_tasks: action.payload.submissions_details_tasks,
        object: action.payload.object,
      });
    case EDIT_CUSTOM_MESSAGE:
      return Object.assign({}, state, {
        edit_custom_message: action.payload.edit_custom_message,
        object: action.payload.object,
      });
    case REOPEN_ORDER:
      return Object.assign({}, state, {
        reopen_order: action.payload.reopen_order,
        object: action.payload.object,
      });
    case EDIT_CONFIGURATION:
      return Object.assign({}, state, {
        edit_configuration: action.payload.edit_configuration,
        object: action.payload.object,
      });
    case LINK_CONFIGURATION:
      return Object.assign({}, state, {
        link_configuration: action.payload.link_configuration,
      });
    case CREATE_TOKEN_MODAL:
      return Object.assign({}, state, {
        create_token: action.payload,
      });
    case CREATE_WEBHOOK_MODAL:
      return Object.assign({}, state, {
        create_webhook: action.payload,
      });
    case DELETE_TOKEN_MODAL:
      return Object.assign({}, state, {
        delete_token: action.payload.delete_token,
        object: action.payload.object,
      });
    case EDIT_TOKEN_MODAL:
      return Object.assign({}, state, {
        edit_token: action.payload.edit_token,
        object: action.payload.object,
      });
    case DELETE_WEBHOOK_MODAL:
      return Object.assign({}, state, {
        delete_webhook: action.payload.delete_webhook,
        object: action.payload.object,
      });
    case EDIT_WEBHOOK_MODAL:
      return Object.assign({}, state, {
        edit_webhook: action.payload.edit_webhook,
        object: action.payload.object,
      });
    case EDIT_INHOUSE_NOTE:
      return Object.assign({}, state, {
        edit_inhouse_note: action.payload.edit_inhouse_note,
        object: action.payload.object,
      });
    case EDIT_GOLDEN_RULE:
      return Object.assign({}, state, {
        edit_golden_rule: action.payload.edit_golden_rule,
        object: action.payload.object,
      });

    case EDIT_SHOP_NOTE:
      return Object.assign({}, state, {
        edit_shop_note: action.payload.edit_shop_note,
        object: action.payload.object,
      });

    case VIEW_SHOP_NOTE:
      return Object.assign({}, state, {
        view_shop_note: action.payload.view_shop_note,
        object: action.payload.object,
      });

    case VIEW_EXTRA_TAGS:
      return Object.assign({}, state, {
        view_extra_tags: action.payload.view_extra_tags,
        object: action.payload.object,
      });

    case EDIT_PHONE_NBR:
      return Object.assign({}, state, {
        edit_phone_nbr: action.payload.edit_phone_nbr,
        object: action.payload.object,
      });
    case REVIEW_FEEDBACK:
      return Object.assign({}, state, {
        show_feedback: action.payload.open,
        object: action.payload.object,
      });
    case SHIP_ORDER:
      return Object.assign({}, state, {
        ship_order: action.payload.open,
        object: action.payload.object,
      });
    case ADD_PIGGY_BANK_NOTE_MODAL:
      return Object.assign({}, state, {
        piggybank_note: action.payload.piggybank_note,
        object: action.payload.object,
      });

    case EDIT_PIGGY_BANK_MODAL:
      return Object.assign({}, state, {
        edit_piggy_bank: action.payload.edit_piggy_bank,
        object: action.payload.object,
      });
    case DETAILS_TASKS:
      return Object.assign({}, state, {
        details_tasks: action.payload.details_tasks,
        object: action.payload.object,
        is_export: action.payload.is_export,
      });
    case EDIT_STAFF_MODAL:
      return Object.assign({}, state, {
        edit_staff: action.payload.edit_staff,
        object: action.payload.object,
      });
    case DELETE_REQUEST_MODAL:
      return Object.assign({}, state, {
        delete_request: action.payload.delete_request,
        object: action.payload.object,
      });
    case APPROVE_OR_REJECT_REQUEST_MODAL:
      return Object.assign({}, state, {
        approve_or_reject_request: action.payload.approve_or_reject_request,
        object: action.payload.object,
        action: action.payload.action,
      });
    case EDIT_LOCATION:
      return Object.assign({}, state, {
        edit_location: action.payload.edit_location,
        from_receiver: action.payload.from_receiver,
        object: action.payload.object,
      });
    case NEW_PRICE:
      return Object.assign({}, state, {
        new_price: action.payload.new_price,
        object: action.payload.object,
      });
    case DELETE_PRICE:
      return Object.assign({}, state, {
        delete_price: action.payload.delete_price,
        object: action.payload.object,
      });
    case EDIT_PRICE:
      return Object.assign({}, state, {
        edit_price: action.payload.edit_price,
        object: action.payload.object,
      });
    case DECLINE_SUBMISSION:
      return Object.assign({}, state, {
        decline_submission: action.payload.decline_submission,
        object: action.payload.object,
      });
    case CONFIRM_SUBMISSION:
      return Object.assign({}, state, {
        confirm_submission: action.payload.confirm_submission,
        object: action.payload.object,
      });
    case NEW_RECEIVER:
      return Object.assign({}, state, {
        new_receiver: action.payload.new_receiver,
        object: action.payload.object,
      });
    case NEW_LOCATION:
      return Object.assign({}, state, {
        new_location: action.payload.new_location,
        object: action.payload.object,
      });
    case PASSWORD_RESET_MODAL:
      return Object.assign({}, state, {
        pwd_reset: action.payload.pwd_reset,
        object: action.payload.object,
      });
    case EMAIL_RESEND_MODAL:
      return Object.assign({}, state, {
        email_resend: action.payload.email_resend,
        object: action.payload.object,
      });
    case CHECK_IN_MODAL:
      return Object.assign({}, state, {
        check_in: action.payload.check_in,
        object: action.payload.object,
      });
    case CHECK_OUT_MODAL:
      return Object.assign({}, state, {
        check_out: action.payload.check_out,
        object: action.payload.object,
      });
    case CONFIRM_TIME_SHEET:
      return Object.assign({}, state, {
        confirm_time_sheet: action.payload.confirm_time_sheet,
        object: action.payload.object,
      });
    case CANCEL_ORDER_MODAL:
      return Object.assign({}, state, {
        cancel_order: action.payload.cancel_order,
        object: action.payload.object,
      });
    case PENDING_CANCELLATION_ORDER_MODAL:
      return Object.assign({}, state, {
        pending_cancel_order: action.payload.pending_cancel_order,
        object: action.payload.object,
      });
    case CANCEL_MULTIPLE_ORDERS_MODAL:
      return Object.assign({}, state, {
        cancel_multiple_orders: action.payload.cancel_multiple_orders,
        orders: action.payload.orders,
      });
    case DELETE_LOCATION_LOG_MODAL:
      return Object.assign({}, state, {
        delete_location_log: action.payload.delete_location_log,
        object: action.payload.object,
      });
    case DELETE_LOCATION_MODAL:
      return Object.assign({}, state, {
        delete_location: action.payload.delete_location,
        object: action.payload.object,
      });
    case EDIT_CONTACT_MODAL:
      return Object.assign({}, state, {
        edit_contact: action.payload.edit_contact,
        object: action.payload.object,
      });
    case EDIT_RECIPIENT_MODAL:
      return Object.assign({}, state, {
        edit_recipient: action.payload.edit_recipient,
        object: action.payload.object,
      });
    case DELETE_CONTACT_MODAL:
      return Object.assign({}, state, {
        delete_contact: action.payload.delete_contact,
        object: action.payload.object,
      });
    case NEW_CONTACT_MODAL:
      return Object.assign({}, state, {
        new_contact: action.payload.new_contact,
        object: action.payload.object,
      });
    case EDIT_ORDER_MODAL:
      return Object.assign({}, state, {
        edit_order: action.payload.edit_order,
        object: action.payload.object,
      });
    case PAID_ORDER_MODAL:
      return Object.assign({}, state, {
        paid_order: action.payload.paid_order,
        object: action.payload.object,
      });
    case PAY_CUSTOMER_ORDERS_MODAL:
      return Object.assign({}, state, {
        pay_customer_orders: action.payload.pay_customer_orders,
        object: action.payload.object,
      });
    case ENHANCED_COMMENTS_MODAL:
      return Object.assign({}, state, {
        enhanced_comments: action.payload.comments,
        object: action.payload.object,
        customer_id: action.payload.customer_id,
        from_order_details: action.payload.from_order_details,
      });
    case DECLINE_MODAL:
      return Object.assign({}, state, {
        decline: action.payload.decline,
        remove_data_on_done: action.payload.remove_data_on_done,
        object: action.payload.object,
      });
    case EDIT_CURRENCY_MODAL:
      return Object.assign({}, state, {
        edit_currency: action.payload.edit_currency,
        object: action.payload.object,
      });
    case DEFAULT_CURRENCY_MODAL:
      return Object.assign({}, state, {
        set_default_currency: action.payload.set_default_currency,
        object: action.payload.object,
      });
    case PREFERRED_ALTERNATIVE_CURRENCY_MODAL:
      return Object.assign({}, state, {
        set_preferred_alternative_currency:
          action.payload.set_preferred_alternative_currency,
        object: action.payload.object,
      });
    case DELETE_CURRENCY_MODAL:
      return Object.assign({}, state, {
        delete_currency: action.payload.delete_currency,
        object: action.payload.object,
      });
    case CREATE_CURRENCY_MODAL:
      return Object.assign({}, state, {
        create_currency: action.payload.create_currency,
      });
    case LINK_CURRENCY_MODAL:
      return Object.assign({}, state, {
        link_currency: action.payload.link_currency,
      });

    // case UPDATE_TASK_OBJECT:
    //   var object = JSON.parse(JSON.stringify(state.object));
    //   if(object.id == action.payload.id){
    //     var task_index = _.findIndex(object.tasks, function(o) { return o.id == action.payload.object.id; })
    //     if(task_index >= 0) {
    //       object.tasks[task_index].collections = action.payload.object.collections
    //     }
    //   }
    //   return Object.assign({}, state, {
    //     object
    //   });

    //bulkOrder
    case CREATE_BULK_ORDER_MODAL:
      return Object.assign({}, state, {
        create_bulk_order: action.payload.create_bulk_order,
        object: action.payload.object,
      });
    //Import Orders from report
    case IMPORT_ORDERS_MODAL:
      return Object.assign({}, state, {
        import_orders: action.payload.import_orders,
      });

    //Import Tasks from report
    case IMPORT_TASKS_MODAL:
      return Object.assign({}, state, {
        import_tasks: action.payload.import_tasks,
      });
    //AREAS
    case EDIT_AREA_MODAL:
      return Object.assign({}, state, {
        edit_area: action.payload.edit_area,
        object: action.payload.object,
      });
    case DELETE_AREA_MODAL:
      return Object.assign({}, state, {
        delete_area: action.payload.delete_area,
        object: action.payload.object,
      });
    case CREATE_AREA_MODAL:
      return Object.assign({}, state, {
        create_area: action.payload.create_area,
      });

    case SHOW_DISTRICT_MODAL:
      return Object.assign({}, state, {
        show_district: action.payload.show_district,
      });

    case CREATE_DISTRICT_MODAL:
      return Object.assign({}, state, {
        create_district: action.payload.create_district,
      });

    case EDIT_DISTRICT_MODAL:
      return Object.assign({}, state, {
        edit_district: action.payload.edit_district,
        object: action.payload.object,
      });

    case DELETE_DISTRICT_MODAL:
      return Object.assign({}, state, {
        delete_district: action.payload.delete_district,
        object: action.payload.object,
      });

    case SHOW_GOVERNORATE_MODAL:
      return Object.assign({}, state, {
        show_governorate: action.payload.show_governorate,
      });

    case CREATE_GOVERNORATE_MODAL:
      return Object.assign({}, state, {
        create_governorate: action.payload.create_governorate,
      });

    case EDIT_GOVERNORATE_MODAL:
      return Object.assign({}, state, {
        edit_governorate: action.payload.edit_governorate,
        object: action.payload.object,
      });

    case DELETE_GOVERNORATE_MODAL:
      return Object.assign({}, state, {
        delete_governorate: action.payload.delete_governorate,
        object: action.payload.object,
      });

    //STOCKS
    case EDIT_STOCK_MODAL:
      return Object.assign({}, state, {
        edit_stock: action.payload.edit_stock,
        object: action.payload.object,
      });
    case DELETE_STOCK_MODAL:
      return Object.assign({}, state, {
        delete_stock: action.payload.delete_stock,
        object: action.payload.object,
      });
    case CREATE_STOCK_MODAL:
      return Object.assign({}, state, {
        create_stock: action.payload.create_stock,
      });

    //Vehicles
    case EDIT_VEHICLE_MODAL:
      return Object.assign({}, state, {
        edit_vehicle: action.payload.edit_vehicle,
        object: action.payload.object,
      });
    case DELETE_VEHICLE_MODAL:
      return Object.assign({}, state, {
        delete_vehicle: action.payload.delete_vehicle,
        object: action.payload.object,
      });
    case CREATE_VEHICLE_MODAL:
      return Object.assign({}, state, {
        create_vehicle: action.payload.create_vehicle,
      });

    //Zones
    case VIEW_ZONE_MODAL:
      return Object.assign({}, state, {
        view_zone: action.payload.view_zone,
        edit_zone: false,
        create_zone: false,
        object: action.payload.object,
      });
    case EDIT_ZONE_MODAL:
      return Object.assign({}, state, {
        edit_zone: action.payload.edit_zone,
        view_zone: false,
        create_zone: false,
        object: action.payload.object,
      });
    case DELETE_ZONE_MODAL:
      return Object.assign({}, state, {
        delete_zone: action.payload.delete_zone,
        view_zone: false,
        create_zone: false,
        edit_zone: false,
        object: action.payload.object,
      });
    case CREATE_ZONE_MODAL:
      return Object.assign({}, state, {
        create_zone: action.payload.create_zone,
        view_zone: false,
        edit_zone: false,
      });

    //Offices
    case EDIT_OFFICE_MODAL:
      return Object.assign({}, state, {
        edit_office: action.payload.edit_office,
        object: action.payload.object,
      });
    case DELETE_OFFICE_MODAL:
      return Object.assign({}, state, {
        delete_office: action.payload.delete_office,
        object: action.payload.object,
      });
    case CREATE_OFFICE_MODAL:
      return Object.assign({}, state, {
        create_office: action.payload.create_office,
      });
    case SET_DEFUALT_WAYBILL_ADDRESS_MODAL:
      return Object.assign({}, state, {
        set_default_waybill_address: action.payload.set_default_waybill_address,
        object: action.payload.object,
      });
    //Types
    case EDIT_TYPE_MODAL:
      return Object.assign({}, state, {
        edit_type: action.payload.edit_type,
        create_type: false,
        object: action.payload.object,
      });
    case CREATE_TYPE_MODAL:
      return Object.assign({}, state, {
        create_type: action.payload.create_type,
        edit_type: false,
      });

    case CANCEL_TASK_MODAL:
      return Object.assign({}, state, {
        cancel_task: action.payload.cancel_task,
        object: action.payload.object,
      });
    case EDIT_TASK_MODAL:
      return Object.assign({}, state, {
        edit_task: action.payload.edit_task,
        object: action.payload.object,
      });
    case FAIL_MODAL:
      return Object.assign({}, state, {
        fail: action.payload.fail,
        object: action.payload.object,
      });
    case COMPLETE_MODAL:
      return Object.assign({}, state, {
        complete: action.payload.complete,
        object: action.payload.object,
      });
    case ASSIGN_DRIVER_MODAL:
      return Object.assign({}, state, {
        assign_driver: action.payload.assign_driver,
        object: action.payload.object,
      });
    case ENHANCED_ASSIGN_DRIVER_MODAL:
      return Object.assign({}, state, {
        enhanced_assign_driver: action.payload.assign_driver,
        object: action.payload.object,
      });
    case UNASSIGN_DRIVER_MODAL:
      return Object.assign({}, state, {
        unassign_driver: action.payload.unassign_driver,
        object: action.payload.object,
      });

    //Activate/Deactivate
    case ACTIVATE_DEACTIVATE_DRIVER_MODAL:
      return Object.assign({}, state, {
        activate_deactivate_driver: action.payload.activate_deactivate_driver,
        object: action.payload.object,
      });
    case ACTIVATE_DEACTIVATE_CUSTOMER_MODAL:
      return Object.assign({}, state, {
        activate_deactivate_customer:
          action.payload.activate_deactivate_customer,
        object: action.payload.object,
      });
    case ACTIVATE_DEACTIVATE_LOCATION_MODAL:
      return Object.assign({}, state, {
        activate_deactivate_location:
          action.payload.activate_deactivate_location,
        object: action.payload.object,
      });
    case MAKE_DEFAULT_LOCATION_MODAL:
      return Object.assign({}, state, {
        make_default_location: action.payload.make_default_location,
        object: action.payload.object,
      });
    case ACTIVATE_DEACTIVATE_USER_MODAL:
      return Object.assign({}, state, {
        activate_deactivate_user: action.payload.activate_deactivate_user,
        object: action.payload.object,
      });

    case RESCHEDULE_MODAL:
      return Object.assign({}, state, {
        reschedule: action.payload.reschedule,
        object: action.payload.object,
      });
    case REQUEST_SIGN_MODAL:
      return Object.assign({}, state, {
        request_sign: action.payload.request_sign,
        object: action.payload.object,
      });
    case CREATE_STAFF_MODAL:
      return Object.assign({}, state, {
        create_staff: action.payload,
      });
    case CLOSE_FAIL_MODAL:
      return Object.assign({}, state, {
        close_fail: action.payload.close_fail,
        object: action.payload.object,
      });
    case POSTPONE_MODAL:
      return Object.assign({}, state, {
        postpone: action.payload.postpone,
        object: action.payload.object,
      });
    case CONFRIM_MODAL:
      return Object.assign({}, state, {
        confirm: action.payload.confirm,
        object: action.payload.object,
      });
    case ADD_REVIEW_MODAL:
      return Object.assign({}, state, {
        add_review: action.payload.add_review,
        object: action.payload.object,
      });
    //services
    case CREATE_SERVICE_MODAL:
      return Object.assign({}, state, {
        create_service: action.payload.create_service,
      });
    case EDIT_SERVICE_MODAL:
      return Object.assign({}, state, {
        edit_service: action.payload.edit_service,
        object: action.payload.object,
      });
    case DELETE_SERVICE_MODAL:
      return Object.assign({}, state, {
        delete_service: action.payload.open,
        object: action.payload.object,
      });

    case DISPLAY_ORDER_PICTURE_MODAL:
      return Object.assign({}, state, {
        open_order_picture_dialog: action.payload.open,
        object: action.payload.object,
      });

    //banners
    case CREATE_BANNER_MODAL:
      return Object.assign({}, state, {
        create_banner: action.payload.create_banner,
      });
    case CREATE_BULK_RETURN_MODAL:
      return Object.assign({}, state, {
        bulk_return_modal: action.payload.bulk_return_modal,
        is_edit: action.payload.is_edit,
        order_id: action.payload.order_id,
      });
    case EDIT_BANNER_MODAL:
      return Object.assign({}, state, {
        edit_banner: action.payload.edit_banner,
        object: action.payload.object,
      });
    case DELETE_BANNER_MODAL:
      return Object.assign({}, state, {
        delete_banner: action.payload.delete_banner,
        object: action.payload.object,
      });

    case NOTIFICATION_BANNER_MODAL:
      return Object.assign({}, state, {
        notification_banner: action.payload.notification_banner,
        object: action.payload.object,
      });

    // marketplace
    case UPGRADE_TO_MARKETPLACE_SELLER_MODAL:
      return Object.assign({}, state, {
        upgrade_to_marketplace_seller: action.payload.open,
        object: action.payload.customer_id,
      });

    // internal banners
    case CREATE_INTERNAL_BANNER_MODAL:
      return Object.assign({}, state, {
        create_internal_banner: action.payload.create_internal_banner,
      });
    case EDIT_INTERNAL_BANNER_MODAL:
      return Object.assign({}, state, {
        edit_internal_banner: action.payload.edit_internal_banner,
        object: action.payload.object,
      });
    case DELETE_INTERNAL_BANNER_MODAL:
      return Object.assign({}, state, {
        delete_internal_banner: action.payload.delete_internal_banner,
        object: action.payload.object,
      });

    case CONFIRM_ORDER_MODAL:
      return Object.assign({}, state, {
        confirm_order: action.payload.confirm_order,
        object: action.payload.object,
        handleAreaConfirmationRequired: action.payload.handleAreaConfirmationRequired
      });

    case EXPRESS_WARNING_MODAL:
      return Object.assign({}, state, {
        express_warning: action.payload.express_warning,
        handleAreaConfirmationRequiredExpress: action.payload.handleAreaConfirmationRequiredExpress
      });

    //Area Route
    case EDIT_AREA_ROUTE_MODAL:
      return Object.assign({}, state, {
        edit_area_route: action.payload.edit_area_route,
        object: action.payload.object,
      });
    case DELETE_AREA_ROUTE_MODAL:
      return Object.assign({}, state, {
        delete_area_route: action.payload.delete_area_route,
        object: action.payload.object,
      });
    case CREATE_AREA_ROUTE_MODAL:
      return Object.assign({}, state, {
        create_area_route: action.payload.create_area_route,
      });

    //Assign Area To Driver
    case ASSIGN_AREA_TO_DRIVER:
      return Object.assign({}, state, {
        assign_area_to_driver: action.payload.assign_area_to_driver,
        route_id: action.payload.route_id,
      });

    //Assign Area To Driver
    case EDIT_ROUTE_AREA:
      return Object.assign({}, state, {
        edit_route_area: action.payload.edit_route_area,
        route_id: action.payload.route_id,
      });

    //Add Excluded Client
    case ADD_EXCLUDED_CLIENT:
      return Object.assign({}, state, {
        add_excluded_client: action.payload.add_excluded_client,
      });
    case CREATE_CASHBOX_ORDER_MODAL:
      return Object.assign({}, state, {
        create_cashbox_order: action.payload.create_cashbox_order,
        cashbox: action.payload.cashbox,
      });
    case CREATE_CASHBOX_SETTLEMENT:
      return Object.assign({}, state, {
        cashbox_settlement_modal: action.payload.cashbox_settlement_modal,
        cashbox: action.payload.cashbox,
      });

    case ADD_CASHBOX_EXTRA_FEES_MODAL:
      return Object.assign({}, state, {
        add_cashbox_extra_fees: action.payload.add_cashbox_extra_fees,
        cashbox: action.payload.cashbox,
      });
    case UPDATE_CASHBOX_EXTRA_FEE_MODAL:
      return Object.assign({}, state, {
        update_cashbox_extra_fee: action.payload.update_cashbox_extra_fee,
        cashbox_extra_fee_id: action.payload.cashbox_extra_fee_id,
      });
    case CANCEL_CASHBOX_MODAL:
      return Object.assign({}, state, {
        cancel_cashbox: action.payload.cancel_cashbox,
        cashbox: action.payload.cashbox,
      });
    case CANCEL_MULTIPLE_CASHBOXES_MODAL:
      return Object.assign({}, state, {
        cancel_multiple_cashboxes: action.payload.cancel_multiple_cashboxes,
        cashboxes: action.payload.cashboxes,
      });
    case CREATE_MULTIPLE_CASHBOX_ORDERS_MODAL:
      return Object.assign({}, state, {
        create_multiple_cashbox_orders:
          action.payload.create_multiple_cashbox_orders,
        cashboxes: action.payload.cashboxes,
      });
    case ADD_CASHBOX_MODAL:
      return Object.assign({}, state, {
        add_cashbox: action.payload.add_cashbox
      });
    case EDIT_AUDITED_ORDER_COLLECTIONS_MODAL:
      return Object.assign({}, state, {
        edit_audited_order_collections:
          action.payload.edit_audited_order_collections,
        auditedCollectionType: action.payload.audited_collection_type,
        order_id: action.payload.order_id,
      });
    case SAVE_AUDITED_ORDER_COLLECTIONS_MODAL:
      return Object.assign({}, state, {
        save_audited_order_collections:
          action.payload.save_audited_order_collections,
        auditedOrderCollections: action.payload.audited_collections,
        newAuditedOrderCollections: action.payload.new_audited_collections,
        auditedCollectionType: action.payload.audited_collection_type,
        order_id: action.payload.order_id,
      });
    case CANCEL_AUDITED_ORDER_COLLECTIONS_MODAL:
      return Object.assign({}, state, {
        cancel_audited_order_collections:
          action.payload.cancel_audited_order_collections,
        order_id: action.payload.order_id,
      });
    case EDIT_ORDER_DELIVERY_PRICE_MODAL:
      return Object.assign({}, state, {
        edit_order_delivery_price:
          action.payload.edit_order_delivery_price,
        object: action.payload.data,
      });
    case AREA_CONFIRMATION_MODAL:
      return Object.assign({}, state, {
        is_area_confirmation_required: action.payload.is_area_confirmation_required,
        areaData: action.payload.areaData,
        confirmAreaAndSubmit: action.payload.confirmAreaAndSubmit
      });
    case EDIT_HOLIDAY_MODAL:
      return Object.assign({}, state, {
        edit_holiday: action.payload.edit_holiday,
        holiday: action.payload.holiday,
      });
    case DELETE_HOLIDAY_MODAL:
      return Object.assign({}, state, {
        delete_holiday: action.payload.delete_holiday,
        holiday: action.payload.holiday,
      });
    case CREATE_HOLIDAY_MODAL:
      return Object.assign({}, state, {
        create_holiday: action.payload.create_holiday,
      });
    case CREATE_EDIT_INTERNATIONAL_PACKAGE_ITEM_MODAL:
      return Object.assign({}, state, {
        create_edit_item: action.payload.create_edit_item,
        i: action.payload.i,
        k: action.payload.k,
        item_to_edit_index: action.payload.item_to_edit_index,
        item_to_edit: action.payload.item_to_edit
      })
    default:
      return state;
  }
};

export default modalReducer;
