const CHARTCONFIG = {
  color: {
    primary: "rgba(33,150,243,.85)", // #2196F3
    success: "rgba(102,187,106,.85)", // #66BB6A
    info: "rgba(0,188,212,.85)", // #00BCD4
    infoAlt: "rgba(126,87,194,.85)", // #7E57C2
    warning: "rgba(255,202,40,.85)", // #FFCA28
    danger: "rgba(233,75,59,.85)", // #E94B3B
    gray: "rgba(221,221,221,.3)",
    text: "#898989", // for dark theme as well
    splitLine: "rgba(0,0,0,.05)",
    splitArea: ["rgba(250,250,250,0.035)", "rgba(200,200,200,0.1)"]
  },
  days: {
    Monday: "rgba(33,150,243,.85)",
    Tuesday: "rgba(102,187,106,.85)",
    Wednesday: "rgba(0,188,212,.85)",
    Thursday: "rgba(126,87,194,.85)",
    Friday: "rgba(255,202,40,.85)",
    Saturday: "rgba(233,75,59,.85)",
    Sunday: "rgba(221,221,221,.3)"
  }
};

module.exports = CHARTCONFIG;
