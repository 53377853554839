import { FETCH_ACTIVE_INTERNAL_BANNER_RESOLVED } from "../ActionTypes/internalBanners";

const initialState = {
  activeInternalBanner: {}
};

const internalBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_INTERNAL_BANNER_RESOLVED:
      //local storage is updtaed here to avoid delay
      if (action.payload.data.length) {
        if (!localStorage.getItem("current_inernal_banner_content")) {
          localStorage.setItem(
            "current_inernal_banner_content",
            action.payload.data[0].subject + action.payload.data[0].message
          );
          localStorage.setItem("is_internal_banner_viewed", "no");
        } else {
          if (
            localStorage.getItem("current_inernal_banner_content") !==
            action.payload.data[0].subject + action.payload.data[0].message
          ) {
            localStorage.setItem(
              "current_inernal_banner_content",
              action.payload.data[0].subject + action.payload.data[0].message
            );
            localStorage.setItem("is_internal_banner_viewed", "no");
          }
        }
      }
      return Object.assign({}, state, {
        activeInternalBanner: action.payload.data.length ? action.payload.data[0] : {}
      });

    default:
      return state;
  }
};

export default internalBannerReducer;