import React from 'react';
import { withStyles } from '@mui/styles';
class MaintenancePage extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: '#ffc107',

          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <div className="row justify-content-center">
          <div
            className="col-md-12 mobile-width"
            style={{ paddingRight: '0px' }}
          >
            <img
              src="/assets/images/wakilni-logo-v2-white.png"
              height="50"
              alt="{APPCONFIG.brand}"
            />
          </div>
          <div
            className="col-md-12 mobile-width"
            style={{ paddingRight: '0px' }}
          >
            <h1 style={{ fontSize: '3rem' }}>
              Sorry, we're down for maintenance.
            </h1>
            <p style={{ fontSize: '2rem', marginTop: '2rem' }}>
              We will be back soon.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MaintenancePage;
