import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import * as settingService from 'services/settingService';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ButtonCircularProgress from '@mui/material/CircularProgress';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

class SurveysModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      close: false,
      collector_weblink: '',
      survey_id: '',
      screenWidth: window.innerWidth, // Initialize with the current screen width
      iframeLoaded: false,
    };
  }
  componentDidMount() {
    this.loadSurveys();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    window.removeEventListener('resize', this.handleResize);
  }
  // Update the screenWidth state when the screen width changes
  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };
  loadSurveys = () => {
    this.props
      .dispatch(settingService.fetchActiveSurvey())
      .then((data) => {
        if (data) {
          this.setState({
            close: true,
            collector_weblink: data.collector_weblink,
            survey_id: data.id,
          });
        } else {
          this.setState({ close: false });
        }
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error,
        };
        this.setState((state) => ({
          isLoading: {
            ...this.state.isLoading,
            surveys: false,
          },
        }));
      });
  };

  SurveyEmbed = () => {
    const { user } = this.props;
    const { collector_weblink, screenWidth } = this.state;
    let iframeHeight;
    switch (true) {
      case screenWidth <= 768:
        iframeHeight = '350px';
        break;
      case screenWidth < 1299 && screenWidth > 768:
        iframeHeight = '400px';
        break;
      case screenWidth < 1790 && screenWidth > 1300:
        iframeHeight = '500px';
        break;
      default:
        iframeHeight = '650px';
        break;
    }
    const email = user ? user.email : 'undefined';
    return (
      <div>
        {this.state.iframeLoaded ? (
          <span></span>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ButtonCircularProgress />
          </div>
        )}
        <iframe
          id="SurveyMonkey"
          title="SurveyMonkey"
          src={`${collector_weblink}?email=${email}`}
          width="100%"
          height={iframeHeight}
          frameBorder="0"
          onLoad={() => this.setState({ iframeLoaded: true })}
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  updateUserSurvey = () => {
    const { survey_id } = this.state;
    const data = { survey_id: survey_id };
    this.props
      .dispatch(settingService.updateUserSurvey(data))
      .then(() => {
        this.handleToggleChange(updatedSurvey);
      })
      .catch(({ error, statusCode }) => {
        // Handle Error Here
      });
  };

  snoozeUserSurvey = () => {
    this.setState({ close: false });
    this.updateUserSurvey();
  };
  handleClose = () => {
    this.setState({ close: false });
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.state.close}
          sx={{
            '& .MuiDialog-paper': {
              minWidth: { xs: 'auto', lg: '700px' },
            },
          }}
        >
          <DialogTitle>{
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
             Your feedback matters 💛
              <IconButton onClick={this.handleClose}>
                <i className="material-icons grey-icon">close</i>
              </IconButton>
            </div>
          }</DialogTitle>
          <DialogContent
            sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}
          >
          {this.SurveyEmbed()}
          </DialogContent>
          <DialogActions>
          <div
            style={{
              paddingTop: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Button
              variant=""
              color=""
              className=""
              onClick={this.snoozeUserSurvey}
              style={{ background: 'transparent', color: '#ffc107' }}
            >
              Snooze until next month
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ background: '#ffc107' }}
              onClick={this.handleClose}
            >
              Later
            </Button>
          </div>

          <br />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

SurveysModel.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeSurvey: state.settings.activeSurvey,
    user: state.auth.user,
  };
};

export default withStyles(null)(connect(mapStateToProps)(SurveysModel));
