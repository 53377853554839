export const FETCH_TRANSACTIONS_RESOLVED = "FETCH_TRANSACTIONS_RESOLVED";
export const FETCH_TASKS_FOR_SUBMISSION = "FETCH_TASKS_FOR_SUBMISSION";
export const UPDATE_TASK_COLLECTION_RESOLEVED =
  "UPDATE_TASK_COLLECTION_RESOLEVED";
export const FETCH_PIGGY_BANK_ORDERS_RESOLEVED =
  "FETCH_PIGGY_BANK_ORDERS_RESOLEVED";
export const UPDATE_TASK_COLLECTION_IN_PIGGYBANK_RESOLEVED =
  "UPDATE_TASK_COLLECTION_IN_PIGGYBANK_RESOLEVED";
export const UPDATE_PIGGY_BANK_ORDERS_RESOLVED =
  "UPDATE_PIGGY_BANK_ORDERS_RESOLVED";
