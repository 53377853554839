import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRole, hasHrPrivilege } from "utils/helpers";
import { push } from 'connected-react-router';

export const Authorization = allowedRoles => WrappedComponent => {
  class WithAuthorization extends React.Component {

    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const his_role = getRole(this.props.user);

      if (!allowedRoles.includes(his_role)) {
        if (
          !(
            hasHrPrivilege(this.props.user) &&
            this.props.location.hash === "#hr-tab"
          )
        ) {
          if (_.isEmpty(this.props.user) || this.props.user == {}) {
            this.props.dispatch(push(`/login`));
          } else {
            this.props.dispatch(push(`/404`));
          }
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return connect(state => {
    return {
      user: state.auth.user,
      isAuthenticated: state.auth.isAuthenticated
    };
  })(WithAuthorization);
};
