import * as settingsActions from '../stores/actions/settings';
import Http from '../utils/Http';
import * as authActions from '../stores/actions/auth';
import {
  createCurrencyResolved,
  updateCurrencyResolved,
  deleteCustomerCurrencyResolved,
  fetchCustomerCurrenciesResolved,
} from '../stores/actions/customers';
import { serializeSearchInput } from 'utils/helpers';

export function getTypes($type) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('types?search=type:', $type)
        .then((res) => {
          dispatch(settingsActions.getType(res.data));
          return resolve();
        })
        .catch((data) => {
          return reject(data);
        });
    });
}

export function fetchCustomMessages() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('custom/messages')
        .then((res) => {
          dispatch(settingsActions.fetchCustomMessagesResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editCustomMessage(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('custom/messages/' + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateCustomMessageResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchConfigurations() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('settings')
        .then((res) => {
          dispatch(settingsActions.fetchConfigurationsResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function linkStoreConfiguration(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('settings', input.data)
        .then((res) => {
          dispatch(settingsActions.linkConfigurationResolved(res.data.data));
          dispatch(
            authActions.serverSuccess('Configuration Linked successfully'),
          );
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function editConfiguration(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('settings/' + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateConfigurationResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchCurrencies() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        `${
          process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : ''
        }currencies`,
      )
        .then((res) => {
          dispatch(settingsActions.fetchCurrenciesResolved(res.data.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateCurrency(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('currencies/' + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateCurrencyResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteCurrency(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete(`currencies/${input.id}`)
        .then((res) => {
          dispatch(settingsActions.deleteCurrencyResolved(res.data));
          dispatch(authActions.deleteUserCurrencyResolved(res.data));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteCustomerCurrency(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete(`customers/${input.customer_id}/currencies/${input.id}`)
        .then((res) => {
          dispatch(deleteCustomerCurrencyResolved(res.data));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createCurrency(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('currencies', input.data)
        .then((res) => {
          dispatch(settingsActions.createCurrencyResolved(res.data.data));
          dispatch(authActions.serverSuccess('Currency Added successfully'));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function linkStoreCurrency(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('stores_currencies', input.data)
        .then((res) => {
          dispatch(
            settingsActions.createCurrencyResolved(res.data.data.currency),
          );
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function linkCustomerCurrency(input, update_customer_currency) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('customers_currencies', input.data)
        .then((res) => {
          if (update_customer_currency) {
            dispatch(createCurrencyResolved(res.data.data.currency));
          } else {
            dispatch(
              settingsActions.createCurrencyResolved(res.data.data.currency),
            );
            dispatch(authActions.createUserCurrencyResolved(res.data.data));
          }
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateCustomerCurrency(input, update_customer_currency) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('customers_currencies/' + input.id, input.data)
        .then((res) => {
          if (update_customer_currency) {
            dispatch(updateCurrencyResolved(res.data.data.currency));
          } else {
            dispatch(
              settingsActions.updateCurrencyResolved(res.data.data.currency),
            );
            dispatch(authActions.updateUserCurrenciesResolved(res.data.data));
          }

          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchPreferences() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('preferences')
        .then((res) => {
          dispatch(settingsActions.fetchPreferencesResolved(res.data.data));
          return resolve(res.data.meta);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function linkPreferences(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('preferences', input)
        .then((res) => {
          dispatch(settingsActions.linkPreferencesResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchAreas(
  input = [],
  with_pagination = false,
  pageNumber = null,
) {
  const isShopify = process.env.REACT_APP_IS_SHOPIFY === 'true';
  const urlBase = `${isShopify ? 'shipping/' : ''}areas`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url =
        input.length > 0
          ? `${urlBase}${serializeSearchInput(input, false)}&with_filter=true`
          : urlBase;
      Http.get(
        with_pagination
          ? url +
              (input.length == 0 ? '?' : '&') +
              'with_pagination=true&page=' +
              pageNumber
          : url,
      )
        .then((res) => {
          dispatch(settingsActions.fetchAreasResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function getAreaById(area_id) {
  const urlBase = `areas/${area_id}`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = urlBase;
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateArea(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('areas/' + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateAreaResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.errors));
          return reject(err);
        });
    });
}

export function deleteArea(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete('areas/' + input.id)
        .then((res) => {
          dispatch(settingsActions.deleteAreaResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createArea(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('areas', input.data)
        .then((res) => {
          dispatch(settingsActions.createAreaResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchAppTokens(search_input = [], page = null) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url =
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
        (search_input.length
          ? `app_tokens${serializeSearchInput(search_input, false)}&`
          : 'app_tokens?');
      if (page) {
        url = url.concat(`page=${page}`);
      }
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchAppTokensResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createToken(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
          'app_tokens',
        input,
      )
        .then((res) => {
          dispatch(settingsActions.createTokenResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchWebhooks(search_input = [], page = null) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = (search_input.length
        ? `webhooks${serializeSearchInput(search_input, false)}&`
        : 'webhooks?');
      if (page) {
        url = url.concat(`page=${page}`);
      }
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchWebhooksResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createWebhook(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(
          'webhooks',
        input,
      )
        .then((res) => {
          dispatch(settingsActions.createWebhookResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteWebhook(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete(
          'webhooks/' +
          input.id,
      )
        .then((res) => {
          dispatch(settingsActions.deleteWebhookResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateWebhook(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(
          'webhooks/' +
          input.id,
        input.data,
      )
        .then((res) => {
          dispatch(settingsActions.updateWebhookResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteToken(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete(
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
          'app_tokens/' +
          input.id,
      )
        .then((res) => {
          dispatch(settingsActions.deleteTokenResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateToken(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
          'app_tokens/' +
          input.id,
        input.data,
      )
        .then((res) => {
          dispatch(settingsActions.updateTokenResolved(res.data.data));
          dispatch(authActions.updateCustomerThirdPartyResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchApps(search_input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url =
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
        (search_input.length > 0
          ? `apps${serializeSearchInput(search_input, false)}`
          : 'apps');
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchAppsResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchWebhookEvents(search_input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url ='webhook-events'
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchWebhooksEventsResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
      });
  });
}

export function createApp(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
          'apps',
        input,
      )
        .then((res) => {
          dispatch(settingsActions.createAppResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.meta);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateApp(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
          `apps/${input.id}`,
        input.data,
      )
        .then((res) => {
          dispatch(settingsActions.updateAppResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteApp(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete(
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
          `apps/${input.id}`,
      )
        .then((res) => {
          dispatch(settingsActions.deleteAppResolved(res.data.data));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

//Stocks
export function fetchStocks() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('stocks')
        .then((res) => {
          dispatch(settingsActions.fetchStocksResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateStock(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('stocks/' + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateStockResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteStock(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete('stocks/' + input.id)
        .then((res) => {
          dispatch(settingsActions.deleteStockResolved(res.data));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createStock(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('stocks', input.data)
        .then((res) => {
          dispatch(settingsActions.createStockResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

//Vehicles
export function fetchVehicles() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('vehicles')
        .then((res) => {
          dispatch(settingsActions.fetchVehiclesResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateVehicle(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('vehicles/' + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateVehicleResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteVehicle(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete('vehicles/' + input.id)
        .then((res) => {
          dispatch(settingsActions.deleteVehicleResolved(res.data));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createVehicle(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('vehicles', input.data)
        .then((res) => {
          dispatch(settingsActions.createVehicleResolved(res.data));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

//Zones
export function fetchZones() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('zones')
        .then((res) => {
          dispatch(settingsActions.fetchZonesResolved(res.data.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createZone(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('zones', info.input)
        .then((res) => {
          dispatch(settingsActions.createZoneResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateZone(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('zones/' + info.id, info.input)
        .then((res) => {
          dispatch(settingsActions.updateZoneResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteZone(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete('zones/' + info.id)
        .then((res) => {
          dispatch(settingsActions.deleteZoneResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

//Offices
export function fetchOffices() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('offices')
        .then((res) => {
          dispatch(settingsActions.fetchOfficesResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateOffice(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('offices/' + info.id, info.input)
        .then((res) => {
          dispatch(settingsActions.updateOfficeResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
export function setDefaultWaybillAddress(info, update_office_reducer) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('offices/default-waybill-address', info.input)
        .then((res) => {
          if (update_office_reducer) {
            dispatch(settingsActions.fetchOfficesResolved(res.data));
            dispatch(authActions.serverSuccess(res.data.meta.message));
            return resolve(res.data);
          } else {
            dispatch(settingsActions.fetchOfficesResolved(res.data));
            return resolve(res.data);
          }
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function setOfficeSelfPickupAddress(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('offices/self-pickup-address', info.input)
        .then((res) => {
          dispatch(settingsActions.updateOfficeResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
export function deleteOffice(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete('offices/' + info.id)
        .then((res) => {
          dispatch(settingsActions.deleteOfficeResolved(res.data));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createOffice(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('offices', info.input)
        .then((res) => {
          dispatch(settingsActions.createOfficeResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

//services
export function fetchServices() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('services')
        .then((res) => {
          dispatch(settingsActions.fetchServicesResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createService(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('services', input.data)
        .then((res) => {
          dispatch(settingsActions.createServiceResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateService(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(`services/${input.id}`, input.data)
        .then((res) => {
          dispatch(settingsActions.updateServiceResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteService(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete('services/' + info.id)
        .then((res) => {
          dispatch(settingsActions.deleteServiceResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

//banners
export function fetchBanners(input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url =
        (process.env.REACT_APP_IS_SHOPIFY === 'true' ? 'shipping/' : '') +
        (input.length > 0
          ? `banners${serializeSearchInput(input, false)}`
          : 'banners');
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchBannersResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createBanner(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('banners', input.data)
        .then((res) => {
         
          dispatch(settingsActions.createBannerResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateBanner(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(`banners/${input.id}`, input.data)
        .then((res) => {
          dispatch(settingsActions.updateBannerResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteBanner(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete('banners/' + info.id)
        .then((res) => {
          dispatch(settingsActions.deleteBannerResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function notificationBanner(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('banners_notification', input.data)
        .then((res) => {
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

//internal banners
export function fetchInternalBanners(input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url =
        input.length > 0
          ? `internal_banners${serializeSearchInput(input, false)}`
          : 'internal_banners';
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchInternalBannersResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createInternalBanner(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('internal_banners', input.data)
        .then((res) => {
          dispatch(settingsActions.createInternalBannerResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateInternalBanner(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(`internal_banners/${input.id}`, input.data)
        .then((res) => {
          dispatch(settingsActions.updateInternalBannerResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteInternalBanner(info) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete('internal_banners/' + info.id)
        .then((res) => {
          dispatch(settingsActions.deleteInternalBannerResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
// internal banners

export function setDefaultCurrency(info, update_customer_reducer) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('customers_currencies/update/defaultcurrency', info)
        .then((res) => {
          if (update_customer_reducer) {
            dispatch(
              fetchCustomerCurrenciesResolved(
                res.data.data.map((item) => item.currency),
              ),
            );
          } else {
            dispatch(
              settingsActions.fetchCurrenciesResolved(
                res.data.data.map((item) => item.currency),
              ),
            );
          }
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function setPreferredAlternativeCurrency(info, update_customer_reducer) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('customers_currencies/update/preferredalternativecurrency', info)
        .then((res) => {
          if (update_customer_reducer) {
            dispatch(
              fetchCustomerCurrenciesResolved(
                res.data.data.map((item) => item.currency),
              ),
            );
          } else {
            dispatch(
              settingsActions.fetchCurrenciesResolved(
                res.data.data.map((item) => item.currency),
              ),
            );
          }
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchSurveys(input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = 'surveys/list';
      input.length > 0
        ? `'surveys'${serializeSearchInput(input, false)}`
        : 'surveys';
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchSurveyListResolved(res.data.data));
          return resolve(res.data.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function setSurveyActive(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('survey', input)
        .then((res) => {
          dispatch(authActions.serverSuccess(res.data.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateUserSurvey(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('survey/customer/update', input)
        .then((res) => {
          dispatch(authActions.serverSuccess(res.data.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchActiveSurvey(input = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = 'survey/active';
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchActiveSurveyResolved(res.data.data));
          return resolve(res.data.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchAreaRoutes(
  input = [],
  with_pagination = false,
  pageNumber = null,
) {
  const urlBase = `area/routes`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url =
        input.length > 0
          ? `${urlBase}${serializeSearchInput(input, false)}&with_filter=true`
          : urlBase;
      Http.get(
        with_pagination
          ? url +
              (input.length == 0 ? '?' : '&') +
              'with_pagination=true&page=' +
              pageNumber
          : url,
      )
        .then((res) => {
          dispatch(settingsActions.fetchAreaRoutesResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createAreaRoute(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post('area/routes', input.data)
        .then((res) => {
          dispatch(settingsActions.createAreaRouteResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}


export function updateAreaRoute(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('area/routes/' + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateAreaRouteResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteAreaRoute(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put('area/routes/delete/' + input.id, input)
        .then((res) => {
          dispatch(settingsActions.deleteAreaRouteResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchDistricts() {
  const urlBase = `districts/name`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = urlBase;
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchDistrictsResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchGovernorates() {
  const urlBase = `governorates/name`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url = urlBase;
      Http.get(url)
        .then((res) => {
          dispatch(settingsActions.fetchGovernoratesResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createDistrict(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("district", input.data)
        .then((res) => {
          dispatch(settingsActions.createDistrictResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateDistrict(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put("district/" + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateDistrictResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteDistrict(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete("district/" + input.id, input)
        .then((res) => {
          dispatch(settingsActions.deleteDistrictResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createGovernorate(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("governorate", input.data)
        .then((res) => {
          dispatch(settingsActions.createGovernorateResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function updateGovernorate(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put("governorate/" + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateGovernorateResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteGovernorate(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete("governorate/" + input.id, input)
        .then((res) => {
          dispatch(settingsActions.deleteGovernorateResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function fetchHolidays(
  pageNumber = null,
) {
  const isShopify = process.env.REACT_APP_IS_SHOPIFY === "true";
  const urlBase = `${isShopify ? "shipping/" : ""}holidays`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let url =urlBase;
      Http.get(
        url + "?page=" + pageNumber
      )
        .then((res) => {
          dispatch(settingsActions.fetchHolidaysResolved(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
export function getHoliday(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put("holidays/" + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.getHolidayResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
export function updateHoliday(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put("holidays/" + input.id, input.data)
        .then((res) => {
          dispatch(settingsActions.updateHolidayResolved(res.data.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve(res.data.data);
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function deleteHoliday(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete("holidays/" + input.id)
        .then((res) => {
          dispatch(settingsActions.deleteHolidayResolved( input.id));
          dispatch(authActions.serverSuccess(res.data.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}

export function createHoliday(input) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("holidays", input)
        .then((res) => {
          dispatch(settingsActions.createHolidayResolved(res.data));
          dispatch(authActions.serverSuccess(res.data.meta.message));
          return resolve();
        })
        .catch((err) => {
          dispatch(authActions.serverError(err.error));
          return reject(err);
        });
    });
}
