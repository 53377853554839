import * as types from "../ActionTypes/modals";

export function enhancedComments(
  comments,
  object,
  customer_id = null,
  from_order_details = false,
) {
  return {
    type: types.ENHANCED_COMMENTS_MODAL,
    payload: { comments, object, customer_id, from_order_details },
  };
}
export function updatePendingObject(object_id) {
  return { type: types.UPDATE_PENDING_OBJECT, payload: { object_id } };
}
export function setPackageWithTheDriverModal(
  open_set_package_with_driver,
  object,
) {
  return {
    type: types.SET_PACKAGE_WITH_DRIVER_MODAL,
    payload: { open_set_package_with_driver, object },
  };
}
export function reschedule(reschedule, object) {
  return { type: types.RESCHEDULE_MODAL, payload: { reschedule, object } };
}
export function complete(complete, object, order) {
  return { type: types.COMPLETE_MODAL, payload: { complete, object, order } };
}
export function isCriticalOrder(is_critical, object) {
  return { type: types.IS_CRITICAL_MODAL, payload: { is_critical, object } };
}
export function createStaff(open) {
  return { type: types.CREATE_STAFF_MODAL, payload: open };
}
export function createApp(open) {
  return { type: types.CREATE_APP_MODAL, payload: open };
}
export function editApp(edit_app, object) {
  return { type: types.EDIT_APP_MODAL, payload: { edit_app, object } };
}
export function deleteApp(delete_app, object) {
  return { type: types.DELETE_APP_MODAL, payload: { delete_app, object } };
}
export function createToken(open) {
  return { type: types.CREATE_TOKEN_MODAL, payload: open };
}
export function deleteToken(delete_token, object) {
  return { type: types.DELETE_TOKEN_MODAL, payload: { delete_token, object } };
}
export function editToken(edit_token, object) {
  return { type: types.EDIT_TOKEN_MODAL, payload: { edit_token, object } };
}
export function createWebhook(open) {
  return { type: types.CREATE_WEBHOOK_MODAL, payload: open };
}
export function deleteWebhook(delete_webhook, object) {
  return { type: types.DELETE_WEBHOOK_MODAL, payload: { delete_webhook, object } };
}
export function editWebhook(edit_webhook, object) {
  return { type: types.EDIT_WEBHOOK_MODAL, payload: { edit_webhook, object } };
}
export function editStaff(edit_staff, object) {
  return { type: types.EDIT_STAFF_MODAL, payload: { edit_staff, object } };
}
export function deleteRequest(delete_request, object) {
  return {
    type: types.DELETE_REQUEST_MODAL,
    payload: { delete_request, object },
  };
}
export function approveOrRejectRequest(approve_or_reject_request, object) {
  return {
    type: types.APPROVE_OR_REJECT_REQUEST_MODAL,
    payload: { approve_or_reject_request, object },
  };
}
export function closeFail(close_fail, object) {
  return { type: types.CLOSE_FAIL_MODAL, payload: { close_fail, object } };
}

export function postpone(postpone, object) {
  return { type: types.POSTPONE_MODAL, payload: { postpone, object } };
}

export function confirm(confirm, object) {
  return { type: types.CONFRIM_MODAL, payload: { confirm, object } };
}
export function decline(decline, object, remove_data_on_done = false) {
  return {
    type: types.DECLINE_MODAL,
    payload: { decline, object, remove_data_on_done },
  };
}
export function fail(fail, object) {
  return { type: types.FAIL_MODAL, payload: { fail, object } };
}
export function cancelTask(cancel_task, object) {
  return { type: types.CANCEL_TASK_MODAL, payload: { cancel_task, object } };
}
export function editTask(edit_task, object) {
  return { type: types.EDIT_TASK_MODAL, payload: { edit_task, object } };
}
export function requestSign(request_sign, object) {
  return { type: types.REQUEST_SIGN_MODAL, payload: { request_sign, object } };
}
export function assignDriver(assign_driver, object) {
  return {
    type: types.ASSIGN_DRIVER_MODAL,
    payload: { assign_driver, object },
  };
}
export function enhancedAssignDriver(assign_driver, object) {
  return {
    type: types.ENHANCED_ASSIGN_DRIVER_MODAL,
    payload: { assign_driver, object },
  };
}
export function unassignDriver(unassign_driver, object) {
  return {
    type: types.UNASSIGN_DRIVER_MODAL,
    payload: { unassign_driver, object },
  };
}
export function cancelOrder(cancel_order, object) {
  return { type: types.CANCEL_ORDER_MODAL, payload: { cancel_order, object } };
}
export function pendingCancelOrder(pending_cancel_order, object) {
  return { type: types.PENDING_CANCELLATION_ORDER_MODAL, payload: { pending_cancel_order, object } };
}
export function cancelMultipleOrders(cancel_multiple_orders, orders) {
  return { type: types.CANCEL_MULTIPLE_ORDERS_MODAL, payload: { cancel_multiple_orders, orders } };
}
export function editOrder(edit_order, object) {
  return { type: types.EDIT_ORDER_MODAL, payload: { edit_order, object } };
}
export function editAccountantOrder(edit_order, object) {
  return {
    type: types.EDIT_ACCOUNTANT_ORDER_MODAL,
    payload: { edit_order, object },
  };
}
export function enhancedEditOrder(edit_order, object, from_order_details = false, edit_bulk_return = false) {
  return {
    type: types.ENHANCED_EDIT_ORDER_MODAL,
    payload: { edit_order, object , from_order_details, edit_bulk_return},
  };
}
export function editGoldenRule(edit_golden_rule, object) {
  return {
    type: types.EDIT_GOLDEN_RULE,
    payload: { edit_golden_rule, object },
  };
}

export function editShopNote(edit_shop_note, object) {  
  return {
    type: types.EDIT_SHOP_NOTE,
    payload: { edit_shop_note, object },
  };
}

export function viewShopNote(view_shop_note, object) {
  return {
    type: types.VIEW_SHOP_NOTE,
    payload: { view_shop_note, object },
  };
}

export function viewExtraTags(view_extra_tags, object) {
  return {
    type: types.VIEW_EXTRA_TAGS,
    payload: { view_extra_tags, object },
  };
}
export function editInhouseNote(edit_inhouse_note, object) {
  return {
    type: types.EDIT_INHOUSE_NOTE,
    payload: { edit_inhouse_note, object },
  };
}
export function linkConfiguration(link_configuration) {
  return { type: types.LINK_CONFIGURATION, payload: { link_configuration } };
}
export function editConfiguration(edit_configuration, object) {
  return {
    type: types.EDIT_CONFIGURATION,
    payload: { edit_configuration, object },
  };
}
export function editCustomMessage(edit_custom_message, object) {
  return {
    type: types.EDIT_CUSTOM_MESSAGE,
    payload: { edit_custom_message, object },
  };
}
export function reopenOrder(reopen_order, object) {
  return { type: types.REOPEN_ORDER, payload: { reopen_order, object } };
}
export function paidOrder(paid_order, object) {
  return { type: types.PAID_ORDER_MODAL, payload: { paid_order, object } };
}
export function payAllCustomerOrders(pay_customer_orders, object) {
  return {
    type: types.PAY_CUSTOMER_ORDERS_MODAL,
    payload: { pay_customer_orders, object },
  };
}
export function newContact(new_contact, object) {
  return { type: types.NEW_CONTACT_MODAL, payload: { new_contact, object } };
}
export function editContact(edit_contact, object) {
  return { type: types.EDIT_CONTACT_MODAL, payload: { edit_contact, object } };
}
export function editCustomer(edit_customer, object) {
  return {
    type: types.EDIT_CUSTROMER_MODAL,
    payload: { edit_customer, object },
  };
}
export function customizeWaybill(customize_waybill, object, user) {
  return {
    type: types.CUSTOMIZE_WAYBILL_MODAL,
    payload: { customize_waybill, object, user},
  };
}
export function editCustomersAccountManager(edit_customer, object) {
  return {
    type: types.EDIT_CUSTROMER_ACCOUNT_MANAGER_MODAL,
    payload: { edit_customer, object },
  };
}
export function editMultipleCustomersAccountManager(open) {
  return {
    type: types.EDIT_MULTIPLE_CUSTROMERS_ACCOUNT_MANAGER_MODAL,
    payload: { open },
  };
}
export function editCustomersIndustry(edit_customer, object) {
  return {
    type: types.EDIT_CUSTROMER_INDUSTRY_MODAL,
    payload: { edit_customer, object },
  };
}
export function multipleDecline(multiple_decline, array) {
  return {
    type: types.MULTIPLE_DECLINE_ORDERS,
    payload: { multiple_decline, array },
  };
}
export function deleteContact(delete_contact, object) {
  return {
    type: types.DELETE_CONTACT_MODAL,
    payload: { delete_contact, object },
  };
}
export function editRecipient(edit_recipient, object) {
  return {
    type: types.EDIT_RECIPIENT_MODAL,
    payload: { edit_recipient, object },
  };
}
export function deleteLocationLog(delete_location_log, object) {
  return {
    type: types.DELETE_LOCATION_LOG_MODAL,
    payload: { delete_location_log, object },
  };
}
export function deleteLocation(delete_location, object) {
  return {
    type: types.DELETE_LOCATION_MODAL,
    payload: { delete_location, object },
  };
}
export function pwdReset(pwd_reset, object) {
  return { type: types.PASSWORD_RESET_MODAL, payload: { pwd_reset, object } };
}
export function emailResend(email_resend, object) {
  return { type: types.EMAIL_RESEND_MODAL, payload: { email_resend, object } };
}
export function checkIn(check_in, object) {
  return { type: types.CHECK_IN_MODAL, payload: { check_in, object } };
}
export function checkOut(check_out, object) {
  return { type: types.CHECK_OUT_MODAL, payload: { check_out, object } };
}
export function confirmTimeSheet(confirm_time_sheet, object) {
  return {
    type: types.CONFIRM_TIME_SHEET,
    payload: { confirm_time_sheet, object },
  };
}
export function confirmSubmission(confirm_submission, object) {
  return {
    type: types.CONFIRM_SUBMISSION,
    payload: { confirm_submission, object },
  };
}
export function declineSubmission(decline_submission, object) {
  return {
    type: types.DECLINE_SUBMISSION,
    payload: { decline_submission, object },
  };
}
export function newLocation(new_location, object) {
  return { type: types.NEW_LOCATION, payload: { new_location, object } };
}

export function newReceiver(new_receiver, object) {
  return { type: types.NEW_RECEIVER, payload: { new_receiver, object } };
}
export function editPrice(edit_price, object) {
  return { type: types.EDIT_PRICE, payload: { edit_price, object } };
}
export function newPrice(new_price, object) {
  return { type: types.NEW_PRICE, payload: { new_price, object } };
}
export function deletePrice(delete_price, object) {
  return { type: types.DELETE_PRICE, payload: { delete_price, object } };
}
export function editLocation(edit_location, object, from_receiver = false) {
  return {
    type: types.EDIT_LOCATION,
    payload: { edit_location, object, from_receiver },
  };
}
export function submissionsDetailsTasks(submissions_details_tasks, object) {
  return {
    type: types.SUBMISSIONS_DETAILS_TASKS,
    payload: { submissions_details_tasks, object },
  };
}
export function editPiggyBank(edit_piggy_bank, object) {
  return {
    type: types.EDIT_PIGGY_BANK_MODAL,
    payload: { edit_piggy_bank, object },
  };
}
export function piggyBankOrders(piggy_bank_orders, object) {
  return {
    type: types.PIGGY_BANK_ORDERS_MODAL,
    payload: { piggy_bank_orders, object },
  };
}
export function piggyBankNote(piggybank_note, object) {
  return {
    type: types.ADD_PIGGY_BANK_NOTE_MODAL,
    payload: { piggybank_note, object },
  };
}
export function closePiggyBank(close_piggy_bank, object) {
  return {
    type: types.CLOSE_PIGGY_BANK_MODAL,
    payload: { close_piggy_bank, object },
  };
}
export function openImpComment(open_imp_comment, object) {
  return {
    type: types.IMPORTANT_COMMENT_MODAL,
    payload: { open_imp_comment, object },
  };
}
export function openBarcodeConfirmation(open_barcode_confirmation, object) {
  return {
    type: types.BARCODE_CONFIRMATION_MODAL,
    payload: { open_barcode_confirmation, object },
  };
}
//Activate/Deactivate
export function activateDeactivateDriver(activate_deactivate_driver, object) {
  return {
    type: types.ACTIVATE_DEACTIVATE_DRIVER_MODAL,
    payload: { activate_deactivate_driver, object },
  };
}
export function activateDeactivateCustomer(
  activate_deactivate_customer,
  object,
) {
  return {
    type: types.ACTIVATE_DEACTIVATE_CUSTOMER_MODAL,
    payload: { activate_deactivate_customer, object },
  };
}
export function activateDeactivateLocation(
  activate_deactivate_location,
  object,
) {
  return {
    type: types.ACTIVATE_DEACTIVATE_LOCATION_MODAL,
    payload: { activate_deactivate_location, object },
  };
}
export function makeDefaultLocation(make_default_location, object) {
  return {
    type: types.MAKE_DEFAULT_LOCATION_MODAL,
    payload: { make_default_location, object },
  };
}
export function activateDeactivateUser(activate_deactivate_user, object) {
  return {
    type: types.ACTIVATE_DEACTIVATE_USER_MODAL,
    payload: { activate_deactivate_user, object },
  };
}
//Currency
export function editCurrency(edit_currency, object) {
  return {
    type: types.EDIT_CURRENCY_MODAL,
    payload: { edit_currency, object },
  };
}
export function defaultCurrency(set_default_currency, object, userId) {
  return {
    type: types.DEFAULT_CURRENCY_MODAL,
    payload: { set_default_currency, object, userId },
  };
}
export function preferredAlternativeCurrency(
  set_preferred_alternative_currency,
  object,
  userId,
) {
  return {
    type: types.PREFERRED_ALTERNATIVE_CURRENCY_MODAL,
    payload: { set_preferred_alternative_currency, object, userId },
  };
}
export function deleteCurrency(delete_currency, object) {
  return {
    type: types.DELETE_CURRENCY_MODAL,
    payload: { delete_currency, object },
  };
}
export function createCurrency(create_currency) {
  return { type: types.CREATE_CURRENCY_MODAL, payload: { create_currency } };
}
export function linkCurrency(link_currency) {
  return { type: types.LINK_CURRENCY_MODAL, payload: { link_currency } };
}
//bulkOrder
export function createBulkOrder(create_bulk_order, object) {
  return {
    type: types.CREATE_BULK_ORDER_MODAL,
    payload: { create_bulk_order, object },
  };
}
//Import Orders
export function importOrders(import_orders) {
  return {
    type: types.IMPORT_ORDERS_MODAL,
    payload: { import_orders },
  };
}

//Import Orders
export function importTasks(import_tasks) {
  return {
    type: types.IMPORT_TASKS_MODAL,
    payload: { import_tasks },
  };
}
//Areas
export function editArea(edit_area, object) {
  return { type: types.EDIT_AREA_MODAL, payload: { edit_area, object } };
}
export function deleteArea(delete_area, object) {
  return { type: types.DELETE_AREA_MODAL, payload: { delete_area, object } };
}
export function createArea(create_area) {
  return { type: types.CREATE_AREA_MODAL, payload: { create_area } };
}

//District
export function showDistrict(show_district) {
  return { type: types.SHOW_DISTRICT_MODAL, payload: { show_district } };
}
export function createDistrict(create_district) {
  return { type: types.CREATE_DISTRICT_MODAL, payload: { create_district } };
}
export function editDistrict(edit_district, object) {
  return { type: types.EDIT_DISTRICT_MODAL, payload: { edit_district,object } };
}
export function deleteDistrict(delete_district, object) {
  return { type: types.DELETE_DISTRICT_MODAL, payload: { delete_district,object } };
}

//Governorate
export function showGovernorate(show_governorate) {
  return { type: types.SHOW_GOVERNORATE_MODAL, payload: { show_governorate } };
}
export function createGovernorate(create_governorate) {
  return { type: types.CREATE_GOVERNORATE_MODAL, payload: { create_governorate } };
}
export function editGovernorate(edit_governorate, object) {
  return { type: types.EDIT_GOVERNORATE_MODAL, payload: { edit_governorate,object } };
}
export function deleteGovernorate(delete_governorate, object) {
  return { type: types.DELETE_GOVERNORATE_MODAL, payload: { delete_governorate,object } };
}

//Stocks
export function editStock(edit_stock, object) {
  return { type: types.EDIT_STOCK_MODAL, payload: { edit_stock, object } };
}
export function deleteStock(delete_stock, object) {
  return { type: types.DELETE_STOCK_MODAL, payload: { delete_stock, object } };
}
export function createStock(create_stock) {
  return { type: types.CREATE_STOCK_MODAL, payload: { create_stock } };
}

//Vehicle
export function editVehicle(edit_vehicle, object) {
  return { type: types.EDIT_VEHICLE_MODAL, payload: { edit_vehicle, object } };
}
export function deleteVehicle(delete_vehicle, object) {
  return {
    type: types.DELETE_VEHICLE_MODAL,
    payload: { delete_vehicle, object },
  };
}
export function createVehicle(create_vehicle) {
  return { type: types.CREATE_VEHICLE_MODAL, payload: { create_vehicle } };
}

//Zone
export function viewZone(view_zone, object) {
  return { type: types.VIEW_ZONE_MODAL, payload: { view_zone, object } };
}
export function editZone(edit_zone, object) {
  return { type: types.EDIT_ZONE_MODAL, payload: { edit_zone, object } };
}
export function deleteZone(delete_zone, object) {
  return { type: types.DELETE_ZONE_MODAL, payload: { delete_zone, object } };
}
export function createZone(create_zone) {
  return { type: types.CREATE_ZONE_MODAL, payload: { create_zone } };
}

//Office
export function editOffice(edit_office, object) {
  return { type: types.EDIT_OFFICE_MODAL, payload: { edit_office, object } };
}
export function deleteOffice(delete_office, object) {
  return {
    type: types.DELETE_OFFICE_MODAL,
    payload: { delete_office, object },
  };
}
export function createOffice(create_office) {
  return { type: types.CREATE_OFFICE_MODAL, payload: { create_office } };
}

export function setDefaultWaybillAddress(set_default_waybill_address, object) {
  return {
    type: types.SET_DEFUALT_WAYBILL_ADDRESS_MODAL,
    payload: { set_default_waybill_address, object },
  };
}
//Types
export function editType(edit_type, object) {
  return { type: types.EDIT_TYPE_MODAL, payload: { edit_type, object } };
}
export function createType(create_type) {
  return { type: types.CREATE_TYPE_MODAL, payload: { create_type } };
}

//Third party shipOrder
export function shipOrder(open, object) {
  return { type: types.SHIP_ORDER, payload: { open, object } };
}

//Reviews
export function reviewFeedback(open, object) {
  return { type: types.REVIEW_FEEDBACK, payload: { open, object } };
}

export function editPhoneNumber(edit_phone_nbr, object) {
  return { type: types.EDIT_PHONE_NBR, payload: { edit_phone_nbr, object } };
}

export function exportOrderFields(
  order_report_fields_open,
  order_report_fields,
) {
  return {
    type: types.ORDER_REPORT_FIELDS,
    payload: { order_report_fields_open, order_report_fields },
  };
}

export function createExistingRecipientLocation(
  create_location_open,
  customer_id,
  recipient=null
) {
  return {
    type: types.CREATE_EXISTING_RECIPIENT_LOCATION_MODAL,
    payload: { create_location_open, customer_id, recipient },
  };
}

export function addReview(add_review, object) {
  return { type: types.ADD_REVIEW_MODAL, payload: { add_review, object } };
}

// Barcodes
export function generateAndPrintBarcodes(open, object) {
  return {
    type: types.GENERATE_AND_PRINT_BARCODES,
    payload: {
      open,
      object,
    },
  };
}

export function createDeliveryOrderForBarcode(open, object) {
  return {
    type: types.CREATE_DELIVERY_ORDER_FOR_BARCODE_MODAL,
    payload: { open, object },
  };
}

export function createReturnDeliveryOrderForBarcode(open, object) {
  return {
    type: types.CREATE_RETURN_DELIVERY_ORDER_FOR_BARCODE_MODAL,
    payload: { open, object },
  };
}

export function attachBarcodeToExistingOrder(open, objectBarcode, from_path) {
  return {
    type: types.ATTACH_BARCODE_TO_EXISTING_ORDER,
    payload: { open, objectBarcode, from_path},
  };
}

export function deleteBarcode(open, object) {
  return {
    type: types.DELETE_BARCODE_MODAL,
    payload: { open, object },
  };
}

// Master Barcodes
export function generateAndPrintMasterBarcodes(open, object) {
  return {
    type: types.GENERATE_AND_PRINT_MASTER_BARCODES,
    payload: {
      open,
      object,
    },
  };
}

export function deleteMasterBarcode(open, object) {
  return {
    type: types.DELETE_MASTER_BARCODE_MODAL,
    payload: { open, object },
  };
}

// Move orders from master barcode to another master barcode
export function moveOrdersFromMasterBarcode(open, object) {
  return {
    type: types.MOVE_ORDERS_FROM_MASTER_BARCODE,
    payload: { open },
  };
}
//Services
export function createService(create_service) {
  return {
    type: types.CREATE_SERVICE_MODAL,
    payload: { create_service },
  };
}

export function editService(edit_service, object) {
  return {
    type: types.EDIT_SERVICE_MODAL,
    payload: { edit_service, object },
  };
}

export function deleteService(open, object) {
  return {
    type: types.DELETE_SERVICE_MODAL,
    payload: { open, object },
  };
}

// Order
export function displayOrderPicture(open, object) {
  return {
    type: types.DISPLAY_ORDER_PICTURE_MODAL,
    payload: { open, object },
  };
}

//Banners
export function createBanner(create_banner) {
  return {
    type: types.CREATE_BANNER_MODAL,
    payload: { create_banner },
  };
}

export function editBanner(edit_banner, object) {
  return {
    type: types.EDIT_BANNER_MODAL,
    payload: { edit_banner, object },
  };
}

export function deleteBanner(delete_banner, object) {
  return {
    type: types.DELETE_BANNER_MODAL,
    payload: { delete_banner, object },
  };
}

export function notificationBanner(notification_banner, object) {
  return {
    type: types.NOTIFICATION_BANNER_MODAL,
    payload: { notification_banner, object },
  };
}
// internal banner
export function createInternalBanner(create_internal_banner) {
  return {
    type: types.CREATE_INTERNAL_BANNER_MODAL,
    payload: { create_internal_banner },
  };
}

export function editInternalBanner(edit_internal_banner, object) {
  return {
    type: types.EDIT_INTERNAL_BANNER_MODAL,
    payload: { edit_internal_banner, object },
  };
}

export function deleteInternalBanner(delete_internal_banner, object) {
  return {
    type: types.DELETE_INTERNAL_BANNER_MODAL,
    payload: { delete_internal_banner, object },
  };
}

export function confirmOrder(confirm_order, object, is_pending, handleAreaConfirmationRequired) {
  return {
    type: types.CONFIRM_ORDER_MODAL,
    payload: { confirm_order, object, is_pending, handleAreaConfirmationRequired },
  };
}


// Marketplace
export function upgradeToMarketplaceSeller(open, customer_id) {
  return {
    type: types.UPGRADE_TO_MARKETPLACE_SELLER_MODAL,
    payload: { open, customer_id }
  };
}
export function expressWarning(express_warning, handleAreaConfirmationRequiredExpress) {
  return {
    type: types.EXPRESS_WARNING_MODAL,
    payload: { express_warning, handleAreaConfirmationRequiredExpress },
  };
}

//Areas Routes
export function createAreaRoute(create_area_route) {
  return {
    type: types.CREATE_AREA_ROUTE_MODAL,
    payload: { create_area_route },
  };
}

export function editAreaRoute(edit_area_route, object) {
  return {
    type: types.EDIT_AREA_ROUTE_MODAL,
    payload: { edit_area_route, object },
  };
}

export function deleteAreaRoute(delete_area_route, object) {
  return {
    type: types.DELETE_AREA_ROUTE_MODAL,
    payload: { delete_area_route, object },
  };
}

//Assign Area To Driver
export function assignAreaToDriver(assign_area_to_driver, route_id = "") {
  return {
    type: types.ASSIGN_AREA_TO_DRIVER,
    payload: { assign_area_to_driver,route_id },
  };
}

//Edit Route Area
export function editRouteArea(edit_route_area, route_id = "") {
  return {
    type: types.EDIT_ROUTE_AREA,
    payload: { edit_route_area,route_id },
  };
}

export function createBulkReturnOrder(bulk_return_modal, is_edit = false, order_id = null) {
  return {
    type: types.CREATE_BULK_RETURN_MODAL,
    payload: { bulk_return_modal, is_edit, order_id },
  };
}

export function CreateCashboxSettlement(cashbox_settlement_modal, cashbox) {
  return {
    type: types.CREATE_CASHBOX_SETTLEMENT,
    payload: { cashbox_settlement_modal, cashbox },
  };
}

//Add Excluded Client
export function addExcludedClient(add_excluded_client) {
  return {
    type: types.ADD_EXCLUDED_CLIENT,
    payload: { add_excluded_client},
  };
}

export function createCashboxOrder(create_cashbox_order, cashbox) {
  return {
    type: types.CREATE_CASHBOX_ORDER_MODAL,
    payload: { create_cashbox_order, cashbox }
  };
}


export function addCashboxExtraFees(add_cashbox_extra_fees, cashbox) {
  return {
    type: types.ADD_CASHBOX_EXTRA_FEES_MODAL,
    payload: { add_cashbox_extra_fees, cashbox },
  };
}
export function updateCashboxExtraFee(update_cashbox_extra_fee, cashbox_extra_fee_id) {
  return {
    type: types.UPDATE_CASHBOX_EXTRA_FEE_MODAL,
    payload: { update_cashbox_extra_fee, cashbox_extra_fee_id },
  };
}

export function cancelCashbox(cancel_cashbox, cashbox) {
  return {
    type: types.CANCEL_CASHBOX_MODAL,
    payload: { cancel_cashbox, cashbox },
  };
}
export function cancelMultipleCashboxes(cancel_multiple_cashboxes, cashboxes) {
  return {
    type: types.CANCEL_MULTIPLE_CASHBOXES_MODAL,
    payload: { cancel_multiple_cashboxes, cashboxes },
  };
}

export function createMultipleCashboxOrders(create_multiple_cashbox_orders, cashboxes) {
  return {
    type: types.CREATE_MULTIPLE_CASHBOX_ORDERS_MODAL,
    payload: { create_multiple_cashbox_orders, cashboxes },
  };
}

export function addCashbox(add_cashbox) {
  return {
    type: types.ADD_CASHBOX_MODAL,
    payload: { add_cashbox },
  };
}
export function editAuditedOrderCollections(edit_audited_order_collections, audited_collection_type, order_id) {
  return {
    type: types.EDIT_AUDITED_ORDER_COLLECTIONS_MODAL,
    payload: { edit_audited_order_collections, audited_collection_type, order_id },
  };
}

export function editOrderDeliveryPrice(edit_order_delivery_price, data) {
  return {
    type: types.EDIT_ORDER_DELIVERY_PRICE_MODAL,
    payload: { edit_order_delivery_price, data },
  };
}

export function saveAuditedOrderCollections(save_audited_order_collections, audited_collections, new_audited_collections, audited_collection_type, order_id) {
  return {
    type: types.SAVE_AUDITED_ORDER_COLLECTIONS_MODAL,
    payload: { save_audited_order_collections, audited_collections, new_audited_collections, audited_collection_type, order_id },
  };
}

export function cancelEditAuditedOrderCollections(cancel_audited_order_collections, order_id) {
  return {
    type: types.CANCEL_AUDITED_ORDER_COLLECTIONS_MODAL,
    payload: { cancel_audited_order_collections, order_id },
  };
}

export function areaConfirmation(is_area_confirmation_required, areaData, confirmAreaAndSubmit) {
  return { type: types.AREA_CONFIRMATION_MODAL, payload: { is_area_confirmation_required, areaData, confirmAreaAndSubmit } };
}
export function editHoliday(edit_holiday, holiday) {
  return { type: types.EDIT_HOLIDAY_MODAL, payload: { edit_holiday, holiday } };
}
export function deleteHoliday(delete_holiday, holiday) {
  return { type: types.DELETE_HOLIDAY_MODAL, payload: { delete_holiday, holiday } };
}
export function createHoliday(create_holiday) {
  return { type: types.CREATE_HOLIDAY_MODAL, payload: { create_holiday } };
}

export function createOrEditInternationalPackageItem(create_edit_item, i, k, item_to_edit_index, item_to_edit) {
  return { type: types.CREATE_EDIT_INTERNATIONAL_PACKAGE_ITEM_MODAL, payload: { create_edit_item, i, k, item_to_edit_index, item_to_edit} };
}