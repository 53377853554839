import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as authService from "services/authService";
import * as dataService from "services/dataService";
import * as bannerService from "services/bannerService";
import APPCONFIG from "constants/Config";
import Echo from "laravel-echo";
window.io = require("socket.io-client");
import { isAdmin, isCustomer, isOperator, isAccountant } from "utils/helpers";
import { push } from 'connected-react-router';

class MainCalls extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isloading: false,
      responseError: {
        isError: false,
        code: "",
        text: ""
      }
    };
  }

  componentWillMount(){
    if (isCustomer(this.props.user)) {
      this.loadActiveBanner();
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("jwt_token")) {
      this.props.dispatch(push("/login"));
    } else if (
      this.props.typeList.length == 0 &&
      (!this.props.areasList && !this.state.isloading)
    ) {
      this.getMe();
      this.loadTypeList();
      this.loadNotifications();
      this.loadAreas();
      this.loadUserNames();
      this.loadActiveFlag();
    
      if (
        isAdmin(this.props.user) ||
        isOperator(this.props.user) ||
        isAccountant(this.props.user)
      ) {
        // this.openSocket();
      }
    }
  }

  componentWillUpdate(nextProps) {
    if (!localStorage.getItem("jwt_token")) {
      this.props.dispatch(push("/login"));
    }
  }

  setLoading = isLoading => {
    this.setState({
      ...this.state,
      isLoading: isLoading
    });
  };

  loadActiveBanner = () => {
    this.setLoading(true);
    let search_input = [{ key: "is_active", element: 1 }];

    this.props
      .dispatch(bannerService.fetchActiveBanner(search_input))
      .then(response => {
        this.setLoading(false);

        let currentBanner = localStorage.getItem("current_banner_content");
        
        if(! currentBanner.startsWith('[')){
          localStorage.setItem(
            "current_banner_content",
            JSON.stringify(response.data)
          );
          localStorage.setItem("is_banner_viewed", "no");
        }else {
        let currentBanner = JSON.parse(localStorage.getItem("current_banner_content"));  
        if (response.data.length) {
          if (!currentBanner) {
            localStorage.setItem(
              "current_banner_content",
              JSON.stringify(response.data)
            );
            localStorage.setItem("is_banner_viewed", "no");
          } else {
            let isBannerChanged =
            currentBanner.length !== response.data.length || // Check if length changed
            currentBanner.some((savedItem, index) => {     // Check if any item in the array changed
                  const bannerItem = response.data[index];
                  return savedItem.subject !== bannerItem.subject || savedItem.message !== bannerItem.message;
                });
            if (isBannerChanged)
             {
              localStorage.setItem(
                "current_banner_content",
                JSON.stringify(response.data)
              );
              localStorage.setItem("is_banner_viewed", "no");
            }
          }
        }
      }
      })
      .catch(({ error, statusCode }) => {
        this.setState({
          isLoading: false,
          responseError: {
            isError: true
          }
        });
      });
  };

  loadActiveFlag = () => {
    this.props
      .dispatch(dataService.fetchFlag())
      .then((response) => {
        const flagData = JSON.stringify(response.data);
        localStorage.setItem('storedFlag', flagData);
      })
      .catch(({ error, statusCode }) => {
        this.setState({
          isLoading: false,
          responseError: {
            isError: true,
          },
        });
      });
  };

  openSocket() {
    if (typeof io !== "undefined") {
      window.Echo = new Echo({
        broadcaster: "socket.io",
        host: APPCONFIG.socket_api + ":2083",
        transports: ["websocket", "polling", "flashsocket"],
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt_token")}`
          }
        }
      });
    }
    window.Echo.private("order-created").listen(".created", e => {
      // this.props.dispatch(orderActions.listenerOrderCreated(e.order_data))
    });
    window.Echo.private("order-updated").listen(".updated", e => {
      // this.props.dispatch(orderActions.listenerUpdateOrderResolved(e.order))
    });
  }

  getMe() {
    this.setState(state => ({ isLoading: true }));

    this.props
      .dispatch(authService.getMe())
      .then(response => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState(state => ({ isLoading: false, responseError }));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState(state => ({ isLoading: false, responseError }));
      });
  }

  loadAreas() {
    this.setState(state => ({ isLoading: true }));

    this.props
      .dispatch(dataService.fetchAreas())
      .then(response => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState(state => ({ isLoading: false, responseError }));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState(state => ({ isLoading: false, responseError }));
      });
  }

  loadTypeList() {
    this.setState(state => ({ isLoading: true }));

    this.props
      .dispatch(dataService.fetchTypeList())
      .then(response => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState(state => ({ isLoading: false, responseError }));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState(state => ({ isLoading: false, responseError }));
      });
  }

  loadUserNames() {
    this.props
      .dispatch(dataService.fetchOperators())
      .then(response => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState(state => ({ isLoading: false, responseError }));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState(state => ({ isLoading: false, responseError }));
      });
  }

  loadNotifications() {
    this.setState(state => ({ isLoading: true }));

    this.props
      .dispatch(authService.fetchNotifications())
      .then(response => {
        const responseError = {
          isError: false,
          code: "",
          text: ""
        };
        this.setState(state => ({ isLoading: false, responseError }));
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error
        };
        this.setState(state => ({ isLoading: false, responseError }));
      });
  }

  render() {
    return <div />;
  }
}

MainCalls.propTypes = {
  typeList: PropTypes.any.isRequired
};

const mapStateToProps = state => {
  return {
    typeList: state.data.typeList,
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(MainCalls);
