import {
  FETCH_ROUTES_RESOLVED,
  FETCH_ROUTE_LISTS_RESOLVED,
} from "../ActionTypes/area-routes";

const initialState = {
  routeList: [],
  sortOrder: "asc",
  sortBy: "name",
};

const naturalSort = (a, b) => {
  const re = /(\d+)|(\D+)/g;
  const aNameParts = String(a).match(re);
  const bNameParts = String(b).match(re);

  while (aNameParts.length && bNameParts.length) {
    const aPart = aNameParts.shift();
    const bPart = bNameParts.shift();
    const isNumeric = /\d/.test(aPart);

    if (isNumeric) {
      const diff = parseInt(aPart, 10) - parseInt(bPart, 10);
      if (diff !== 0) {
        return diff;
      }
    } else if (aPart !== bPart) {
      return aPart.localeCompare(bPart);
    }
  }

  return aNameParts.length - bNameParts.length;
};

const areaRouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROUTES_RESOLVED: {
      let sortedRouteList = [...action.payload.data];

      sortedRouteList.sort((a, b) => {
        const nameA = a[state.sortBy];
        const nameB = b[state.sortBy];

        return state.sortOrder === "asc"
          ? naturalSort(nameA, nameB)
          : naturalSort(nameB, nameA);
      });

      return {
        ...state,
        routeList: sortedRouteList,
      };
    }
    case FETCH_ROUTE_LISTS_RESOLVED: {
      return {
        ...state,
        areaRouteLists: action.payload.data.routes,
      };
    }
    default:
      return state;
  }
};

export default areaRouteReducer;
