import * as types from "../ActionTypes/data";

export function fetchZonesResolved(payload) {
  return {
    type: types.FETCH_ZONES_RESOLVED,
    payload
  };
}
export function fetchAreasResolved(payload) {
  return {
    type: types.FETCH_AREAS_RESOLVED,
    payload
  };
}

export function fetchAutocompleteAreas(payload) {
  return {
    type: types.FETCH_AUTOCOMPLETE_AREAS_RESOLVED,
    payload
  };
}
export function clearAutocompleteAreas() {
  return {
    type: types.CLEAR_AUTOCOMPLETE_AREAS_RESOLVED
  };
}

export function fetchAutocompleteRecipients(payload) {
  return {
    type: types.FETCH_AUTOCOMPLETE_RECIPIENTS_RESOLVED,
    payload
  };
}

export function fetchTopAreasByCustomer(payload) {
  return {
    type: types.FETCH_TOP_AREAS_BY_CUSTOMER_RESOLVED,
    payload
  };
}
export function clearAutocompleteRecipients() {
  return {
    type: types.CLEAR_AUTOCOMPLETE_RECIPIENTS_RESOLVED
  };
}

export function fetchVehiclesResolved(payload) {
  return {
    type: types.FETCH_VEHICLES_RESOLVED,
    payload
  };
}
export function fetchListResolved(payload) {
  return {
    type: types.FETCH_LIST_RESOLVED,
    payload
  };
}
export function createTypeResolved(payload, type) {
  return {
    type: types.CREATE_TYPE_RESOLVED,
    payload,
    objectType: type
  };
}
export function updateTypeResolved(payload, type) {
  return {
    type: types.UPDATE_TYPE_RESOLVED,
    payload,
    objectType: type
  };
}

export function fetchRecipientsResolved(payload) {
  return {
    type: types.FETCH_RECIPIENTS_RESOLVED,
    payload
  };
}

export function fetchOperatorsNames(payload) {
  return {
    type: types.FETCH_OPERATORS_NAMES_RESOLVED,
    payload
  };
}
