import React from "react";
import IconMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { withStyles } from "@mui/styles";
import _ from "lodash";
import { logout } from "services/authService";
import { push } from 'connected-react-router';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ForwardIcon from '@mui/icons-material/Forward';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const ImgIconButtonStyle = {
  width: "60px",
  height: "60px"
};

const listItemStyle = {
  paddingLeft: "50px" // 36 + 16, algin with sub list
};

const styles = {
  avatar: {
    margin: 10
  },
  purpleAvatar: {
    // margin: 10,
    //color: "#fff",
    backgroundColor: "#ffc107",
    width: "30px",
    height: "30px",
    color: "black",
    fontSize: "0.875rem"
  },
  row: {
    display: "flex",
    justifyContent: "center"
  }
};

class NavRightList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (value) => {
    if (value == "/login") {
      this.props
        .dispatch(logout())
        .then(() => {
          this.handleClose();
          this.props.dispatch(push(value));
        })
        .catch(({ error, statusCode }) => {
          const responseError = {
            isError: true,
            code: statusCode,
            text: error
          };
          this.setState({ responseError });
          this.setState({
            isLoading: false
          });
        });
    } else {
      this.handleClose();
      this.props.dispatch(push(value));
    }
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <ul className="list-unstyled float-right">
        <li style={{ marginRight: "10px" }}>
        <div>
          <IconButton
            style={ImgIconButtonStyle}
            onClick={this.handleClick}
          >
            <Avatar sx={styles.purpleAvatar}>
                {this.props.user.contact
                  ? this.props.user.contact.first_name.slice(0, 1)
                  : null}
                {this.props.user.contact && this.props.user.contact.last_name
                  ? this.props.user.contact.last_name.slice(0, 1)
                  : null}
            </Avatar>
          </IconButton>
          <IconMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            MenuListProps={{
              sx: {
                minWidth: '150px',
              }
            }}
          >
            <MenuItem 
              onClick={() => this.handleMenuItemClick('/app/profile')} 
              sx={{ paddingRight: "50px" }}
            >
              <ListItemIcon>
                <PersonOutlineIcon sx={{fontSize: "24px", color: "black"}}/>
              </ListItemIcon>
              <ListItemText 
                primary="Profile"  
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '14px',
                    lineHeight: '38px',
                  },
                }}/>
            </MenuItem>

            <MenuItem
              onClick={() => this.handleMenuItemClick('/login')} 
              sx={{ paddingRight: "50px" }}
            >
              <ListItemIcon>
                <ForwardIcon sx={{fontSize: "24px", color: "black"}} />
              </ListItemIcon>
              <ListItemText
                primary="Log Out"
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '14px',
                    lineHeight: '38px',
                  },
                }}/>
            </MenuItem>
          </IconMenu>
        </div>
        </li>
      </ul>
    );
  }
}

NavRightList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default
  withStyles(styles)(connect(mapStateToProps)(NavRightList))
;
