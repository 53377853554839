import {
  FETCH_TIMESHEETS_RESOLVED,
  FETCH_MESSAGES_RESOLVED,
  UPDATE_TIMESHEET_RESOLVED,
  UPDATE_MESSAGE_RESOLVED,
  FETCH_USERS_RESOLVED,
  CREATE_STAFF,
  EDIT_STAFF,
  DELETE_USER,
  CREATE_RECRUITMENT_REQUEST,
  FETCH_RECRUITMENT_REQUESTS_RESOLVED,
  DELETE_RECRUITMENT_REQUEST,
  APPROVE_OR_REJECT_RECRUITMENT_REQUEST,
  CREATE_LEAVE_REQUEST,
  FETCH_LEAVE_REQUESTS_RESOLVED,
  FETCH_LEAVE_REQUEST_RESOLVED,
  DELETE_LEAVE_REQUEST,
  APPROVE_OR_REJECT_LEAVE_REQUEST,
  CREATE_EXIT_REQUEST,
  FETCH_EXIT_REQUESTS_RESOLVED,
  DELETE_EXIT_REQUEST,
  APPROVE_OR_REJECT_EXIT_REQUEST
} from "../ActionTypes/hr";
import _ from "lodash";
import moment from "moment";

const initialState = {
  timesheets: [],
  messages: [],
  users: [],
  recruitment_requests: [],
  leave_requests: [],
  leave_request: {},
  exit_requests: []
};

const hrReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_STAFF:{
      let users = JSON.parse(JSON.stringify(state.users));
      users.forEach((item, index) => {
        if (item.id == action.payload.id) {
          users[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        users
      });
    }
    case DELETE_USER: {
      let users = JSON.parse(JSON.stringify(state.users));
      let user = _.remove(users, function(o) {
        return o.id == action.payload.id;
      });
      return Object.assign({}, state, {
        users
      });
    }
    case FETCH_TIMESHEETS_RESOLVED:
      return Object.assign({}, state, {
        timesheets: action.payload
      });

    case FETCH_MESSAGES_RESOLVED: {
      const messages = _.sortBy(action.payload, function(o) {
        return new moment(o.created_at);
      }).reverse();
      return Object.assign({}, state, {
        messages
      });
    }

    case UPDATE_TIMESHEET_RESOLVED: {
      let timesheets = JSON.parse(JSON.stringify(state.timesheets));
      timesheets.forEach((item, index) => {
        if (item.id == action.payload.id) {
          timesheets[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        timesheets
      });
    }

    case UPDATE_MESSAGE_RESOLVED: {
      let messages = JSON.parse(JSON.stringify(state.messages));
      messages.forEach((item, index) => {
        if (item.id == action.payload.id) {
          messages[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        messages
      });
    }

    case FETCH_USERS_RESOLVED:
      return Object.assign({}, state, {
        users: action.payload
      });

    case CREATE_STAFF: {
      let users = JSON.parse(JSON.stringify(state.users));
      users.push(action.payload);
      return Object.assign({}, state, {
        users
      });
    }

    case CREATE_RECRUITMENT_REQUEST: {
      let recruitment_requests = JSON.parse(
        JSON.stringify(state.recruitment_requests)
      );
      recruitment_requests.push(action.payload);

      return Object.assign({}, state, {
        recruitment_requests
      });
    }

    case FETCH_RECRUITMENT_REQUESTS_RESOLVED:
      return Object.assign({}, state, {
        recruitment_requests: action.payload
      });
    case DELETE_RECRUITMENT_REQUEST: {
      let recruitment_requests = JSON.parse(
        JSON.stringify(state.recruitment_requests)
      );
      _.remove(recruitment_requests, function(o) {
        return o.id == action.payload.id;
      });

      return Object.assign({}, state, {
        recruitment_requests
      });
    }

    case APPROVE_OR_REJECT_RECRUITMENT_REQUEST: {
      let recruitment_requests = JSON.parse(
        JSON.stringify(state.recruitment_requests)
      );
      recruitment_requests.forEach((item, index) => {
        if (item.id == action.payload.id) {
          recruitment_requests[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        recruitment_requests
      });
    }

    case CREATE_LEAVE_REQUEST: {
      let leave_requests = JSON.parse(JSON.stringify(state.leave_requests));
      leave_requests.push(action.payload);

      return Object.assign({}, state, {
        leave_requests
      });
    }

    case FETCH_LEAVE_REQUESTS_RESOLVED:
      return Object.assign({}, state, {
        leave_requests: action.payload
      });

    case FETCH_LEAVE_REQUEST_RESOLVED:
      return Object.assign({}, state, {
        leave_request: action.payload
      });

    case DELETE_LEAVE_REQUEST: {
      let leave_requests = JSON.parse(JSON.stringify(state.leave_requests));
      _.remove(leave_requests, function(o) {
        return o.id == action.payload.id;
      });

      return Object.assign({}, state, {
        leave_requests
      });
    }

    case APPROVE_OR_REJECT_LEAVE_REQUEST: {
      let leave_requests = JSON.parse(JSON.stringify(state.leave_requests));
      leave_requests.forEach((item, index) => {
        if (item.id == action.payload.id) {
          leave_requests[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        leave_requests
      });
    }

    case CREATE_EXIT_REQUEST: {
      let exit_requests = JSON.parse(JSON.stringify(state.exit_requests));
      exit_requests.push(action.payload);

      return Object.assign({}, state, {
        exit_requests
      });
    }

    case FETCH_EXIT_REQUESTS_RESOLVED:
      return Object.assign({}, state, {
        exit_requests: action.payload
      });

    case DELETE_EXIT_REQUEST: {
      let exit_requests = JSON.parse(JSON.stringify(state.exit_requests));
      _.remove(exit_requests, function(o) {
        return o.id == action.payload.id;
      });

      return Object.assign({}, state, {
        exit_requests
      });
    }

    case APPROVE_OR_REJECT_EXIT_REQUEST: {
      let exit_requests = JSON.parse(JSON.stringify(state.exit_requests));
      exit_requests.forEach((item, index) => {
        if (item.id == action.payload.id) {
          exit_requests[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        exit_requests
      });
    }

    default:
      return state;
  }
};

export default hrReducer;
