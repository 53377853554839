import * as types from "../ActionTypes/customers";

export function fetchCustomersResolved(payload) {
  return {
    type: types.FETCH_CUSTOMERS_RESOLVED,
    payload
  };
}

export function fetchCustomerResolved(payload) {
  return {
    type: types.FETCH_CUSTOMER_RESOLVED,
    payload
  };
}

export function fetchCustomerCurrenciesResolved(payload) {
  return {
    type: types.FETCH_CUSTOMER_CURRENCIES,
    payload
  };
}

export function createCurrencyResolved(payload) {
  return {
    type: types.CREATE_CUSTOMER_CURRENCY_RESOLVED,
    payload
  };
}

export function updateCurrencyResolved(payload) {
  return {
    type: types.UPDATE_CUSTOMER_CURRENCY_RESOLVED,
    payload
  };
}

export function deleteCustomerCurrencyResolved(payload) {
  return {
    type: types.DELETE_CUSTOMER_CURRENCY_RESOLVED,
    payload
  };
}

export function fetchPiggyBanksResolved(payload) {
  return {
    type: types.FETCH_PIGGYBANKS_RESOLVED,
    payload
  };
}

export function updatePiggyBanksResolved(payload) {
  return {
    type: types.UPDATE_PIGGYBANKS_RESOLVED,
    payload
  };
}

export function fetchPiggyBankTasksResolved(payload) {
  return {
    type: types.FETCH_PIGGYBANK_TASKS_RESOLVED,
    payload
  };
}
export function updatePiggyBankResolved(payload) {
  return {
    type: types.UPDATE_PIGGYBANK_RESOLVED,
    payload
  };
}
export function updatePiggyBankList(payload, piggyBank_id) {
  return {
    type: types.UPDATE_PIGGYBANK_List,
    payload,
    piggyBank_id
  };
}

export function updateCustomerResolved(payload) {
  return {
    type: types.UPDATE_CUSTOMER_RESOLVED,
    payload
  };
}

export function createContactResolved(payload) {
  return {
    type: types.CREATE_CONTACT_RESOLVED,
    payload
  };
}

export function editContactResolved(payload) {
  return {
    type: types.EDIT_CONTACT_RESOLVED,
    payload
  };
}

export function deleteContactResolved(payload) {
  return {
    type: types.DELETE_CONTACT_RESOLVED,
    payload
  };
}

export function fetchRecipientsResolved(payload) {
  return {
    type: types.FETCH_RECIPIENTS_RESOLVED,
    payload
  };
}

export function editRecipeintResolved(payload) {
  return {
    type: types.EDIT_RECIPIENT_RESOLVED,
    payload
  };
}

export function fetchCustomerPricesResolved(payload) {
  return {
    type: types.FETCH_PRICES_RESOLVED,
    payload
  };
}

export function editPriceResolved(payload) {
  return {
    type: types.EDIT_PRICE_RESOLVED,
    payload
  };
}

export function deletePriceResolved(payload) {
  return {
    type: types.DELETE_PRICE_RESOLVED,
    payload
  };
}

export function newPriceResolved(payload) {
  return {
    type: types.NEW_PRICE_RESOLVED,
    payload
  };
}

export function chosenCustomer(payload) {
  return {
    type: types.CHOSEN_CUSTOMER,
    payload
  };
}

export function fetchContactsResolved(payload) {
  return {
    type: types.FETCH_CONTACTS_RESOLVED,
    payload
  };
}

export function fetchCustomersNamesResolved(payload) {
  return {
    type: types.FETCH_CUSTOMERS_NAMES_LIST_RESOLVED,
    payload
  };
}

export function fetchActiveCustomersResolved(payload) {
  return {
    type: types.FETCH_ACTIVE_CUSTOMERS_RESOLVED,
    payload
  };
}

export function updateTaskCollectionsResolved(payload, piggybank_id, task_id) {
  return {
    type: types.UPDATE_TASK_COLLECTIONS_RESOLVED,
    payload,
    piggybank_id,
    task_id
  };
}
export function updateCustomerNameResolved(payload) {
  return {
    type: types.UPDATE_CUSTOMER_NAME_RESOLVED,
    payload
  };
}

export function fetchAutoCompleteCustomers(payload) {
  return {
    type: types.FETCH_AUTOCOMPLETE_CUSTOMERS_RESOLVED,
    payload
  };
}

export function fetchShopNotesResolved(payload) {
  return {
    type: types.FETCH_SHOP_NOTES_RESOLVED,
    payload
  };
}

export function createShopNoteResolved(payload) {
  return {
    type: types.CREATE_SHOP_NOTE_RESOLVED,
    payload
  };
}

export function deleteShopNoteResolved(payload) {
  return {
    type: types.DELETE_SHOP_NOTE_RESOLVED,
    payload
  };
}
