/**
 * Create a new customer object
 * @param {int} id Id
 * @param {string} id_string Id int to string
 * @param {string} full_name Full name
 */
export function customer(props) {
  return {
    id: props.id,
    is_active: props.is_active,
    created_at: props.created_at,
    full_name: props.name,
    has_prices: props.has_prices,
    name: props.name,
    payment_method: props.payment_method,
    subscription_type: props.subscription_type,
    quality_check: props.quality_check ? props.quality_check : "N/A",
    mof: props.mof ? props.mof : "N/A",
    vat: props.vat ? props.vat : "N/A",
    accounting_ref: props.accounting_ref ? props.accounting_ref : "N/A",
    discount: props.discount ? props.discount : "N/A",
    phone_number: props.phone_number,
    default_address: props.default_address,
    type: props.type,
    type_other_label: props.type_other_label,
    users: props.users ? props.users : [],
    golden_rule: props.golden_rule,
    shop_note: props.shop_note || '',
    shop_name: props.shop_name,
    image: props.image,
    image_id: props.image_id,
    shop_phone_number: props.shop_phone_number,
    email_notifications: props.email_notifications,
    show_items_in_comments: props.show_items_in_comments,
    rate_update_email_notifications: props.rate_update_email_notifications,
    sms_notifications: props.sms_notifications,
    deactivate_reason: props.deactivate_reason,
    inhouse_note: props.inhouse_note,
    note: props.note ? props.note : "N/A",
    account_manager: props.account_manager,
    account_manager_id: props.account_manager_id,
    industry_id: props.industry_id,
    locations: props.locations ? props.locations : [],
    online_platform: props.online_platform,
    established_year: props.established_year,
    is_product_delicate: props.is_product_delicate,
    require_bigger_car: props.require_bigger_car,
    pickup_preference: props.pickup_preference,
    orders_per_month: props.orders_per_month,
    order_average_value: props.order_average_value,
    return_products: props.return_products,
    beneficiary_name: props.beneficiary_name,
    official_invoice_needed: props.official_invoice_needed,
    exchange_rate_lbp: props.exchange_rate_lbp,
    marketplace_id: props.marketplace_id,
    is_marketplace_seller: props.is_marketplace_seller,
    currency_rates: props.currency_rates,
    default_currency: props.default_currency,
    preferred_alternative_currency: props.preferred_alternative_currency,
    waybill_customizations: props.waybill_customizations,
    userActivity:props.userActivity
    
  };
}
