export const FETCH_DRIVERS_IN_ZONE_RESOLVED = "FETCH_DRIVERS_IN_ZONE_RESOLVED";
export const FETCH_DRIVERS_RESOLVED = "FETCH_DRIVERS_RESOLVED";
export const FETCH_DRIVER_RESOLVED = "FETCH_DRIVER_RESOLVED";
export const FETCH_DRIVERS_TASKS_RESOLVED = "FETCH_DRIVERS_TASKS_RESOLVED";
export const FETCH_RFID_DROPOFF_LOGS_RESOLVED = "FETCH_RFID_DROPOFF_LOGS_RESOLVED";
export const FETCH_DRIVERS_ROUTE_RESOLVED = "FETCH_DRIVERS_ROUTE_RESOLVED";
export const UPDATE_DRIVER_RESOLVED = "UPDATE_DRIVER_RESOLVED";
export const CHECK_IN_RESOLVED = "CHECK_IN_RESOLVED";
export const CHECK_OUT_RESOLVED = "CHECK_OUT_RESOLVED";
export const CONFIRM_TIME_SHEET_RESOLVED = "CONFIRM_TIME_SHEET_RESOLVED";
export const FETCH_DRIVER_SUBMISSIONS_RESOLVED =
  "FETCH_DRIVER_SUBMISSIONS_RESOLVED";
export const UPDATE_DRIVER_SUBMISSIONS_RESOLVED =
  "UPDATE_DRIVER_SUBMISSIONS_RESOLVED";
export const CHOSEN_DRIVER = "CHOSEN_DRIVER";
export const UPDATE_DRIVERS_TASK_RESOLVED = "UPDATE_DRIVERS_TASK_RESOLVED";
export const FETCH_SUBMISSIONS_RESOLVED = "FETCH_SUBMISSIONS_RESOLVED";
export const FETCH_DRIVERS_NAMES_LIST_RESOLVED =
  "FETCH_DRIVERS_NAMES_LIST_RESOLVED";
export const UPDATE_TASK_COLLECTIONS_RESOLVED =
  "UPDATE_TASK_COLLECTIONS_RESOLVED";
