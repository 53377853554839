import {
  FETCH_TASKS_RESOLVED,
  UPDATE_TASKS_RESOLVED,
  UPDATE_MULTIPLE_TASKS_RESOLVED,
  FETCH_TASK_IMPORTS_RESOLVED,
  FETCH_REVERTED_TASK_IMPORT_RESOLVED,
  FETCH_TASK_RESOLVED,
  FETCH_TASKS_REPORTS_RESOLVED
} from "../ActionTypes/tasks";

const initialState = {
  taskList: [],
  taskReportList: [],
  task: {},
  taskImportsList:[],
  reverted_import: {},
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TASKS_RESOLVED:
      return Object.assign({}, state, {
        taskList: action.payload
      });
    case FETCH_TASK_RESOLVED:
      return Object.assign({}, state, {
        task: action.payload
      });
    case FETCH_TASKS_REPORTS_RESOLVED:
      return Object.assign({}, state, {
        taskReportList: action.payload
      });
    case UPDATE_TASKS_RESOLVED: {
      let taskList = JSON.parse(JSON.stringify(state.taskList));
      taskList.forEach((item, index) => {
        if (item.id == action.payload.id) {
          taskList[index] = action.payload;
        }
      });
      return Object.assign({}, state, {
        taskList
      });
    }
    case UPDATE_MULTIPLE_TASKS_RESOLVED:

      return Object.assign({}, state, { });

      case FETCH_TASK_IMPORTS_RESOLVED:
        return Object.assign({}, state, {
          taskImportsList: action.payload,
        });
      case FETCH_REVERTED_TASK_IMPORT_RESOLVED:
        return Object.assign({}, state, {
          reverted_import: action.payload,
        });
    default:
      return state;
  }
};

export default taskReducer;
